import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';
import { ConsultationObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { FinalCheckMapper, OHConsentSummaryMapper } from '../../../shared/interfaces/dataMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
//TODO: Add logged in user profile.
// import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-consultation-summary',
  templateUrl: './consultation-summary.component.html',
  styleUrls: ['./consultation-summary.component.scss']
})
export class OHConsultationSummaryComponent implements OnInit {

  isLoading = false;
  consultationDetail: ConsultationObject;
  testObject: TestRequestedObject[] = [];
  clientId = '';
  isFailed = false;
  finalCheck: FinalCheckMapper;
  consent: OHConsentSummaryMapper;
  consentRequired = false;
  consentTr: TestRequestedObject;
  // userProfile: KeycloakProfile;

  constructor(private consultationWorkflowService: ConsultationWorkflowService, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private ref: ChangeDetectorRef) {
    this.globalData.updateBackRoute('/appointment');
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getData();
  }

  getData(): void {

    this.globalData.finalCheckVal.subscribe(result => this.finalCheck = result);
    this.globalData.ohConsentVal.subscribe(result => this.consent = result);
    // this.globalData.userProfileVal.subscribe(result => this.userProfile = result);
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);

    setTimeout(() => {
      this.consultationService.getConsultationDetail(this.clientId).subscribe(returnValue => {
        const result = returnValue as ConsultationObject;
        if (result) {
          try {
            let tr = result
              .testsRequested
              .filter(d =>
                d.testMapping &&
                d.testMapping.groupName &&
                d.testMapping.summary &&
                //d.testMapping.detail &&
                d.testMapping.questionnaireId != "00000000-0000-0000-0000-000000000000");
            result.testsRequested = tr;
          }
          catch (e) {

          }
          this.finalCheck.disabled = false;
          this.consultationDetail = result;

          try {
            if (this.consultationDetail.testsRequested) {
              if (this.testObject.length === 0) {
              }

              for (let i = 0; i < this.consultationDetail.testsRequested.length; i++) {
                let tr2 = this.consultationDetail.testsRequested[i];
                let testMapping = tr2.testMapping || { workflowName: '', groupName: '', iconName: '', testMappingId: '', summary: '', workflowId: '', questionnaireId: '', detail: '' };
                let groupName = testMapping.groupName.toLowerCase();

                if (groupName == 'consent') {
                  this.consentRequired = true;
                  this.consentTr = tr2;
                  if (this.consentTr.testsPerformed.length > 0) {
                    this.consent = {
                      notes: this.consentTr.testsPerformed.find((a) => a.result.toLowerCase() == "consentgiven") ? "consent given" : 'Agree to consent to continue',
                      createdDate: this.consentTr.testsPerformed.find((a) => a.result.toLowerCase() == "consentgiven") ? this.consentTr.testsPerformed.find((a) => a.result.toLowerCase() == "consentgiven").createDate : (this.consentTr.testsPerformed.length > 0 ? this.consentTr.testsPerformed[0].createDate : null),
                      disabled: false,
                      groupName: 'Consent',
                      status: this.consentTr.testsPerformed.find((a) => a.result.toLowerCase() == "consentgiven") ? 'finished' : 'refused',
                      required: false
                      // status: this.consentMapper.dataConsent === false || this.consentMapper.examConsent === false ? 'inProgress' : 'finished'
                    };
                  }
                }
                else {
                  if (tr2.testsPerformed.length < 1) {
                    this.testObject.unshift(tr2);
                  } else {
                    this.testObject.push(tr2);
                  }
                }

                this.ref.detectChanges();
              }

              if (this.consentRequired) {

              }
            }
            var vacList = this.testObject.filter(tr => tr.testMapping.groupName.toLowerCase().includes('vaccination'));
            if (vacList.length > 1) {
              for (var k = vacList.length - 1; k > 1; k--) {
                this.testObject.unshift(vacList[k]);
              }
            }
          } catch (ex) {
            this.isLoading = false;

            this.isFailed = true;
          }

          for (let i = 0; i < this.testObject.length; i++) {
            if (this.testObject[i].status != 'finished') { //  &&  (!workflowName.includes('diver') || !workflowName.includes('file') || !workflowName.includes('specialist') || !workflowName.includes('consultation'))) {
              this.finalCheck.disabled = true;
              this.ref.detectChanges();
            }
          }

          var vaccinationStatusString = null;
          var vaccinations = this
            .consultationDetail
            .testsRequested

            .filter((tr) => tr
              .testMapping
              .groupName
              .toLowerCase()
              .includes('vaccination') &&
              tr.testsPerformed.some((tp) => [0, 1, 3, 4, 5, 6, 9].includes(tp.resultValue)));

          vaccinationStatusString = vaccinations.length ? 'Done' : 'Test Not Done';
          if (returnValue.consultationWorkflowStatus) {
            switch (returnValue.consultationWorkflowStatus) {
              case 5: //'finalized':
                this.finalCheck.status = "finished";
                this.finalCheck.completedBy = returnValue.consultationDetailWorkflow.find(x => x.id == returnValue.consultationWorkflowStatusID).statusSetByName;
                this.finalCheck.createdDate = returnValue.consultationDetailWorkflow.find(x => x.id == returnValue.consultationWorkflowStatusID).statusDate;
                break;
              case 3: //'ohnpFileReview':
                this.finalCheck.status = "ohnpFileReview";
                break;
              case 4: //'ompFileReview':
                this.finalCheck.status = "ompFileReview";
                break;
              case 2: //'All completed':
                this.finalCheck.status = "ready";
                break;
              default:

                break;
            }

          } // Need to convert the gobbletygook in testObject.testsPerformed.result to proper cased English
          this.testObject.forEach(to => {
            if (to.testMapping.groupName.toLowerCase().includes('vaccination')) {
              to.testsPerformed.forEach(tp => {
                tp.resultInEnglish = vaccinationStatusString;
              });
            }
            else {

              to.testsPerformed.forEach(tp => {
                switch (tp.resultValue) {
                  case 0:
                    if (tp.questionnaireResult) {
                      if (tp.questionnaireResult == 0) {
                        tp.resultInEnglish = 'Normal';
                      }
                      else {
                        if (tp.questionnaireResult == 1) {
                          tp.resultInEnglish = 'Abnormal'
                        }
                        else {
                          tp.resultInEnglish = 'Normal';
                        }
                      }
                    }
                    else {
                      tp.resultInEnglish = 'Normal';
                    }

                    // Special processing for Respiratory, SHE history, External deployment history and pre-employment history questionnaire
                    if (tp.testMapping.questionnaireId.toUpperCase() == 'ED35B3BE-9415-495C-A929-B37042D89961'
                      || tp.testMapping.questionnaireId.toUpperCase() == '76651826-F059-4D58-AAE6-E5C0F941B3C3'
                      || tp.testMapping.questionnaireId.toUpperCase() == 'BE788A56-55F4-4E0C-9026-96138F665292'
                      || tp.testMapping.questionnaireId.toUpperCase() == 'A7A5CB5A-B677-4572-9BB2-59E078108089') {
                      tp.resultInEnglish = 'Done';
                    }
                    break;
                  case 1:
                    tp.resultInEnglish = 'Abormal';
                    break;
                  case 2:
                    tp.resultInEnglish = 'Test Not Done';
                    break;
                  case 3:
                    tp.resultInEnglish = 'Contraindicated';
                    break;
                  case 4:
                    tp.resultInEnglish = 'Awaiting Test Results';
                    break;
                  case 5:
                    tp.resultInEnglish = 'Not Required';
                    break;
                  case 6:
                    tp.resultInEnglish = 'Consent Given';
                    break;
                  case 7:
                    tp.resultInEnglish = 'Consent Refused';
                    break;
                  case 8:
                    tp.resultInEnglish = 'Test Refused';
                    break;
                  case 9:
                    tp.resultInEnglish = 'Test In Progress';
                    break;
                  default:
                    tp.resultInEnglish = 'Unknown';
                    break;
                }
              });
            }

            if (to.testsPerformed.length) {
              var resultInEnglish = to.testsPerformed[0].resultInEnglish;
              var resultValue = to.testsPerformed[0].resultValue;
              var isVaccination = to.testMapping.groupName.toLowerCase().includes('vaccination');
              var isFailedResult = [1, 3, 7, 8].includes(resultValue);
              var isIntermediateResult = [2, 4, 9].includes(resultValue);
              var isPassResult = [5, 6].includes(resultValue);

              to.testStatusFailed = !isVaccination && (resultInEnglish == 'Abnormal' || isFailedResult);
              to.testStatusIntermediate = (isVaccination && resultInEnglish == 'Test Not Done') || (!isVaccination && isIntermediateResult);
              to.testStatusPass = (isVaccination && resultInEnglish == 'Done') || (!isVaccination && (resultInEnglish == 'Done' || resultInEnglish == 'Normal' || isPassResult));
              to.testStatusError = to.testsPerformed[0].result == null;
            }
          });

          this.isLoading = false;
        } else {
          this.isLoading = false;

          this.isFailed = true;

        }
      });
    }, 1000);

  }

  viewTest(): void {

  }

  previousPage(): void {
    this.isLoading = true;
    this.router.navigate(['/appointment']);
  }

  startTest(tests: TestRequestedObject): void {
    this.isLoading = true;
    ///* console.log(tests) */;
    this.globalData.updateQuestionnaireClientData(tests);
    this.globalData.updateQuestionnaireId(tests.testMapping.questionnaireId);
    const groupName = tests.testMapping.groupName.toLowerCase();
    if (groupName.includes('vaccination')) {
      this.globalData.updateBackRoute('/oh-consultation-summary');
      this.router.navigate(['/vaccines']);

    } else if (groupName.includes('pathology')) {
      this.router.navigate(['/pathology']);
    } else {
      this.router.navigate(['/questionnaire']);
    }
  }

  completeFinalCheck(): void {
    this.isLoading = true;
    if (this.consultationDetail.consultationWorkflowStatus < 2) {
      this.consultationWorkflowService.setConsultationDetailWorkflow(
        this.consultationDetail.id,
        2,
        /*this.userProfile.firstName + ' ' + this.userProfile.lastName*/'locum',
        /*this.userProfile.username*/'locum')
    }
    this.globalData.updateBackRoute('/oh-consultation-summary');
    this.router.navigate(['/stepper-overview']);
  }

  completeConsent(): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireClientData(this.consentTr);
    this.router.navigate(['/oh-consent']);
  }

  reviewTest(tests): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireClientData(tests);
    this.globalData.updateQuestionnaireId(tests.testMapping.questionnaireId);
    const groupName = tests.testMapping.groupName.toLowerCase();
    if (groupName.includes('vaccination')) {
      this.router.navigate(['/vaccines']);
    } else if (groupName.includes('pathology')) {
      this.router.navigate(['/pathology']);
    } else {
      this.globalData.updateReviewQuestionnaireId(tests.testsPerformed[0].reviewId);
      this.router.navigate(['/questionnaire']);
    }
  }

  viewConsent(): void {

  }
}
