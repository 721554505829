import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';
import { ConsultationObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { FinalCheckMapper, OHConsentSummaryMapper } from '../../../shared/interfaces/dataMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
//TODO: Add logged in user profile here.
// import { KeycloakProfile } from 'keycloak-js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eap',
  templateUrl: './eap.component.html',
  styleUrls: ['./eap.component.scss']
})
export class EAPComponent implements OnInit {

  isLoading = false;
  intakeCompleted = false;
  closeOutCompleted = false;
  consultationDetail: ConsultationObject;
  testObject: TestRequestedObject[] = [];
  clientId = '';
  isFailed = false;
  finalCheck: FinalCheckMapper;
  consent: OHConsentSummaryMapper;
  consentRequired = false;
  consentTr: TestRequestedObject;
  demographicsTr: TestRequestedObject;
  referrersTr: TestRequestedObject;
  // userProfile: KeycloakProfile;

  constructor(private consultationWorkflowService: ConsultationWorkflowService, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private ref: ChangeDetectorRef, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.globalData.intakeCompletedVal.subscribe(val => this.intakeCompleted = val);
    this.globalData.closeOutCompletedVal.subscribe(val => this.closeOutCompleted = val);
    this.getData();
  }

  getData(): void {

    setTimeout(() => {


      this.isLoading = false;
    }, 1000);

  }

  previousPage(): void {
    this.isLoading = true;
  }

  viewDemo(): void {

    const dialogRef = this.dialog.open(EAPDemoDialogComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((data) => {

    });
  }

  viewCaseManager(): void {

    const dialogRef = this.dialog.open(EAPCaseManagerDialogComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe((data) => {

    });
  }

  viewReferrer(): void {

    const dialogRef = this.dialog.open(EAPReferrerDialogComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe((data) => {

    });
  }

  completeIntake(): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireId('52B3CB77-31F1-436C-8671-1404BDE39C0D');
    this.router.navigate(['/eap-intake']);
  }
  completeCloseOut(): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireId('CCA6DA0E-3006-44A1-A99A-0552C99578B5');
    this.router.navigate(['/eap-close-out']);
  }
}

@Component({
  selector: 'eap-demo-dialog',
  templateUrl: 'eap-demo-dialog.component.html',
})
export class EAPDemoDialogComponent {

  constructor(public dialogRef: MatDialogRef<EAPDemoDialogComponent>, private router: Router) { }

  ok() {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'eap-referrer-dialog',
  templateUrl: 'eap-referrer-dialog.component.html',
})
export class EAPReferrerDialogComponent {

  constructor(public dialogRef: MatDialogRef<EAPReferrerDialogComponent>, private router: Router) { }

  ok() {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'eap-casemanager-dialog',
  templateUrl: 'eap-casemanager-dialog.component.html',
})
export class EAPCaseManagerDialogComponent {

  constructor(public dialogRef: MatDialogRef<EAPCaseManagerDialogComponent>, private router: Router) { }

  ok() {
    this.dialogRef.close();
  }
}
