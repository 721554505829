import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

interface Dropdown {
  id: string,
  description: string
}
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  //For reference:https://www.youtube.com/watch?v=zUp3Unc-ynM

  isLoading: boolean = false;
  parentData: any;
  form: FormGroup;
  filteredOptions: Dropdown[] = [];
  allData: Dropdown[] = [];
  showButton: boolean = true;

  constructor(private ref: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.parentData = this.data;
    this.populateFilter();
    this.createForm();
    this.isLoading = false;
  }

  populateFilter() {
    if (this.parentData.mainData) {
      this.parentData.mainData.forEach(element => {
        var obj: Dropdown = { id: element.id, description: `${element.provinceName} - ${element.stationName} (${element.eventDate.slice(0, 10)})` }
        if (!this.allData.some(d => d.id.toLowerCase() == obj.id.toLowerCase())) {
          this.allData.push(obj)
        }
      });
      this.allData.sort((a, b) => a.description.localeCompare(b.description))
      this.filteredOptions = this.allData;
    }
  }

  lButClickAction() {
    this.isLoading = true;
    if (this.parentData.lButNavUrl) {
      if (this.parentData.lButNavUrl == 'calendar') {
        this.ref.close(this.parentData.lButNavUrl);
      } else if (this.parentData.lButNavUrl == 'cancel') {
        this.ref.close('cancel');
      } else {
        this.router.navigate([`${this.parentData.lButNavUrl}`])
        this.ref.close();
      }
    }
    this.isLoading = false;
  }
  rButClickAction() {
    this.isLoading = true;
    if (this.parentData.rButNavUrl) {
      if (this.parentData.rButNavUrl == 'calendar') {
        this.ref.close(this.parentData.rButNavUrl);
      } else if (this.parentData.rButNavUrl == 'back') {
        this.ref.close();
      } else if (this.parentData.rButNavUrl == 'cancel') {
        this.ref.close('cancel');
      } else {
        this.router.navigate([`${this.parentData.rButNavUrl}`])
        this.ref.close();
      }
    }
    if (this.form.get('selectedSearchDropdownID').value) {
      var selectedForEdit = {};
      this.parentData.mainData.forEach(element => {
        if (this.form.get('selectedSearchDropdownID').value == element.id) {
          this.form.get('selectedSearchDropdownName').setValue(`${element.provinceName} - ${element.stationName}`);
          if (this.parentData.eventEdit) {
            selectedForEdit = element;
          }
        }
      });
      //send entire event through
      if (this.parentData.eventEdit) {
        this.ref.close(selectedForEdit);
      } else {
        this.ref.close(this.form.value);
      }
    } else {
      this.form.get("selectedSearchDropdownID").setErrors({ ...(this.form.get("selectedSearchDropdownID").errors || {}), 'Invalid': 'Invalid selection.' });
    }
    this.isLoading = false;
  }
  createForm() {
    this.form = this.fb.group({
      selectedSearchDropdownID: null,
      selectedSearchDropdownName: null,
    })
  }

  filterSearchDropdownValues(value: string) {

    if (value && value.length > 0) {
      let result = this.select(value.toLocaleLowerCase());
      this.filteredOptions = result;
    } else if (this.filteredOptions.length > 0) {
      this.populateFilter();
      //this.filteredOptions = this.populateFilter();
    }

  }

  public select(query: string): Dropdown[] {
    let result: Dropdown[] = [];
    for (let a of this.allData) {
      if (a.description.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
}
