import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurse-intervention',
  templateUrl: './nurse-intervention.component.html',
  styleUrls: ['./nurse-intervention.component.scss']
})
export class NurseInterventionComponent implements OnInit {

  nurseInterventions = [
    { id: 0, name: 'Health education', checked: false},
    { id: 1, name: 'Follow up', checked: false},
    { id: 2, name: 'Referred', checked: false},
    { id: 3, name: 'Medication prescribed', checked: false},
    { id: 4, name: 'Medication administered on site', checked: false},
  ];

  cofOption = [
    { id: 0, name: 'Fit for duty as per job description ', follow: false},
    { id: 1, name: 'Fitness for duty - subject to further review', follow: false},
    { id: 2, name: 'Fit for duty subject to specific exclusions / job modifications', follow: true},
    { id: 3, name: 'Temporarily unfit for duty', follow: false},
    { id: 4, name: 'Unfit for duty', follow: false}
  ];

  cofFollowUp = {
    type: 'none',
    questions: [
      {name: ''}
    ]
  };

  isLoading = false;
  cof: string;
  cofFollow = [];
  option: any;

  constructor() { }

  ngOnInit(): void {
  }

  getFollowQuestions(): void {
    //console.log(this.cof);

    //console.log('Fit for duty subject to specific exclusions / job modifications');

    const isMatch = this.cof === 'Fit for duty subject to specific exclusions / job modifications' ? true : false;
    ///* console.log(isMatch) */;

    switch (this.cof) {
      case 'Fit for duty subject to specific exclusions / job modifications':
        ///* console.log('1') */;
        this.cofFollowUp = {
          type: 'multiple',
          questions: [
            {name: 'Noise zone'},
            {name: 'Respiratory risk zone'},
            {name: 'Heat stress zone'},
            {name: 'Driving – Codes (A1, A, B or EB)'},
            {name: 'Safety critical work'},
            {name: 'Driving – Codes (A1, A, B or EB)'},
            {name: 'Other'},
          ]
        };
        break;

      case 'Temporarily unfit for duty':
        ///* console.log('2') */;
        this.cofFollowUp = {
          type: 'single',
          questions: [
            {name: 'Non work related condition'},
            {name: 'Work related condition'}
          ]
        };
        break;

      case 'Unfit for duty':
        ///* console.log('3') */;
        this.cofFollowUp = {
          type: 'single',
          questions: [
            {name: 'Non work related condition'},
            {name: 'Work related condition'}
          ]
        };
        break;

      default:
        this.cofFollowUp = {
          type: 'none',
          questions: [
            {name: ''}
          ]
        };
    }
  }

  getValue(): void {
    //console.log(this.cofFollow);
  }
}
