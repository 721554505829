import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { WellnessHivConsentMapper } from 'src/app/shared/interfaces/consentMapper';

import { SignatureFieldComponent } from '../../../shared/components/signature-field/signature-field.component';
import { TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

@Component({
  selector: 'app-wellness-hiv-consent',
  templateUrl: './hiv-consent.component.html',
  styleUrls: ['../consent/consent.component.scss']
})
export class WellnessHIVConsentComponent implements OnInit {
  public form: FormGroup;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;

  sig1Border = false;
  sig2Border = false;
  userId: string = null;

  consent: boolean = null;

  showValidationErrors = false;

  constructor(private fb: FormBuilder, private snackBar: MatSnackBar, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private questionnaireService: QuestionnaireService) {
    this.form = fb.group({
      consentPaperForm: ['', Validators.required],
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required]
    });

    this.globalData.userIdVal.subscribe(d => this.userId = d);
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    console.log('Resizing signature pad canvas to suit container size');
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
    sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
  }
  get hasConsent() {
    return this.form.get('consentPaperForm').value;
  }
  public setOptions() {
    this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.sigs.last.signaturePad.set('penColor', 'rgb(255, 255, 0)');
    this.sigs.last.signaturePad.set('backgroundColor', 'rgb(0, 0, 255)');
    this.sigs.last.signaturePad.clear();
    // clearing is needed to set the background colour
  }

  public clear() {
    this.sigs.first.clear();
    this.sigs.last.clear();
  }

  signatureImg: string;
  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 250,
    canvasHeight: 200
  };

  agreeList = [
    { name: 'Yes', id: 0, value: true },
    { name: 'No', id: 1, value: false }
  ];


  isLoading = false;
  showError = false;
  clientID = '';
  userRoles: any[] = [];
  userRole = '';
  consentMapper: WellnessHivConsentMapper;
  testRequested: TestRequestedObject;
  testPerformed: any;
  urVal = {
    Clinician: 1,
    Technician: 2,
    Manager: 3,
    Reception: 4,
    Generic: 5
  };
  showDownload = false;
  downloadButton = null;
  href = "";
  download = "";
  consentRequired: boolean = false;


  ngOnInit(): void {
    this.globalData.userRolesVal.subscribe(result => {
      this.userRoles = result as any[];
      this.userRole = this.userRoles.filter(r => r.indexOf("Perigon_") > -1).map(r => r.replace("Perigon_", "")).sort((a, b) => this.urVal[a] === this.urVal[b] ? 0 : (this.urVal[a] < this.urVal[b] ? -1 : 1))[0];
    });
    this.globalData.updateBackRoute('/wellness-consultation-summary');
    this.globalData.selectedClientVal.subscribe(result => this.clientID = result);
    this.globalData.questionnaireClientDataVal.subscribe(tests => { this.testRequested = tests; });

    this.isLoading = true;
    this.consultationService.getConsultationDetail(this.clientID).subscribe(result => {
      /* console.log(result) */;

      if (result) {
        this.consentMapper = {
          member_FullName: `${(result.patientDetail.fullNames || '')} ${(result.patientDetail.surname || '')}`,
          member_IdNumber: result.patientDetail.rsaidnumber,
          consultationDetailId: this.clientID,
          member_Signature: undefined,
          member_SignatureDate: undefined,
          examiner_Signature: undefined,
          examiner_SignatureDate: undefined,
          consent: false,
          testPerformedId: undefined,
        };
        this.isLoading = false;
      }
    });
  }

  drawComplete(sig): void {
    if (sig.includes('emp1')) {
      this.consentMapper.examiner_Signature = this.signaturePad.toDataURL();
      this.consentMapper.examiner_SignatureDate = new Date();

    } else if (sig.includes('client')) {
      this.consentMapper.member_Signature = this.signaturePad.toDataURL();
      this.consentMapper.member_SignatureDate = new Date();
    }
  }

  drawStart(): void {
    console.log('begin drawing');
  }

  clearSignature(): void {
    this.signaturePad.clear();
  }

  savePad(): void {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }

  submit(sectionOne, sectionTwo): void {

    this.sig1Border = false;
    this.sig2Border = false;

    this.showValidationErrors = true;
    this.consentRequired = true;

    this.consentMapper.examiner_SignatureDate = this.consentMapper.examiner_SignatureDate || new Date();
    this.consentMapper.member_SignatureDate = this.consentMapper.member_SignatureDate || new Date();
    this.consentMapper.member_Signature = this.sigs.first.signaturePad.toDataURL();
    this.consentMapper.examiner_Signature = this.sigs.last.signaturePad.toDataURL();
    if (this.consent != null
      && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()
    ) {
      this.showError = false;
      let options: MatSnackBarConfig = { duration: 2500 };
      let snackBarRef = this.snackBar.open('Saving consent...', null, options);
      let username = '';
      // this.globalData.userProfileVal.subscribe(val => console.log(val));
      this.globalData.userFullNameVal.subscribe(val => username = val);
      const clinicianId = this.userId;
      this.testPerformed = {
        active: true,
        clinicianId: clinicianId,
        completed: true,
        consultationId: this.clientID,
        createDate: new Date(),
        createName: username,
        notes: '',
        result: this.consent ? 6 : 7,
        reviewId: '00000000-0000-0000-0000-000000000000',
        testMappingId: this.testRequested.testMappingId,
        testRequestedId: this.testRequested.id,
        changeDate: null,
        changeName: null,
        subTestMappingId: null
      };

      this.consentMapper.consultationDetailId = this.clientID;
      this.consentMapper.consent = this.hasConsent;

      this.consentMapper.examiner_SignatureDate = this.consentMapper.examiner_SignatureDate || new Date();
      this.consentMapper.member_SignatureDate = this.consentMapper.member_SignatureDate || new Date();
      this.consentMapper.member_Signature = this.sigs.first.signaturePad.toDataURL();
      this.consentMapper.examiner_Signature = this.sigs.last.signaturePad.toDataURL();
      if (!this.hasConsent) {
        this.consentMapper.member_Signature = this.sigs.first.signaturePad.toDataURL();
        this.consentMapper.examiner_Signature = this.sigs.last.signaturePad.toDataURL();
      }
      this.isLoading = true;
      this.questionnaireService.postTestPerformed(this.testPerformed).subscribe(postResult => {
        this.testRequested.status = 3;
        this.testRequested.changeDate = new Date();
        this.testRequested.changeName = 'Perigon-UI';
        this.questionnaireService.putTestStatus(this.testRequested).subscribe(updateStatus => {
          this.isLoading = false;
          /* console.log(updateStatus) */
          this.consultationService.postWellnessHIVConsent(this.consentMapper).subscribe(postResult => {
            this.router.navigate(['/wellness-consultation-summary']);
          });
        });
      });
    } else {
      this.showError = true;
      if (!this.hasConsent) {
        this.sig1Border = this.sigs.first.signaturePad.isEmpty();
        this.sig2Border = this.sigs.last.signaturePad.isEmpty();
      }
      //this.sig1Border = this.sigs.first.signaturePad.isEmpty();
      //this.sig2Border = this.sigs.last.signaturePad.isEmpty();
    }
  }

  back(): void {
    this.router.navigate(['/wellness-consultation-summary']);
  }
}
