
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailAddressValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (!email) {
      // Return null if the control value is empty
      return null;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      // Return an error object if the email address is invalid
      return { invalidEmail: true };
    }

    // Return null if the email address is valid
  return null;
}
