import { DatePipe, ViewportScroller, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarOptions, DateSelectArg, DatesSetArg, DayCellContentArg, EventApi, EventClickArg, EventContentArg, EventMountArg, ToolbarInput } from '@fullcalendar/core';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { KeycloakService } from 'keycloak-angular';
import { CancellationDialogComponent } from 'src/app/shared/components/cancellation-dialog/cancellation-dialog.component';
import { EventStatusSelectorComponent } from 'src/app/shared/components/event-status-selector/event-status-selector.component';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { CustomNumberFormatDirective } from 'src/app/shared/directives/custom-number-format.directive';
import * as Enums from 'src/app/shared/enums';
import { ClinicMappingObject } from 'src/app/shared/interfaces/clinicMappingMapper';
import { EventSubmissionCoordinator } from 'src/app/shared/interfaces/event-submission-coordinator';
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper';
import { SiteCoordinator } from 'src/app/shared/interfaces/site-coordinator';
import { CorporateWellnessEventBookingForm } from 'src/app/shared/interfaces/wellness-event-booking';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { ClinicianService } from 'src/app/shared/services/clinician.service';
import { CorporateWellnessDayEventBookingService } from 'src/app/shared/services/corporate-wellness-day-event-booking.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ListService } from 'src/app/shared/services/list.service';
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator';
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator';

//import { INITIAL_EVENTS, createEventId } from './event-utils';

interface ExistingEvents {
  id: string,
  title: string,
  start: string,
  end: string,
  eventStatusId: string
}

@Component({
  selector: 'app-corporate-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class CorporateWellnessBookingComponent implements OnInit, AfterViewInit {
  upComingEvents: EventInput[] = [];
  bookingStage: string = '';
  pageYoffset = 0;
  wellnessEventType: Enums.WELLNESS_EVENT_TYPE;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }
  @ViewChild(FormGroupDirective)
  formDirective!: FormGroupDirective;
  calendarVisible = true;
  calendarStartDate: Date = new Date();
  calendarMaxDate: Date = new Date();
  isBooked: boolean = false;
  calendarOptions: CalendarOptions;
  eventTimeSelection: string = "event";
  shiftWorkerTimeSelection: string = "shiftWorker";
  currentEvents: EventApi[] = [];
  eventFormMain: FormGroup;
  //expansion panels
  @ViewChild('eventDetailsPanel') eventDetailsPanel: MatExpansionPanel;
  @ViewChild('eventVenuePanel') eventVenuePanel: MatExpansionPanel;
  @ViewChild('siteCoordinatorPanel') siteCoordinatorPanel: MatExpansionPanel;
  expandEventVenue: boolean = false;
  expandEventDetails: boolean = false;

  allocatedEquipment: ListItemObject[];
  wellnessAssessmentOptions: ListItemObject[];
  groupProducts: ListItemObject[];
  selectedProvinceTownsAndStations: ListItemObject[];
  serviceProviders: ClinicMappingObject[];
  serviceTypes: ListItemObject[];
  eventTypes: ListItemObject[];
  healthPractitionerTypes: ListItemObject[];
  eventStatuses: ListItemObject[];
  paymentOptions: ListItemObject[];
  siteCoordinator: SiteCoordinator = new SiteCoordinator();
  submissionCoordinator: EventSubmissionCoordinator = new EventSubmissionCoordinator();


  model: CorporateWellnessEventBookingForm = new CorporateWellnessEventBookingForm();
  ToggleForm: boolean = false;
  isSubmit: boolean = false;
  isLoading: boolean = false;
  showError: boolean = false;
  showStation: boolean = false;
  validEventTimeInputs: boolean = null;
  validShiftWorkerTimeInputs: boolean = null;
  showTown: boolean = false;
  showForm = false;
  siteCoordinatorValidation: boolean = false;
  invalidForm: boolean = false;
  validIDLength: boolean = true;
  filteredOptions: ListItemObject[] = [];
  allUpcomingWellnessEvents: CorporateWellnessEventBookingForm[] = [];
  showEvents: boolean = false;
  disableEventTimeInput: boolean = false;
  existingEvents: ExistingEvents[] = [];
  captureCoordinatorGUID: string = '';
  startTime: string;
  endTime: string;
  editMode: boolean = false;
  myBookings: boolean = false;

  filterOption: string[] = [];

  ngAfterViewInit(): void {
    this.setupEventHarnessObserver();
  }

  get cancellable(): boolean {
    if (!this.editEvent) { return false }
    return [Enums.EVENT_STATUS_IDS.CANCELLED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(this.editEvent.eventStatusId) == -1;
  }

  maxMarketingCampaignDate: string;

  get bookedEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];

    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() == Enums.EVENT_STATUS_IDS.BOOKED) {
        matchingEvents.push(event);
      }
    }
    return matchingEvents;
  }

  get cancelledEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];

    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() == Enums.EVENT_STATUS_IDS.CANCELLED) {
        matchingEvents.push(event);
      }
    }

    return matchingEvents;
  }

  get plannedEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];
    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() ==
        Enums.EVENT_STATUS_IDS.PLANNED) {
        matchingEvents.push(event);
      }
    }

    return matchingEvents;
  }

  get executedEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];

    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() ==
        Enums.EVENT_STATUS_IDS.EXECUTED) {
        matchingEvents.push(event);
      }
    }

    return matchingEvents;
  }



  get events(): ExistingEvents[] {
    if (this.allUpcomingWellnessEvents.length > 0) {
      let ev = this.allUpcomingWellnessEvents;
      if (this.filterOption.length && this.filterOption.every(d => d != 'All')) {
        ev = ev.filter(d => this.filterOption.map(fo => fo.toLowerCase()).indexOf(d.eventStatusId?.toLowerCase()) > -1)
      }

      return ev.map((e: any) => ({
        id: e.id,
        title: `${e.companyName}`,
        start: this.dateFormat(e.eventDate, e.eventStartTime),
        end: new Date(e.eventDate).getMonth() == 0 && new Date(e.eventDate).getDate() == 2 ? this.dateFormat(new Date(new Date(e.eventDate).setDate(new Date(e.eventDate).getDate() + 1)).toDateString(), e.eventEndTime) : this.dateFormat(e.eventDate, e.eventEndTime),
        eventStatusId: e.eventStatusId?.toLowerCase()
      }))
    }
    return [];
  }

  get headerToolbar(): ToolbarInput {
    return {
      left: 'next today prev',
      center: 'title',
      right: `${this.bookingStage == 'create' ? `editButton` : `searchButton`} ${this.bookingStage == 'create' ? `filterButton` : `filterButton`} dayGridMonth ${this.bookingStage == 'create' ? `timeGridDay` : `listWeek`}`
    };
  }

  get ExpectedParticipentsSum() {

    return this.eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').value + this.eventFormMain.get('employeeDetails.amountOfExpectedSchemeParticipants').value;
  }

  get SumBoolean(): boolean {

    return this.ExpectedParticipentsSum > this.eventFormMain.get('employeeDetails.amountOfEmployeesOnSite');
  }


  calendarAPI: any;

  constructor(private http: HttpClient,
    private el: ElementRef<Element>,
    private snackBar: MatSnackBar,
    private wellnessDayEventBookingService: CorporateWellnessDayEventBookingService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private listService: ListService,
    private scroll: ViewportScroller,
    private globalData: GlobalDataService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private clinicService: ClinicService,
    private keycloak: KeycloakService,
    private clinicianService: ClinicianService
  ) {
    this.globalData.userIdVal.subscribe(val => { this.captureCoordinatorGUID = val })
    this.createForm();
    this.bookingStage = this.router.getCurrentNavigation().extras.state.mode;
    //this.wellnessEventType = this.router.getCurrentNavigation().extras.state.wellnessEventType;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.listService.get(Enums.LISTS.ALLOCATED_EQUIPMENT).subscribe((data) => {
      this.allocatedEquipment = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.WELLNESS_ASSESSMENT_OPTIONS).subscribe((data) => {
      this.wellnessAssessmentOptions = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.GROUP_PRODUCTS).subscribe((data) => {
      this.groupProducts = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.EVENT_ATMOSPHERES).subscribe((data) => {
      this.eventTypes = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.SERVICE_TYPES).subscribe((data) => {
      this.serviceTypes = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.EVENT_STATUSES).subscribe((data) => {
      this.eventStatuses = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.PAYMENT).subscribe((data) => {
      this.paymentOptions = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.HEALTH_PRACTITIONER_TYPES).subscribe((data) => {
      this.healthPractitionerTypes = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.clinicService.getServiceProviders().subscribe((data) => {
      this.serviceProviders = data;
    })
    this.globalData.updateBackRoute(['/wellness-event/corporate/landing']);
    this.showEvents = false;

    if (!this.captureCoordinatorGUID) {
      this.keycloak.loadUserProfile().then(userProfile => {
        let instance = this.keycloak.getKeycloakInstance();
        this.clinicianService.getClinicianByKeycloakId(instance.subject.toString()).subscribe(clinician => {
          this.captureCoordinatorGUID = clinician.id;
          this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe((data) => {
            this.loadEvents(data);
          })
        })
      })
    }
    else {
      this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe((data) => {

        this.loadEvents(data);
      })
    }
    //this.isLoading = false;
  }

  private eventHarnessObserver!: MutationObserver;
  @ViewChild('calendar') calendarEl!: ElementRef;

  get totalEvents() { return this.events.length; };

  populateData(data: any) {
    data.forEach(res => {
      let today = new Date();

      //if (new Date(res.eventDate) > new Date(today.setDate(today.getDate() - 21))) {
      if (this.bookingStage != 'create') {
        if ([Enums.EVENT_STATUS_IDS.PLANNED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(res.eventStatusId) > -1) {
          this.allUpcomingWellnessEvents.push(res);
        }

      }
      else {
        this.allUpcomingWellnessEvents.push(res);
      }
      //}
    });

  }

  loadEvents(data: any) {

    this.populateData(data);;

    if (this.allUpcomingWellnessEvents.length != 0) {
      this.calendarOptions = {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
        ],
        customButtons: {
          searchButton: {
            text: 'Search',
            hint: 'Search for event',
            click: () => this.searchWellnessEvents()
          },
          editButton: {
            text: 'Search',
            hint: 'Search for booking',
            click: () => this.editWellnessEvents()
          },
          filterButton: {
            text: 'Filter',
            hint: 'Filter by event Status',
            click: () => this.filterWellnessEvents()
          }
        },
        headerToolbar: this.headerToolbar,
        initialView: 'dayGridMonth',
        //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventContent: function (arg: EventContentArg) {
          let start = new DatePipe('en-US').transform(arg.event.start, 'hh:mm a');
          let end = new DatePipe('en-US').transform(arg.event.end, 'hh:mm a');

          let className = "";
          if (arg.event.extendedProps.eventStatusId) {
            switch (arg.event.extendedProps.eventStatusId.toUpperCase()) {
              case "DDBB6282-ECAA-455B-9357-42CE9CA44325": {
                className = 'event-booked';
                break;
              }
              case "256B8A8E-77FC-4E9D-AC3D-43302C442192": {
                className = 'event-planned';
                break;
              }
              case Enums.EVENT_STATUS_IDS.EXECUTED: {
                className = 'event-executed';
                break;
              }
              case "377D722B-7400-4160-98E8-F7084CE2A7E6": {
                className = 'event-cancelled';
                break;
              }
              case "120AD178-D3C5-4361-9547-484D8E6B3A35": {
                className = 'event-executed';
                break;
              }
              default: {
                className = "";
                break;
              }
            }
          }

          let html = `
              <div class="fc-event-main ${className}">
                <div class="fc-event-time">${start} - ${end}</div>
                <div class="fc-event-title">${arg.event.title}</div>
              </div>
            `;




          return { html: html };
        },
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        events: this.events,
        validRange: {
          start: formatDate(this.calendarStartDate.setDate(this.calendarStartDate.getDate() - 200), 'yyyy-MM-dd', 'en').toString(),
          end: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd', 'en').toString()
        }
      };
    } else {
      this.calendarOptions = {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
        ],
        customButtons: {
          filterButton: {
            text: 'Filter',
            hint: 'Filter by event status',
            click: () => this.filterWellnessEvents()
          },
          searchButton: {
            text: 'Search',
            hint: 'Search for event',
            click: () => this.searchWellnessEvents()
          },
          editButton: {
            text: 'Search',
            hint: 'Search for booking',
            click: () => this.editWellnessEvents()
          }
        },
        headerToolbar: this.headerToolbar,
        initialView: 'dayGridMonth',
        //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,

        eventContent: function (arg: EventContentArg) {
          let start = new DatePipe('en-US').transform(arg.event.start, 'hh:mm a');
          let end = new DatePipe('en-US').transform(arg.event.end, 'hh:mm a');

          let className = "";
          if (arg.event.extendedProps.eventStatusId) {
            switch (arg.event.extendedProps.eventStatusId.toLowerCase()) {
              case Enums.EVENT_STATUS_IDS.BOOKED: {
                className = 'event-booked';
                break;
              }
              case Enums.EVENT_STATUS_IDS.PLANNED: {
                className = 'event-planned';
                break;
              }
              case Enums.EVENT_STATUS_IDS.EXECUTED: {
                className = 'event-executed';
                break;
              }
              case Enums.EVENT_STATUS_IDS.CANCELLED: {
                className = 'event-cancelled';
                break;
              }
              default: {
                className = "";
                break;
              }
            }
            arg.event.setProp('classNames', [className]);
          }


          let html = `
              <div class="fc-event-main ${className}">
                <div class="fc-event-time">${start} - ${end}</div>
                <div class="fc-event-title">${arg.event.title}</div>
              </div>
            `;
          return { html: html };

        },
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        eventSources: [
          { events: this.bookedEvents },
          {
            events: this.cancelledEvents,
            color: 'black',     // an option!
            textColor: 'yellow' // an option!
          },
          {
            events: this.plannedEvents,
            color: 'blush',     // an option!
          },
          { events: this.executedEvents, color: '#a2d298' }
        ],
        validRange: {
          start: formatDate(this.calendarStartDate.setDate(this.calendarStartDate.getDate() - 200), 'yyyy-MM-dd', 'en').toString(),
          end: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd', 'en').toString()
        },
        /* you can update a remote database when these fire:
        eventAdd:
        },
        */
      };
    }
    this.isLoading = false;
    this.showEvents = true;
    this.setupEventHarnessObserver();
  }
  private setupEventHarnessObserver(): void {
    // Create a new MutationObserver
    this.eventHarnessObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((addedNode) => {
            if (addedNode instanceof HTMLElement && (addedNode.classList.contains('fc-daygrid-event-harness') || addedNode.classList.contains('fc-h-event'))) {
              // Handle the newly added .fc-daygrid-event-harness element
              addedNode.classList.remove('event-booked', 'event-planned', 'event-executed', 'event-cancelled');
              ['event-booked', 'event-planned', 'event-cancelled', 'event-executed'].forEach(d => {
                if (addedNode.children[0].children[0].classList.contains(d)) {
                  addedNode.classList.add(d);
                }
              });
            }
          });
        }
        if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement && mutation.target.classList.contains('fc-event-main')) {
          const element = mutation.target;
          const grandad = element.parentElement.parentElement;
          grandad.classList.remove('event-booked', 'event-planned', 'event-executed', 'event-cancelled');

          // Check for changes in class names
          ['event-booked', 'event-planned', 'event-cancelled', 'event-executed'].forEach(d => {
            if (element.classList.contains(d)) {
              grandad.classList.add(d);
            }
          });
        }
        if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement && mutation.target.classList.contains('fc-h-event')) {
          const element = mutation.target;
          element.classList.remove('event-booked', 'event-planned', 'event-executed', 'event-cancelled');

          // Check for changes in class names
          ['event-booked', 'event-planned', 'event-cancelled', 'event-executed'].forEach(d => {
            if (element.children[0].children[0].classList.contains(d)) {
              element.classList.add(d);
            }
          });
        }
      });
    });

    // Start observing changes in the container element (where FullCalendar events are rendered)
    this.eventHarnessObserver.observe(this.el.nativeElement, {
      childList: true, // Watch for changes in the child nodes
      subtree: true,   // Watch for changes in all descendant nodes
      attributes: true,
      attributeFilter: ['class']
    });
  }
  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9\s]/g, '');
  }
  refreshBookings(useFilter: boolean) {
    this.myBookings = useFilter;

    // this.calendarOptions.eventSources = [
    //   { events: this.bookedEvents },
    //   {
    //     events: this.cancelledEvents,
    //     color: 'black',     // an option!
    //     textColor: 'yellow' // an option!
    //   },
    //   {
    //     events: this.plannedEvents,
    //     color: 'black',     // an option!
    //     textColor: 'yellow' // an option!
    //   },
    //   { events: this.executedEvents, color: '#a2d298' }
    // ];

    this.calendarOptions.events = this.events;
    this.calendarOptions.headerToolbar = this.headerToolbar;
  }
  dateFormat(day: string, time: string): string {
    return new DatePipe('en-US').transform(new Date(`${formatDate(day, 'yyyy-MM-dd', 'en')}T${time}`), 'yyyy-MM-ddTHH:mm');
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    if (this.bookingStage == 'create') {
      this.editMode = false;
      this.showForm = true;
      this.calendarAPI = selectInfo.view.calendar;
      this.showEvents = false;
      document.getElementById("eventForm").style.display = "block";

      if (selectInfo.allDay) {
        //month selection
        this.model.eventDate = new DatePipe('en-US').transform(selectInfo.startStr, "yyyy-MM-ddTh:mm:ss.sss'Z'");
        this.eventFormMain.get("date").setValue(formatDate(this.model.eventDate, 'yyyy-MM-dd', 'en'));
        this.eventFormMain.get("eventStartTime").patchValue("07:00:00");
        this.eventFormMain.get("eventEndTime").patchValue("17:00:00");
        this.eventFormMain.get("date").disable();
      } else {
        this.disableEventTimeInput = true;
        this.startTime = new DatePipe('en-US').transform(selectInfo.startStr, "HH:mm");
        this.endTime = new DatePipe('en-US').transform(selectInfo.endStr, "HH:mm");
        //day + time selection
        this.datePipe.transform(selectInfo.startStr, 'yyyy-MM-dd');
        this.model.eventDate = new DatePipe('en-US').transform(selectInfo.startStr, "yyyy-MM-ddTHH:mm:ss.sss'Z'");
        this.eventFormMain.get("date").patchValue(formatDate(selectInfo.startStr, 'yyyy-MM-dd', 'en'));
        this.eventFormMain.get("eventStartTime").patchValue(this.startTime);
        this.eventFormMain.get("eventEndTime").patchValue(this.endTime);
        this.eventFormMain.get("eventStartTime").disable();
        this.eventFormMain.get("eventEndTime").disable();
      }
      this.scroll.scrollToPosition([0, 0]);
    }

  }
  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }
  searchWellnessEvents() {
    var _temp = this.dialog.open(PopupComponent, {
      width: '55%',
      data: {
        title: 'Register members',
        rButTitle: 'Continue',
        lButTitle: 'Back to calendar',
        lButNavUrl: 'calendar',
        mainData: this.allUpcomingWellnessEvents,//try the events listed under the person
        eventSearch: true,
      }
    })
    _temp.afterClosed().subscribe(data => {
      if (data.selectedSearchDropdownID) {
        this.router.navigate(['../registration', data.selectedSearchDropdownID, data.selectedSearchDropdownName], { relativeTo: this.route })
      }
    })
  }
  // Your calling component

  filterWellnessEvents() {
    const dialogRef = this.dialog.open(EventStatusSelectorComponent, {
      width: '55%',
    });

    dialogRef.componentInstance.statusSelected.subscribe((selectedStatus: string[]) => {
      // Use the selectedStatus in your logic
      this.filterOption = selectedStatus;
      this.refreshBookings(true)


      dialogRef.close(selectedStatus);
      // Perform additional logic or method calls using the selectedStatus
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle dialog closure if needed
    });
  }



  editWellnessEvents() {
    var _temp = this.dialog.open(PopupComponent, {
      width: '55%',
      data: {
        title: 'Edit booking',
        rButTitle: 'Continue',
        lButTitle: 'Back to calendar',
        lButNavUrl: 'calendar',
        mainData: this.allUpcomingWellnessEvents,//this should be returning filtered events
        eventSearch: true,
        eventEdit: true
      }
    })
    _temp.afterClosed().subscribe((data: CorporateWellnessEventBookingForm) => {
      if (data && data.id) {
        this.editBooking(data);
        this.showEvents = false;
        document.getElementById("eventForm").style.display = "block";
      }
    })
  }
  //reload events after the filter
  editEvent: any;

  private editBooking(data: CorporateWellnessEventBookingForm) {
    this.editMode = true;
    this.isLoading = true;
    this.editEvent = data;
    [
      "id",
      "date",
      "company.companyName",
      "company.accountExecutive",
      "company.companyAccountExecutiveName",
      "company.companyAccountExecutiveSurname",
      "company.companyAccountExecutiveCellphone",
      "company.companyAccountExecutiveEmail",
      "company.companyFacilityManagerName",
      "company.companyFacilityManagerSurname",
      "company.companyFacilityManagerCellphone",
      "company.companyFacilityManagerEmail",
      "assessments",
      "eventStartTime",
      "eventEndTime",
      "eventStatusId",
      "serviceType.serviceTypeId",
      "serviceType.serviceTypeName",
      "shiftWorkersStartTime",
      "shiftWorkersEndTime",
      "address",
      "paymentOptions",
      "specialLandmarks",
      "employeeDetails.amountOfEmployeesOnSite",
      "employeeDetails.amountOfExpectedEventParticipants",
      "employeeDetails.amountOfExpectedSchemeParticipants",
      "employeeDetails.amountOfExpectedNonSchemeParticipants",
      "eventAtmosphere.atmosphereId",
      "eventAtmosphere.atmosphereType",
      "eventAtmosphere.venueOpenPlan",
      "eventAtmosphere.privateRoomsAvailable",
      "eventAtmosphere.numberOfGazebosRequired",
      "eventAtmosphere.numberOfMobileUnitsRequired",
      "isGazebosRequisitioned",
      "specialRequirements",
      "venueRestrictions",
      "dropOffFacilityDetails",
      "eventVenueDetail",
      "siteCoordinator.siteCoordinatorFirstName",
      "siteCoordinator.siteCoordinatorSurname",
      "siteCoordinator.siteCoordinatorEmail",
      "siteCoordinator.siteCoordinatorCellphone",
      "siteCoordinator.siteCoordinatorTelephone",
      "siteCoordinator.teamLeaderFirstName",
      "siteCoordinator.teamLeaderSurname",
      "siteCoordinator.teamLeaderEmail",
      "siteCoordinator.teamLeaderCellphone",
      "eventPlanning.companyDebtorCode",
      "eventPlanning.serviceProviderId",
      "eventPlanning.healthPractitionerTypeId",
      "eventPlanning.numberOfHealthPractitioners",
      "eventPlanning.allocatedEquipment",
      "eventPlanning.numberOfHealthPractitioners",
      "eventPlanning.marketingCampaignDate",
      "eventPlanning.strikeDownTimes",
      "eventPlanning.strikeDownHours",
      "eventPlanning.strikeDownMinutes",
      "eventPlanning.setUpTimes",
      "eventPlanning.setUpHours",
      "eventPlanning.setUpMinutes"
    ].forEach(d =>
      ([Enums.EVENT_STATUS_IDS.CANCELLED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(data.eventStatusId as Enums.EVENT_STATUS_IDS) > -1) ? (this.eventFormMain.get(d).disable()) : (this.eventFormMain.get(d).enable()));
    this.showEvents = false;
    document.getElementById("eventForm").style.display = "block";
    this.eventFormMain.get("id").setValue(data.id);
    this.eventFormMain.get("date").setValue(formatDate(data.eventDate, 'yyyy-MM-dd', 'en'));
    if ([Enums.EVENT_STATUS_IDS.CANCELLED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(data.eventStatusId as Enums.EVENT_STATUS_IDS) == -1) {
      this.eventFormMain.get("date").enable();
    }
    this.eventFormMain.get("eventStartTime").setValue(data.eventStartTime);
    this.eventFormMain.get("eventEndTime").setValue(data.eventEndTime);
    this.eventFormMain.get('eventStatusId').setValue(data.eventStatusId || this.eventStatuses[0].id);
    this.showTown = true;
    this.eventFormMain.get("company.companyName").setValue(data.companyName);
    this.eventFormMain.get("eventPlanning.companyDebtorCode").setValue(data.companyDebtorCode);
    this.eventFormMain.get("company.accountExecutive").setValue(data.accountExecutive);
    this.eventFormMain.get("company.companyAccountExecutiveName").setValue(data.companyAccountExecutiveName);
    this.eventFormMain.get("company.companyAccountExecutiveSurname").setValue(data.companyAccountExecutiveSurname);
    this.eventFormMain.get("company.companyAccountExecutiveCellphone").setValue(data.companyAccountExecutiveCellphone);
    this.eventFormMain.get("company.companyAccountExecutiveEmail").setValue(data.companyAccountExecutiveEmail);
    this.eventFormMain.get("company.companyFacilityManagerName").setValue(data.companyFacilityManagerName);
    this.eventFormMain.get("company.companyFacilityManagerSurname").setValue(data.companyFacilityManagerSurname);
    this.eventFormMain.get("company.companyFacilityManagerCellphone").setValue(data.companyFacilityManagerCellphone);
    this.eventFormMain.get("company.companyFacilityManagerEmail").setValue(data.companyFacilityManagerEmail);
    const assessments = data.assessments || [];
    this.eventFormMain.get("assessments").setValue(assessments);
    this.eventFormMain.get("serviceType.serviceTypeId").setValue(data["serviceTypeId"]);
    this.eventFormMain.get("serviceType.serviceTypeName").setValue(null);
    this.eventFormMain.get("shiftWorkersStartTime").setValue(data.shiftWorkerStartTime);
    this.eventFormMain.get("shiftWorkersEndTime").setValue(data.shiftWorkerEndTime);
    const paymentOptions = data.paymentOptions || [];
    this.eventFormMain.get('paymentOptions').setValue(paymentOptions);
    this.eventFormMain.get("address").setValue(data.address);
    this.eventFormMain.get("specialLandmarks").setValue(data.specialLandmarks);
    this.eventFormMain.get("employeeDetails.amountOfEmployeesOnSite").setValue(data.amountOfEmployeesOnSite);
    this.eventFormMain.get("employeeDetails.amountOfExpectedEventParticipants").setValue(data.amountOfExpectedParticipants);
    this.eventFormMain.get("employeeDetails.amountOfExpectedSchemeParticipants").setValue(data.amountOfExpectedSchemeParticipants);
    this.eventFormMain.get("employeeDetails.amountOfExpectedNonSchemeParticipants").setValue(data.amountOfExpectedNonSchemeParticipants);
    this.eventFormMain.get("eventAtmosphere.atmosphereId").setValue(data["eventAtmosphereId"]);
    this.eventFormMain.get("eventAtmosphere.atmosphereType").setValue(null);
    this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(data.isVenueOpenPlan);
    this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(data.amountOfPrivateRoomsAvailable);
    this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(data.amountOfGazebos);

    if (this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired")) {
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").setValue(data.numberOfMobileUnitsRequired);
    }


    this.eventFormMain.get("isGazebosRequisitioned").setValue(data.areGazebosRequisitioned);
    this.eventFormMain.get("specialRequirements").setValue(data.specialRequirements);
    this.eventFormMain.get("venueRestrictions").setValue(data.venueRestrictions);
    this.eventFormMain.get("eventVenueDetail").setValue(data.eventVenueDetail);
    this.eventFormMain.get("siteCoordinator.teamLeaderFirstName").setValue(data.teamLeaderFirstName);
    this.eventFormMain.get("siteCoordinator.teamLeaderSurname").setValue(data.teamLeaderSurname);
    this.eventFormMain.get("siteCoordinator.teamLeaderCellphone").setValue(data.teamLeaderCellphone);
    this.eventFormMain.get("siteCoordinator.teamLeaderEmail").setValue(data.teamLeaderEmail);
    this.eventFormMain.get("dropOffFacilityDetails").setValue(data.dropOffFacilityDetails);


    let prov = (data.siteCoordinatorFullName || "").split(" ");
    //first entry in array will always be first name
    if (prov.length == 2) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setValue(prov[0]);
      this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setValue(prov[1]);
    } else if (prov.length > 2) {
      let provSur = "";
      this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setValue(prov[0]);
      prov.forEach(element => {
        if (element != prov[0]) {
          provSur += `${element} `
        }
      });
      this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setValue(provSur.toString().trim());
    }
    this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setValue(data.siteCoordinatorEmail);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setValue(data.siteCoordinatorCellphone);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorTelephone").setValue(data.siteCoordinatorTelephone);
    if (this.eventFormMain.get('id').value) {
      this.eventFormMain.get("eventPlanning.serviceProviderId").setValue(data.serviceProviderId);
      this.onSelectedServiceProvider(data.serviceProviderId);
      const healthPractitionerTypeId = data.healthPractitionerTypeId || [];
      this.eventFormMain.get('eventPlanning.healthPractitionerTypeId').setValue(healthPractitionerTypeId);
      const allocatedEquipment = data.allocatedEquipment || [];
      this.eventFormMain.get('eventPlanning.allocatedEquipment').setValue(allocatedEquipment);
      this.eventFormMain.get('eventPlanning.numberOfHealthPractitioners').setValue(data.numberOfHealthPractitioners || null);
      this.maxMarketingCampaignDate = !data ? formatDate(new Date(), 'yyyy-MM-dd', 'en') : formatDate(new Date(data.eventDate), 'yyyy-MM-dd', 'en');
      this.eventFormMain.get('eventPlanning.marketingCampaignDate').setValue(formatDate(data.marketingCampaignDate || new Date(), 'yyyy-MM-dd', 'en'));
      this.eventFormMain.get('eventPlanning.setUpHours').setValue((data.setUpTimes ? data.setUpTimes.split(':')[0] : "01"));
      this.eventFormMain.get('eventPlanning.setUpMinutes').setValue((data.setUpTimes ? data.setUpTimes.split(':')[1] : "00"));
      this.eventFormMain.get('eventPlanning.strikeDownHours').setValue((data.strikeDownTimes ? data.strikeDownTimes.split(':')[0] : "01"));
      this.eventFormMain.get('eventPlanning.strikeDownMinutes').setValue((data.strikeDownTimes ? data.strikeDownTimes.split(':')[1] : "00"));
    }
    this.isLoading = false;
  }

  get eventSetUpTimes(): string {
    return (this.eventFormMain.get('eventPlanning.setUpHours').value || "00") + ':' + (this.eventFormMain.get('eventPlanning.setUpMinutes').value || "00");
  }

  get company(): string {
    return this.eventFormMain.get('company.companyName').value;
  }

  get eventStrikeDownTimes(): string {
    return (this.eventFormMain.get('eventPlanning.strikeDownHours').value || "00") + ':' + (this.eventFormMain.get('eventPlanning.strikeDownMinutes').value || "00");
  }

  get eventStatusId(): string {
    return this.eventFormMain.get('eventStatusId').value;
  }

  get eventDates(): Date {
    return this.eventFormMain.get('date').value;
  }

  get eventStartTime(): string {
    return this.eventFormMain.get('eventStartTime').value;
  }

  get eventEndTime(): string {
    return this.eventFormMain.get('eventEndTime').value;
  }

  cancelBooking(): void {
    const dialogRef = this.dialog.open(CancellationDialogComponent, {
      width: '55%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.eventFormMain.get('eventStatusId').setValue(Enums.EVENT_STATUS_IDS.CANCELLED);
        this.eventFormMain.get('cancellationReasonId').setValue(result.cancellationReason);
        this.eventFormMain.get('cancelledBy').setValue(result.cancelledBy);
        var data = this.eventFormMain.getRawValue();
        this.model.id = this.editMode ? data.id : "00000000-0000-0000-0000-000000000000";
        this.model.cancelledBy = data.cancelledBy;
        this.model.cancellationReasonId = data.cancellationReasonId;
        this.model.eventStatusId = data.eventStatusId || this.eventStatuses[0].id;
        this.model.eventSubmissionCoordinatorId = this.model.eventSubmissionCoordinatorId || this.captureCoordinatorGUID;
        this.model.assessments = data.assessments;
        const headers = { 'Content-Type': 'application/json' };
        this.isLoading = true;
        this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.CANCEL_CORPORATE_WELLNESS_DAY_EVENT_BOOKING, this.model, { headers }).subscribe(
          (next) => {
            let snackBar = this.snackBar.open('Saved', null, { duration: 3000, panelClass: 'app-notification-success' });
            snackBar.afterDismissed().subscribe(() => {
              //get the model out of this.allUpcomingWellnessEvents with id == data.id

              let index = this.allUpcomingWellnessEvents.findIndex(d => d.id == data.id);

              this.allUpcomingWellnessEvents.splice(index, 1);
              this.backToCalendar(true);

              setTimeout(() => this.allUpcomingWellnessEvents.push(data), 2000);
            });
          },
          error => {
            this.isLoading = false;
            let snackBar = this.snackBar.open('Submission Error', null, { duration: 3000, panelClass: 'app-notification-error' })
            snackBar.afterDismissed().subscribe(() => this.backToCalendar());
          }
        )
      }
      console.log('The dialog was closed', result);
      // Here you can handle the result, e.g., send it to the server or use it in your component
    });
  }



  backToCalendar(refresh: boolean = false): void {
    if (refresh) {
      this.isLoading = true; //below loads the events based on the user's details
      this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe(events => {
        this.allUpcomingWellnessEvents = [];
        if (this.bookingStage != 'create') {
          events = events.filter(d => [Enums.EVENT_STATUS_IDS.EXECUTED, Enums.EVENT_STATUS_IDS.PLANNED].indexOf(d.eventStatusId) > -1);
        }
        events.forEach(e => this.allUpcomingWellnessEvents.push(e));
        this.refreshBookings(this.myBookings);
        this.isLoading = false;
      });
    }
    else {
      this.isLoading = false;
    }
    document.getElementById("eventForm").style.display = "none";
    this.showEvents = true;
    // this.calendarAPI.unselect();
    this.eventFormMain.reset();
    this.formDirective.resetForm();
    this.model = new CorporateWellnessEventBookingForm();
    this.invalidForm = false;
    // this.siteCoordinatorValidation = false;
    this.resetPanels();
  }
  handleEventClick(clickInfo: EventClickArg) {
    if (this.bookingStage == 'create') {
      this.editBooking(this.allUpcomingWellnessEvents.find(d => d.id == clickInfo.event.id));
      this.dynamicValidation();
    }
    else {
      //adding dynamic validation for event planning section only
      this.globalData.updateBookingEventId(clickInfo.event.id);
      this.router.navigate(['../registration', clickInfo.event.id, clickInfo.event.title], { relativeTo: this.route });
    }
  }

  dynamicValidation() {
    //event planning section validation
    this.eventFormMain.get("eventPlanning.serviceProviderId").addValidators(Validators.required);
    this.eventFormMain.get("eventPlanning.healthPractitionerTypeId").addValidators(Validators.required);
    this.eventFormMain.get("eventPlanning.numberOfHealthPractitioners").addValidators(Validators.required);
    this.eventFormMain.get("eventPlanning.allocatedEquipment").addValidators(Validators.required);
    this.eventFormMain.get("eventPlanning.setUpHours").addValidators(Validators.required);
    this.eventFormMain.get("eventPlanning.strikeDownHours").addValidators(Validators.required);

    //site cordinator validation
    this.isBooked = true;
    this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").addValidators(Validators.required);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").addValidators(Validators.required);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").addValidators([Validators.required, cellNumberValidator]);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").addValidators([Validators.required, Validators.email]);

    this.eventFormMain.updateValueAndValidity();
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.changeDetector.detectChanges();
  }
  onSubmit() {
    if (!this.eventFormMain.get('eventStatusId').value) {
      this.eventFormMain.get('eventStatusId').setValue('DDBB6282-ECAA-455B-9357-42CE9CA44325');
    }
    // var data = this.eventFormMain.value;
    var data = this.eventFormMain.getRawValue();



    this.resetPanels();
    if (this.eventFormMain.valid) {
      this.invalidForm = false;
      this.model.id = this.editMode ? data.id : "00000000-0000-0000-0000-000000000000";
      if (this.editMode) {
        this.model.eventDate = data.date;
      }
      this.model.companyName = data.company.companyName;
      this.model.companyDebtorCode = data.eventPlanning.companyDebtorCode;
      this.model.accountExecutive = data.company.accountExecutive;
      this.model.companyAccountExecutiveName = data.company.companyAccountExecutiveName;
      this.model.companyAccountExecutiveSurname = data.company.companyAccountExecutiveSurname;
      this.model.companyAccountExecutiveEmail = data.company.companyAccountExecutiveEmail;
      this.model.companyAccountExecutiveCellphone = data.company.companyAccountExecutiveCellphone;
      this.model.companyFacilityManagerName = data.company.companyFacilityManagerName;
      this.model.companyFacilityManagerSurname = data.company.companyFacilityManagerSurname;
      this.model.companyFacilityManagerCellphone = data.company.companyFacilityManagerCellphone;
      this.model.companyFacilityManagerEmail = data.company.companyFacilityManagerEmail;
      this.model.assessments = data.assessments;

      this.model.serviceTypeID = data.serviceType.serviceTypeId;
      this.model.eventStartTime = this.editMode ? data.eventStartTime : (this.startTime || data.eventStartTime);
      this.model.eventStatusId = data.eventStatusId || this.eventStatuses[0].id;
      this.model.eventEndTime = this.editMode ? data.eventEndTime : (this.endTime || data.eventEndTime);

      this.model.shiftWorkerStartTime = data.shiftWorkersStartTime;
      this.model.shiftWorkerEndTime = data.shiftWorkersEndTime;
      this.model.address = data.address;
      this.model.paymentOptions = data.paymentOptions;
      this.model.specialLandmarks = data.specialLandmarks;
      this.model.amountOfEmployeesOnSite = data.employeeDetails.amountOfEmployeesOnSite
      this.model.amountOfExpectedParticipants = data.employeeDetails.amountOfExpectedEventParticipants;
      this.model.amountOfExpectedSchemeParticipants = data.employeeDetails.amountOfExpectedSchemeParticipants;
      this.model.amountOfExpectedNonSchemeParticipants = data.employeeDetails.amountOfExpectedNonSchemeParticipants;

      //Event Atmosphere
      this.model.eventAtmosphereID = data.eventAtmosphere.atmosphereId;

      this.model.amountOfPrivateRoomsAvailable = data.eventAtmosphere.privateRoomsAvailable;
      this.model.isVenueOpenPlan = data.eventAtmosphere.venueOpenPlan;
      this.model.areGazebosRequisitioned = data.eventAtmosphere.numberOfGazebosRequired > 0;

      this.model.amountOfGazebos = this.model.areGazebosRequisitioned ? data.eventAtmosphere.numberOfGazebosRequired : 0;

      this.model.numberOfMobileUnitsRequired = data.eventAtmosphere.numberOfMobileUnitsRequired;

      this.model.specialRequirements = data.specialRequirements;
      this.model.venueRestrictions = data.venueRestrictions;
      this.model.dropOffFacilityDetails = data.dropOffFacilityDetails;
      this.model.eventVenueDetail = data.eventVenueDetail;
      //Site Coordinator
      if ((data.siteCoordinator.siteCoordinatorFirstName && data.siteCoordinator.siteCoordinatorSurname) != null) {
        this.model.siteCoordinatorFullName = `${data.siteCoordinator.siteCoordinatorFirstName.toString().trim()} ${data.siteCoordinator.siteCoordinatorSurname.toString().trim()}`;
      } else {
        this.model.siteCoordinatorFullName = null;
      }
      this.model.siteCoordinatorTelephone = data.siteCoordinator.siteCoordinatorTelephone;
      this.model.siteCoordinatorCellphone = data.siteCoordinator.siteCoordinatorCellphone;
      this.model.siteCoordinatorEmail = data.siteCoordinator.siteCoordinatorEmail;
      this.model.eventSubmissionCoordinatorId = this.model.eventSubmissionCoordinatorId || this.captureCoordinatorGUID;

      this.model.cancelledBy = data.cancelledBy;
      this.model.cancellationReasonId = data.cancellationReasonId;

      this.model.allocatedEquipment = data.eventPlanning.allocatedEquipment;
      this.model.healthPractitionerTypeId = data.eventPlanning.healthPractitionerTypeId;
      this.model.numberOfHealthPractitioners = data.eventPlanning.numberOfHealthPractitioners;
      this.model.marketingCampaignDate = data.eventPlanning.marketingCampaignDate;
      this.model.setUpTimes = data.eventPlanning.setUpHours && data.eventPlanning.setUpMinutes ? data.eventPlanning.setUpHours + ":" + data.eventPlanning.setUpMinutes : null;
      this.model.strikeDownTimes = data.eventPlanning.strikeDownHours && data.eventPlanning.strikeDownMinutes ? data.eventPlanning.strikeDownHours + ":" + data.eventPlanning.strikeDownMinutes : null;
      this.model.serviceProviderId = data.eventPlanning.serviceProviderId;

      this.model.teamLeaderFirstName = data.siteCoordinator.teamLeaderFirstName;
      this.model.teamLeaderSurname = data.siteCoordinator.teamLeaderSurname;
      this.model.teamLeaderCellphone = data.siteCoordinator.teamLeaderCellphone;
      this.model.teamLeaderEmail = data.siteCoordinator.teamLeaderEmail;
      const headers = { 'Content-Type': 'application/json' };
      this.isLoading = true;
      let uriPath = this.editMode ? Enums.API_PATHS.UPDATE_CORPORATE_WELLNESS_DAY_EVENT_BOOKING : Enums.API_PATHS.CREATE_CORPORATE_WELLNESS_DAY_EVENT_BOOKING;
      this.http.post<any>(Enums.API_PATH + uriPath, this.model, { headers }).subscribe({
        next: data => {
          let snackBar = this.snackBar.open('Saved', null, { duration: 3000, panelClass: 'app-notification-success' });
          snackBar.afterDismissed().subscribe(() => {
            //get the model out of this.allUpcomingWellnessEvents with id == data.id

            let index = this.allUpcomingWellnessEvents.findIndex(d => d.id == data.id);

            this.allUpcomingWellnessEvents.splice(index, 1);
            this.backToCalendar(true);

            setTimeout(() => this.allUpcomingWellnessEvents.push(data), 2000);
          });
        },
        error: error => {
          this.isLoading = false;
          let snackBar = this.snackBar.open('Submission Error', null, { duration: 3000, panelClass: 'app-notification-error' })
          snackBar.afterDismissed().subscribe(() => this.backToCalendar());
        }
      });
    } else {
      this.expandInvalidFieldsPanel(this.eventFormMain)
      this.invalidForm = true;
    }
  }

  expandInvalidFieldsPanel(form: any) {
    if (((form.get('date').status == "DISABLED" || form.get('date').invalid == true)) || ((form.get('shiftWorkersEndTime').invalid || form.get('shiftWorkersStartTime').invalid || form.get('eventEndTime').invalid || form.get('eventStartTime').invalid || form.get('serviceType.serviceTypeId').invalid || form.get('specialLandmarks').invalid || form.get('employeeDetails.amountOfExpectedEventParticipants').invalid || form.get('employeeDetails.amountOfExpectedEventSchemeParticipants') || form.get('employeeDetails.amountOfExpectedEventNonSchemeParticipants') || form.get('employeeDetails.amountOfEmployeesOnSite').invalid || form.get('address').invalid) == true)) {
      this.eventDetailsPanel.open();
      this.expandEventDetails = true;
    }
    if (((form.get('dropOffFacilityDetails').status) == "INVALID" || ((form.get('eventAtmosphere.privateRoomsAvailable').invalid || form.get('eventAtmosphere.venueOpenPlan').invalid || form.get('eventAtmosphere.atmosphereId').invalid || form.get('eventAtmosphere.numberOfGazebosRequired').invalid) == true)) && !this.expandEventDetails) {
      this.eventVenuePanel.open();
      this.expandEventVenue = true;
    }
    if (this.eventFormMain.get('siteCoordinator.teamLeaderCellphone').invalid || this.eventFormMain.get('siteCoordinator.teamLeaderEmail').invalid || this.eventFormMain.get('siteCoordinator.siteCoordinatorTelephone').invalid || this.eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').invalid || this.eventFormMain.get('siteCoordinator.siteCoordinatorEmail').invalid && !this.expandEventVenue && !this.expandEventDetails) {
      this.siteCoordinatorPanel.open();
    }
  }





  resetPanels() {
    this.eventDetailsPanel.close();
    this.eventVenuePanel.close();
    this.siteCoordinatorPanel.close();
    this.expandEventVenue = false;
    this.expandEventDetails = false;
  }
  createForm() {
    this.eventFormMain = this.fb.group({
      id: null,
      date: [{ value: null, disabled: true }, Validators.required],
      company: this.fb.group({
        companyName: [null, Validators.required],
        accountExecutive: [null],
        companyAccountExecutiveName: [null, Validators.required],
        companyAccountExecutiveSurname: [null, Validators.required],
        companyAccountExecutiveCellphone: [null, [Validators.required, cellNumberValidator]],
        companyAccountExecutiveEmail: [null, [Validators.required, Validators.email]],
        companyFacilityManagerName: [null],
        companyFacilityManagerSurname: [null],
        companyFacilityManagerCellphone: [null, cellNumberValidator],
        companyFacilityManagerEmail: [null, Validators.email],
        // provinceTown: this.fb.group({
        //   townID: ['', Validators.required],
        //   townName: '',
        //   provinceStation: this.fb.group({
        //     stationID: ['', Validators.required],
        //     stationName: ''
        //   })
        // })
      }),
      assessments: [null, Validators.required],
      eventStatusId: [null],
      serviceType: this.fb.group({
        serviceTypeId: [null, Validators.required],
        serviceTypeName: ''
      }),
      paymentOptions: [null, Validators.required],
      eventStartTime: [null, Validators.required],
      eventEndTime: [null, Validators.required],
      shiftWorkersStartTime: null,
      shiftWorkersEndTime: null,
      address: [null, Validators.required],
      specialLandmarks: [null],
      employeeDetails: this.fb.group({
        amountOfEmployeesOnSite: ['', Validators.required],
        amountOfExpectedEventParticipants: ['', Validators.required],
        amountOfExpectedSchemeParticipants: ['', Validators.required],
        amountOfExpectedNonSchemeParticipants: ['', Validators.required]
      }),
      eventAtmosphere: this.fb.group({
        atmosphereId: [null, Validators.required],
        atmosphereType: '',
        venueOpenPlan: null,
        privateRoomsAvailable: null,
        numberOfGazebosRequired: null,
        numberOfMobileUnitsRequired: null
      }),
      isGazebosRequisitioned: null,//input (if outdoor {could also be indoor for registration that happens outside})

      specialRequirements: null, //input (Biokenetist must also be on premises) -> could this be turned into a dropdown
      venueRestrictions: [null, Validators.required],//input -> see if we can come up with a definite list

      dropOffFacilityDetails: [''],//input etc. parking lot?
      eventVenueDetail: [null, Validators.required],
      siteCoordinator: this.fb.group({
        siteCoordinatorFirstName: [null],
        siteCoordinatorSurname: [null],
        siteCoordinatorTelephone: [null],
        siteCoordinatorCellphone: [null],
        siteCoordinatorEmail: [null],
        teamLeaderFirstName: ['Jayshree', Validators.required],
        teamLeaderSurname: ['Pillay', Validators.required],
        teamLeaderCellphone: ['0823763502', [Validators.required, cellNumberValidator]],
        teamLeaderEmail: ['jayshree.pillay@momentum.co.za', [Validators.required, Validators.email]]
      }),
      cancellationReasonId: [null],
      cancelledBy: [null],
      eventPlanning: this.fb.group({
        companyDebtorCode: [null],
        serviceProviderId: [null],
        healthPractitionerTypeId: [null],
        numberOfHealthPractitioners: [null],
        allocatedEquipment: [null],
        marketingCampaignDate: [new Date(), null],
        setUpTimes: [null],
        strikeDownTimes: [null],
        strikeDownHours: [null,],
        strikeDownMinutes: [null],
        setUpHours: [null,],
        setUpMinutes: [null]
      }),
    });
  }


  backToCalendarAttempt() {
    if (this.eventFormMain.dirty) {
      var _popup = this.dialog.open(PopupComponent, {
        width: '55%',
        data: {
          title: 'All changes will be lost. Are you sure you want to continue?',
          lButTitle: 'Cancel',
          rButTitle: 'OK',
          lButNavUrl: 'back',
          rButNavUrl: 'calendar',
        }
        //height: '50%'
      }).afterClosed().subscribe((data) => {
        if (data == 'calendar') {
          this.backToCalendar();
        }
      });
    }
    else {
      this.backToCalendar();
    }
    // _popup.afterClosed().subscribe(item => {
    //   if (item == 'calendar') {
    //     this.backToCalendar();
    //   }
    // })
  }
  onVenueSelection(value: string) {
    //list item ID for inside
    if (value == '69d34741-ddf0-4025-b2b8-916cb017b0df') {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").removeValidators(Validators.required);

      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").removeValidators(Validators.required);

      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").clearValidators();
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").addValidators(Validators.required);
      //list item ID for outside
    } else if (value == '9a859b37-8f61-497b-b0b0-771ea4f1fac5') {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").addValidators(Validators.required);


      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").addValidators(Validators.required);

      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").clearValidators();
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(null);
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").clearValidators();
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(null);
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").clearValidators();
      //list item ID for combined
    } else if (value == 'a423a72a-5552-469c-a64b-68cf9bce6e91') {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").clearValidators();
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").setValue(null);


      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(null);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(null);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").addValidators(Validators.required);
    }
    else if (value == "ab6d6558-4452-4359-ba87-c0f0be384fe4") {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").clearValidators();

      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").addValidators(Validators.required);

      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").clearValidators();
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(null);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").clearValidators();
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(null);
    }
    this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").updateValueAndValidity();

    this.eventFormMain.get("eventAtmosphere.numberOfMobileUnitsRequired").updateValueAndValidity();

    this.eventFormMain.get("eventAtmosphere.venueOpenPlan").updateValueAndValidity();
    this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").updateValueAndValidity();
    this.eventFormMain.updateValueAndValidity();
  }
  onShiftWorkerTimeSelection() {
    this.eventFormMain.get("shiftWorkersStartTime").addValidators(Validators.required);
    this.eventFormMain.get("shiftWorkersEndTime").addValidators(Validators.required);

    this.eventFormMain.updateValueAndValidity();
  }
  clearShiftWorkerTimeEntries() {
    this.eventFormMain.controls["shiftWorkersStartTime"].setValue(null);
    this.eventFormMain.controls["shiftWorkersEndTime"].setValue(null);
    this.eventFormMain.get("shiftWorkersStartTime").clearValidators();
    this.eventFormMain.get("shiftWorkersEndTime").clearValidators();
    this.eventFormMain.controls["shiftWorkersStartTime"].setErrors(null);
    this.eventFormMain.controls["shiftWorkersEndTime"].setErrors(null);
    this.eventFormMain.updateValueAndValidity();
  }
  setIDLengthValidation(value: string, id: number) {
    let provId = id.toString();
    if (provId.length > 0) {
      if (provId.length < 13) {
        this.eventFormMain.controls[value].setErrors({ min: true });
      }
    } else {
      this.eventFormMain.get(value).clearValidators();
      this.eventFormMain.controls[value].setErrors(null);
    }
    this.eventFormMain.updateValueAndValidity();
  }
  onSiteCoordinatorEntry() {
    //this.siteCoordinatorValidation = true;
    console.log(this.eventFormMain)
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").errors || {}), 'required': "Site Coordinator's full name is required." });
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").errors || {}), 'required': "Site Coordinator's full name is required." });
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").errors || {}), 'required': "Site Coordinator's full name is required." });
    } else {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").addValidators(Validators.email);
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").errors || {}), 'required': "Site Coordinator's full name is required." });
    } else {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").addValidators([cellNumberValidator]);
    }
    this.eventFormMain.updateValueAndValidity();
  }
  clearSiteCoordinatorEntry() {
    this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorTelephone").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setErrors(null);
    this.eventFormMain.updateValueAndValidity();
  }

  onSelectedTown(value: string) {

  }

  onSelectedServiceProvider(value: string) {
  }

  setAddressValidation(value: string) {
    if (value == "address") {
      this.eventFormMain.get(value).addValidators(Validators.required);
      this.eventFormMain.get("specialLandmarks").clearValidators();
      this.eventFormMain.controls["specialLandmarks"].setErrors(null);
    } else if (value == "specialLandmarks") {
      this.eventFormMain.get(value).addValidators(Validators.required);
      this.eventFormMain.get("address").clearValidators();
      this.eventFormMain.controls["address"].setErrors(null);
    }
    this.eventFormMain.updateValueAndValidity();
  }
  timeValidation(start: string, end: string, timeType: string) {
    if (end != null || end != "") {
      let startTime: Date = new Date(Date.parse("2015/01/01 " + start));
      let endTime: Date = new Date(Date.parse("2015/01/01 " + end));
      let sum: number = (endTime.getTime() - startTime.getTime());

      if (sum <= 0) {
        if (timeType.toString() == "event") {
          this.validEventTimeInputs = false;
        }
        if (timeType.includes("shiftWorker")) {
          this.validShiftWorkerTimeInputs = false;
        }
      } else {
        if (timeType.toString() == "event") {
          this.validEventTimeInputs = true;
        }
        if (timeType.toString() == "shiftWorker") {
          this.validShiftWorkerTimeInputs = true;
        }
      }
      this.timeError(timeType);
    }
  }
  specialCharacterChecker(value: number, field: string) {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(value.toString())) {
      this.eventFormMain.get(`${field}`).setErrors({ ...(this.eventFormMain.get(`${field}`).errors || {}), 'Special': 'Special characters not allowed.' });
    }
    this.eventFormMain.updateValueAndValidity();
  }
  timeError(timeType: string): void {
    console.log(this.eventFormMain)
    if (timeType == "event") {
      if (this.validEventTimeInputs == false) {
        this.eventFormMain.get("eventEndTime").setErrors({ ...(this.eventFormMain.get("eventEndTime").errors || {}), 'Invalid': 'Invalid time selection.' });
      } else {
        this.eventFormMain.get("eventEndTime").setErrors(null);
        this.eventFormMain.get("eventEndTime").clearValidators();
      }
      this.eventFormMain.updateValueAndValidity();
    } else if (timeType == "shiftWorker") {
      if (this.validShiftWorkerTimeInputs == false) {
        this.eventFormMain.get("shiftWorkersEndTime").setErrors({ ...(this.eventFormMain.get("shiftWorkersEndTime").errors || {}), 'Invalid': 'Invalid time selection.' });
      } else {
        this.eventFormMain.get("shiftWorkersEndTime").setErrors(null);
        this.eventFormMain.get("shiftWorkersEndTime").clearValidators();
      }
      this.eventFormMain.updateValueAndValidity();
    }
  }

  getMaxParticipants(): number {
    let amountOfExpectedSchemeParticipants = this.eventFormMain.get('employeeDetails.amountOfExpectedSchemeParticipants').value || 0;
    let amountOfExpectedNonSchemeParticipants = this.eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').value || 0;

    if (amountOfExpectedSchemeParticipants > 0 && amountOfExpectedNonSchemeParticipants > 0) {
      let add = (amountOfExpectedSchemeParticipants + amountOfExpectedNonSchemeParticipants);
      if (this.eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').value >= add) //9>10
        return this.eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').value;
      else
        return -1;
    }
    else if (amountOfExpectedSchemeParticipants == 0 || amountOfExpectedNonSchemeParticipants == 0) {
      return this.eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').value;
    }
  }

  ngOnDestroy(): void {
    // Disconnect the MutationObserver when the component is destroyed
    if (this.eventHarnessObserver) {
      this.eventHarnessObserver.disconnect();
    }
  }
}
