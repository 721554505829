import { Component, OnInit, } from '@angular/core';
import { ConsultationObject } from '../../interfaces/consultationMapper';
import { ConsultationService } from '../../services/consultation.service';
import { GlobalDataService } from '../../services/global-data.service';
import { Router } from '@angular/router';
import { ScheduleService } from '../../services/schedule.service';

@Component({
  selector: 'app-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss']
})
export class InfoHeaderComponent implements OnInit {

  isLoading = false;
  consultationDetail: ConsultationObject;
  clientId = '';
  scheduleDate: any;
  nameIconClass: string = 'nameIcon';
  consultationSummaryClass: string = 'baId';
  routeIncludesConsultationSummary: boolean = true;

  constructor(private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private scheduleDetail: ScheduleService) {
    this.routeIncludesConsultationSummary = this.router.url.includes('oh-consultation-summary');
    this.nameIconClass = this.routeIncludesConsultationSummary ? 'nameIcon' : 'nameIconNoId';
    this.consultationSummaryClass = this.routeIncludesConsultationSummary ? 'baId' : 'baNoId';
  }

  ngOnInit(): void {

    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);

    this.isLoading = true;
    this.consultationService.getConsultationDetail(this.clientId).subscribe(r => {
      if (r) {
        /* console.log(r) */;
        this.consultationDetail = r;
        this.scheduleDetail.getSchedule(this.clientId).subscribe(scheduleDetails => {
          this.scheduleDate = scheduleDetails.scheduleDate;
          this.isLoading = false;
        });
      } else {

        // TODO: show error
        this.isLoading = false;
      }
    });

  }
}
