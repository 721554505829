import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators';

import * as Enums from '../enums';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class WellnessReportService {

  httpOptions: any = {};

  constructor(public http: HttpClient, private globalServ: GlobalDataService) { }

  //Had to move this to reporting.service.ts

  getEventNames(): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_ALL_EVENT_NAMES, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get Event names', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

}
