<article class="grid padding-8">
  <div class="grid__inner grid__inner--layout-12 gap-15">
    <div class="grid__cell" *ngIf="keycloakProfile">
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item>
          <mat-icon>person</mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="profileMenu">
        <mat-icon>account_circle</mat-icon>
        <span>&nbsp;{{keycloakProfile.firstName}} {{keycloakProfile.lastName}}</span>
      </button>

    </div>
  </div>
</article>