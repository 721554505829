<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false && isFailed === true">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-center mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center"><a class="motion-button--cancel" tabindex="0"
                    title="Return to previous page" (click)="previousPage()">Back</a></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="isLoading === false && isFailed === false">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Client Search</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <div *ngIf="dataSource.data.length > 0">
        <div class="text--align-left">
          <form layout-align='center center' layout='column' style="display: flex">
            <mat-form-field style="display: block; position: relative; text-align: left; width: 30% !important;">
              <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search"
                class="search-bar">
              <mat-icon matPrefix style="transform: translate(-8px, 6px);">search</mat-icon>
            </mat-form-field>
          </form>
        </div>
        <table mat-table [dataSource]="dataSource" matSort matSortActive="companyName" matSortDirection="asc"
          matSortDisableClear>
          <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="firstname"><span *ngIf="element.firstName==null">Not
                available</span> {{element.firstName | titlecase}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="surname">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Surname</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="surname"><span *ngIf="element.surname==null">Not
                available</span> {{element.surname | titlecase}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="idNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ID number</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="idNumber"><span *ngIf="element.rsaidnumber==null">Not
                available</span> {{element.rsaidnumber}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="clientNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Client number</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="clientNumber"><span *ngIf="element.employeeNumber==null">Not
                available</span> {{element.employeeNumber}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="actions"><span *ngIf="element.fullNames==null">Not
                available</span>
              <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--short">View
                client</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row class="element-row" *matRowDef="let row; columns: displayedColumns;"
            (click)="viewClient(row.firstName, row.surname, row.rsaidnumber, row.id)">
          </mat-row>
          <ng-template [ngIf]="isCompanyDetails">
            <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
          </ng-template>
        </table>
        <table [hidden]="inProgress">
          <tr *ngIf="!dataSource.data.length">
            <td class="text--align-center" [attr.colspan]="displayedColumns.length">
              <br>
              <span>No data available</span>
              <br> <br>
            </td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center"><a class="motion-button--cancel" tabindex="0"
                    title="Return to previous page" (click)="previousPage()">Back</a></div>
              </div>
            </div>
          </div>
        </div>
        <span class="blank-line blank-line-4 d-block"></span>
      </section>
    </div>
  </article>
</div>
