import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CORPWELLNESSCONSENTSTATUS } from 'src/app/shared/enums';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';

import { ConsultationObject, ConsultationTestPerformedObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { FinalCheckMapper, WellnessConsentSummaryMapper } from '../../../shared/interfaces/dataMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

//TODO: Add logged in user profile here.
// import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-corporate-wellness-consultation-summary',
  templateUrl: './consultation-summary.component.html',
  styleUrls: ['./consultation-summary.component.scss']
})
export class CorporateWellnessConsultationSummaryComponent implements OnInit {

  isLoading = false;
  consultationDetail: ConsultationObject;
  testObject: TestRequestedObject[] = [];
  clientId = '';
  isFailed = false;
  finalCheck: FinalCheckMapper;
  consent: WellnessConsentSummaryMapper;
  hivConsent: WellnessConsentSummaryMapper;
  psaConsent: WellnessConsentSummaryMapper;
  pConsent: WellnessConsentSummaryMapper;
  fluConsent: WellnessConsentSummaryMapper;
  fConsent: WellnessConsentSummaryMapper;
  papSmearConsent: WellnessConsentSummaryMapper;
  papSmear: WellnessConsentSummaryMapper;
  consentRequired = false;
  consentTr: TestRequestedObject;
  vCounselTr: TestRequestedObject;
  hivConsentTr: TestRequestedObject;
  psaConsentTr: TestRequestedObject;
  pConsentTr: TestRequestedObject;
  fluConsentTr: TestRequestedObject;
  fConsentTr: TestRequestedObject;
  papSmearConsentTr: TestRequestedObject;
  papSmearTr: TestRequestedObject;
  // userProfile: KeycloakProfile;
  eventId: string | null;
  eventTitle: string;
  sortOrder: {
    "CORPORATECONSENT": 0,
    "CORPPHRA": 1,
    "CORPTB": 2,
    "CORPORATEHIVCONSENT": 3,
    "CORPVCOUNCELLING": 4,
    "CORPHIVT": 5,
    "CORPPSACONSENT": 6,
    "CORPPSA": 7,
    "CORPFLUCONSENT": 8,
    "CORPFLU": 9,
    "CORPWLNSS": 10,
    "CORPWPSYCH": 11,
  };
  hivVoluntary: any;
  isHealthPractitioner: boolean = false;

  constructor(private questionnaireService: QuestionnaireService, private route: ActivatedRoute, private consultationWorkflowService: ConsultationWorkflowService, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private ref: ChangeDetectorRef) {
    this.globalData.updateBackRoute('/wellness-event/corporate/landing');

    this.globalData.finalCheckVal.subscribe(result => this.finalCheck = result);
    this.globalData.wellnessConsentVal.subscribe(result => this.consent = result);
    this.globalData.bookingEventTitleVal.subscribe(result => this.eventTitle = result);
    // this.userProfile = {
    //   "id": "b3502ad7-e723-4371-b762-27de8352152d",
    //   "username": "pjmotake",
    //   "firstName": "SAPS",
    //   "lastName": "User",
    //   "email": "sapsuser@perigon.net",
    //   "emailVerified": false,
    // };
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);
    this.globalData.userRolesVal.subscribe(roles => this.isHealthPractitioner = roles.indexOf('Health practitioner') > -1);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getData();
  }

  addWeekToDate(dt: Date) {
    dt.setDate(dt.getDate() + 7);
    return dt;
  }

  get consentStatus() {
    return {
      HRA: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.HRA) == CORPWELLNESSCONSENTSTATUS.HRA
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT,

      BREAST: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BREAST) == CORPWELLNESSCONSENTSTATUS.BREAST && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,

      HIVConsent: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.HIV) == CORPWELLNESSCONSENTSTATUS.HIV
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT,

      PAPCONSENT: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.PAPSMEAR) == CORPWELLNESSCONSENTSTATUS.PAPSMEAR && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,

      FLUCONSENT: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.FLU) == CORPWELLNESSCONSENTSTATUS.FLU
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,

      CorpPSAConsent: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.PSA) == CORPWELLNESSCONSENTSTATUS.PSA
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,

      MentalWellbeing: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.MENTALWELLBEING) == CORPWELLNESSCONSENTSTATUS.MENTALWELLBEING
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT,

      TBQuestionnaire: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.TB) == CORPWELLNESSCONSENTSTATUS.TB
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT,

      Stress: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.STRESS) == CORPWELLNESSCONSENTSTATUS.STRESS
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT,

      HIVVoluntary: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.HIV) == CORPWELLNESSCONSENTSTATUS.HIV
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT
        ? (!this.hivConsent ? null : (this.hivConsent.status == 'Consent given' ? true : false))
        : false,

      HIV: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.HIV) == CORPWELLNESSCONSENTSTATUS.HIV
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT
        ? (!this.hivVoluntary ? null : (this.hivVoluntary.status == 'Completed' ? true : false))
        : false,

      PSA: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.PSA) == CORPWELLNESSCONSENTSTATUS.PSA
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT
        ? (!this.psaConsent ? null : (this.psaConsent.status == 'Consent given' ? true : false))
        : false,

      FLU: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.FLU) == CORPWELLNESSCONSENTSTATUS.FLU
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BIOMETRIC) == CORPWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY
        // && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.BENEFIT) == CORPWELLNESSCONSENTSTATUS.BENEFIT
        ? (!this.fluConsent ? null : (this.fluConsent.status == 'Consent given' ? true : false))
        : false,

      PAPSMEAR: (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.PAPSMEAR) == CORPWELLNESSCONSENTSTATUS.PAPSMEAR && (this.consent.consentStatus & CORPWELLNESSCONSENTSTATUS.INDEMNITY) == CORPWELLNESSCONSENTSTATUS.INDEMNITY ? (!this.papSmearConsent ? null : (this.papSmearConsent.status == 'Consent given' ? true : false)) : false
    }
  }


  getData(): void {

    this.consultationService.getConsultationDetail(this.clientId).subscribe(returnValue => {
      const result = returnValue as ConsultationObject;
      localStorage.setItem('consultationDetail', JSON.stringify(result));
      this.eventId = result.adhocEvent.id;
      this.globalData.updateBackRoute(['/wellness-event/corporate/registration', this.eventId, this.eventTitle]);
      if (result) {
        try {
          let tr = result
            .testsRequested
            .filter(d =>
              d.testMapping &&
              d.testMapping.groupName &&
              d.testMapping.summary &&
              //d.testMapping.detail &&
              d.testMapping.questionnaireId != "00000000-0000-0000-0000-000000000000");
          result.testsRequested = tr.sort((a, b) => b.sequenceNo > a.sequenceNo ? 1 : (a.sequenceNo > b.sequenceNo ? -1 : 0));
        }
        catch (e) {

        }
        this.consultationDetail = result;
        console.log("consultation details", this.consultationDetail);
        try {
          if (this.consultationDetail.testsRequested) {
            if (this.testObject.length === 0) {
            }

            for (let i = 0; i < this.consultationDetail.testsRequested.length; i++) {
              let tr2 = this.consultationDetail.testsRequested[i];
              let testMapping = tr2.testMapping || { workflowName: '', groupName: '', iconName: '', testMappingId: '', summary: '', workflowId: '', questionnaireId: '', detail: '' };
              let groupName = testMapping.groupName.toLowerCase();

              if (testMapping.workflowId == 'CORPORATEHIVCONSENT') {
                this.hivConsentTr = tr2;
                if (this.hivConsentTr && this.hivConsentTr.status == "finished") {
                  if (this.hivConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.hivConsentTr.testsPerformed[0];
                    this.hivConsent = {
                      notes: tp.resultValue == 6 ? "Consent given" : "Consent refused",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'HIV',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 6 ? "Consent given" : "Consent refused"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPVCOUNCELLING') {
                this.vCounselTr = tr2;
                if (this.vCounselTr && this.vCounselTr.status == "finished") {
                  if (this.vCounselTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.vCounselTr.testsPerformed[0];
                    this.hivVoluntary = {
                      notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'HIV',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                    };
                  }
                }

                if (testMapping.workflowId == 'CORPVCOUNCELLING') {
                  this.vCounselTr = tr2;
                  if (this.vCounselTr && this.vCounselTr.status == "finished") {
                    if (this.vCounselTr.testsPerformed.length > 0) {
                      let tp: ConsultationTestPerformedObject = this.vCounselTr.testsPerformed[0];
                      this.hivVoluntary = {
                        notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                        createdDate: tp.createDate,
                        disabled: false,
                        groupName: 'HIV',
                        consentStatus: tp.resultValue,
                        required: false,
                        status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                      };

                    }
                  }
                }
              }

              if (testMapping.workflowId == 'CORPPSA') {
                this.pConsentTr = tr2;
                if (this.pConsentTr && this.pConsentTr.status == "finished") {
                  if (this.pConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.pConsentTr.testsPerformed[0];
                    this.pConsent = {
                      notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'PSA',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPPSACONSENT') {
                this.psaConsentTr = tr2;
                if (this.psaConsentTr && this.psaConsentTr.status == "finished") {
                  if (this.psaConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.psaConsentTr.testsPerformed[0];
                    this.psaConsent = {
                      notes: tp.resultValue == 6 ? "Consent given" : "Consent refused",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'PSA',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 6 ? "Consent given" : "Consent refused"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPFLUCONSENT') {
                this.fluConsentTr = tr2;
                if (this.fluConsentTr && this.fluConsentTr.status == "finished") {
                  if (this.fluConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.fluConsentTr.testsPerformed[0];
                    this.fluConsent = {
                      notes: tp.resultValue == 6 ? "Consent given" : "Consent refused",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'FLU',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 6 ? "Consent given" : "Consent refused"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPFLU') {
                this.fConsentTr = tr2;
                if (this.fConsentTr && this.fConsentTr.status == "finished") {
                  if (this.fConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.fConsentTr.testsPerformed[0];
                    this.fConsent = {
                      notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'FLU',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPPAPCONSENT') {
                this.papSmearConsentTr = tr2;
                if (this.papSmearConsentTr && this.papSmearConsentTr.status == "finished") {
                  if (this.papSmearConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.papSmearConsentTr.testsPerformed[0];
                    this.papSmearConsent = {
                      notes: tp.resultValue == 6 ? "Consent given" : "Consent refused",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'PAPSMEAR',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 6 ? "Consent given" : "Consent refused"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPPAPSMEAR') {
                this.papSmearTr = tr2;
                if (this.papSmearTr && this.papSmearTr.status == "finished") {
                  if (this.papSmearTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.papSmearTr.testsPerformed[0];
                    this.papSmear = {
                      notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'PAPSMEAR',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'CORPORATECONSENT') {
                this.consentRequired = true;
                this.consentTr = tr2;
                if (this.consentTr.testsPerformed.length > 0) {
                  let tp: ConsultationTestPerformedObject = this.consentTr.testsPerformed[0];
                  let consentStatus: CORPWELLNESSCONSENTSTATUS = tp.resultValue;

                  let hivTest: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.HIV) == CORPWELLNESSCONSENTSTATUS.HIV;
                  let stress: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.STRESS) == CORPWELLNESSCONSENTSTATUS.STRESS;
                  let mentalWellbeing: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.MENTALWELLBEING) == CORPWELLNESSCONSENTSTATUS.MENTALWELLBEING;
                  let tbQuestionnaire: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.TB) == CORPWELLNESSCONSENTSTATUS.TB;
                  let hra: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.HRA) == CORPWELLNESSCONSENTSTATUS.HRA;
                  let breast: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.BREAST) == CORPWELLNESSCONSENTSTATUS.BREAST;
                  let flu: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.FLU) == CORPWELLNESSCONSENTSTATUS.FLU;
                  let psa: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.PSA) == CORPWELLNESSCONSENTSTATUS.PSA;
                  let pap: boolean = (consentStatus & CORPWELLNESSCONSENTSTATUS.PAPSMEAR) == CORPWELLNESSCONSENTSTATUS.PAPSMEAR;
                  let notes: string = hra || hivTest || stress || flu || psa || breast || mentalWellbeing || tbQuestionnaire || pap ? 'Consent given' : 'Consent to some wellness tests to continue';
                  let status: string = hra || hivTest || stress || flu || psa || breast || mentalWellbeing || tbQuestionnaire || pap ? 'finished' : 'refused';
                  this.consent = {
                    notes: notes,
                    createdDate: tp.createDate,
                    disabled: false,
                    groupName: 'Consent',
                    consentStatus: consentStatus,
                    status: status,
                    required: false
                    // status: this.consentMapper.dataConsent === false || this.consentMapper.examConsent === false ? 'inProgress' : 'finished'
                  };
                }
              }
              else {
                if (tr2.testsPerformed.length < 1) {
                  this.testObject.unshift(tr2);
                } else {

                  this.testObject.push(tr2);
                }
              }

              this.ref.detectChanges();
            }

            if (this.consentRequired) {

            }
          }
        } catch (ex) {
          this.isLoading = false;

          this.isFailed = true;
        }
        //localStorage.setItem("consentStatus", JSON.stringify(this.consentStatus));
        this.testObject.forEach(to => {

          to.testsPerformed.forEach(tp => {
            switch (tp.resultValue) {
              case 0:
                if (tp.questionnaireResult) {
                  if (tp.questionnaireResult == 0) {
                    tp.resultInEnglish = 'Normal';
                    if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                      tp.resultInEnglish = "Negative";
                    }
                    if (tp.testMapping.workflowName == "Corporate: Pap smear screening") {
                      tp.resultInEnglish = "Sample Collected";
                    }
                  }
                  else {
                    if (tp.questionnaireResult == 1) {
                      tp.resultInEnglish = 'Abnormal';
                      if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                        tp.resultInEnglish = "Positive";
                      }
                      if (tp.testMapping.workflowName == "Corporate: Pap smear screening") {
                        tp.resultInEnglish = "Sample not collected";
                      }
                    }
                    else {
                      tp.resultInEnglish = 'Normal';
                      if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                        tp.resultInEnglish = "Negative";
                      }
                    }
                  }
                }
                else {
                  tp.resultInEnglish = 'Normal';
                  if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                    tp.resultInEnglish = "Negative";
                  }
                  if (tp.testMapping.workflowName == "Corporate: Pap smear screening") {
                    tp.resultInEnglish = "Sample collected";
                  }
                }



                // Special processing for Respiratory, SHE history, External deployment history and pre-employment history questionnaire
                // if (tp.testMapping.questionnaireId.toUpperCase() == 'ED35B3BE-9415-495C-A929-B37042D89961'
                //   || tp.testMapping.questionnaireId.toUpperCase() == '76651826-F059-4D58-AAE6-E5C0F941B3C3'
                //   || tp.testMapping.questionnaireId.toUpperCase() == 'BE788A56-55F4-4E0C-9026-96138F665292'
                //   || tp.testMapping.questionnaireId.toUpperCase() == 'A7A5CB5A-B677-4572-9BB2-59E078108089'
                //   || tp.testMapping.questionnaireId.toUpperCase() == '94B8C4DD-84FD-4881-92A0-AEA5A989D20E'
                //   || tp.testMapping.questionnaireId.toUpperCase() == '8F730DAB-4B1D-4025-972A-8348C0120988') {
                //   tp.resultInEnglish = 'Done';
                // }
                break;
              case 1:
                tp.resultInEnglish = 'Abormal';
                if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                  tp.resultInEnglish = "Positive";
                }
                break;
              case 2:
                tp.resultInEnglish = 'Test Not Done';
                break;
              case 3:
                tp.resultInEnglish = 'Contraindicated';
                break;
              case 4:
                tp.resultInEnglish = 'Awaiting test results';
                break;
              case 5:
                tp.resultInEnglish = 'Not required';
                break;
              case 6:
                tp.resultInEnglish = 'Consent given';
                break;
              case 7:
                tp.resultInEnglish = 'Consent refused';
                break;
              case 8:
                tp.resultInEnglish = 'Test refused';
                break;
              case 9:
                tp.resultInEnglish = 'Member refused';
                break;
              default:
                tp.resultInEnglish = 'Unknown';
                break;
            }
          });

          if (to.testsPerformed.length) {
            var resultInEnglish = to.testsPerformed[0].resultInEnglish;
            var resultValue = to.testsPerformed[0].resultValue;
            var isVaccination = to.testMapping.groupName.toLowerCase().includes('vaccination');
            var isFailedResult = [1, 3, 7, 8].includes(resultValue);
            var isIntermediateResult = [2, 4, 9].includes(resultValue);
            var isPassResult = [5, 6].includes(resultValue);

            to.testStatusFailed = !isVaccination && (resultInEnglish == 'Abnormal' || resultInEnglish == "Positive" || resultInEnglish == "Sample not collected" || isFailedResult);
            to.testStatusIntermediate = (isVaccination && resultInEnglish == 'Test Not Done') || (!isVaccination && isIntermediateResult);
            to.testStatusPass = (isVaccination && resultInEnglish == 'Done') || (!isVaccination && (resultInEnglish == 'Done' || resultInEnglish == 'Normal' || resultInEnglish == "Sample collected" || resultInEnglish == "Negative" || isPassResult));
            to.testStatusError = to.testsPerformed[0].result == null;
          }
        });

        this.isLoading = false;
      } else {
        this.isLoading = false;

        this.isFailed = true;

      }
    });

  }

  viewTest(): void {

  }

  previousPage(): void {
    this.isLoading = true;
    this.router.navigate(['../landing'], { relativeTo: this.route });
  }

  back() {
    this.router.navigate(['../registration', this.eventId, this.eventTitle], { relativeTo: this.route });
  }

  startTest(tests: TestRequestedObject): void {
    this.isLoading = true;
    console.log(tests);
    this.globalData.updateQuestionnaireClientData(tests);
    this.globalData.updateQuestionnaireId(tests.testMapping.questionnaireId);
    let route: String;
    switch (tests.testMapping.workflowId) {
      case "CORPORATEHIVCONSENT":
        route = '../hiv-consent';
        break;
      case "CORPPSACONSENT":
        route = '../psa-consent';
        break;
      case "CORPFLUCONSENT":
        route = '../flu-consent';
        break;
      case "CORPPAPCONSENT":
        route = '../pap-smear-consent';
        break;
      default:
        route = '../questionnaire';
        break;
    }
    this.router.navigate([route], { relativeTo: this.route });
  }

  completeConsent(): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireClientData(this.consentTr);
    this.router.navigate(['../consent'], { relativeTo: this.route });
  }

  reviewTest(tests): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireClientData(tests);
    this.globalData.updateQuestionnaireId(tests.testMapping.questionnaireId);
    this.globalData.updateReviewQuestionnaireId(tests.testsPerformed[0].reviewId);
    this.router.navigate(['../questionnaire'], { relativeTo: this.route });
  }

  viewConsent(): void {

  }
}
