<div class="container">
  <article class="grid">
    <div class="mdc-typography--headline3">
      <p class="text--align-center">{{customTitle}}</p>
    </div>
    <app-info-header></app-info-header>
    <div class="grid__inner grid__inner--layout-c6c">
      <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
        <h3 class="mdc-list-group__subheader" style="padding-left: unset;">Job summary</h3>
        <p class="text--align-left"> </p>
        <p class="text--align-left"><strong>{{consultationDetail?.patientDetail?.jobTitle}}</strong> </p>
      </div>
    </div>
    <div *ngIf="customTitle != 'Consent'">
      <ng-container [formGroup]="formGroup">
        <div class="text--align-center">
          <button (click)="btnViewResults_Click()" [disabled]="isFinalized"
            class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">View Results</button>
        </div>
        <span style="display: none" class="blank-line blank-line-1 d-block"></span>
        <div style="display: none" class="mdc-typography--headline3">
          <p class="text--align-center">Test Verification</p>
        </div>
        <div style="display: none" class="grid__inner grid__inner--layout-c6c">
          <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
            <mat-select (selectionChange)="selectOption($event)" [disabled]="isFinalized" formControlName="verifySelect"
              #verifySelect placeholder="Select status">
              <mat-option value="0">Normal</mat-option>
              <mat-option value="2">Abnormal</mat-option>
              <mat-option value="3">Not done</mat-option>
              <mat-option value="4">Contraindicated</mat-option>
              <mat-option value="5">Awaiting results</mat-option>
              <mat-option value="6">Not required</mat-option>
            </mat-select>
          </div>
        </div>
        <div style="display: none" class="text--align-center">
          <button (click)="btnSubmit_Click()" [disabled]="isFinalized"
            class="mdc-button mdc-button--stroked mdc-ripple-upgraded motion-button--medium">SUBMIT</button>
        </div>
      </ng-container>
    </div>
    <section class="grid__inner">
      <div class="mb-1">
        <span class="blank-line blank-line-2 d-block"></span>
      </div>
      <div class="grid__cell--span-all">
        <div class="grid__inner grid__inner--layout-b8b">
          <div class="grid__cell grid__cell--justify-center">
            <div class="mdc-btn-container">
              <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
                (click)="goForward(stepper)">Next</button>
            </div>
            <div class="mdc-btn-container">
              <div class="grid__cell text--align-center">
                <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                  (click)="goBack(stepper)">Back</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</div>
