import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppointmentsService } from 'src/app/shared/services/appointments.service';
import { Router } from '@angular/router';
import { EXAMINATIONRESULT, GLOBAL_VARS } from '../../../shared/enums';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';
import { clinicianObject } from 'src/app/shared/interfaces/clinicianMapper';

@Component({
  selector: 'app-filtered-appointment',
  templateUrl: './filtered-appointment.component.html',
  styleUrls: ['./filtered-appointment.component.scss']
})

export class FilteredAppointmentComponent implements AfterViewInit {

  resultTypes = new FormControl();
  types: string[] = [];

  clinics: any;

  errorMessage: string;
  userDateRange: boolean = true;

  clinicGuid: any;

  startDate: any;
  endDate: any;

  filterValues: any = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['time', 'type', 'name', 'consent', 'procedure', 'status', 'company', 'actions'];

  word: string;
  filterSelectObj = [] as any;

  appointmentResult: any = [];
  appointmentPicker: FormGroup;
  appointmentSelect: FormGroup;
  typeResultSet = FormGroup;
  isLoading = false;
  showError = false;

  originalSearchData: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  clinician: clinicianObject;



  constructor(
    public fb: FormBuilder,
    private router: Router,
    private globalData: GlobalDataService,
    private appointmentLeads: AppointmentsService,) {
    this.isLoading = true;

    this.globalData.updateBackRoute('/landing');

    this.clinician = JSON.parse(localStorage.getItem("Clinician"));

    // Object to create Filter for
    this.filterSelectObj = [
      /* {
        name: 'TYPE',
        columnProp: 'type',
        options: []
      }, */ {
        name: 'NAME',
        columnProp: 'name',
        options: []
        /*   }, {
            name: 'PROCEDURE',
            columnProp: 'procedure',
            options: [] */
      }, {
        name: 'STATUS',
        columnProp: 'status',
        options: []
      }, {
        name: 'COMPANY',
        columnProp: 'company',
        options: []
      }];


    const today = new Date();

    this.appointmentPicker = new FormGroup({
      dateRangeStart: new FormControl(new Date(today)),
      dateRangeEnd: new FormControl(new Date(today))
    });

    this.appointmentSelect = new FormGroup({
      clinicSelect: new FormControl()
    });

    this.globalData.selectedStartDateVal.subscribe(result => this.startDate = result);
    this.globalData.selectedEndDateVal.subscribe(result => this.endDate = result);

    if (this.startDate != '' && this.endDate != '') {
      this.appointmentPicker = new FormGroup({
        dateRangeStart: new FormControl(new Date(this.startDate)),
        dateRangeEnd: new FormControl(new Date(this.endDate))
      });
    }

    this.globalData.selectedClinicIdVal.subscribe(result => this.clinicGuid = result);
    if (this.clinicGuid != '') {
      this.appointmentSelect = new FormGroup({
        clinicSelect: new FormControl(this.clinicGuid)
      });
    }

    if (this.startDate != '' && this.endDate != '' && this.clinicGuid != '') {
      this.btnSearch_Click();
    }
  }

  ngAfterViewInit() {
    this.isLoading = true;
    this.appointmentLeads.getClinics().subscribe({
      next: data => {
        if (data) {
          this.clinics = [{ id: "", workflowName: 'Select a clinic' }];
          for (var i = 0; i < data.length; i++) {
            if (this.clinician.clinicIds.indexOf(data[i].id) > -1) {
              if (this.clinician.clinicIds.indexOf(data[i].id) > -1) {
                this.clinics.push(data[i]);
              }
            }
          }
        }
        this.isLoading = false;
      },
      error: error => {
        this.errorMessage = error.message;
        this.isLoading = false;
        this.showError = true;
        console.error('There was an error!', error);
      }
    });
  }

  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj: any, key: any) {
    const uniqChk = [] as any;
    fullObj.filter((obj: any) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  // Called on Filter change
  filterChange(filter: any, event: any) {

    this.dataSource.filterPredicate = function (data: any, filter: any) {
      let result = false;
      let name = false;
      let status = false;
      let company = false;
      let type = false;

      if (filter.name) {
        if (data.name.toLowerCase().trim() == filter.name.toLowerCase().trim()) {
          name = true
        }
        else {
          name = false;
        }
      }
      else {
        name = true;
      }

      if (filter.status) {
        if (data.status.toLowerCase().trim() == filter.status.toLowerCase().trim()) {
          status = true
        }
        else {
          status = false;
        }
      }
      else {
        status = true;
      }

      if (filter.company) {
        if (data.company.toLowerCase().trim() == filter.company.toLowerCase().trim()) {
          company = true
        }
        else {
          company = false;
        }
      }
      else {
        company = true;
      }

      if (filter.type) {
        if (filter.type.length > 0) {
          if (filter.type.indexOf(data.type) > -1) {
            type = true
          }
          else {
            type = false;
          }
        }
        else {
          type = true;
        }
      }
      else {
        type = true;
      }
      return name && status && company && type;
    }

    // if (filter.columnProp == 'name') {
    //   this.dataSource.filterPredicate = function (data: any, filter: any) {
    //     return data.name.toLowerCase().trim() == filter.name.toLowerCase().trim();
    //   }
    // }
    // if (filter.columnProp == 'status') {
    //   this.dataSource.filterPredicate = function (data: any, filter: any) {
    //     return data.status.toLowerCase().trim() == filter.status.toLowerCase().trim();
    //   }
    // }
    // if (filter.columnProp == 'company') {
    //   this.dataSource.filterPredicate = function (data: any, filter: any) {
    //     return data.company.toLowerCase().trim() == filter.company.toLowerCase().trim();
    //   }
    // }

    if (filter == "type") {
      this.filterValues["type"] = event.value;
    }
    else {
      this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    }
    this.dataSource.filter = this.filterValues
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      ///* console.log(searchTerms) */;

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found

        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value: any, key: any) => {
      value.modelValue = undefined;
    })
    this.dataSource.filter = "";
    this.clinicGuid = "";
  }

  selectOption(clId) {

    this.clinicGuid = clId ? clId.value : clId;

    this.globalData.updateAppointmentClinicId(clId ? clId.value : clId);
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.startDate = dateRangeStart.value;
    this.endDate = dateRangeEnd.value;

    this.globalData.updateAppointmentDates(this.startDate, this.endDate);
  }

  processData(data) {

    //this.dataSource = data;
    if (data) {
      this.appointmentResult = data;
      this.dataSource.data = data
      const remoteDummyData = []
      this.appointmentResult.forEach(child => remoteDummyData.push(child));
      ;;/*
      this.appointmentResult.sort((a, b) => {
        let aScheduleDateTime = new Date(a.scheduleDate.substring(0, 10) + " " + a.timeStart.substring(0, 5));
        let bScheduleDateTime = new Date(b.scheduleDate.substring(0, 10) + " " + b.timeStart.substring(0, 5));

        return aScheduleDateTime > bScheduleDateTime ? 1 : (bScheduleDateTime > aScheduleDateTime ? -1 : 0);
      });

      this.appointmentResult.forEach(childObj => {


        var testRequestedList;
        var testPerformedList;

        testRequestedList = childObj.consultation.testsRequested;
        testPerformedList = childObj.consultation.testsPerformed;

        var consent = "No";

        var consentTestRequested = testRequestedList?.find(t => t.testMapping.workflowId == "CONSENT001");
        if (consentTestRequested == undefined) {
          var consent = "Yes";
        }
        else {
          //.find(t => t.testMappingId == consentTestRequested.Id);

          if (!consentTestRequested?.testsPerformed.length) {
            consent = "Pending";
          }
          else {
            var consentTestPerformed = consentTestRequested?.testsPerformed.sort((a, b) => a.createDate > b.createDate ? -1 : (b.createDate > a.createDate ? 1 : 0))[0];
            var consentResult = (consentTestPerformed?.resultValue || "") as EXAMINATIONRESULT;
            switch (consentResult) {
              case EXAMINATIONRESULT.CONSENTREFUSED:
                consent = 'No';
                break;
              case EXAMINATIONRESULT.CONSENTGIVEN:
                consent = 'Yes';
                break;
              default:
                consent = 'Pending';
                break;
            }
          }
        }

        var consultationStatus = "";
        var completedORcancelled = false;

        if (childObj.consultation.isCancelled == true) {
          consultationStatus = "Cancelled";
          completedORcancelled = true;
        }
        else if (childObj.consultation.captureComplete == true) {
          consultationStatus = "Finalized";
          completedORcancelled = true;
        }

        if (completedORcancelled == false) {
          if (consent == "Yes") {
            var haveInprogress = false;
            var havePending = false;
            var havePartlyCompleted = false;
            var haveFinished = false;

            if (testRequestedList.find(t => t.status == "inProgress") != undefined) {
              haveInprogress = true;
            }
            if (testRequestedList.find(t => t.status == "pending") != undefined) {
              havePending = true;
            }
            /*  if (testRequestedList.find(t => t.status == "partlyCompleted") != undefined) {
              havePartlyCompleted = true;
            } */
            /*
            if (testRequestedList.find(t => t.status == "finished") != undefined) {
              haveFinished = true;
            }

            if (haveInprogress == true) {
              consultationStatus = "In-Progress";
            }
            else if (havePending == true) {
              consultationStatus = "Waiting room";
            }
            // else if (havePartlyCompleted == true) {
            //   consultationStatus = "Partly Completed";
            // }
            else if (haveFinished == true) {
              consultationStatus = "Completed";
            }
            else {
              consultationStatus = "No Valid tests configured";
            }
          }
          else if (consent == "No") {
            consultationStatus = "Consent Refused";
          }
          else {
            consultationStatus = "Not yet arrived";
          }
        }

        var testMappingList = [];
        var procedureGroupList = "";

        testRequestedList.sort((a, b) => GLOBAL_VARS.CORRECT_GROUP_NAMES.indexOf(a.testMapping.groupName) - GLOBAL_VARS.CORRECT_GROUP_NAMES.indexOf(b.testMapping.groupName));

        // var testGroups = this.groups.map((groupName) => testRequestedList.filter(test => test.testMapping.groupName.toLowerCase() == groupName.toLowerCase()));
        // var tests = [];
        // testGroups.forEach(tgs => {
        //   if (tgs && tgs.length) {
        //    tgs.forEach(t => tests.push(t));
        //   }
        // });
        childObj.consultation.testsRequested = testRequestedList;
        //  = tests;
        //  console.log(tests) 
        testRequestedList.forEach(childTestRequest => {

          if (childTestRequest != 'undefined') {
            if (childTestRequest.testMapping?.groupName != 'Consent') {

              if (!procedureGroupList.includes(childTestRequest.testMapping?.groupName)) {
                procedureGroupList += "" + childTestRequest.testMapping?.groupName + " ";

                var imageSVG = "";
                if (testRequestedList.find(t => t.testMapping.groupName == childTestRequest.testMapping?.groupName && t.status == "pending") != undefined) {
                  imageSVG = "wellness__not-yet-started";
                }
                else if (testRequestedList.find(t => t.testMapping.groupName == childTestRequest.testMapping?.groupName && t.status == "inProgress") != undefined) {
                  imageSVG = "inProgress";
                }
                /*  else if (testRequestedList.find(t => t.testMapping.groupName == childTestRequest.testMapping?.groupName && t.status == "partlyCompleted") != undefined) {
                  imageSVG = "wellness_part_complete";
                } */
                /*
                else if (testRequestedList.find(t => t.testMapping.groupName == childTestRequest.testMapping?.groupName && t.status == "finished") != undefined) {
                  imageSVG = "completed";
                }
                else {
                  imageSVG = "abnormal-round";
                }

                var groupHavePending = false;
                var groupHaveFinished = false;


                if (testRequestedList.find(t => t.testMapping.groupName == childTestRequest.testMapping?.groupName && t.status == "pending") != undefined) {
                  groupHavePending = true;
                }
                if (testRequestedList.find(t => t.testMapping.groupName == childTestRequest.testMapping?.groupName && t.status == "finished") != undefined) {
                  groupHaveFinished = true;
                }

                if (groupHavePending && groupHaveFinished) {
                  imageSVG = "wellness_part_complete";
                }


                const groupImageMapping =
                {
                  groupName: childTestRequest.testMapping?.groupName,
                  imageUrl: imageSVG
                }

                testMappingList.push(groupImageMapping);
              }
            }
          }
        });
        var d = new Date(childObj.scheduleDate);
        var date = childObj.scheduleDate.substring(0, 10);
        var time = childObj.timeStart.substring(0, 5);
        let scheduleDateTime = childObj.scheduleDate.substring(0, 10) + " " + childObj.timeStart.substring(0, 5);

        if (consultationStatus == "Not yet arrived") {
          consent = "Pending";
        }

        if (childObj.consultation.consultationWorkflowStatus == 3) //'ohnpFileReview')
        {
          consultationStatus = "OHNP Review";
        }
        else if (childObj.consultation.consultationWorkflowStatus == 4) // 'ompFileReview')
        {
          consultationStatus = "OMP Review";
        }
        else if (childObj.consultation.consultationWorkflowStatus == 5) // 'finalized')
        {
          consultationStatus = "Finalized";
        }

        var tempTableData =
        {
          date: date,
          time: time,
          type: childObj.consultation.examinationType,
          name: childObj.consultation.patientDetail.fullNames,
          consent: consent,
          procedure: procedureGroupList,
          status: consultationStatus,
          company: childObj.consultation.patientDetail.companyMapping.workflowName,
          procedures: [] = testMappingList,
          consultationId: childObj.consultation.id,
          expanded: false,
        };

        remoteDummyData.push(tempTableData);

      });

      this.dataSource.data = remoteDummyData;*/
      this.originalSearchData = this.dataSource;

      this.types = this.uniqueBy(remoteDummyData, "type");
      //this.types = [...new Map(remoteDummyData.map(item => [item["type"], item])).values()];

      this.filterSelectObj.filter((o: any) => {
        o.options = this.getFilterObject(remoteDummyData, o.columnProp);
      });
    }
  } 

  previousPage() {
console.log("Ping");
  }

  btnSearch_Click() {
    this.isLoading = true;
    this.appointmentLeads.getAppointmentsRange("?clinicId=" + this.clinicGuid + "&fromScheduleDate=" + this.startDate + "&toScheduleDate=" + this.endDate).subscribe({
      next: data => {
        this.processData(data);
        this.isLoading = false;
        ///////////////////////////////////////////////////////////////////////////
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 1000);
      },
      error: error => {
        this.errorMessage = error.message;
        this.isLoading = false;
        this.showError = true;
        console.error('There was an error!', error);
      }
    });


    // Overrride default filter behaviour of Material Datatable
    this.dataSource.filterPredicate = this.createFilter();
    this.resetFilters();


  }

  uniqueBy(arr, prop) {
    return arr.reduce((a, d) => {
      if (!a.includes(d[prop])) { a.push(d[prop]); }
      return a;
    }, []);
  }

  redirectStepper(cId) {

    this.globalData.updateSelectedClient(cId);
    this.router.navigate(['/oh-consultation-summary']);


  }

  selectType(typeValue) {


    var typeString = "";

    if (typeValue.value.length > 0) {

      if (typeValue.value.length == 1) {
        typeString = typeValue.value[0];
      }

      if (typeValue.value.length > 1) {

        typeValue.value.forEach(element => {
          typeString = typeString + element + " && ";
        });

        var n = typeString.lastIndexOf(" && ");
        typeString = typeString.substring(0, n)

      }

      const typeStringObject =
      {
        type: typeString
      }

      this.dataSource.filter = JSON.stringify(typeStringObject)
    }
    else {
      this.resetFilters();
    }

    // testMappingList.push(typeMapping);


  }

}


