
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const southAfricanIdNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const idNumber: string = control.value?.toString().replace(/\D/g, '')
  if (!idNumber) {
    return null; // Return null if the control value is empty
  }

  if (idNumber.length !== 13) {
    return { 'invalidIdLength': true } // Return an error object if the ID number is not 13 digits long
  }

  if (control.value?.toString().indexOf(".") > -1) {
    return { 'invalidSouthAfricanIdNumber': true };
  }

  if (!/^[0-9]{13}/.test(idNumber)) {
    return { 'invalidSouthAfricanIdNumber': true };
  }

  const reverse: string = idNumber.split('').reverse().join('')
  const digits: number[] = reverse.split('').map(Number)
  let oddIndexes: number[] = [];
  let evenIndexes: number[] = [];

  for (let i = 0; i < digits.length; i++) {
    if (i % 2 === 0) {
      evenIndexes.push(digits[i]);
    } else {
      oddIndexes.push(digits[i]);
    }
  }
  const doubledDigits = oddIndexes.map(d => { var doubled = (d * 2); if (doubled > 9) { doubled = doubled - 9; } return doubled; });
  let total: number = 0;
  for (let i = 0; i < doubledDigits.length; i++) {
    total += doubledDigits[i]
  }
  for (let i = 0; i < evenIndexes.length; i++) {
    total += evenIndexes[i];
  }
  if (total % 10 !== 0) {
    return { 'invalidSouthAfricanIdNumber': true }// Return an error object if the checksum is invalid
  }

  return null // Return null if the ID number is valid
}
