<article class="grid">
  <div class="activity-history">
    <div *ngFor="let activity of activities" class="activity-item">
      <div class="activity-date">
        <span class="timestamp">{{ activity.date | date: 'dd MMMM yyyy hh:mm a' }}</span>
      </div>
      <ng-container [ngTemplateOutlet]="getActivityTemplate(activity.activityType)"
        [ngTemplateOutletContext]="{ $implicit: activity }">
      </ng-container>
    </div>
  </div>
</article>

<ng-template #createActivity let-activity>
  <div>{{activity.user}} created booking.</div>
</ng-template>

<ng-template #cancelActivity let-activity>
  <div>
    <div>{{activity.user}} cancelled booking: </div>
    <div>
      <ul>
        <li *ngFor="let activityDetail of activity.details">
          {{activityDetail.message}}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #executeActivity let-activity>
  <div>{{activity.user}} initiated event by starting a consultation.</div>
</ng-template>

<ng-template #planActivity let-activity>
  <div>{{activity.user}} changed event status to "Planned".</div>
</ng-template>

<ng-template #modifyActivity let-activity>
  <div>
    <div>{{activity.user}} made changes: </div>
    <div>
      <ul>
        <li *ngFor="let activityDetail of activity.details">
          <!-- {{activityDetail.message}} -->
          <div [innerHTML]="activityDetail.message"></div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>