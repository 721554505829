import { Component, OnInit } from '@angular/core';
import { ReportingService } from 'src/app/shared/services/reporting.service';

@Component({
  selector: 'app-eapa',
  templateUrl: './eapa.component.html',
  styleUrls: ['./eapa.component.scss']
})
export class EapaComponent implements OnInit {

  isLoading = false;
  errorMessage: string;
  showError = false;


  constructor(private reportingService: ReportingService,) { }

  ngOnInit(): void {
  }

  downloadReport() {
    this.isLoading = true;
    this.reportingService.downloadEAPAConferenceReport().subscribe({
      next: data => {
        this.downloadExcel(data.fileData, data.fileName);
        this.isLoading = false;
      },
      error: error => {
        this.errorMessage = error.message;
        this.isLoading = false;
        this.showError = true;
        console.error('There was an error!', error);
      }
    });
  }

  downloadExcel(base64String, fileName): void {
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }



}
