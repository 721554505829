<div class="container">
  <article class="grid">
    <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading"></three-bar-loader>
    <div class="grid__inner grid__inner--layout-b8b">
      <div class="grid__cell">
        <div class="text--align-center">
          <!-- <h1 class="mdc-typography--headline2">{{providerName}}</h1> -->
          <h1 class="mdc-typography--headline2">Conference registration</h1>
        </div>
        <br />
        <br />
        <br />
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="mdc-list-item text--align-left">
                <strong>User details</strong>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>


            <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Conference Name</mat-label>
                  <input matInput type="text" formControlName="conferenceName" placeholder="e.g. John" readonly>
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Conference date</mat-label>
                  <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="conferenceDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Full name</mat-label>
                  <input matInput type="text" formControlName="name" required placeholder="e.g. John">
                  <mat-error *ngIf="signupForm.get('name').invalid || signupForm.get('name').touched">Full name is
                    required.</mat-error>
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Surname</mat-label>
                  <input matInput type="text" formControlName="surname" required placeholder="e.g. Khumalo">
                  <mat-error *ngIf="signupForm.get('surname').invalid || signupForm.get('surname').touched">Surname is
                    required.</mat-error>
                </mat-form-field>
              </div>

              <mat-radio-group formControlName="searchType" #searchType>
                <mat-radio-button value="id" (click)="OnClick('id')">ID Number</mat-radio-button>
                <mat-radio-button value="dateofbirth" (click)="OnClick('dob')">Date Of Birth</mat-radio-button>
              </mat-radio-group>
              <br />

              <div class="form-group" *ngIf="signupForm.get('searchType').value === 'id'">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>South African ID </mat-label>
                  <input matInput type="number" formControlName="idNumber" (input)="onInput($event)">
                  <mat-error *ngIf="signupForm.get('idNumber').errors?.required">ID is required.</mat-error>
                  <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidSouthAfricanIdNumber">ID is
                    invalid.</mat-error>
                  <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidIdLength">ID is invalid.</mat-error>
                </mat-form-field>
              </div>

              <div class="form-group" *ngIf="signupForm.get('searchType').value === 'dateofbirth'">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Date of birth </mat-label>
                  <input matInput [matDatepicker]="picker" [max]="todayMax" formControlName="dob">
                  <mat-error *ngIf="isControlInvalid('dob')">Date of birth is required</mat-error>
                  <mat-error *ngIf="signupForm.get('dob').invalid && signupForm.get('dob').touched">Date of birth is
                    invalid.</mat-error>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
              </div>
              <br />
              <div class="form-group">
                <h4>Gender </h4>
                <div class="form-group">
                  <mat-radio-group formControlName="genderId" [(ngModel)]="EapaConferencing.genderId">
                    <mat-radio-button *ngFor="let gender of genders"
                      [value]="gender.id">{{gender.description}}</mat-radio-button>
                  </mat-radio-group>
                  <!-- <mat-error *ngIf="isControlInvalid('genderId')">Please select gender</mat-error> -->
                </div>
              </div>
              <br />

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Contact Number</mat-label>
                  <input matInput (input)="onInput($event)" formControlName="contactNumber">
                  <mat-error
                    *ngIf="signupForm.get('contactNumber').errors?.required || signupForm.get('contactNumber').touched">Contact
                    Number is required.</mat-error>
                  <mat-error *ngIf="signupForm.get('contactNumber').errors?.invalidCellphone">Contact number is
                    invalid.</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Email</mat-label>
                  <input matInput type="text" formControlName="email" placeholder="e.g. john.khumalo@gmail.com">
                  <mat-error *ngIf="signupForm.get('email').errors?.required">Email is required.</mat-error>
                  <mat-error *ngIf="signupForm.get('email').errors?.email">Email is required.</mat-error>
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Practice Name</mat-label>
                  <input matInput type="text" formControlName="PracticeName" placeholder="e.g. john khumalo">
                </mat-form-field>
              </div>


              <!-- <div formGroupName="certificationType">
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Certification type</mat-label>
                    <mat-select (selectionChange)="onCertificationSelection($event)" required
                      formControlName="certificationTypeID" #selectedCertificationType>
                      <mat-option value="" selected disabled>e.g. SANC - South African Nursing Council</mat-option>
                      <mat-option *ngFor="let cert of certifications"
                        [value]="cert.id">{{cert.description}}</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="signupForm.get('certificationType.certificationTypeID').invalid && signupForm.get('certificationType.certificationTypeID').touched">Conference
                      certification type is required.</mat-error>
                  </mat-form-field>
                </div>
              </div> -->

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Please select a language</mat-label>
                  <mat-select formControlName="languageId" [(ngModel)]="EapaConferencing.languageId">
                    <mat-option [value]="" disabled selected>Please select a language</mat-option>
                    <mat-option *ngFor="let li of languages" [value]="li.id">{{li.description}}</mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="isControlInvalid('languageId')">Please select a language</mat-error> -->
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Please select a Province</mat-label>
                  <mat-select formControlName="provinceId" placeholder="Please select a Province">
                    <mat-option *ngFor="let li of filteredProvinces" [value]="li.id">{{li.description}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="isControlInvalid('provinceId')">Please select a Province</mat-error>
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Please select the closest town</mat-label>
                  <mat-select formControlName="CityId" [(ngModel)]="EapaConferencing.CityId">
                    <mat-option [value]="" disabled selected>Please select a City</mat-option>
                    <mat-option *ngFor="let li of city" [value]="li.id">{{li.description}}</mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="isControlInvalid('CityId')">Please select a City</mat-error> -->
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Please select a Provider Type</mat-label>
                  <mat-select formControlName="ProviderTypeId" [(ngModel)]="EapaConferencing.ProviderTypeId">
                    <mat-option [value]="" disabled selected>Please select a Provider Type</mat-option>
                    <mat-option *ngFor="let li of ProviderType" [value]="li.id">{{li.description}}</mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="isControlInvalid('ProviderTypeId')">Please select a Provider Type</mat-error> -->
                </mat-form-field>
              </div>

              <div class="form-group radio-space">
                <h4>Willing to travel more than 50KM? </h4>
                <div class="form-group">
                  <mat-radio-group formControlName="WillingToTravelMoreThan50KmId"
                    [(ngModel)]="EapaConferencing.WillingToTravelMoreThan50KmId">
                    <mat-radio-button *ngFor="let travel of WillingToTravelMoreThan50km"
                      [value]="travel.id">{{travel.description}}</mat-radio-button>
                  </mat-radio-group>
                  <!-- <mat-error *ngIf="isControlInvalid('WillingToTravelMoreThan50kmId')">Please select willingness to
                    travel</mat-error> -->
                </div>
              </div>
              <br />

              <div class="form-group radio-space">
                <h4>Willing to sleep over? </h4>
                <div class="form-group">
                  <mat-radio-group formControlName="WillingToSleepOverId"
                    [(ngModel)]="EapaConferencing.willingToSleepOverId">
                    <mat-radio-button *ngFor="let sleep of willingToSleepOver"
                      [value]="sleep.id">{{sleep.description}}</mat-radio-button>
                  </mat-radio-group>
                  <!-- <mat-error *ngIf="isControlInvalid('WillingToSleepOverId')">Please select willingness to
                    sleep</mat-error> -->
                </div>
              </div>
              <br />
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Area of specialisation</mat-label>
                  <input matInput type="text" formControlName="speacialisation" placeholder="e.g. Health Care">
                  <!-- <mat-error *ngIf="signupForm.get('speacialisation').invalid || signupForm.get('speacialisation').touched">Full name is
                    required.</mat-error> -->
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Certification Number</mat-label>
                  <input matInput type="text" formControlName="certNumber" placeholder="e.g. 123456">
                </mat-form-field>
              </div>

              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Certification Type</mat-label>
                  <mat-select (selectionChange)="onCertificationSelection($event)" formControlName=" CertificationType"
                    #selectedCertificationType>
                    <mat-option value="" selected disabled>e.g. SANC - South African Nursing Council</mat-option>
                    <mat-option *ngFor="let cert of certifications" [value]="cert.id">{{cert.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <br />
              <div class="form-group">
                <mat-checkbox required formControlName="discretionApproved">I confirm that all information given is
                  correct and that the {{cert}}
                  membership is active.</mat-checkbox>
                <mat-error
                  *ngIf="signupForm.get('discretionApproved').invalid && signupForm.get('discretionApproved').touched">
                  EAPA Conference confirmation is required.
                </mat-error>
              </div>
              <div id="saveContainer">
                <button
                  [ngClass]="{ 'mdc-button--raised': signupForm.valid && signupForm.dirty, 'mdc-ripple-upgraded': signupForm.valid && signupForm.dirty, 'mdc-button__fill--darkblue': signupForm.valid && signupForm.dirty, 'mdc-button': signupForm.valid && signupForm.dirty, 'mat-raised-button': signupForm.invalid || signupForm.pristine }"
                  [disabled]="!signupForm.get('name').valid || !signupForm.get('surname').valid || !signupForm.get('idNumber').valid"
                  id="saveButton" style="vertical-align: middle;">Save</button>
              </div>
            </form>
            <div class="grid__cell"
              *ngIf="(!signupForm.get('name').valid || !signupForm.get('surname').valid || !signupForm.get('idNumber').valid) && signupForm.dirty">
              <mat-error>
                Invalid form submitted. Please fill in all required fields.
              </mat-error>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </article>
</div>