import { Component, Input, OnInit } from '@angular/core';
import { NgControlStatusGroup } from '@angular/forms';
import { Router } from '@angular/router';

import * as enums from '../../enums';
import { ConsultationObject } from '../../interfaces/consultationMapper';
import { SAPSWellnessEventBookingForm } from '../../interfaces/wellness-event-booking';
import { ConsultationService } from '../../services/consultation.service';
import { GlobalDataService } from '../../services/global-data.service';
import { ScheduleService } from '../../services/schedule.service';
import { SAPSWellnessDayEventBookingService } from '../../services/wellness-day-event-booking.service';

@Component({
  selector: 'app-wellness-event-header',
  templateUrl: './wellness-event-header.component.html',
  styleUrls: ['./wellness-event-header.component.scss']
})
export class WellnessEventHeaderComponent implements OnInit {

  @Input() mode = "event";
  @Input() location: string;
  @Input() eventDate: Date;
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() setUpTime: string;
  @Input() strikeDownTime: string;
  @Input() town: string;
  @Input() province: string;
  @Input() company: string;
  @Input() eventStatusId: string;

  isLoading = false;
  clientId = '';
  bookingEventId = '';
  scheduleDate: any;
  eventObject: SAPSWellnessEventBookingForm

  get planned(): boolean {
    return this.eventSetUpTime && this.eventStatusId && this.eventStatusId != enums.EVENT_STATUS_IDS.BOOKED;
  }

  get companyText(): string {
    if (this.company) {
      return this.company;
    }
    return null; ""
  }

  get eventStartTime(): Date {
    if (this.eventDate && this.startTime) {
      return new Date(this.eventDate + " " + this.startTime);
    }
    return null;
  }

  get eventEndTime(): Date {
    if (this.eventDate && this.endTime) {
      return new Date(this.eventDate + " " + this.endTime);
    }
    return null;
  }

  // setup time
  get eventSetUpTime(): Date {
    if (this.eventStartTime && /^\d{2}\:\d{2}/.test(this.setUpTime)) {
      let hours = Number(this.setUpTime.split(':')[0]) * 60 * 60 * 1000;
      let minutes = Number(this.setUpTime.split(':')[1]) * 60 * 1000;
      let totalTime = hours + minutes;
      return new Date(this.eventStartTime.getTime() - totalTime);
    }

    return null;
  }

  // strike down time
  get eventStrikeDownTime(): Date {
    if (this.eventEndTime && /^\d{2}\:\d{2}/.test(this.strikeDownTime)) {
      let hours = Number(this.strikeDownTime.split(':')[0]) * 60 * 60 * 1000;
      let minutes = Number(this.strikeDownTime.split(':')[1]) * 60 * 1000;
      let totalTime = hours + minutes;
      return new Date(this.eventEndTime.getTime() + totalTime);
    }

    return null;
  }

  constructor(
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    private scheduleDetail: ScheduleService,
    private wellnessDayEventBookingService: SAPSWellnessDayEventBookingService) {
  }

  get eventDescription(): String | null {
    let stationDesc = this.town;
    let provinceDesc = this.province;
    let eventDesc: string;
    let splitStation: string[];
    // if (stationDesc.indexOf(';') > -1) {
    //   splitStation = stationDesc.split(';');
    //   eventDesc = `${provinceDesc} - ${splitStation[0]} station, ${splitStation[1]}`;
    // }
    // else if (stationDesc.indexOf('|') > -1) {
    //   splitStation = stationDesc.split(' | ');
    //   eventDesc = `${provinceDesc} - ${splitStation[0]}, ${splitStation[1]}`;
    // }
    return eventDesc;
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.isLoading = false;
  }
}
