import { Component, OnInit } from '@angular/core';
import { ConsultationObject } from '../../interfaces/consultationMapper';
import { ConsultationService } from '../../services/consultation.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../services/global-data.service';
import { ConsultationReviewSummaryMapper, ReviewSummaryMapper } from '../..//interfaces/reviewMapper';

@Component({
  selector: 'app-q-preview',
  templateUrl: './q-preview.component.html',
  styleUrls: ['./q-preview.component.scss']
})
export class QPreviewComponent implements OnInit {

  groupName: string = "questionnaires";

  isLoading = true;
  consultationId: string;

  mapper: ConsultationReviewSummaryMapper;
  showError = false;


  constructor(private router: Router, private globalData: GlobalDataService, private consultationService: ConsultationService) {
    this.globalData.updateBackRoute('/stepper-overview');
  }

  ngOnInit(): void {
    this.globalData.selectedGroupNameVal.subscribe(result => {
      this.groupName = result || this.groupName;
    },
      error => console.log("Ping"),
      () => this.isLoading = false);

    this.globalData.selectedClientVal.subscribe(r => {
      this.consultationId = r || "94B8C4DD-84FD-4881-92A0-AEA5A989D20E";
      this.consultationService.getConsultationDetail(this.consultationId).subscribe(data => {
        this.mapper = {
          consultation: data as ConsultationObject,
          reviews: data.testsRequested.filter(e => e.testMapping?.groupName.toLowerCase() == this.groupName.toLowerCase() && e.testsPerformed.length)
            .map(function (r) {
              const mappingObject: ReviewSummaryMapper = {
                testRequested: r,
                review: r.testsPerformed.sort((a, b) => a.createDate > b.createDate ? 1 : a.createDate == b.createDate ? 0 : -1)[0]
              };
              return mappingObject;
            })
        }
        this.isLoading = false;
      })
    });
  }

  onInputChange($event): void {
  }

  back(): void {
    this.router.navigate(['/stepper-overview']);
  }

  onSaved(q): void {

  }
}


