import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  formatDate(date: any) {
    return this.datePipe.transform(date, 'yyyy-MM-dd', this.locale);
  }
}
