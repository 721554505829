<article class="grid" *ngIf="!isLoading">
  <div class="grid__inner grid__inner--layout-12">
    <div class="grid__cell" style="background-color: #ffffff;padding: 14px;/*! margin: 15px; */">
      <div class="row" style="position: relative">
        <div style="vertical-align: top;/*! align-items: flex-start; *//*! float: left; */">
          <div class="eapImage">&nbsp;</div>
          <div style="display: inline-block; vertical-align: top;" *ngIf="mode == 'consultation'">
            <h6 class="mdc-typography--headline5" style="padding-bottom: unset !important; display: inline-block; color: #ff9f1a;vertical-align: top;" *ngIf="consultationDetail">
              {{consultationDetail.patientDetail.fullNames}} {{consultationDetail.patientDetail.surname}}
            </h6>
            <div style="text-align: center; display: flex;">
              <div style="display: inline-block; line-height:1.5;">{{consultationDetail.patientDetail.rsaidnumber}}</div>
            </div>
            <div>
              <div style="display: inline-block; line-height:1.5;">&nbsp;</div>
            </div>
          </div>
        </div>
        <div class="cell--align-bottom align-text-bottom mdc-typography--body-1" style="vertical-align: bottom; position: absolute; right: 0; bottom: 0;text-align: right;">
          <div style="line-height: 1.5;">Wellness event</div>
          <div style="line-height: 1.5;">{{event.eventDate | date: 'dd MMMM yyyy'}}</div>
          <div style="line-height: 1.5;">{{eventStartTime | date: 'hh:mm a'}} - {{ eventEndTime | date: 'hh:mm a'}}</div>
          <div style="line-height: 1.5;">{{eventDescription}}</div>
        </div>
        <!-- <div style="vertical-align: bottom; position: absolute; right: 0; bottom: 0;" class="cell--align-bottom align-text-bottom mdc-typography--body-1">
          <div style="line-height:1.5;">&nbsp;</div>
          <div style="font-weight: bold; line-height:1.5;">&nbsp;</div>
          <div style="line-height: 1.5;">&nbsp;</div>
          <div>Wellness event: {{eventTitle}}</div>
        </div> -->
      </div>
      <hr />
    </div>
  </div>
</article>