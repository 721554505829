import { Component, OnInit, Input } from '@angular/core';
import { CSS_POSITION } from '../../enums';

@Component({
  selector: 'three-bar-screen',
  templateUrl: './three-bar-screen.component.html',
  styleUrls: ['./three-bar-screen.component.scss']
})
export class ThreeBarScreenComponent implements OnInit {

  @Input()
  loading: boolean = false;
  @Input()
  hasLoader: boolean = false;
  @Input()
  cssScope: CSS_POSITION = CSS_POSITION.FIXED;;


  constructor() {

    
  }

  ngOnInit() {

  }

}
