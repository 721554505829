<div class="container">
  <article class="grid">
    <section class="mdc-typography--body1">
      <h1 class="mdc-typography--headline2 text--align-center">EAP</h1>
    </section>

    <span class="blank-line blank-line-2 d-block"></span>

    <app-info-header-eap></app-info-header-eap>

    <div class="grid__inner grid__inner--layout-b8b">
      <div class="grid__cell">
        <div class="row">
          <div class="grid__cell" style="text-align: -webkit-center">
            <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
          </div>

          <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
            <div class="row">
              <div style="width: 80%;" class="grid__cell">
                <div class="mdc-typography--subtitle1">
                  Personal and demographical information
                </div>
              </div>
              <div class="grid__cell" style="width: 20%">
                <button style="width: 150px;"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small" (click)="viewDemo()">
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="grid__cell" style="text-align: -webkit-center">
            <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
          </div>

          <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
            <div class="row">
              <div style="width: 80%;" class="grid__cell">
                <div class="grid__cell mdc-typography--subtitle1">
                  Referrers detail
                </div>
              </div>
              <div class="grid__cell" style="width: 20%">
                <button style="width: 150px;"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                  (click)="viewReferrer()">
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="grid__cell" style="text-align: -webkit-center">
            <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
          </div>

          <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
            <div class="row">
              <div style="width: 80%;" class="grid__cell">
                <div class="grid__cell mdc-typography--subtitle1">
                  EAP case manager
                </div>
              </div>
              <div class="grid__cell" style="width: 20%">
                <button style="width: 150px;"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                  (click)="viewCaseManager()">
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="grid__cell" style="text-align: -webkit-center">
            <img *ngIf="!intakeCompleted" class="" style="height: 40px" src="assets/img/consultation/not_completed.svg"
              alt="ico" />
            <img *ngIf="intakeCompleted" class="" style="height: 40px" src="assets/img/consultation/completed.svg"
              alt="ico" />
            <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
          </div>

          <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
            <div class="row">
              <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                <div class="eapAssessment">&nbsp;</div>
              </div>
              <div style="width: 90%" class="grid__cell">
                <div class="grid__cell mdc-typography--subtitle1">
                  Intake assessment
                </div>
              </div>
              <div style="width: 90%" class="grid__cell"></div>
              <div class="grid__cell" style="width: 20%">
                <button style="width: 150px;"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                  [disabled]="intakeCompleted" (click)="completeIntake()">
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="grid__cell" style="text-align: -webkit-center">
            <img *ngIf="!closeOutCompleted" class="" style="height: 40px"
              src="assets/img/consultation/not_completed.svg" alt="ico" />
            <img *ngIf="closeOutCompleted" class="" style="height: 40px" src="assets/img/consultation/completed.svg"
              alt="ico" />
            <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
          </div>

          <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
            <div class="row">
              <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                <div class="eapAssessment">&nbsp;</div>
              </div>
              <div style="width: 90%" class="grid__cell">
                <div class="grid__cell mdc-typography--subtitle1">
                  Close-out assessment
                </div>
              </div>
              <div style="width: 90%" class="grid__cell"></div>
              <div class="grid__cell" style="width: 20%">
                <button style="width: 150px;"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                  [disabled]="!intakeCompleted || closeOutCompleted" (click)="completeCloseOut()">
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
