import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as Enums from 'src/app/shared/enums';
import { AdminAssistantRegistration, HealthPractitionerRegistration } from 'src/app/shared/interfaces/healthPractitionerRegistration';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator';
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator';

@Component({
  selector: 'app-sp-aa-creation',
  templateUrl: './create-admin-assistant.component.html',
  styleUrls: ['./create-admin-assistant.component.scss']
})
export class CreateAdminAssistantComponent implements OnInit {

  formDirective!: FormGroupDirective;
  isLoading: boolean = false;
  signupForm!: FormGroup;
  invalidForm: boolean = false;
  assistant: AdminAssistantRegistration = new AdminAssistantRegistration();
  loggedInServiceProviderId: string;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private globalData: GlobalDataService) {
    this.createForm();
  }

  ngOnInit(): void {
    //this.isLoading = true;
    this.globalData.hideBookingCalendarButton()
    this.globalData.updateBackRoute(['/sp-landing']);
    this.globalData.userServiceProviderId$.subscribe((data) => {
      this.loggedInServiceProviderId = data;
    });
    this.globalData.updateBackRoute(['/sp-access-management']);
  }

  onSubmit() {
    if (this.signupForm.valid) {
      var data = this.signupForm.value;
      this.invalidForm = false;
      this.isLoading = true;
      //
      this.assistant.serviceProviderId = this.loggedInServiceProviderId;
      this.assistant.name = data.name;
      this.assistant.surname = data.surname;
      this.assistant.idNumber = data.idNumber;
      this.assistant.contactNumber = data.contactNumber;
      this.assistant.email = data.email;
      const headers = { 'Content-Type': 'application/json' };
      this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.POST_ADMIN_ASSISTANT_REGISTRATION, this.assistant, { headers }).subscribe({
        next: data => {
          let snackBar = this.snackBar.open('Saved', null, { duration: 3000, panelClass: 'app-notification-success' });
          snackBar.afterDismissed().subscribe(() => this.backToLanding());
        },
        error: error => {
          if (error.error == null) {
            let snackBar = this.snackBar.open('Submission error', null, { duration: 3000, panelClass: 'app-notification-success' });
            //snackBar.afterDismissed().subscribe(() => this.backToLanding());
          } else {
            let snackBar = this.snackBar.open(error.error, null, { duration: 3000, panelClass: 'app-notification-success' });
            //snackBar.afterDismissed().subscribe(() => this.backToLanding());
          }
        }
      })
    } else {
      this.invalidForm = true;
    }
  }
  createForm() {
    this.signupForm = this.fb.group({
      id: null,
      name: [null, Validators.required],
      surname: [null, Validators.required],
      idNumber: [null, [Validators.required, southAfricanIdNumberValidator]],
      contactNumber: [null, [Validators.required, cellNumberValidator]],
      email: [null, [Validators.required, Validators.email]]
    })
  }
  backToLanding() {
    this.assistant = new HealthPractitionerRegistration();
    this.isLoading = false;
    this.signupForm.reset();
    // this.formDirective.resetForm();
    this.router.navigate(['sp-landing']);
  }
  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }
  resetForm() {
    this.signupForm.reset();
    //this.formDirective.resetForm();
  }
  ToLogin() {
    this.isLoading = false;
    this.resetForm();
    this.router.navigate(['login'])
  }
}
