import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { ConsultationService } from '../../services/consultation.service';
import { GlobalDataService } from '../../services/global-data.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  isLoading = false;
  keycloakProfile: KeycloakProfile;

  constructor(
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    private keycloak: KeycloakService) {

  }

  ngOnInit(): void {
    this.keycloak.loadUserProfile().then(userProfile => {
      this.keycloakProfile = userProfile;
    });
  }

  public logout() {
    if (window.location.origin.indexOf('localhost') > 0) {
      this.keycloak.logout(window.location.origin + this.globalData.appDefaultPath());
    }
    else {
      this.keycloak.logout(window.location.origin + "/perigon/#" + this.globalData.appDefaultPath());
    }
  }
}
