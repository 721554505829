<div class="container">
  <h1 mat-dialog-title class="text--align-center">Referrer information</h1>
  <div mat-dialog-content>
    <div class="grid">
      <div class="grid__inner grid__inner--layout-66">
        <div class="grid__cell">Name: </div>
        <div class="grid__cell text--align-center">N/A</div>
        <div class="grid__cell">Surname: </div>
        <div class="grid__cell text--align-center">N/A</div>
        <div class="grid__cell">Position: </div>
        <div class="grid__cell text--align-center">N/A</div>
        <div class="grid__cell">Email address: </div>
        <div class="grid__cell text--align-center">N/A</div>
        <div class="grid__cell">Office number: </div>
        <div class="grid__cell text--align-center">N/A</div>
        <div class="grid__cell">Mobile number: </div>
        <div class="grid__cell text--align-center">N/A</div>
        <div class="grid__cell">Primary referral source: </div>
        <div class="grid__cell text--align-center">Other</div>
      </div>
    </div>
  </div>
  <div class="text--align-center">
    <div mat-dialog-actions>
      <button mat-button (click)="ok()" cdkFocusInitial
        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">OK</button>
    </div>
  </div>
</div>
