import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import * as Enums from 'src/app/shared/enums'
import { WellnessPatientRegistrationModel } from 'src/app/shared/interfaces/patientMapper'
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper'
import { SouthAfricanId } from 'src/app/shared/interfaces/south-african-id'
import { ConsultationService } from 'src/app/shared/services/consultation.service'
import { GlobalDataService } from 'src/app/shared/services/global-data.service'
import { ListService } from 'src/app/shared/services/list.service'
import { PatientService } from 'src/app/shared/services/patient.service'
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator'
import { emailAddressValidator } from 'src/app/shared/validators/email-address.validator'
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator'

@Component({
  selector: 'app-wellness-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class WellnessRegistrationComponent implements OnInit, AfterViewChecked {

  // Element Refs
  @ViewChild('saveButton', { static: false, read: ElementRef }) saveButtonRef!: ElementRef;


  // Forms
  registrationForm: FormGroup;
  searchForm: FormGroup;

  // Lookups
  ranks: ListItemObject[];
  genders: ListItemObject[];
  maritalStatuses: ListItemObject[];
  languages: ListItemObject[];
  medicalAids: ListItemObject[];
  employmentActs: ListItemObject[];

  // Loader Switches
  ranksLoaded: boolean = false;
  employmentActsLoaded: boolean = false;
  gendersLoaded: boolean = false;
  medicalAidsLoaded: boolean = false;
  maritalStatussesLoaded: boolean = false;
  languagesLoaded: boolean = false;
  searching: boolean = false;
  saving: boolean = false;
  consulting: boolean = false;

  // Model
  registrationData: WellnessPatientRegistrationModel = null;

  // Booking arguments
  bookingEventId: string = null;
  bookingEventTitle: string = null;
  saved: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private listService: ListService,
    private patientService: PatientService,
    private router: Router,
    private globalServ: GlobalDataService,
    private consultationService: ConsultationService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.globalServ.userIdVal.subscribe(id => this.globalServ.updateBackRoute(['/wellness-landing']));
    this.activatedRoute.paramMap.subscribe((params) => {
      this.bookingEventId = params.get('eventId');
      this.bookingEventTitle = params.get('eventTitle');
    });

    this.searchForm = this.formBuilder.group({
      searchId: ['', Validators.compose([Validators.required, southAfricanIdNumberValidator])]
    });

    this.registrationForm = this.formBuilder.group({
      fullNames: ['', Validators.required],
      surname: ['', Validators.required],
      idNo: ['', null],
      persalNumber: ['', Validators.required],
      genderId: ['', Validators.required],
      rankId: ['', Validators.required],
      languageId: ['', Validators.required],
      medicalAidId: ['', Validators.required],
      employmentAct: ['', null],
      //medicalAidIdRadio: ['', null],
      medicalAidNumber: ['', null],
      email: ['', emailAddressValidator],
      cellphone: ['', Validators.compose([Validators.required, cellNumberValidator])],
      maritalStatusId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.populateLookups()
  }

  ngAfterViewChecked(): void {
    const secondFormExists = document.getElementById('registrationForm') !== null;
    if (secondFormExists && this.saved) {
      this.saved = false;
      this.saveButtonRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  get employmentActValue(): string | null {
    if (this.ranksLoaded) {
      if (this.registrationData && this.registrationData.rankId) {
        return [
          "1D969A7F-A71C-4C47-A941-ADABED1FE029",
          "2F4B2E0B-7423-4CDE-A5B7-B03B7C51DE66",
          "F9235517-AC23-4E91-B912-21E126FF9802",
          "060541E4-2D8D-4680-9BDD-C685C27DA14D",
          "39FF5A33-44EB-437A-9EE8-DB78B55FFBC3",
          "D0D3937C-B68F-4B70-85E8-887254B5C0E1",
          "F61DA642-7927-417C-9BBD-12B367A6E7DD",
          "59392FB2-2BA8-41C6-A708-AA8ED94E4811"
        ].indexOf(this.registrationData.rankId.toUpperCase()) > -1 ? '4c837d0a-c843-4bac-adc0-99adf6fd9029' : 'f5163c76-02c7-4d47-821a-9fb8819ef8f6';
      }
    }
    return null;
  }
  set employmentActValue(value: string | null) {

  }

  restrictInput(event: any) {
    const pattern = /[\.\+\#]/; // regular expression
    let inputChar = String.fromCharCode(event.charCode);

    if (pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  clinicianId: string;
  createConsultation(): void {
    this.consulting = true;

    this.globalServ.userIdVal.subscribe(val => { this.clinicianId = val })
    this.consultationService.getWellnessDayConsultation(this.bookingEventId, this.registrationData.id, this.clinicianId).subscribe(consultationId => {
      this.globalServ.updateSelectedClient(consultationId);
      this.globalServ.updateBookingEventTitle(this.bookingEventTitle);
      this.router.navigate(['wellness-consultation-summary']);
    })
  }

  search(): void {
    if (this.searchForm.valid) {
      this.searching = true;
      this.patientService.deepSearch(this.searchForm.get('searchId').value).subscribe((data) => {
        data = data || {
          id: "00000000-0000-0000-0000-000000000000",
          fullNames: null,
          surname: null,
          persalNumber: null,
          genderId: new SouthAfricanId(this.searchForm.get('searchId').value).genderId,
          rankId: null,
          idNo: this.searchForm.get('searchId').value,
          languageId: null,
          medicalAidId: null,
          medicalAidNumber: null,
          email: null,
          cellphone: null,
          maritalStatusId: null
        };

        this.registrationData = data;
        this.registrationForm.markAsPristine();
        this.searching = false;
      })
    }
    else {
      this.searchForm.updateValueAndValidity();
    }
  }

  save() {
    if (this.registrationForm.valid) {
      this.saving = true;
      this.registrationData.fullNames = this.registrationData.fullNames.trim().replace(/\s+/, " ");
      this.registrationData.surname = this.registrationData.surname.trim().replace(/\s+/, " ");
      this.registrationData.persalNumber = this.registrationData.persalNumber.trim().replace(/\s+/, " ");
      //this.registrationData.
      this.patientService.updateWellnessPatient(this.registrationData).subscribe({
        next: (data) => {
          this.saved = true;
          this.saving = false;
          this.registrationData.id = data;
          this.registrationForm.markAsPristine();
          this.saveButtonRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
          this.snackBar.open('Member details saved', null, { duration: 2500, panelClass: 'app-notification-success' });
        },
        error: (e) => {
          this.snackBar.open('Submission Error', null, { duration: 2500, panelClass: 'app-notification-error' });
        }
      });
    }
    else {
      this.registrationForm.updateValueAndValidity();
    }
  }

  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  // markFormGroupTouched(formGroup: FormGroup) {
  //   Object.values(formGroup.controls).forEach((control) => {
  //     control.markAsTouched()

  //     if (control instanceof FormGroup) {
  //       this.markFormGroupTouched(control)
  //     }
  //   })
  // }

  isControlInvalid(controlName: string): boolean {
    const control = this.registrationForm.get(controlName) || this.searchForm.get(controlName);
    return control.invalid && control.touched;
  }

  populateLookups(): void {
    this.listService.get(Enums.LISTS.SAPS_RANKS).subscribe((data) => {
      this.ranks = data.listItems
      this.ranksLoaded = true
    })
    this.listService.get(Enums.LISTS.EMPLOYMENT_ACTS).subscribe((data) => {
      this.employmentActs = data.listItems
      this.employmentActsLoaded = true
    })
    this.listService.get(Enums.LISTS.GENDERS).subscribe((data) => {
      this.genders = data.listItems
      this.gendersLoaded = true
    })
    this.listService.get(Enums.LISTS.MEDICAL_AIDS).subscribe((data) => {
      this.medicalAids = data.listItems
      this.medicalAidsLoaded = true
    })
    this.listService.get(Enums.LISTS.MARITAL_STATUSES).subscribe((data) => {
      this.maritalStatuses = data.listItems
      this.maritalStatussesLoaded = true
    })
    this.listService.get(Enums.LISTS.LANGUAGES).subscribe((data) => {
      this.languages = data.listItems
      this.languagesLoaded = true
    })
  }

}
