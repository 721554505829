<div class="container">
  <h1 mat-dialog-title class="text--align-center">Personal and demographical information</h1>
  <div mat-dialog-content>
    <div class="grid">
      <div class="grid__inner grid__inner--layout-66">
        <div class="grid__cell">Name: </div>
        <div class="grid__cell">Izak</div>
        <div class="grid__cell">Surname: </div>
        <div class="grid__cell">Steyn</div>
        <div class="grid__cell">ID number: </div>
        <div class="grid__cell">8608115031082</div>
        <div class="grid__cell">Medical aid: </div>
        <div class="grid__cell">Momentum medical scheme</div>
        <div class="grid__cell">Mobile number: </div>
        <div class="grid__cell">062 268 5531</div>
        <div class="grid__cell">Alternative number: </div>
        <div class="grid__cell">-</div>
        <div class="grid__cell">EAP customer: </div>
        <div class="grid__cell">SARS</div>
        <div class="grid__cell">Employee number: </div>
        <div class="grid__cell">55268</div>
        <div class="grid__cell">Occupation: </div>
        <div class="grid__cell">Auditor</div>
        <div class="grid__cell">Department: </div>
        <div class="grid__cell">-</div>
        <div class="grid__cell">Business unit: </div>
        <div class="grid__cell">-</div>
        <div class="grid__cell">Gender: </div>
        <div class="grid__cell">Male</div>
        <div class="grid__cell">Marital status: </div>
        <div class="grid__cell">Married</div>
      </div>
    </div>
  </div>
  <div class="text--align-center">
    <div mat-dialog-actions>
      <button mat-button (click)="ok()" cdkFocusInitial
        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">OK</button>
    </div>
  </div>
</div>
