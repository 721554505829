import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, of, tap } from 'rxjs'
import * as Enums from '../../shared/enums'
import { HealthPractitionerEditMapper } from '../interfaces/health-practitioner-edit-mapper'
import { GlobalDataService } from './global-data.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  httpOptions: any = {};
  constructor(
    public http: HttpClient,
    private globalServ: GlobalDataService,
  ) { }

  getUserDetails(userId: string): Observable<HealthPractitionerEditMapper> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<HealthPractitionerEditMapper>(Enums.API_PATH + Enums.API_PATHS.GET_HEALTH_PRACTITIONER + userId, { headers })
      .pipe(
        tap(data => console.log("Ping")),
        catchError(this.handleError('Unable to get users', undefined))
      );
  }

  createHealthPractitioner(registrationData: any): any {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.POST_HEALTH_PRACTITIONER_REGISTRATION, registrationData, { headers }).subscribe({
      next: data => {
        console.log(data)
        return true;
      },
      error: error => {
        console.log(error)
        return false;
      }
    })
  }

  GetServiceProviderDetails(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.Get_Service_Provider_Details + id, this.httpOptions).pipe(
      tap(data => console.log(data)),
      catchError(this.handleError('Unable to get service provide details', undefined))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error)
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T)
    }
  }

  private log(message: string) {
    console.log(`${message}`)
  }
}
