<article class="grid">
	<div class="grid__inner grid__inner--layout-b8b">
		<div class="grid__cell" style="background-color: #ffffff;padding: 14px;/*! margin: 15px; */">
      <div class="row" style="position: relative" *ngIf="consultationDetail">
        <div style="vertical-align: top;/*! align-items: flex-start; *//*! float: left; */">
          <div class="eapImage">&nbsp;</div>
          <div style="display: inline-block; vertical-align: top;">
            <h6  class="mdc-typography--headline5" style="padding-bottom: unset !important; display: inline-block; color: #ff9f1a;vertical-align: top;">
              {{consultationDetail.patientDetail.fullNames}}
            </h6>
            <div style="text-align: center; display: flex;">
              <div style="display: inline-block; line-height:1.5;">{{scheduleDate | date: 'dd MMMM yyyy'}}</div>
            </div>
            <div>
              <div style="display: inline-block; line-height:1.5;">{{consultationDetail.patientDetail.rsaidnumber}}</div>
            </div>
          </div>
        </div>
        <div style="vertical-align: bottom; position: absolute; right: 0; bottom: 0;" class="cell--align-bottom align-text-bottom mdc-typography--body-1">
          <div style="line-height:1.5;">&nbsp;</div>
          <div style="font-weight: bold; line-height:1.5;">&nbsp;</div>
          <div style="line-height: 1.5;">&nbsp;</div>
          <div>Type: {{consultationDetail.examinationType}}</div>
        </div>
      </div>
      <hr/>
    </div>
  </div>
  <span class="blank-line blank-line-2 d-block"></span>
</article>
