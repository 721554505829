export class EAPAConferencing {
  PracticeName: string;
  Fullname: string;
  Surname: string;
  Idnumber: string;
  PassportNumber: string | null;
  genderId: string | null
  ContactNumber: string;
  EmailAddress: string;
  CertificationType: string;
  CertificationValid?: boolean;
  languageId: string | null;
  provinceId: string | null;
  CityId: string | null;
  ProviderTypeId: string | null;
  WillingToTravelMoreThan50KmId: string | null;
  willingToSleepOverId: string | null;
  otherProviderType?: string;
  certNumber: number;
  speacialisation: string;
  conferenceName: string | null;
  conferenceDate: Date | null;
  dob: Date | null;
}


