import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as Enums from 'src/app/shared/enums';
import { EAPAConferencing } from 'src/app/shared/interfaces/EAPAConferencing';
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EAPAConference } from 'src/app/shared/services/EAPAConference.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ListService } from 'src/app/shared/services/list.service';
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator';
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator';

//import { eapaConferencingRegistration } from 'src/app/shared/interfaces/eapaConferencingRegistration'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  isLoading: boolean = false;
  signupForm!: FormGroup;

  providerName: string;
  cert: string;
  invalidForm: boolean = false;
  eapaConferencingRegistration: any;

  //Lookups
  certifications: ListItemObject[];
  languages: ListItemObject[];
  provinces: ListItemObject[];
  filteredProvinces: ListItemObject[];

  city: ListItemObject[];
  ProviderType: ListItemObject[];
  WillingToTravelMoreThan50km: ListItemObject[];
  willingToSleepOver: ListItemObject[];

  genders: ListItemObject[];


  formValid: boolean = false;
  startDate: Date = new Date(2024, 9, 13); // January 1, 2015
  maxDate: Date = new Date(2025, 0, 1); // January 1, 2020
  todayMax: Date = new Date()

  // Loader Switches
  gendersLoaded: boolean = false;
  languagesLoaded: boolean = false;
  provincesLoaded: boolean = false;
  citiesLoaded: boolean = false;
  providerTypeLoaded: boolean = false;

  EapaConferencing: EAPAConferencing = new EAPAConferencing();

  formDirective!: FormGroupDirective;
  selectedFile: string;
  //loggedInServiceProviderId: string;
  //clinicianDetailsObj: any;
  // showOtherProviderType: boolean = false;


  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private listService: ListService

  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.isLoading = false;

    this.listService.get(Enums.LISTS.HP_CERTIFICATION_TYPE).subscribe((data) => {
      this.certifications = data.listItems;
      this.isLoading = false;
    })

    this.listService.get(Enums.LISTS.GENDERS).subscribe((data) => {
      this.genders = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.gendersLoaded = true
    })

    this.listService.get(Enums.LISTS.LANGUAGES).subscribe((data) => {
      this.languages = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.languagesLoaded = true
    })

    this.listService.get(Enums.LISTS.PROVINCES).subscribe(data => {
      this.provinces = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.filteredProvinces = this.provinces.filter(province =>
        province.description.trim().toLowerCase() !== 'head office'
      );
      console.log('Filtered provinces:', this.filteredProvinces);
      this.provincesLoaded = true;
    })

    this.listService.get(Enums.LISTS.CITIES).subscribe((data) => {
      this.city = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.citiesLoaded = true
    })
    this.listService.get(Enums.LISTS.PROVIDER_TYPES).subscribe((data) => {
      this.ProviderType = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.providerTypeLoaded = true
    })
    this.listService.get(Enums.LISTS.YES_NO).subscribe((data) => {
      this.willingToSleepOver = data.listItems;
      this.WillingToTravelMoreThan50km = data.listItems;
    })

  }

  // onProviderTypeChange(event: any) {
  //   this.showOtherProviderType = event.value === 'other';
  //   if (this.showOtherProviderType) {
  //     this.signupForm.get('otherProviderType').setValidators([Validators.required]);
  //   } else {
  //     this.signupForm.get('otherProviderType').clearValidators();
  //   }
  //   this.signupForm.get('otherProviderType').updateValueAndValidity();
  // }

  createForm() {
    this.signupForm = this.fb.group({
      id: null,
      conferenceName: ['EAPA'],
      conferenceDate: [new Date()],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      idNumber: [null, [Validators.required, southAfricanIdNumberValidator]],
      genderId: [''],
      contactNumber: [null, [Validators.required, cellNumberValidator]],
      email: [null, [Validators.required, Validators.email]],
      PracticeName: [null],
      PracticeNumber: [null],
      CertificationType: [null],
      speacialisation: [null],
      certNumber: [null],
      certification: [null],
      discretionApproved: [null, [Validators.required]],
      languageId: [''],
      provinceId: [null],
      CityId: [null],
      ProviderTypeId: [null],
      otherProviderType: [''],
      WillingToTravelMoreThan50KmId: [null],
      WillingToSleepOverId: [null],
      searchType: [null, Validators.required],
      dob: [null, Validators.required]
    })
  }

  backToLanding() {
    this.EapaConferencing = new EAPAConferencing();
    this.isLoading = false;
    this.signupForm.reset();
    //this.createForm.resetForm();
    this.router.navigate(['app-eapa']);
  }

  OnClick(data: any) {
    if (data == "dob") {
      let idNum = this.signupForm.get("idNumber");
      idNum.clearValidators();
      idNum.updateValueAndValidity();

      let dob = this.signupForm.get("dob");
      dob.setValue('');
      dob.setValidators(Validators.required);
      dob.updateValueAndValidity();
    }
    else {
      let dob = this.signupForm.get("dob");
      dob.clearValidators();
      dob.updateValueAndValidity();

      let idNum = this.signupForm.get("idNumber");
      idNum.setValue('');
      idNum.setValidators([Validators.required, southAfricanIdNumberValidator]);
      idNum.updateValueAndValidity();
    }
  }


  onSubmit() {
    if (this.signupForm.valid) {
      var data = this.signupForm.value;
      this.invalidForm = false;
      this.isLoading = true;
      this.EapaConferencing.Fullname = data.name;
      this.EapaConferencing.Surname = data.surname;
      this.EapaConferencing.Idnumber = data.idNumber;
      this.EapaConferencing.dob = data.dob;
      this.EapaConferencing.ContactNumber = data.contactNumber;
      this.EapaConferencing.EmailAddress = data.email;
      this.EapaConferencing.PracticeName = data.PracticeName;
      this.EapaConferencing.languageId = data.languageId;
      //this.EapaConferencing.CertificationType = data.certificationType;
      this.EapaConferencing.CertificationValid = data.CertificationValid;
      this.EapaConferencing.certNumber = data.certNumber;
      this.EapaConferencing.CityId = data.CityId;
      this.EapaConferencing.speacialisation = data.speacialisation;
      this.EapaConferencing.conferenceName = data.conferenceName;
      this.EapaConferencing.conferenceDate = data.conferenceDate;

      if (data.ProviderTypeId === 'other') {
        this.EapaConferencing.ProviderTypeId = null;
        this.EapaConferencing.otherProviderType = data.otherProviderType;
      } else {
        this.EapaConferencing.ProviderTypeId = data.ProviderTypeId;
        this.EapaConferencing.otherProviderType = null;
      }


      this.EapaConferencing.provinceId = data.provinceId,
        this.EapaConferencing.willingToSleepOverId = data.WillingToSleepOverId,
        this.EapaConferencing.WillingToTravelMoreThan50KmId = data.WillingToTravelMoreThan50KmId;
      this.EapaConferencing.PassportNumber = data.PassportNumber;
      this.EapaConferencing.genderId = data.genderId;


      if (this.EapaConferencing.Fullname && this.EapaConferencing.Surname && this.EapaConferencing.Idnumber) {
        this.formValid = true;
      }

      console.log("Conferencing Object", this.EapaConferencing);

      const headers = { 'Content-Type': 'application/json' };
      this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.POST_EAPA_CONFERENCING_REGISTRATION, this.EapaConferencing, { headers }).subscribe({
        next: data => {
          let snackBar = this.snackBar.open('Saved', null, { duration: 3000, panelClass: 'app-notification-success' });
          snackBar.afterDismissed().subscribe(() => this.backToLanding());
        },
        error: error => {
          if (error.error == null) {
            let snackBar = this.snackBar.open('Submission error', null, { duration: 3000, panelClass: 'app-notification-success' });
            //snackBar.afterDismissed().subscribe(() => );
          } else {
            let snackBar = this.snackBar.open(error.error, null, { duration: 3000, panelClass: 'app-notification-success' });
            //snackBar.afterDismissed().subscribe(() => this.backToLanding());
          }
        }
      })
    } else {
      this.invalidForm = true;
    }
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.signupForm.get(controlName);
    return control.invalid && control.touched;
  }

  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  onFileSelect(event: Event) {
    this.isLoading = true;
    const file = (event.target as HTMLInputElement).files[0];
    //error changes
    if (file) {
      if (file) {

        const reader = new FileReader();

        reader.onload = (e: any) => {

          const base64 = e.target.result.split(',')[1];

          this.selectedFile = base64;
        };

        reader.readAsDataURL(file);
        this.isLoading = false;
      }
    }
  }

  onCertificationSelection(event) {
    //   //   //SANC selected
    if (event.value == "f6ff76d6-9158-466f-90e8-ca8e3449869c") {
      this.EapaConferencing.CertificationType = "SANC";
    }
    //   //   //HPCSA selected
    if (event.value == "893a2f84-3c1f-4502-adf2-6bdf63afd322") {
      this.EapaConferencing.CertificationType = "HPCSA";
    }
    //   //   //SACSSP selected
    if (event.value == "8cd1ffc4-a5d2-4f16-ad7b-abee8cf85cec") {
      this.EapaConferencing.CertificationType = "SACSSP";
    }
  }
}

