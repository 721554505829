import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCustomNumberFormat]'
})
export class CustomNumberFormatDirective {

  constructor(private el: ElementRef) { }

  @HostListener('blur') onBlur() {
    let value: number = this.el.nativeElement.value;
    if (value != null) {
      this.el.nativeElement.value = value.toString().padStart(2, '0');
    }
  }

  // Optional: Implement additional logic for converting input back to a number when needed
}

