import {Injectable} from '@angular/core';
import {GlobalDataService} from './global-data.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import * as Enums from '../../shared/enums';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
                    private globalServ: GlobalDataService) {
    }



    getClinics(): Observable<any> {

      this.httpOptions = this.globalServ.getHttpOptions();
      return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_CLINICS, this.httpOptions.headers).pipe(
        tap(data => console.log("Ping")),
        catchError(this.handleError('CLINIC:Get All Clinic details ', undefined)));
    }

    getListItems(listName): Observable<any> {

      var apiPath = Enums.API_PATHS.LIST_ITEMS_BY_NAME;
      var paramPath = apiPath.replace("{name}", listName);

      this.httpOptions = this.globalServ.getHttpOptions();
      return this.http.get(Enums.API_PATH + "/" + paramPath, this.httpOptions.headers).pipe(
        tap(data => console.log("Ping")),
        catchError(this.handleError('LIST:Get All list details ', undefined)));
    }


    getAppointments(params): Observable<any> {

     /*  let param = new HttpParams();
        param.append("clinicId", clinicId) ;
        param.append("scheduleDate", appointmentDate) ; */

      this.httpOptions = this.globalServ.getHttpOptions();
       return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_APPOINTMENTS + params, {headers:this.httpOptions.headers} ).pipe(
        tap(data => console.log("Ping")),
        catchError(this.handleError('APPOINTMENT:Get All appointment details ', undefined)));
    }


    getAppointmentsRange(params): Observable<any> {

      /*  let param = new HttpParams();
         param.append("clinicId", clinicId) ;
         param.append("scheduleDate", appointmentDate) ; */

       this.httpOptions = this.globalServ.getHttpOptions();
        return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_APPOINTMENTS_RANGE + params, {headers:this.httpOptions.headers} ).pipe(
         tap(data => console.log("Ping")),
         catchError(this.handleError('APPOINTMENT:Get All appointment details ', undefined)));
     }


     putTestPerformed(body): Observable<any> {

      this.httpOptions = this.globalServ.getHttpOptions();
      return this.http.put(Enums.API_PATH + Enums.API_PATHS.PUT_TESTPERFORMED, body, this.httpOptions).pipe(
        tap(data => console.log("Ping")),
        catchError(this.handleError('Unable to update testperformed.', undefined)));
    }


    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        console.error(error);
        this.log(`${operation} failed: ${error.message}`);
        return of(result as T);
      };
    }

    private log(message: string) {
      console.log(`${message}`);
    }

    reduceObject(obj): any {

      /* console.log(obj) */;
      return obj;
    }


}
