import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { GlobalDataService } from './shared/services/global-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  globalData: GlobalDataService;

  constructor(protected readonly router: Router, protected readonly keycloak: KeycloakService, gd: GlobalDataService) {
    super(router, keycloak);
    this.globalData = gd;
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //TODO: Implement after token has been added
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      let
        origin = window.location.origin,
        redirectUrl = origin + (origin.indexOf('localhost') > 0 ? "" : "/perigon/#") + this.globalData.appDefaultPath();
      await this.keycloak.login({ redirectUri: redirectUrl });

      // if (window.location.origin.indexOf('localhost') > 0) {
      //   await this.keycloak.login({
      //     redirectUri: window.location.origin + "/landing",
      //   });
      // }
      // else {
      //   await this.keycloak.login({
      //     redirectUri: window.location.origin + "/perigon/#/landing",
      //   });
      // }
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    this.globalData.updateUserRoles(this.roles);
    // Allow the user to to proceed if no additional roles are required to access the route.
    if (requiredRoles.length === 0) {
      return true;
    }
    //console.log(this.roles);
    // Allow the user to proceed if all the required roles are present.
    if (!(requiredRoles instanceof Array)) {
      return this.roles.includes(requiredRoles);
    }
    else {
      let result = false;

      // return requiredRoles.every((role) => this.roles.includes(role));
      for (let role of requiredRoles) {
        if (this.roles.includes(role)) {
          result = true;
          break;
        }
      };

      return result;
    }
  }
}
