import { Component, OnInit } from '@angular/core';
import { ConsultationObject } from '../../interfaces/consultationMapper';
import { ConsultationService } from '../../services/consultation.service';
import { GlobalDataService } from '../../services/global-data.service';
import { Router } from '@angular/router';
import { ScheduleService } from '../../services/schedule.service';

@Component({
  selector: 'app-info-header-eap',
  templateUrl: './info-header-eap.component.html',
  styleUrls: ['./info-header-eap.component.scss']
})
export class InfoHeaderEAPComponent implements OnInit {

  isLoading = false;
  nameIconClass: string = 'nameIcon';
  consultationSummaryClass: string = 'baId';
  routeIncludesConsultationSummary: boolean = true;
  scheduleDate: Date = new Date();

  constructor(private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private scheduleDetail: ScheduleService) {
    this.routeIncludesConsultationSummary = true;
    this.nameIconClass = this.routeIncludesConsultationSummary ? 'nameIcon' : 'nameIconNoId';
    this.consultationSummaryClass = this.routeIncludesConsultationSummary ? 'baId' : 'baNoId';
  }

  ngOnInit(): void {

  }
}
