import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { TestRequestedObject } from 'src/app/shared/interfaces/consultationMapper';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-eap-intake',
  templateUrl: './eap-intake.component.html',
  styleUrls: ['./eap-intake.component.scss']
})
export class EAPIntakeComponent implements OnInit {
  isSubmit = false;
  isLoading = false;
  showError = false;

  // Variables for handling questionnaires
  consultationId: string = '8B135CD0-E0AD-4CF2-8A49-F0D113182166'; //Req
  questionnaireId: string = '52B3CB77-31F1-436C-8671-1404BDE39C0D'; //Req

  // Variables for handling reviews
  reviewId: string; //Req

  testRequested: TestRequestedObject = {
    id: '8A52BAC9-1961-44C8-94BE-23F118043689',
    consultationId: '8B135CD0-E0AD-4CF2-8A49-F0D113182166',
    testMappingId: '42D94DA1-31A1-4726-AA70-C6164B1BF859',
    sequenceNo: 5,
    active: true,
    status: 0,
    createDate: new Date('2022-07-19 12:44:30.980'),
    createName: 'Perigon Service',
    changeDate: null,
    changeName: null,
    groupName: 'EAP',
    notes: '',
    testsPerformed: [],
    testMapping: {
      id: '42D94DA1-31A1-4726-AA70-C6164B1BF859',
      ocsaid: '00000000-0000-0000-0000-000000000000',
      ocsaname: null,
      workflowId: 'EAPIAA',
      workflowName: 'Intake Assessment', 
      questionnaireId: '52B3CB77-31F1-436C-8671-1404BDE39C0D', 
      active: true,
      groupName: 'EAP',
      summary: null,
      detail: 'EAP Intake Assessment',
      iconName: null
    }
  }; //Req
  consultationSelection: string = 'Questionnaire'; //Req

  constructor(private router: Router, private globalData: GlobalDataService, private questionnaireService: QuestionnaireService, private snackBar: MatSnackBar) {

    this.globalData.updateBackRoute('/eap');

  }

  ngOnInit(): void {

    // Get the consultation selection (indicates if a questionnaire of review was selected)
  //this.snackBar.open('Submitted', null, { duration: 25000000 }).afterDismissed().subscribe(() => this.router.navigate(['/eap']));

    this.isLoading = false;
  }

  onSaved(): void {
    this.globalData.setIntakeCompleted(true);
    this.snackBar.open('Submitted', null, { duration: 2500 }).afterDismissed().subscribe(() => this.router.navigate(['/eap']));
  }
}
