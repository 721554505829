import { Component, OnInit } from '@angular/core';
import { ClinicianService } from 'src/app/shared/services/clinician.service';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
//TODO: Add user profile here
// import { KeycloakService } from 'keycloak-angular';
import * as Enums from 'src/app/shared/enums';
import { clinicianObject } from 'src/app/shared/interfaces/clinicianMapper';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-sp-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class SPLandingPageComponent implements OnInit {

  isLoading = true;
  badgeValue = 0;
  userManagementAccess: boolean = false;
  accName: string;

  constructor(
    private consultations: ConsultationService,
    private clinician: ClinicianService,
    private globalData: GlobalDataService,
  ) { }

  ngOnInit(): void {
    //only service providers can add health practioners in order to link the service provider id to the clinician
    this.globalData.userRolesVal.subscribe(ur => {
      (ur as any[]).forEach(element => {
        if (element == Enums.ROLES.SERVICE_PROVIDER) {
          this.userManagementAccess = true;
        }
      });
    });
    this.isLoading = false;
    let clinics: string[] = [];
    this.globalData.userFullNameVal.subscribe(user => {
      this.accName = user;
    })
  }

  ContinueLogin(clinician: clinicianObject, clinics: string[]) {
    let ohnpCount = 0;
    let ompCount = 0;

    // Check that clinic list is up to date
    clinician.clinicIds.forEach(clinic => {
      clinic = clinic.toLowerCase();
    })

    localStorage.setItem("Clinician", JSON.stringify(clinician));
  }

}
