import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { ArrayFunctions } from 'src/app/shared/array-functions';

import { SignatureFieldComponent } from '../../../shared/components/signature-field/signature-field.component';
import { WELLNESSCONSENTSTATUS } from '../../../shared/enums';
import { WellnessConsentMapper } from '../../../shared/interfaces/consentMapper';
import { TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

@Component({
  selector: 'app-wellness-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class WellnessConsentComponent implements OnInit {
  public form: FormGroup;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;

  sig1Border = false;
  sig2Border = false;
  userId: string = null;

  descriptions: Array<string> = [
    null,
    "I agree to have my TB status confirmed",
    "I agree to complete a TB questionnaire",
    "I agree to have my HIV status confirmed",
    "I agree to complete a psychosocial wellness questionnaire",
    "I understand and agree that all health information supplied by me in connection with my personal health will be used by Metropolitan HRM, my medical scheme and SAPS to assess my risk and I agree that the information can be sent to Metropolitan HRM, my medical scheme and SAPS to be used to suggest appropriate intervention programmes aimed at improving my health risk and that this information will be kept confidential",
    "I understand and agree that information relevant to my current health can be disclosed to third parties, for the purposes of analysis without the disclosure of my identity",
    "I understand and agree that I will receive a sms regarding my medical condition",
    "I understand and agree  that anonymous data will be shared with my employer to help them understand health trends in my company and to further help me and my colleagues"
  ]; userFullName: string;
  ;
  consents = Object.keys(WELLNESSCONSENTSTATUS)
    .filter(Number)
    .map((k, i) => ({
      key: k,
      label: WELLNESSCONSENTSTATUS[k],
      description: this.descriptions[i]
    }));

  sectionOne: FormGroup;

  showValidationErrors = false;

  constructor(private fb: FormBuilder, private snackBar: MatSnackBar, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private questionnaireService: QuestionnaireService) {
    this.form = fb.group({
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required]
    });
    this.sectionOne = this.fb.group({
      consent: this.fb.array(this.consents.map(c => this.fb.control(null)))
    });
  }

  get consentValues() {
    return this.formValueToBit(WELLNESSCONSENTSTATUS, this.sectionOne.value.consent);
  }

  private formValueToBit(enumeration: object, selection: boolean[]) {
    const bits = this.enumToBitValues(enumeration);
    return selection
      .reduce((total, selected, i) => total + (selected ? bits[i] : 0), 0);
  }

  get incompleteConsents() {
    return this.consentValues !== 511;
  }

  get incompleteDataConsents() {
    return this.consentValues < 480;
  }

  private bitToFormValue(enumeration: object, bit: number) {
    const bits = this.enumToBitValues(enumeration);
    return bits.map(b => (bit & b) === b);
  }

  private enumToBitValues(enumeration: object) {
    return Object.keys(enumeration)
      .map(Number)
      .filter(Boolean);
  }
  // set the dimensions of the signature pad canvas
  public beResponsive() {
    console.log('Resizing signature pad canvas to suit container size');
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
    sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
  }

  public setOptions() {
    this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.sigs.last.signaturePad.set('penColor', 'rgb(255, 255, 0)');
    this.sigs.last.signaturePad.set('backgroundColor', 'rgb(0, 0, 255)');
    this.sigs.last.signaturePad.clear();
    // clearing is needed to set the background colour
  }

  public clear() {
    this.sigs.first.clear();
    this.sigs.last.clear();
  }

  signatureImg: string;
  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 250,
    canvasHeight: 200
  };

  agreeList = [
    { name: 'Yes', id: 0, value: true },
    { name: 'No', id: 1, value: false }
  ];


  isLoading = false;
  showError = false;
  clientID = '';
  userRoles: any[] = [];
  userRole = '';
  consentMapper: WellnessConsentMapper;
  testRequested: TestRequestedObject;
  testPerformed: any;
  urVal = {
    Clinician: 1,
    Technician: 2,
    Manager: 3,
    Reception: 4,
    Generic: 5
  };
  showDownload = false;
  downloadButton = null;
  href = "";
  download = "";

  ngOnInit(): void {
    this.globalData.userRolesVal.subscribe(result => {
      this.userRoles = result as any[];
      this.userRole = this.userRoles.filter(r => r.indexOf("Perigon_") > -1).map(r => r.replace("Perigon_", "")).sort((a, b) => this.urVal[a] === this.urVal[b] ? 0 : (this.urVal[a] < this.urVal[b] ? -1 : 1))[0];
    });
    this.globalData.userIdVal.subscribe(result => this.userId = result);
    this.globalData.updateBackRoute('/wellness-event/saps/consultation');
    this.globalData.selectedClientVal.subscribe(result => this.clientID = result);
    this.globalData.userFullNameVal.subscribe(result => this.userFullName = result);
    this.globalData.questionnaireClientDataVal.subscribe(tests => { this.testRequested = tests; });

    this.isLoading = true;
    this.consultationService.getConsultationDetail(this.clientID).subscribe(result => {
      console.log(result);

      if (result) {
        this.consentMapper = {
          member_FullName: `${(result.patientDetail.fullNames || '')} ${(result.patientDetail.surname || '')}`,
          member_IdNumber: result.patientDetail.rsaidnumber,
          consultationDetailId: this.clientID,
          member_Signature: undefined,
          member_SignatureDate: undefined,
          examiner_Signature: undefined,
          examiner_SignatureDate: undefined,
          consents: 0,

        };
      }
      this.isLoading = false;
    });
  }

  drawComplete(sig): void {
    if (sig.includes('emp1')) {
      this.consentMapper.examiner_Signature = this.signaturePad.toDataURL();
      this.consentMapper.examiner_SignatureDate = new Date();

    } else if (sig.includes('client')) {
      this.consentMapper.member_Signature = this.signaturePad.toDataURL();
      this.consentMapper.member_SignatureDate = new Date();
    }
  }

  drawStart(): void {
    console.log('begin drawing');
  }

  clearSignature(): void {
    this.signaturePad.clear();
  }

  savePad(): void {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }

  submit(sectionOne, sectionTwo): void {

    this.sig1Border = false;
    this.sig2Border = false;

    this.showValidationErrors = true;

    this.checkSignature();

    if (this.consentValues === 0 && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()) {
      this.isLoading = true;

      this.savingConsentData();
      this.consentMapper.consents = 1;

      this.checkSignature();

      const clinicianId = this.userId;
      this.testPerformed = {
        active: true,
        clinicianId: clinicianId,
        completed: true,
        consultationId: this.clientID,
        createDate: new Date(),
        createName: this.userFullName,
        notes: '',
        result: this.consentMapper.consents,
        reviewId: '00000000-0000-0000-0000-000000000000',
        testMappingId: this.testRequested.testMappingId,
        testRequestedId: this.testRequested.id,
        changeDate: null,
        changeName: null,
        subTestMappingId: null
      };
      this.questionnaireService.postTestPerformed(this.testPerformed).subscribe(postResult => {
        this.testRequested.status = 3;
        this.testRequested.changeDate = new Date();
        this.testRequested.changeName = this.userFullName;
        this.questionnaireService.putTestStatus(this.testRequested).subscribe(updateStatus => {

          this.consultationService.postWellnessConsent(this.consentMapper).subscribe(postResult => {

            this.router.navigate(['/wellness-consultation-summary']);
          });
        });
      });

    } else if (!ArrayFunctions.any(d => d == null, sectionOne.controls.consent.value) //for any other value > 0
      && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()) {

      this.savingConsentData();

      this.consentMapper.consents = this.consentValues;

      this.checkSignature();

      const clinicianId = this.userId;
      this.testPerformed = {
        active: true,
        clinicianId: clinicianId,
        completed: true,
        consultationId: this.clientID,
        createDate: new Date(),
        createName: this.userFullName,
        notes: '',
        result: this.consentValues,
        reviewId: '00000000-0000-0000-0000-000000000000',
        testMappingId: this.testRequested.testMappingId,
        testRequestedId: this.testRequested.id,
        changeDate: null,
        changeName: null,
        subTestMappingId: null
      };
      this.isLoading = true;
      this.questionnaireService.postTestPerformed(this.testPerformed).subscribe(postResult => {
        this.testRequested.status = 3;
        this.testRequested.changeDate = new Date();
        this.testRequested.changeName = this.userFullName;
        this.questionnaireService.putTestStatus(this.testRequested).subscribe(updateStatus => {
          console.log(updateStatus)
          this.consultationService.postWellnessConsent(this.consentMapper).subscribe(postResult => {

            this.router.navigate(['/wellness-consultation-summary']);

          });
        });
      });
    } else {
      this.showError = true;

      this.sig1Border = this.sigs.first.signaturePad.isEmpty();
      this.sig2Border = this.sigs.last.signaturePad.isEmpty();
    }
  }

  savingConsentData() {
    this.showError = false;
    let options: MatSnackBarConfig = { duration: 2500 };
    let snackBarRef = this.snackBar.open('Saving consent...', null, options);
    this.consentMapper.consultationDetailId = this.clientID;
  }

  checkSignature() {

    this.consentMapper.examiner_SignatureDate = this.consentMapper.examiner_SignatureDate || new Date();
    this.consentMapper.member_SignatureDate = this.consentMapper.member_SignatureDate || new Date();
    this.consentMapper.member_Signature = this.sigs.first.signaturePad.toDataURL();
    this.consentMapper.examiner_Signature = this.sigs.last.signaturePad.toDataURL();

  }

  downloadPdf(base64String, fileName): void {

    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  updateConsent(index, value, data): void {
    this.sectionOne.controls.consent.value[index] = value;
  }

  back(): void {
    this.router.navigate(['/wellness-consultation-summary']);
  }
}
