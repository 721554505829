<div class="container">
  <h1 mat-dialog-title class="text--align-center">Confirmation</h1>
  <div mat-dialog-content class="text--align-center">
    <p>Are you sure you want to submit? This COF will not be editable after being submitted.</p>
  </div>
  <div class="text--align-center">
    <div mat-dialog-actions>
      <button mat-button (click)="onSubmitCLick()" cdkFocusInitial
        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">Submit</button>
      <button mat-button (click)="onNoClick()"
        class="mdc-button mdc-button--stroked mdc-ripple-upgraded motion-button--medium">Back</button>
    </div>
  </div>
</div>
