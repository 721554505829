import { Component, OnInit } from '@angular/core';
import { ClinicianService } from 'src/app/shared/services/clinician.service';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
//TODO: Add user profile here
// import { KeycloakService } from 'keycloak-angular';
import { KeycloakService } from 'keycloak-angular';
import { clinicianObject } from 'src/app/shared/interfaces/clinicianMapper';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-oh-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class OHLandingPageComponent implements OnInit {

  isLoading = true;
  badgeValue = 0;

  constructor(
    private consultations: ConsultationService,
    private clinician: ClinicianService,
    private keycloak: KeycloakService,
    private globalData: GlobalDataService
  ) { }

  ngOnInit(): void {
    this.isLoading = false;

    this.globalData.hideBookingCalendarButton();
    let clinics: string[] = [];

    // Open the keycloak user profile
    // If the user does not exist in the clinician table, create him.
    // Also update the list of clinics the user has access to
    this.keycloak.loadUserProfile().then(userProfile => {
      let instance = this.keycloak.getKeycloakInstance();

      // Get clinic list from the user's login token
      if (instance.tokenParsed) {
        if (instance.tokenParsed["Clinic"]) {
          instance.tokenParsed["Clinic"].forEach(clinic => {
            clinics.push(clinic.toLowerCase());
          });
        }
      }

      // Check if the user exists
      this.clinician.getClinicianByKeycloakId(instance.subject.toString()).subscribe(clinician => {
        // If user is new, creat a DB record
        if (clinician == null || clinician.id == null) {
          let clinicianRecord: clinicianObject = {
            id: null,
            name: userProfile.firstName + " " + userProfile.lastName,
            ocsaid: null,
            active: true,
            keycloakId: instance.subject.toString(),
            clinicIds: clinics
          }

          this.clinician.addClinician(clinicianRecord).subscribe(clinicianDetail => {
            clinician = clinicianDetail;
            if (clinician) {
              this.ContinueLogin(clinician, clinics);
            }
          });
        }
        else {
          this.ContinueLogin(clinician, clinics);
        }

      });

    });
  }

  ContinueLogin(clinician: clinicianObject, clinics: string[]) {
    let ohnpCount = 0;
    let ompCount = 0;

    // Check that clinic list is up to date
    clinician.clinicIds.forEach(clinic => {
      clinic = clinic.toLowerCase();
    })

    let clinicsFound = true;

    clinics.forEach(clinic => {
      if (clinician.clinicIds.indexOf(clinic) == -1) {
        clinicsFound = false;
      }
    })

    clinician.clinicIds.forEach(clinic => {
      if (clinics.indexOf(clinic) == -1) {
        clinicsFound = false;
      }
    })

    clinician.clinicIds = clinics;

    if (!clinicsFound) {
      this.clinician.updateClinicClinicianMap(clinician).subscribe();
    }

    localStorage.setItem("Clinician", JSON.stringify(clinician));

    this.consultations.getFileReviewRecordCount(3, null).subscribe(ohnpResult => {
      ohnpCount = ohnpResult;
      this.consultations.getFileReviewRecordCount(4, null).subscribe(ompResult => {
        ompCount = ompResult

        this.badgeValue = ohnpCount + ompCount;
      });
    });
  }

}
