import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ClinicMappingObject } from 'src/app/shared/interfaces/clinicMappingMapper';
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper';
import { ClinicService } from 'src/app/shared/services/clinic.service';
//import { ExecutionreportService } from 'src/app/shared/services/executionreport.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ListService } from 'src/app/shared/services/list.service';

@Component({
  selector: 'app-execution-report',
  templateUrl: './execution-report.component.html',
  styleUrls: ['./execution-report.component.scss']
})

export class ExecutionReportComponent implements OnInit {

  errorMessage: string;
  userDateRange: boolean = true;
  startDate: any;
  endDate: any;
  provinces: ListItemObject[];
  serviceProviders: ClinicMappingObject[];
  isLoading = false;
  showError = false;
  reportCreator: FormGroup;
  ProvinceSelect: any;
  ServiceProviderSelect: any;

  constructor(
    //private WellnessReports: ExecutionreportService,
    public executionformbuilder: FormBuilder,
    private router: Router,
    private listService: ListService,
    private clinicsServices: ClinicService,
    private globalData: GlobalDataService) {

    this.globalData.updateBackRoute('/wellness-event/saps/landing');

    const today = new Date();

  }

  ngOnInit(): void {
  }
}
