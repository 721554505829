<!-- <article class="grid">
    <section class="grid__inner grid__inner--layout-b8b text--align-center">
      <div class="grid__cell mdc-typography--body2">
        <h2 class="mdc-typography--headline2">We appreciate your feedback</h2>
        Thank you for completing this satisfaction survey.
      </div>
    </section>

  </article> -->

<div class="container">
  <article class="grid">
    <span class="blank-line blank-line-4 d-block"></span>

    <!-- ADD A TWO-WAY BINDING -->
    <span class="blank-line blank-l ine-2 d-block"></span>
    <h2 class="mdc-typography--headline5 text--align-center mb-3">We appreciate your feedback.</h2>
    <span class="blank-line blank-line-2 d-block"></span>
    <div>
      <span class="mdc-typography--body1">
        <span *ngIf="risk">
          <p>Dear Nedbanker,</p>

          <p>Thank you for completing your mental health check-in. We noticed your results indicate that you may be
            going
            through a
            challenging time. Please remember that you're not alone—we’re here to support you.
          </p>
          <p>We encourage you to take the next step by reaching out to your Employee Wellbeing Programme (EWP) for
            confidential
            counseling and guidance. Prioritising your mental health is key, and we want to ensure you have all the
            tools
            and
            support you need.</p>

          <p>Take care of yourself and reach out for assistance.</p>

        </span>
        <span *ngIf="!risk">
          <p>
            Dear Nedbanker,</p>
          <p>Thank you for completing your mental health check-in.</p>

          <p>Take care of yourself and reach out should you need any assistance.</p>
        </span>
      </span>
    </div>

  </article>
</div>
<!-- <article>
    <section class="grid__inner grid__inner--layout-b8b text--align-center">
      <div class="grid__cell mdc-typography--body2">
        <div *ngIf="risk">
          <p>Dear Nedbanker,</p>

          <p>Thank you for completing your mental health check-in. We noticed your results indicate that you may be
            going
            through a
            challenging time. Please remember that you're not alone—we’re here to support you.
          </p>
          <p>We encourage you to take the next step by reaching out to your Employee Wellbeing Programme (EWP) for
            confidential
            counseling and guidance. Prioritising your mental health is key, and we want to ensure you have all the
            tools
            and
            support you need.</p>

          <p>Take care of yourself and reach out for assistance.</p>


        </div>
        <div *ngIf="!risk">
          <p>
            Dear Nedbanker,</p>
          <p>Thank you for completing your mental health check-in.</p>

          <p>Take care of yourself and reach out should you need any assistance.</p>




        </div>


      </div>
    </section>
  </article> -->