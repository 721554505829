import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { clinicianObject } from 'src/app/shared/interfaces/clinicianMapper';
import { ClinicianService } from 'src/app/shared/services/clinician.service';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-corporate-wellness-landing',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class CorporateWellnessLandingPageComponent implements OnInit, AfterViewInit {
  id: string;
  bookingAccess: boolean = false;
  executionAccess: boolean = false;
  verificationAccess: boolean = false;
  reportAccess: boolean = false;;
  constructor(
    private activatedRoute: ActivatedRoute,
    private globalData: GlobalDataService,
    private clinicianService: ClinicianService,
    private router: Router,
    private consultations: ConsultationService,
    private clinician: ClinicianService,
    private keycloak: KeycloakService,
    private documentService: DocumentService
  ) {

  }

  ngAfterViewInit() {

  }

  isLoading = false


  ngOnInit(): void {
    this.globalData.userRolesVal.subscribe(ur => {
      this.executionAccess = (ur.indexOf("Health practitioner") > -1) || (ur.indexOf("Admin assistant") > -1);
      this.bookingAccess = ur.indexOf('Coordinator') > -1;
      this.verificationAccess = true;
      this.reportAccess = ur.indexOf('Coordinator') > -1;
      this.globalData.updateBackRoute();
    });
    this.isLoading = false
    this.globalData.hideBookingCalendarButton();
    let clinics: string[] = []; // Open the keycloak user profile
    // If the user does not exist in the clinician table, create him.
    // Also update the list of clinics the user has access to
    this.keycloak.loadUserProfile().then(userProfile => {
      let instance = this.keycloak.getKeycloakInstance();

      // Get clinic list from the user's login token
      if (instance.tokenParsed) {
        if (instance.tokenParsed["Clinic"]) {
          instance.tokenParsed["Clinic"].forEach(clinic => {
            clinics.push(clinic.toLowerCase());
          });
        }
      }
      this.globalData.updateUserFullName(userProfile.firstName + " " + userProfile.lastName);
      // Check if the user exists
      this.clinician.getClinicianByKeycloakId(instance.subject.toString()).subscribe(clinician => {
        // If user is new, creat a DB record

        if (clinician == null || clinician.id == null) {
          let clinicianRecord: clinicianObject = {
            id: null,
            name: userProfile.firstName + " " + userProfile.lastName,
            ocsaid: null,
            active: true,
            keycloakId: instance.subject.toString(),
            clinicIds: clinics
          }

          this.clinician.addClinician(clinicianRecord).subscribe(clinicianDetail => {
            clinician = clinicianDetail;
            this.globalData.updateUserId(clinician.id)
            this.globalData.udpateUserServiceProviderId(clinician.serviceProviderId);
            if (clinician) {
              this.ContinueLogin(clinician, clinics);
            }
          });
        }
        else {
          this.globalData.updateUserId(clinician.id);
          this.globalData.udpateUserServiceProviderId(clinician.serviceProviderId);
          this.ContinueLogin(clinician, clinics);
        }

      });

    });
  }

  ContinueLogin(clinician: clinicianObject, clinics: string[]) {

    // Check that clinic list is up to date
    clinician.clinicIds.forEach(clinic => {
      clinic = clinic.toLowerCase();
    })

    let clinicsFound = true;

    // clinics.forEach(clinic => {
    //   if (clinician.clinicIds.indexOf(clinic) == -1) {
    //     clinicsFound = true;
    //   }
    // })

    // clinician.clinicIds.forEach(clinic => {
    //   if (clinics.indexOf(clinic) == -1) {
    //     clinicsFound = true;
    //   }
    // })

    // clinician.clinicIds = clinics;

    // if (!clinicsFound) {
    //   this.clinician.updateClinicClinicianMap(clinician).subscribe();
    // }

    this.globalData.updateUserId(clinician.id);
    localStorage.setItem("Clinician", JSON.stringify(clinician));

  }

}

