import {Component, Input, OnInit} from '@angular/core';
import {InfoBlockMapper} from '../../interfaces/InfoBlockMapper';

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent implements OnInit {
  @Input() data: InfoBlockMapper;

  constructor() { }

  ngOnInit(): void {
  }

  doAction(tests: any): void {

  }

  doActionButton(action): void {

  }
}
