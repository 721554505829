<div class="container">
  <!-- <mat-progress-bar mode="determinate" [value]=appProgress></mat-progress-bar> -->
  <ng-container>
    <mat-horizontal-stepper class="stepper__width" selectedIndex="stepIndex"
      [ngStyle]="{'background-color': 'transparent'}" [linear]="true" #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon class="mat-step-icon-content">check</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon class="mat-step-icon-content">check</mat-icon>
      </ng-template>
      <mat-step *ngFor="let step of stepperList">
        <ng-template matStepLabel>
          <span class="mdc-typography--caption ">{{step}}</span>
        </ng-template>
        <div *ngIf="step != 'Completion'">
          <app-final-check-test customTitle="{{step}}"></app-final-check-test>
        </div>
        <div *ngIf="step == 'Completion'">
          <app-close-out></app-close-out>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>
</div>
