import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject } from 'rxjs';

import { TestRequestedObject } from '../interfaces/consultationMapper';
import { FinalCheckMapper, OHConsentSummaryMapper, WellnessConsentSummaryMapper } from '../interfaces/dataMapper';
import { ClientMapper } from '../interfaces/InfoBlockMapper';

//TODO:Add Token profile here.






@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  httpOptions: any = {};
  constructor(protected readonly keycloak: KeycloakService) {
    this.userRoles.next(keycloak.getUserRoles());
    //this.userRoles.next(["Perigon_Generic", "Perigon_OMP", "Perigon_Reception", "Perigon_Manager"]);
    //this.keycloak.getKeycloakInstance().

  }

  private userServiceProviderId = new BehaviorSubject(null);
  public userServiceProviderId$ = this.userServiceProviderId.asObservable();

  private editUserId = new BehaviorSubject(null);
  public editUserId$ = this.editUserId.asObservable();

  private userFullName = new BehaviorSubject(localStorage.getItem('userFullName') || 'Locum');
  userFullNameVal = this.userFullName.asObservable();

  private bookingCalendarVisible = new BehaviorSubject(false);
  bookingCalendarVisibleVal = this.bookingCalendarVisible.asObservable();

  private stepperHead = new BehaviorSubject(0);
  stepperHeadVal = this.stepperHead.asObservable();

  private userId = new BehaviorSubject(null)
  userIdVal = this.userId.asObservable();

  private schemeName = new BehaviorSubject('MOMENTUM');
  schemeNameVal = this.schemeName.asObservable();

  private appProgress = new BehaviorSubject(10);
  appProgressVal = this.appProgress.asObservable();

  private selectedClient = new BehaviorSubject(localStorage.getItem("selectedClient") || 'DD0CB992-A440-4ACC-9462-29169EA1737A');
  selectedClientVal = this.selectedClient.asObservable();

  private bookingEventTitle = new BehaviorSubject(localStorage.getItem("bookingEventTitle") || null);
  bookingEventTitleVal = this.bookingEventTitle.asObservable();

  private selectedReferral = new BehaviorSubject('778a7034-6e60-4a18-8921-65f3d3b59c6d');
  selectedReferralVal = this.selectedReferral.asObservable();

  private selectedGroupName = new BehaviorSubject("questionnaires")
  selectedGroupNameVal = this.selectedGroupName.asObservable();

  private userRoles = new BehaviorSubject(localStorage.getItem("userRoles") || []);
  userRolesVal = this.userRoles.asObservable();

  private bookingEventId = new BehaviorSubject(localStorage.getItem("bookingEventId") || null);
  bookingEventIdVal = this.bookingEventId.asObservable();

  private questionnaireId = new BehaviorSubject(localStorage.getItem("questionnaireId") || '94B8C4DD-84FD-4881-92A0-AEA5A989D20E');
  questionnaireIdVal = this.questionnaireId.asObservable();

  private username = new BehaviorSubject(undefined);
  usernameVal = this.username.asObservable();

  private consultationSelection = new BehaviorSubject(localStorage.getItem("consultationSelection") || '');
  consultationSelectionVal = this.consultationSelection.asObservable();

  private questionnaireClientData = new BehaviorSubject<TestRequestedObject>(localStorage.getItem("questionnaireClientData") ?
    JSON.parse(localStorage.getItem("questionnaireClientData")) : {
      active: false,
      consultationId: '',
      createDate: undefined,
      createName: '',
      notes: '',
      id: '',
      sequenceNo: 0,
      status: '',
      testMapping: undefined,
      testMappingId: '',
      testsPerformed: [],
      groupName: ''
    });
  questionnaireClientDataVal = this.questionnaireClientData.asObservable();

  private consultationReferralId = new BehaviorSubject<string>(null);
  consultationReferralIdVal = this.consultationReferralId.asObservable();

  private reviewQuestionnaireId = new BehaviorSubject('bfa80412-539f-4171-924b-678d1748ce7d');
  reviewQuestionnaireIdVal = this.reviewQuestionnaireId.asObservable();

  private testRequestedId = new BehaviorSubject(
    localStorage.getItem("testRequestedId") || '');
  testRequestedIdVal = this.testRequestedId.asObservable();

  private intakeCompleted = new BehaviorSubject(false);
  intakeCompletedVal = this.intakeCompleted.asObservable();

  private closeOutCompleted = new BehaviorSubject(false);
  closeOutCompletedVal = this.closeOutCompleted.asObservable();

  private finalCheck = new BehaviorSubject<FinalCheckMapper>({
    groupName: 'Final Check',
    createdDate: null,
    status: 'pending',
    completedBy: '',
    disabled: false
  });
  finalCheckVal = this.finalCheck.asObservable();



  private ohConsent = new BehaviorSubject<OHConsentSummaryMapper>({
    groupName: 'Consent',
    createdDate: null,
    status: 'pending',
    notes: '',
    disabled: false,
    required: false
  });
  ohConsentVal = this.ohConsent.asObservable();

  private wellnessConsent = new BehaviorSubject<WellnessConsentSummaryMapper>(localStorage.getItem("wellnessConsent") ? JSON.parse(localStorage.getItem("wellnessConsent")) : {
    groupName: 'Consent',
    createdDate: null,
    status: 'pending',
    consentStatus: null,
    notes: '',
    disabled: false,
    required: false
  });
  wellnessConsentVal = this.wellnessConsent.asObservable();

  private adhocWellnessConsent = new BehaviorSubject<WellnessConsentSummaryMapper>(localStorage.getItem("adhocWellnessConsent") ? JSON.parse(localStorage.getItem("adhocWellnessConsent")) : {
    groupName: 'Consent',
    createdDate: null,
    status: 'pending',
    consentStatus: null,
    notes: '',
    disabled: false,
    required: false
  });
  adhocWellnessConsentVal = this.adhocWellnessConsent.asObservable();

  private selectedStartDate = new BehaviorSubject('');
  selectedStartDateVal = this.selectedStartDate.asObservable();

  private userProfile = new BehaviorSubject<KeycloakProfile>(null);
  userProfileVal = this.userProfile.asObservable();

  private selectedEndDate = new BehaviorSubject('');
  selectedEndDateVal = this.selectedEndDate.asObservable();

  private selectedClinicId = new BehaviorSubject('');
  selectedClinicIdVal = this.selectedClinicId.asObservable();

  private backRoute = new BehaviorSubject('');
  backRouteVal = this.backRoute.asObservable();

  private clientInfo = new BehaviorSubject<ClientMapper>(localStorage.getItem("clientInfo") ? JSON.parse(localStorage.getItem("clientInfo")) : {
    firstName: '',
    surname: '',
    idNumber: '',
    initials: '',
    jobTitle: '',
    date: undefined
  });
  clientInfoVal = this.clientInfo.asObservable();

  public formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad month with leading zero
    const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero

    return `${year}-${month}-${day}`;
  }

  updateBookingEventId(id: string) {
    localStorage.setItem("bookingEventId", id);
    this.bookingEventId.next(id);
  }

  showBookingCalendarButton() {
    this.bookingCalendarVisible.next(true);
  }

  hideBookingCalendarButton() {
    this.bookingCalendarVisible.next(false);
  }

  updateEditUserId(id: string) {
    this.editUserId.next(id);
  }

  updateBackRoute(route: any = null) {
    if (route == null && this.backRoute.getValue() != null || route != null && !this.backRoute.getValue()) {
      this.backRoute.next(route);
    }
  }

  setIntakeCompleted(completed: boolean = true) {
    this.intakeCompleted.next(completed);
  }

  updateBookingEventTitle(bookingEventTitle: string) {
    localStorage.setItem("bookingEventTitle", bookingEventTitle);
    this.bookingEventTitle.next(bookingEventTitle);
  }

  updateUserId(id: string) {
    localStorage.setItem("userId", id);
    this.userId.next(id);
  }

  updateUserFullName(fullName: string) {
    localStorage.setItem("userFullName", fullName);
    this.userFullName.next(fullName);
  }

  setCloseOutCompleted(completed: boolean = true) {
    this.closeOutCompleted.next(completed);
  }

  updateUsername(uname: any): void {
    this.username.next(uname);
  }

  updateClientInfo(info: ClientMapper): void {
    localStorage.setItem("clientInfo", JSON.stringify(info));
    this.clientInfo.next(info);
  }

  updateFinalCheck(finalcheck: FinalCheckMapper): void {
    this.finalCheck.next(finalcheck);
  }

  updateOHConsent(consent: OHConsentSummaryMapper): void {
    this.ohConsent.next(consent);
  }

  updateWellnessConsent(consent: WellnessConsentSummaryMapper): void {
    localStorage.setItem("wellnessConsent", JSON.stringify(consent));
    this.wellnessConsent.next(consent);
  }

  updateConsultationReferralId(id: string): void {
    this.consultationReferralId.next(id)
  }

  updateSelectedClient(selectedClient): void {
    localStorage.setItem("selectedClient", selectedClient);
    this.selectedClient.next(selectedClient);
  }

  updateAppointmentDates(startDate, endDate) {
    this.selectedStartDate.next(startDate);
    this.selectedEndDate.next(endDate);
  }

  updateAppointmentClinicId(clId) {
    this.selectedClinicId.next(clId);
  }

  updateSelectedStep(selectedStep) {
    this.stepperHead.next(selectedStep);
  }

  updateAppProgress(appProgress): void {
    this.appProgress.next(appProgress);
  }

  updateQuestionnaireId(id): void {
    localStorage.setItem("questionnaireId", id);
    this.questionnaireId.next(id);
  }

  updateUserRoles(val: Array<string>): void {
    this.userRoles.next(val);
  }

  updateQuestionnaireClientData(id: TestRequestedObject): void {
    localStorage.setItem("questionnaireClientData", JSON.stringify(id));
    localStorage.setItem("consultationSelected", "Questionnaire");;
    this.questionnaireClientData.next(id);
    this.consultationSelection.next('Questionnaire');
  }

  updateReviewQuestionnaireId(id): void {
    this.reviewQuestionnaireId.next(id);
    this.consultationSelection.next('Review');
  }

  updateSelectedGroupName(groupName): void {
    this.selectedGroupName.next(groupName);
  }

  updateTestRequestedId(id): void {
    localStorage.setItem("testRequestedId", id);
    this.testRequestedId.next(id);
  }

  udpateUserServiceProviderId(id): void {
    this.userServiceProviderId.next(id);
  }

  updateUserProfile(profile: KeycloakProfile) {
    this.userProfile.next(profile);
  }
  //TODO: This is were add token to user
  getHttpOptions(): any {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.keycloak.getToken()
      })
    };

    return this.httpOptions;
  }

  public appDefaultPath() {
    return "/wellness-event/corporate/landing";
  }

}
