import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignatureFieldComponent } from '../../../shared/components/signature-field/signature-field.component';
import { COFMapper } from '../../../shared/interfaces/cofMapper';
import { ConsultationObject, ConsultationTestPerformedObject } from '../../../shared/interfaces/consultationMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { DocumentService } from '../../../shared/services/document.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
//TODO:Add logged in user profile
// import { KeycloakProfile } from 'keycloak-js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cof',
  templateUrl: './cof.component.html',
  styleUrls: ['./cof.component.scss']
})
export class COFComponent implements OnInit {


  model: COFMapper = {
    id: undefined,
    to: undefined,
    consultationDetailId: undefined,
    name: undefined,
    surname: undefined,
    department: undefined,
    date: undefined,
    company: undefined,
    idNumber: undefined,
    employeeNumber: undefined,
    jobDescription: undefined,
    riskExposed: undefined,
    fitnessRecommendation: undefined,
    fitnessComments: undefined,
    audiometryTested: undefined,
    spirometryTested: undefined,
    visionTested: undefined,
    biologicalMonitoringTested: undefined,
    xRayTested: undefined,
    testsOther: undefined,
    noExclusions: undefined,
    noiseZones: undefined,
    respiratoryRiskZone: undefined,
    heatStressZone: undefined,
    drivingCodesAB: undefined,
    drivingCodesCE: undefined,
    safetyCriticalWork: undefined,
    exclusionsOther: undefined,
    ownHealthcareProvider: undefined,
    specialist: undefined,
    optometrist: undefined,
    audiologist: undefined,
    referralsOther: undefined,
    clinicReferral: undefined,
    socialWorker: undefined,
    radiologist: undefined,
    omp: undefined,
    specialTests: undefined,
    employeeSignature: undefined,
    ohnpSignature: undefined,
    ompSignature: undefined,
    practiceDetails: undefined,
    retestDate: undefined,
    active: undefined,
    createName: undefined,
    createDate: undefined,
    changeName: undefined,
    changeDate: undefined
  };
  consultationDetail: ConsultationObject;
  title = 'Load script';
  isLoading = false;
  clientId = '';
  showError = false;
  // userProfile: KeycloakProfile;
  downloadButton = null;
  disableSubmit: Boolean = false;

  public form: FormGroup;

  // for convenience as we don't have a QueryList.index
  public secondSig: SignatureFieldComponent;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;
  @ViewChildren('sigContainer3') public sigContainer3: QueryList<ElementRef>;

  constructor(
    fb: FormBuilder,
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    private documentService: DocumentService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {

    this.form = fb.group({
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required],
      signatureField3: ['', Validators.required]
    });
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    this.secondSig = this.secondSig || this.sigs.find((sig, index) => index === 1);
    //console.log('Resizing signature pad canvas to suit container size');
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.secondSig);
    this.size(this.sigContainer3.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
    sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
  }

  public setOptions() {
    this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.secondSig.signaturePad.set('penColor', 'rgb(255, 255, 0)');
    this.secondSig.signaturePad.set('backgroundColor', 'rgb(0, 0, 255)');
    this.secondSig.signaturePad.clear(); // clearing is needed to set the background colour
  }

  public clear() {
    this.secondSig = this.secondSig || this.sigs.find((sig, index) => index === 1);
    this.sigs.first.clear();
    this.secondSig.clear();
    this.sigs.last.clear();
  }

  public ngAfterViewInit() {
    this.secondSig = this.sigs.find((sig, index) => index === 1);
    this.beResponsive();
    this.setOptions();
  }

  ngOnInit(): void {
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);
    // this.globalData.userProfileVal.subscribe(up => this.userProfile = up);

    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {
      this.consultationDetail = result as ConsultationObject;
      this.documentService.getCOF(this.clientId).subscribe(cof => {
        this.isLoading = false;

        if (cof) {
          this.model = cof as COFMapper;
          this.sigs.first.signaturePad.fromDataURL(this.model.employeeSignature);
          this.secondSig.signaturePad.fromDataURL(this.model.ohnpSignature);
          this.sigs.last.signaturePad.fromDataURL(this.model.ompSignature);
        }
        else {
          this.model.id = null,
            this.model.name = this.consultationDetail.patientDetail.fullNames;
          this.model.surname = this.consultationDetail.patientDetail.surname;;
          this.model.department = this.consultationDetail.patientDetail.hierarchyLvl1;
          this.model.date = new Date();
          this.model.company = "SAPS";
          this.model.idNumber = this.consultationDetail.patientDetail.rsaidnumber;
          this.model.employeeNumber = this.consultationDetail.patientDetail.employeeNumber;
          this.model.jobDescription = this.consultationDetail.patientDetail.jobTitle;
          this.model.consultationDetailId = this.clientId;
          this.model.active = true;
          const tests: Array<ConsultationTestPerformedObject> = [];
          this.consultationDetail.testsRequested.forEach(tr => {
            if (tr.testsPerformed?.length) {
              tr.testsPerformed.forEach(tp => tests.push(tp));
            }
          });
          // this.model.audiometryTested = false; //tests.find(tp => tp.testMapping.groupName.toLocaleLowerCase() == "audiometry") ? true : undefined;
          // this.model.spirometryTested = false; //tests.find(tp => tp.testMapping.groupName.toLocaleLowerCase() == "spirometry evaluation") ? true : undefined;
          // this.model.visionTested = false; //tests.find(tp => tp.testMapping.groupName.toLocaleLowerCase() == "vision screening") ? true : undefined;
          // this.model.xRayTested = false; //tests.find(tp => tp.testMapping.groupName.toLocaleLowerCase() == "radiology") ? true : undefined;
        }
      });
    });
  }

  back() {
    this.router.navigate(['/stepper-overview']);
  }

  setFitnessRecommendation(rec: number) {
    this.model.fitnessRecommendation = rec;
  }

  setRiskExposure(risk: boolean) {
    this.model.riskExposed = risk;
  }


  submit(form) {
    this.save(form, true);
  }

  save(form, submit: boolean = false): void {
    ///* console.log(form) */;
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });

    this.model.employeeSignature = this.sigs.first.signaturePad.toDataURL();
    this.model.ohnpSignature = this.secondSig.signaturePad.toDataURL();
    this.model.ompSignature = this.sigs.last.signaturePad.toDataURL();
    this.model.consultationDetailId = this.clientId;
    this.model.createDate = this.model.createDate || new Date();
    this.model.createName = this.model.createName || /*this.userProfile.firstName + ' ' + this.userProfile.lastName*/'locum'
    if (form.valid) {
      this.showError = false;
      this.downloadButton = true;
      this.isLoading = true;

      if (!this.model.id) {
        this.documentService.postCOF(this.model).subscribe(result => this.saveSuccess(result, submit));
        /*(result) => {
          this.model = result as COFMapper;
          this.isLoading = false;
          this.openNotifyDialog();
        });*/
      }
      else {
        this.documentService.putCOF(this.model).subscribe(result => this.saveSuccess(result, submit));
        /*((result) => {
          this.model = result as COFMapper;
          this.isLoading = false;
          this.openNotifyDialog();
        });*/
      }
    }
    else {
      this.showError = true;
    }
  }

  saveSuccess(result, submit: boolean) {
    this.model = result as COFMapper;
    this.isLoading = false;
    this.openNotifyDialog(submit);
  }

  openNotifyDialog(submit: boolean): void {
    const dialogRef = this.dialog.open(COFNotifyDialogComponent, {
      width: '550px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (submit) {
        this.openConfirmDialog();
      }
      else {
        this.router.navigate(['/stepper-overview']);
        this.isLoading = false;
      }
    });
  }

  openConfirmDialog(): void {
    this.isLoading = true;
    const dialogRef = this.dialog.open(COFConfirmDialogComponent, {
      width: '550px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.disableSubmit = true;
        this.documentService.createCOF(this.model.id).subscribe((result) => {
          this.router.navigate(['/stepper-overview']);
          this.isLoading = false;
        });
      }
      else {
        this.isLoading = false;
      }
    });
  }

  downloadPdf(base64String, fileName): void {
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }


}


@Component({
  selector: 'cof-notify-dialog',
  templateUrl: 'cof-notify-dialog.component.html',
})
export class COFNotifyDialogComponent {

  constructor(public dialogRef: MatDialogRef<COFNotifyDialogComponent>, private router: Router) { }

  ok() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'cof-confirm-dialog',
  templateUrl: 'cof-confirm-dialog.component.html'
})
export class COFConfirmDialogComponent {
  clientId: string;
  constructor(
    public dialogRef: MatDialogRef<COFConfirmDialogComponent>,
    private globalData: GlobalDataService,
    private router: Router,
    private documentService: DocumentService) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmitCLick() {
    this.dialogRef.close(true);
  }
}