

	<!--<div class=""> 
			<div class="overlay-content pl-2 pr-2 padTB"> 
			  <div class="three-bar-loader"></div> 
			</div> 
	</div>-->
  <div class="m-tron__overlay">
    <div class="m-tron">
        <div class="m-tron__face m-tron__face--outer">
            <div class="m-tron__dot"></div>
        </div>
        <div class="m-tron__face m-tron__face--inner">
            <div class="m-tron__dot"></div>
        </div>
        <div class="m-tron__face m-tron__face--logo">
            <div class="m-tron__m-logo"></div>
        </div>
    </div>
</div>


<!-- <mat-spinner [diameter]="40"></mat-spinner> -->

<!-- <div id="motion-main-content" *ngIf="motionLoader" class="mdc-top-app-bar--dense-fixed-adjust mdc-drawer-app-content">
        <div class="m-tron__overlay">
    
            <div class="m-tron">
                <div class="m-tron__face m-tron__face--outer">
                    <div class="m-tron__dot"></div>
                </div>
                <div class="m-tron__face m-tron__face--inner">
                    <div class="m-tron__dot"></div>
                </div>
                <div class="m-tron__face m-tron__face--logo">
                    <div class="m-tron__m-logo"></div>
                    
                </div>
            </div>
        </div>
    </div> -->