<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false && isFailed === true">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-center mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center"><a class="motion-button--cancel" tabindex="0"
                    title="Return to previous page" (click)="previousPage()">Back</a></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="isLoading === false && isFailed === false">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Client Profile</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <div class="row">
            <div class="row" style="justify-content: space-evenly">
              <div class="nameIcon">
                <h5 class="mdc-typography--headline5 nameIconText">{{data.initials}}</h5>
              </div>
              <div>
                <h6 class="mdc-typography--headline5" style="padding-bottom: unset !important;">
                  {{data.firstName}}, {{data.surname}}
                </h6>
                <p class="grid__cell mdc-typography--headline6">{{data.idNumber}}</p>
              </div>
            </div>
          </div>
          <hr>
        </div>
      </div>
      <span class="blank-line blank-line-2 d-block"></span>
      <div *ngFor="let item of listItems; let i = index">
        <app-info-block [data]=item></app-info-block>
      </div>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center"><a class="motion-button--cancel" tabindex="0"
                    title="Return to previous page" (click)="previousPage()">Back</a></div>
              </div>
            </div>
          </div>
        </div>
        <span class="blank-line blank-line-4 d-block"></span>
      </section>
    </div>

  </article>
</div>
