import { Component, OnInit } from '@angular/core';
import { ConsultationObject } from '../../../shared/interfaces/consultationMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { ConsultationReviewSummaryMapper, ReviewSummaryMapper } from 'src/app/shared/interfaces/reviewMapper';
import { GLOBAL_VARS } from 'src/app/shared/enums';

@Component({
  selector: 'app-review-vaccine',
  templateUrl: './review-vaccine.component.html',
  styleUrls: ['./review-vaccine.component.scss']
})
export class ReviewVaccineComponent implements OnInit {

  groupName: string = "Vaccination / Prophylaxis";

  isLoading = true;
  consultationId: string;

  mapper: ConsultationReviewSummaryMapper;
  showError = false;


  constructor(private router: Router, private globalData: GlobalDataService, private consultationService: ConsultationService) {
    this.globalData.updateBackRoute('/stepper-overview');  
  }

  ngOnInit(): void {
    this.globalData.selectedGroupNameVal.subscribe(result => {
      this.groupName = result || this.groupName;
      },
      error => console.log("Ping"),
      () => this.isLoading = false);
      
      this.globalData.selectedClientVal.subscribe(r => {
      this.consultationId =  r || "1CA94F68-140A-493B-B235-EB3C16CFA07C";
      this.consultationService.getConsultationDetail(this.consultationId).subscribe(data => {
        this.mapper = {
          consultation: data as ConsultationObject,
          reviews: data.testsRequested.filter(e => e.testMapping?.groupName.toLowerCase() == this.groupName.toLowerCase() && e.testsPerformed.length)
          .map(function(r) { 
            const mappingObject: ReviewSummaryMapper = {
                testRequested: r,
                review: r.testsPerformed.sort((a,b) => a.createDate > b.createDate ? 1 : a.createDate == b.createDate ? 0 : -1)[0] 
            };
            return mappingObject;
          })
        }
        this.isLoading = false;
      })
    });
  }

  onInputChange($event): void {
  }
  
  back(): void {
    this.router.navigate(['/stepper-overview']);
  }

  onSaved(q): void {

  }
}
