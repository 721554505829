<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>

    <div *ngIf="isLoading === false">
      <div class="grid__inner grid__inner--layout-12">
        <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
          <form #sectionOne=ngForm matNativeControl>
            <span class="blank-line blank-line-2 d-block"></span>
            <div class="text--align-center">
              <mat-error class="mdc-typography--subtitle1">Both consents to be given for every medical examination and
                test. Any refusal will result in cancellation of the consultation and Unfit COF
              </mat-error>
            </div>
            <span class="blank-line blank-line-2 d-block"></span>
            <h4 class="mdc-typography--headline5 text--align-center mb-3">Section 1: Consent / refusal to undergo
              medical
              examination</h4>
            <span class="blank-line blank-line-2 d-block"></span>

            <p class="mdc-typography--body1">
              This is to acknowledge that I,
              <span class="unsetMarginPadding nameWidth">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                  hideRequiredMarker>
                  <input matInput name="name" ngModel #inputControl="ngModel" placeholder="name and surname" type="text"
                    required maxlength="255 " [(ngModel)]="consentMapper.details_FullName">
                </mat-form-field>
              </span>
              <span class="unsetMarginPadding idWidth">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                  hideRequiredMarker>
                  <input matInput name="idnumber" ngModel #inputControl="ngModel" placeholder="ID Number" type="text"
                    required maxlength="13" [(ngModel)]="consentMapper.details_IDNumber">
                </mat-form-field>
              </span> understand that these tests (all or in part) may be repeated on a regular basis, as well as with
              the
              termination of my employment.
              The frequency and content of the testing will depend on the nature of my work, and the test outcomes.
              These medical tests may include, but will not necessarily be limited to:
            </p>

            <ul class="motion-list motion-list--unordered">
              <li class="motion-list--item">A medical history and clinical examination</li>
              <li class="motion-list--item">Audiograms, vision screening tests, lung function screening tests, chest
                X-Rays, laboratory tests (blood and/or urine)</li>
              <li class="motion-list--item">Screening and confirmatory tests for the presence of alcohol and/or drugs
              </li>
              <li class="motion-list--item">Any other tests deemed necessary by the examining practitioner</li>
            </ul>

            <p class="mdc-typography--body1">
              I also understand that:
            </p>

            <ul class="motion-list motion-list--unordered">
              <li class="motion-list--item">It is a condition of employment for certain occupations to be in possession
                of
                a medical certificate of fitness; medical surveillance will be conducted in accordance with the
                Occupational Health and Safety Act 85 of 1993 (the act)</li>
              <li class="motion-list--item">The nature and purpose of the medical testing is to ensure that I am
                suitably
                placed in my intended occupation and the results of these tests may be used to make a decision regarding
                my suitability for the applicable occupation;</li>
              <li class="motion-list--item">The procedure / treatment / vaccination was explained to me, but I have the
                opportunity to ask more questions about it;</li>
              <li class="motion-list--item">The results of all medical tests will be made available to me at my request;
              </li>
              <li class="motion-list--item">My current pathology results will be recorded and the types of the
                laboratory
                and rapid tests requested were explained to me and I understand that I have the opportunity to ask more
                questions about these tests and procedures;</li>
              <li class="motion-list--item">If I do not provide truthful and accurate historical and current medical and
                health information, or fail to declare all relevant facts regarding my health/ pregnancy status, I may
                be
                refused employment and/or be subject to disciplinary action;</li>
              <li class="motion-list--item">I indemnify and hold Momentum Metropolitan harmless against any claim which
                I
                or any other person (natural or juristic) may now or in the future have against them, for any side
                effects
                that I may suffer arising out of my use of the medication, treatment or any other procedure.</li>
            </ul>

            <p class="mdc-typography--body1">
              I hereby
              <span class="unsetMarginPadding consentWidth">
                <mat-form-field class="whitneyFontClass motion-form-field unsetMarginPadding" appearance="standard"
                  hideRequiredMarker>
                  <mat-label>Select Consent</mat-label>
                  <mat-select ngModel #dropdownControl="ngModel" name="consentSelection" required>
                    <mat-option *ngFor="let consent of consentList" [value]="consent.name"
                      (onSelectionChange)="updateConsent('exam', $event)">{{consent.name.toUpperCase()}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
              to undergo a risk based medical examination, tests and/or treatment provided to me as part of the
              Company’s
              medical surveillance and wellness programme.
            </p>
            <!--        <p class="mdc-typography&#45;&#45;body1">-->
            <!--          I hereby <b>REFUSE CONSENT</b> to undergo a risk based medical examination, tests and/or treatment as prescribed by the Company’s medical surveillance and wellness programme-->
            <!--        </p>-->
            <span class="blank-line blank-line-2 d-block"></span>
            <section style="display: flex; flex-direction: row; justify-content: space-between">
              <div>
                <!--
                  <div style="width: 250px; border-bottom: 1px solid dimgrey">
                    <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete('emp1')"></signature-pad>
                  </div>
                -->
                <div [formGroup]="form">
                  <div class="sig-container" [ngStyle]="{'border-color': sig1border}" #sigContainer1>
                    <div fxLayout="row">
                      <button title="clear" class="mat-mini-fab" (click)="sigs.first.clear()"
                        [disabled]="!form.controls.signatureField1.valid">
                        <span class="mat-button-wrapper">
                          <i class="material-icons" role="img" aria-hidden="true">clear</i>
                        </span>
                      </button>
                    </div>
                    <div fxLayout="row">
                      <signature-field formControlName="signatureField1"></signature-field>
                    </div>
                  </div>
                </div>
                <mat-label>{{consentMapper.exam_Witness_Designation}} signature</mat-label>
                <div style="display: flex; flex-direction: row; margin-right: 20px; text-align: -webkit-center;">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                    hideRequiredMarker style="max-width: 10em">
                    <input class="nameWidth" matInput name="surname" ngModel #inputControl="ngModel"
                      placeholder="Surname" type="text" required maxlength="255"
                      [(ngModel)]="consentMapper.exam_Witness_Surname">
                  </mat-form-field>
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                    hideRequiredMarker style="max-width: 4em; padding-left: 10px;">
                    <input class="nameWidth" matInput name="initials" ngModel #inputControl="ngModel"
                      placeholder="Initials" type="text" required maxlength="10"
                      [(ngModel)]="consentMapper.exam_Witness_Initials">
                  </mat-form-field>
                </div>
              </div>
              <div [formGroup]="form">
                <!--<div style="width: 250px; border-bottom: 1px solid dimgrey">
                <signature-pad required [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete('client')"></signature-pad>
              </div>-->
                <div class="sig-container" [ngStyle]="{'border-color': sig2border}" #sigContainer2>
                  <div fxLayout="row">
                    <button title="clear" class="mat-mini-fab" (click)="secondSig.clear()"
                      [disabled]="!form.controls.signatureField2.valid">
                      <span class="mat-button-wrapper">
                        <i class="material-icons" aria-label="Example icon-button with a delete icon" role="img"
                          aria-hidden="true">clear</i>
                      </span>
                    </button>
                  </div>
                  <div fxLayout="row">
                    <signature-field formControlName="signatureField2"></signature-field>
                  </div>
                </div>
                <mat-label>Client signature</mat-label>
              </div>
            </section>
          </form>
        </div>
        <span class="blank-line blank-line-1 d-block"></span>
        <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
          <form #sectionTwo=ngForm matNativeControl>
            <span class="blank-line blank-l ine-2 d-block"></span>
            <h4 class="mdc-typography--headline5 text--align-center mb-3">Section 2: Consent / refusal to disclosure of
              medical data and information</h4>
            <span class="blank-line blank-line-2 d-block"></span>
            <p class="mdc-typography--body1">
              This is to acknowledge that I,
              <span class="unsetMarginPadding nameWidth">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                  hideRequiredMarker>
                  <input matInput name="name2" ngModel #inputControl="ngModel" placeholder="name and surname"
                    type="text" required maxlength="255" [(ngModel)]="consentMapper.details_FullName">
                </mat-form-field>
              </span>
              <span class="unsetMarginPadding idWidth">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                  hideRequiredMarker>
                  <input matInput name="idnumber2" ngModel #inputControl="ngModel" placeholder="ID Number" type="text"
                    required maxlength="13" [(ngModel)]="consentMapper.details_IDNumber">
                </mat-form-field>
              </span> understand that:
            </p>
            <ul class="motion-list motion-list--unordered">
              <li class="motion-list--item">My personal information, medical history and test results will form part of
                my
                medical record and information obtained is confidential and will be handled with sensitivity and stored
                in
                accordance with the provisions of the Protection of Personal Information Act 2013 (the Act); and shall
                be
                divulged to only those for whom the information is relevant;</li>
              <li class="motion-list--item">The results of certain tests may need to be disclosed to the employer and
                other subject to the standards mentioned above, to safeguard the health of my co-workers, myself and/or
                the general public; other healthcare professions in the company’s health team will, of necessity, have
                access to my records;</li>
              <li class="motion-list--item">The results of tests for the presence of drugs or alcohol are not medical
                tests as defined by the Employment Equity Act, and may therefore be disclosed to the employer without my
                consent;</li>
              <li class="motion-list--item">This disclosure may have implications regarding my employment status and I
                trust that the matter will be handled fairly and in line with requirement of current South African
                legislation;</li>
              <li class="motion-list--item">Anonymised data and health outcomes may be shared with contracted third
                parties to be used for the purposes of analysis, including but not limited to publication in academic
                journals, statistical and research publications;</li>
              <li class="motion-list--item">Momentum Metropolitan shall not be held responsible for any loss or damage
                (whether direct or indirect) that may arise from the use of this information, other than where it is due
                to or attributable to gross negligence or fraudulent conduct by Momentum Metropolitan.</li>
            </ul>
            <p class="mdc-typography--body1">
              I hereby
              <span class="unsetMarginPadding consentWidth">
                <mat-form-field class="whitneyFontClass motion-form-field unsetMarginPadding" appearance="standard"
                  hideRequiredMarker>
                  <mat-label>Select Consent</mat-label>
                  <mat-select ngModel #dropdownControl="ngModel" name="consentSelection2" required>
                    <mat-option *ngFor="let consent of consentList" [value]="consent.name"
                      (onSelectionChange)="updateConsent('data', $event)">{{consent.name.toUpperCase()}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
              to the sharing of my data in accordance with the ethical standards prescribed by statutory bodies and
              legislation, as explained above.
            </p>
            <span class="blank-line blank-line-2 d-block"></span>
            <section style="display: flex; flex-direction: row; justify-content: space-between">
              <div>
                <div [formGroup]="form">
                  <div class="sig-container" [ngStyle]="{'border-color': sig3border}" #sigContainer3>
                    <div fxLayout="row">
                      <button title="clear" class="mat-mini-fab" (click)="sigs.last.clear()"
                        [disabled]="!form.controls.signatureField3.valid">
                        <span class="mat-button-wrapper">
                          <i class="material-icons" aria-label="Example icon-button with a delete icon" role="img"
                            aria-hidden="true">clear</i>
                        </span>
                      </button>
                    </div>
                    <div fxLayout="row">
                      <signature-field formControlName="signatureField3"></signature-field>
                    </div>
                  </div>
                </div>

                <div [formGroup]="form">
                  <div>
                    <p> </p>
                  </div>
                </div>
              </div>
            </section>
            <!--<div style="width: 250px; border-bottom: 1px solid dimgrey">
            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete('emp2')"></signature-pad>
          </div>-->
            <mat-label>Client signature</mat-label>
          </form>
          <section class="grid__inner">
            <div class="mb-1">
              <span class="blank-line blank-line-2 d-block"></span>
            </div>
            <div class="grid__cell--span-all">
              <div class="grid__inner grid__inner--layout-b8b">
                <div class="grid__cell grid__cell--justify-center">
                  <div class="mdc-btn-container">
                    <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
                      (click)="submit(sectionOne, sectionTwo)">Submit</button>
                  </div>
                  <div class="mdc-btn-container">
                    <a href="{{href}}" download="{{download}}" *ngIf="downloadButton != null"
                      ng-disabled="!downloadButton"
                      class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">Download</a>
                  </div>
                  <div class="mdc-btn-container">
                    <mat-error class="text--align-center" *ngIf="showError">Please complete all required</mat-error>
                  </div>
                  <div class="mdc-btn-container">
                    <div class="grid__cell text--align-center"><a class="motion-button--cancel" tabindex="0"
                        title="Return to previous page" (click)="back()">Back</a></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </article>
</div>
