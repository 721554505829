import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ClaimParams } from '../interfaces/claimParams';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  parameter: ClaimParams = {
    MediscorPCNCode: '',
    ProviderBHFNumber: '',
    PayeeIndicator: '',
    PatientRefNumber: '',
    DateOfService: '',
    MemberNumber: '',
    DependentCode: '',
    DateOfBirth: '',
    PatientGender: '',
    PatientFirstName: '',
    PatientSurname: '',
    ScriptDate: '',
    PrimaryICD10Code: '',
    TotalVAT: '',
    GrossAmountDue: '',
    SubmittedCost: ''
  }
  constructor(private http: HttpClient) {

  }

  claim(params: ClaimParams, headers: HttpHeaders) {

    this.http.get(`https://mediscor-benefit-api.onrender.com/claims?MediscorPCNCode=MDS0041PW&ProviderBHFNumber=8800002&PayeeIndicator=P&PatientRefNumber=186609&DateOfService=2024-06-14&MemberNumber=919720989&DependantCode=001&DateOfBirth=2024-06-14&PatientGender=F&PatientFirstName=Nicole L&PatientSurname=Gilbert&ScriptDate=2024-06-14&PrimaryICD10Code=Z76.9&TotalVAT=000002054&GrossAmountDue=000015750`, { headers }).subscribe(data => {
      console.log("Ping - Claims Data", data);
    }, err => {
      console.log(err);
    }

    )
  }


}
