import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as Enums from '../enums';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService) { }

  getCOF(id): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_DOCUMENT_COF + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get COF', undefined)));
  }

  postCOF(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_DOCUMENT_COF, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to save COF (POST)', undefined)));
  }

  putCOF(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.put(Enums.API_PATH + Enums.API_PATHS.PUT_DOCUMENT_COF + body.id, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to save COF (PUT)', undefined)));
  }

  getReferralLetter(id): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_DOCUMENT_REFERRAL + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get COF', undefined)));
  }

  postReferralLetter(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_DOCUMENT_REFERRAL, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to save referral letter (POST)', undefined)));
  }

  putReferralLetter(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.put(Enums.API_PATH + Enums.API_PATHS.PUT_DOCUMENT_REFERRAL + body.id, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to save referral letter (PUT)', undefined)));
  }

  createReferralLetter(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.CREATE_DOCUMENT_REFERRAL + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to create referral letter', undefined)));
  }

  createCOF(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.CREATE_DOCUMENT_COF + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to create COF', undefined)));

  }


  getExecutionReport(): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.GET_EXECUTION_REPORT, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get COF', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

  reduceObject(obj): any {

    /* console.log(obj) */;
    return obj;
  }
}
