import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AdminAssistantUser, ServiceProviderUser } from 'src/app/shared/interfaces/service-provider-user';
import { ClinicianService } from 'src/app/shared/services/clinician.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ServiceProviderService } from 'src/app/shared/services/service-provider.service';


@Component({
  selector: 'app-sp-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss']
})
export class SPAccessManagementComponent implements OnInit {
  practitioners: ServiceProviderUser[] = [];
  assistants: AdminAssistantUser[] = [];
  selectedPractitioner: ServiceProviderUser = null;
  displayedColumns: string[] = ['name', 'active', 'email', 'actions'];
  userId: string;
  isLoading: boolean = true;

  constructor(
    private practitionerService: ServiceProviderService,
    private clinicianService: ClinicianService,
    private globalData: GlobalDataService,
    private keycloakService: KeycloakService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    let kc = this.keycloakService.getKeycloakInstance();
    this.globalData.userIdVal.subscribe(d => this.userId = d);
    this.practitionerService.getServiceProviderUsers(this.userId).subscribe(data => {
      this.practitioners = data;
      this.isLoading = false;
    });
    this.practitionerService.getAdminAssistants(this.userId).subscribe(data => {
      this.assistants = data;
      this.isLoading = false;
    });
    this.globalData.updateBackRoute(['/sp-landing']);
  }
  addHealthPractitioner() {
    this.router.navigate(['/sp-add-hp']);
  }
  addAdminAssistant() {
    this.router.navigate(['/sp-add-aa']);
  }

  onSelect(practitioner: ServiceProviderUser): void {
    this.isLoading = true;
    this.globalData.updateEditUserId(practitioner.id);
    this.router.navigate(['/sp-access-management']);
    // var _temp = this.dialog.open(ManageUserComponent, {
    //   width: '55%',
    //   data: {
    //     data: { ...practitioner },
    //     edit: true
    //   }
    // })
    // _temp.afterClosed().subscribe(data => {
    //   if (data.selectedSearchDropdownID) {
    //   this.router.navigate(['/wellness-registration', data.selectedSearchDropdownID, data.selectedSearchDropdownName])
    //   }
    // })
    // this.selectedPractitioner = { ...practitioner }; // Create a copy to edit
  }

  onSave(): void {
    this.practitionerService.updateHealthPractitioner(this.selectedPractitioner.id).subscribe(() => {
      // Update the original list with the updated data
      const index = this.practitioners.findIndex(p => p.id === this.selectedPractitioner.id);
      if (index !== -1) {
        this.practitioners[index] = this.selectedPractitioner;
      }
      this.selectedPractitioner = null; // Reset the selected practitioner
    });
  }
  onActivate(hp: ServiceProviderUser) {
    this.isLoading = true;
    this.clinicianService.enableClinician(hp.email, true).subscribe(data => {
      if (data) {
        hp.active = true;
        this.isLoading = false;
        let snackBar = this.snackBar.open('Accesss granted', null, { duration: 3000, panelClass: 'app-notification-success' });
      }
    });
  }
  onDeactivate(hp: ServiceProviderUser) {
    this.isLoading = true;
    this.clinicianService.enableClinician(hp.email, false).subscribe(data => {
      if (data) {
        hp.active = false;
        this.isLoading = false;
        let snackBar = this.snackBar.open('Accesss revoked', null, { duration: 3000, panelClass: 'app-notification-success' });
      }
    });
  }
  onResetPassword(email: string) {
    this.isLoading = true;
    this.clinicianService.resetPassword(email).subscribe(data => {
      if (data) {
        this.isLoading = false;
        let snackBar = this.snackBar.open('Password reset', null, { duration: 3000, panelClass: 'app-notification-success' });
      }
    });
  }
  onCancel(): void {
    this.selectedPractitioner = null; // Reset the selected practitioner
  }
}
