<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block">
    </span>

    <three-bar-loader *ngIf="isLoading === true"> </three-bar-loader>

    <div *ngIf="isLoading === false">
      <div class="grid__inner grid__inner--layout-3333-44 text--align-center">
        <div class="grid__cell">
          <div class="mdc-card">
            <div class="mdc-card__media mdc-card__media--2-1 img_appointment"></div>

            <div class="mdc-card__content">
              <div class="mdc-typography--headline5">
                <h3 class="mdc-typography--headline5">Appointments</h3>
              </div>

              <p class="mdc-typography--overline text--align-center">
                <span>Dashboard view of scheduled consultations </span>

                <br />
                <br />
              </p>
              <p></p>
              <div class="mdc-btn-container mdc-btn-container--column-stack">
                <a [routerLink]="['/appointment']"
                  class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                  aria-label="Click here to open">
                  <span class="mdc-button__text">Click here to open </span>
                </a>
              </div>
            </div>
          </div>
          <div class="mdc-btn-container mdc-btn-container--column-stack">
            <div class="grid__cell">
              <div class="mdc-card">
                <div class="mdc-card__media mdc-card__media--2-1 img_client"></div>
                <div class="mdc-card__content">
                  <div class="mdc-typography--headline5">
                    <h3 class="mdc-typography--headline5">
                      Client Administration
                    </h3>
                  </div>
                  <p class="mdc-typography--overline text--align-center">
                    <span>Company and client profile administration</span>
                    <br />
                    <br />
                  </p>
                  <p></p>
                  <p></p>
                  <div class="mdc-btn-container mdc-btn-container--column-stack">
                    <a [routerLink]="['/client-profile']"
                      class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                      aria-label="Click here to open">
                      <span class="mdc-button__text">Click here to open </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
