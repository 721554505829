import { Component, OnInit, Input } from '@angular/core';
import { TestNote } from '../../interfaces/notesMapper';

@Component({
  selector: 'app-test-note',
  templateUrl: './test-note.component.html',
  styleUrls: ['./test-note.component.scss']
})
export class TestNoteComponent implements OnInit {
  @Input() note: TestNote;

  constructor() { }


  ngOnInit(): void {
  }

}
