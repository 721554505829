import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import * as Enums from '../enums'
import { EventActivity } from '../interfaces/eventActivity'
import { CorporateWellnessEventBookingForm } from '../interfaces/wellness-event-booking'
import { GlobalDataService } from './global-data.service'

//TODO Is Tken needed here?
// import { KeycloakService } from 'keycloak-angular'






@Injectable({
  providedIn: 'root'
})
export class CorporateWellnessDayEventBookingService {

  httpOptions: any = {}

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }

  createEventBooking(event: CorporateWellnessEventBookingForm): any {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.CREATE_CORPORATE_WELLNESS_DAY_EVENT_BOOKING, event, { headers }).subscribe({
      next: data => {
        return true;
      },
      error: error => {
        return false;
      }
    })
  }

  getEventActivity(eventId: string): Observable<EventActivity[]> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_CORPORATE_EVENT_ACTIVITY_HISTORY + eventId, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event booking', undefined))
      )
  }

  getWellnessDayEventBooking(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_CORPORATE_WELLNESS_DAY_EVENT_BOOKING + id, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event booking', undefined))
      )
  }

  getAllWellnessDayEvents(clinicianId): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_ALL_CORPORATE_WELLNESS_DAY_EVENTS + clinicianId, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get all event bookings', undefined))
      )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error)
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T)
    }
  }

  private log(message: string) {
    console.log(`${message}`)
  }
}
