import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as Enums from '../enums';
import { QuestionnaireSummary } from '../interfaces/questionnaireMapper';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService) { }

  getQuestionnaireNameList(): Observable<Array<QuestionnaireSummary>> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_QUESTIONNAIRES, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get questionsnaire', undefined))
    );
  }

  getQuestionnaire(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_QUESTIONNAIRE + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get questions', undefined)));
  }

  getHierarchicalQuestionnaire(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_HIERARCHICAL_QUESTIONNAIRE + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hierarchical questions', undefined)));
  }

  postQuestionnaire(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH_QFRAME + Enums.API_PATHS.POST_QUESTIONNAIRE, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to save questions (POST)', undefined)));
  }

  putQuestionnaire(body, clinicianId): Observable<any> {
    body.clinicianId = clinicianId;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.put(Enums.API_PATH_QFRAME + Enums.API_PATHS.PUT_QUESTIONNAIRE, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to save questions (PUT)', undefined)));
  }

  getHierarchicalResponse(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_HIERARCHICAL_RESPONSE + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hierarchical response', undefined)));
  }

  getResponse(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_RESPONSE_LIST + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get response list', undefined)));
  }

  deleteReviews(reviewIds: Array<string>): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH_QFRAME + Enums.API_PATHS.POST_DELETE_REVIEWS, reviewIds, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to delete reviews', undefined)));
  }

  getReview(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_REVIEW + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get review', undefined)));
  }

  getTestPerformed(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_REVIEW + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get review', undefined)));
  }

  getTestPerformedForReviewId(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_TEST_PERFORMED_FOR_REVIEWID + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get test performed for review', undefined)));
  }

  postTestPerformed(object): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_TEST_PERFORMED, object, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post test performed', undefined)));
  }

  putTestPerformed(object): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.put(Enums.API_PATH + Enums.API_PATHS.PUT_TEST_PERFORMED, object, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to put test performed', undefined)));
  }

  putTestStatus(obj): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.put(Enums.API_PATH + Enums.API_PATHS.PUT_TEST_REQUESTED, obj, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get review', undefined)));
  }

  getQuestionnaireExaminationOptions(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_QUESTIONNAIRE_EXAM_OPTIONS + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get questionnaire examination options', undefined)));
  }

  getExaminationOptions(): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_ALL_EXAM_OPTIONS, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get examination options list', undefined)));
  }

  getContraindicationList(id): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH_QFRAME + Enums.API_PATHS.GET_ITEMS_FOR_LIST + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get contraindication list', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

  reduceObject(obj): any {

    /* console.log(obj) */;
    return obj;
  }
}
