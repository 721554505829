<article class="grid">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <div class="grid__inner grid__inner--layout-b8b">
    <div class="grid__cell">
      <div class="text--align-center">
        <h1 class="mdc-typography--headline2">{{providerName}}</h1>
        <h1 class="mdc-typography--headline2">Health practitioner registration</h1>
      </div>
      <br />
      <br />
      <br />
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="mdc-list-item text--align-left">
              <strong>User details</strong>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Full name</mat-label>
                <input matInput type="text" formControlName="name" required placeholder="e.g. John">
                <mat-error *ngIf="signupForm.get('name').invalid || signupForm.get('name').touched">Full name is
                  required.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Surname</mat-label>
                <input matInput type="text" formControlName="surname" required placeholder="e.g. Khumalo">
                <mat-error *ngIf="signupForm.get('surname').invalid || signupForm.get('surname').touched">Surname is
                  required.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>South African ID</mat-label>
                <input matInput type="number" formControlName="idNumber" (input)="onInput($event)">
                <mat-error *ngIf="signupForm.get('idNumber').errors?.required">ID is required.</mat-error>
                <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidSouthAfricanIdNumber">ID is
                  invalid.</mat-error>
                <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidIdLength">ID is invalid.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Contact Number</mat-label>
                <input matInput (input)="onInput($event)" formControlName="contactNumber" required>
                <mat-error
                  *ngIf="signupForm.get('contactNumber').errors?.required || signupForm.get('contactNumber').touched">Contact
                  Number is required.</mat-error>
                <mat-error *ngIf="signupForm.get('contactNumber').errors?.invalidCellphone">Contact number is
                  invalid.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" required placeholder="e.g. john.khumalo@gmail.com">
                <mat-error *ngIf="signupForm.get('email').errors?.required">Email is required.</mat-error>
                <mat-error *ngIf="signupForm.get('email').errors?.email">Email is required.</mat-error>
              </mat-form-field>
            </div>

            <!-- New Additional fields -->
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Practice Name</mat-label>
                <input matInput type="text" formControlName="PracticeName" placeholder="e.g. john khumalo">
                <!-- <mat-error *ngIf="signupForm.get('PracticeName').errors?.required">Practice Name is
                  required.</mat-error> -->
                <!-- <mat-error *ngIf="signupForm.get('PracticeName').errors?.PracticeName">Practice Name is
                  required.</mat-error> -->
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Practice Number</mat-label>
                <input matInput type="text" formControlName="PracticeNumber" placeholder="e.g. 123456">
                <!-- <mat-error *ngIf="signupForm.get('PracticeName').errors?.required">Practice Number is
                  required.</mat-error>
                <mat-error *ngIf="signupForm.get('PracticeName').errors?.PracticeNumber">Practice Number is
                  required.</mat-error> -->
              </mat-form-field>
            </div>
            <!-- ends here -->




            <div formGroupName="certificationType">
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Certification type</mat-label>
                  <mat-select (selectionChange)="onCertificationSelection($event)" required
                    formControlName="certificationTypeID" #selectedCertificationType>
                    <mat-option value="" selected disabled>e.g. SANC - South African Nursing Council</mat-option>
                    <mat-option *ngFor="let cert of certifications" [value]="cert.id">{{cert.description}}</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="signupForm.get('certificationType.certificationTypeID').invalid && signupForm.get('certificationType.certificationTypeID').touched">Health
                    practitioner certification type is required.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group" *ngIf="selectedCertificationType.value">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>{{selectedCertificationType.value.toUpperCase() === "F6FF76D6-9158-466F-90E8-CA8E3449869C" ?
                  "SANC number" : selectedCertificationType.value.toUpperCase() ===
                  "893A2F84-3C1F-4502-ADF2-6BDF63AFD322" ? "HPCSA number" : "SACSSP number"}}</mat-label>
                <input matInput type="text" formControlName="certNumber" required>
                <mat-error *ngIf="signupForm.get('certNumber').invalid || signupForm.get('certNumber').touched">
                  {{selectedCertificationType.value.toUpperCase() === "F6FF76D6-9158-466F-90E8-CA8E3449869C" ? "SANC
                  number is required." :selectedCertificationType.value.toUpperCase() ===
                  "893A2F84-3C1F-4502-ADF2-6BDF63AFD322" ? "HPCSA number is required." : "SACSSP number is required."}}
                </mat-error>
              </mat-form-field>
            </div>
            <br />
            <div class="form-group" *ngIf="selectedCertificationType.value">
              <mat-label style="padding-right: 3%;">{{selectedCertificationType.value.toUpperCase() ===
                "F6FF76D6-9158-466F-90E8-CA8E3449869C" ?
                "SANC document upload *"
                :selectedCertificationType.value.toUpperCase() === "893A2F84-3C1F-4502-ADF2-6BDF63AFD322" ? "HPCSA
                document upload *" :
                "SACSSP document upload *"}}</mat-label>
              <input type="file" formControlName="certification" required (change)="onFileSelect($event)">
              <mat-error *ngIf="signupForm.get('certification').invalid && signupForm.get('certification').touched">
                {{selectedCertificationType.value.toUpperCase() === "F6FF76D6-9158-466F-90E8-CA8E3449869C" ?
                "SANC document is required."
                :selectedCertificationType.value.toUpperCase() === "893A2F84-3C1F-4502-ADF2-6BDF63AFD322" ? "HPCSA
                document is required." :
                "SACSSP document is required."}}
              </mat-error>
            </div>
            <br />
            <div class="form-group">
              <mat-checkbox required formControlName="discretionApproved">I confirm that all information given is
                correct and that the {{cert}} membership is active.</mat-checkbox>
              <mat-error
                *ngIf="signupForm.get('discretionApproved').invalid && signupForm.get('discretionApproved').touched">
                Health practitioner confirmation is required.
              </mat-error>
            </div>
            <div id="saveContainer">
              <button
                [ngClass]="{ 'mdc-button--raised': signupForm.valid && signupForm.dirty, 'mdc-ripple-upgraded': signupForm.valid && signupForm.dirty, 'mdc-button__fill--darkblue': signupForm.valid && signupForm.dirty, 'mdc-button': signupForm.valid && signupForm.dirty, 'mat-raised-button': signupForm.invalid || signupForm.pristine }"
                (click)="onSubmit()" #saveButton [disabled]="signupForm.invalid || signupForm.pristine" id="saveButton"
                style="vertical-align: middle;">Save</button>
            </div>
          </form>
          <div class="grid__cell">
            <mat-error *ngIf="invalidForm">
              Invalid form submitted. Please fill in all required fields.
            </mat-error>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</article>