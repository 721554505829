import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConsultationObject } from '../../interfaces/consultationMapper';
import { SAPSWellnessEventBookingForm } from '../../interfaces/wellness-event-booking';
import { ConsultationService } from '../../services/consultation.service';
import { GlobalDataService } from '../../services/global-data.service';
import { ScheduleService } from '../../services/schedule.service';
import { SAPSWellnessDayEventBookingService } from '../../services/wellness-day-event-booking.service';

@Component({
  selector: 'app-wellness-info-header',
  templateUrl: './wellness-info-header.component.html',
  styleUrls: ['./wellness-info-header.component.scss']
})
export class WellnessInfoHeaderComponent implements OnInit {

  @Input() mode: "consultation" | "event" = "consultation";

  isLoading = false;
  consultationDetail: ConsultationObject;
  clientId = '';
  bookingEventId = '';
  scheduleDate: any;
  eventObject: SAPSWellnessEventBookingForm

  get event(): SAPSWellnessEventBookingForm | null {
    return this.mode == "consultation" ? this.consultationDetail?.event as SAPSWellnessEventBookingForm : this.eventObject || null;
  }

  constructor(
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    private scheduleDetail: ScheduleService,
    private wellnessDayEventBookingService: SAPSWellnessDayEventBookingService) {
  }

  get eventStartTime(): Date | null {
    if (this.event) {
      return new Date(this.event.eventDate.substring(0, 10) + " " + this.event.eventStartTime);
    }
    return null;
  }

  get eventEndTime(): Date | null {
    if (this.event) {
      return new Date(this.event.eventDate.substring(0, 10) + " " + this.event.eventEndTime);
    }
    return null;
  }

  get eventDescription(): String | null {
    let stationDesc = this.event.station.description;
    let provinceDesc = this.event.province.description;
    let eventDesc: String;
    let splitStation: string[];
    if (stationDesc.indexOf(';') > -1) {
      splitStation = stationDesc.split(';');
      eventDesc = `${provinceDesc} - ${splitStation[0]} station, ${splitStation[1]}`;
    }
    else if (stationDesc.indexOf('|') > -1) {
      splitStation = stationDesc.split(' | ');
      eventDesc = `${provinceDesc} - ${splitStation[0]}, ${splitStation[1]}`;
    }
    return eventDesc;
  }

  ngOnInit(): void {
    this.isLoading = true;

    if (this.mode == "consultation") {
      this.globalData.selectedClientVal.subscribe(result => {
        if (result) {
          this.clientId = result;
          this.consultationService.getConsultationDetail(this.clientId).subscribe(r => {
            if (r) {
              this.consultationDetail = r;
              this.scheduleDetail.getSchedule(this.clientId).subscribe(scheduleDetails => {
                this.scheduleDate = scheduleDetails.scheduleDate;
                this.isLoading = false;
              });
            }
            else {
              this.isLoading = false;
            }
          });
        } else {
          this.isLoading = false;
        }
      });
    } else {
      this.globalData.bookingEventIdVal.subscribe(result => {
        if (result) {
          this.bookingEventId = result;
          this.wellnessDayEventBookingService.getWellnessDayEventBooking(this.bookingEventId).subscribe(r => {
            if (r) {
              this.eventObject = r;
            }
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      });
    }
  }
}
