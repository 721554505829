<div mat-dialog-content>
  <article class="grid">
    <div class="grid__inner grid__inner--layout-12">
      <div class="grid__cell">
        <h2 class="mdc-typography--headline3">Cancellation details</h2>
        <form [formGroup]="form">
          <div class="form-group">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Reason</mat-label>
              <mat-select formControlName="cancellationReason">
                <mat-option *ngFor="let value of cancellationReasons" [value]="value.id">{{value.description}}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('cancellationReason').invalid">
                Cannot go forth without selection.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Cancelled By</mat-label>
              <input matInput formControlName="cancelledBy" required>
              <mat-error *ngIf="form.get('cancelledBy').invalid">
                Please fill in all the fields
              </mat-error>
            </mat-form-field>
          </div>

        </form>
        <div class="grid__inner grid__inner--layout-66">
          <div class="grid__cell" id="center">
            <button mat-raised-button class="button" (click)="onBack()">Back</button>
          </div>
          <div class="grid__cell" id="center">
            <button mat-raised-button class="button" (click)="confirmCancellation()"[disabled]="!form.valid">Confirm Cancellation</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
