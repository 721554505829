<div class="container">
  <article class="grid">
    <div class="mdc-typography--headline3">
      <p class="text--align-center">FINAL CHECK</p>
    </div>
    <span class="blank-line blank-line-2 d-block"></span>
    <div class="grid__inner grid__inner--layout-c6c">
      <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
        <table>
          <tr>
            <td>
              <p class="text--align-left">Linda Nombula</p>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <p class="text--align-left">25 August 2021</p>
            </td>
            <td>
              <p class="text--align-right">Type: OH Medical Assessment</p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <p class="text--align-right">Status: Active</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="grid__inner grid__inner--layout-c6c">
      <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
        <h3 class="mdc-list-group__subheader" style="padding-left: unset;">Job summary</h3>
        <p class="text--align-left">Well, of course, it’s Latin. But although the phrase appears to be an intelligent
          Latin idiom, it is impossible to translate, at least in a meaningful way. </p>
        <p class="text--align-left">Real Latin words are intermingled with jabberwocky that feigns to be of Roman
          origin.</p>
      </div>
    </div>
    <div class="text--align-center">
      <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">SUBMIT</button>
    </div>
    <span class="blank-line blank-line-2 d-block"></span>
    <div class="text--align-center">
      <button>Back</button>
    </div>
  </article>
</div>
