<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <app-consent-base (submitConsentMapper)="postSubmit($event)" [consent]="consentMapper">
      <!-- ADD A TWO-WAY BINDING -->
      <span class="blank-line blank-l ine-2 d-block"></span>
      <h4 class="mdc-typography--headline5 text--align-center mb-3">Influenza Vaccination Consent</h4>
      <span class="blank-line blank-line-2 d-block"></span>
      <div>
        <span class="mdc-typography--body1">
          <span>I, <u>{{consentMapper?.member_FullName}}</u>, {{consentMapper?.member_IdNumberLabel}}
            <u>{{consentMapper?.member_IdNumber}}</u>,
            have read and understood the information given to me regarding influenza vaccination, including the
            risks and benefits.
            I understand that there are contra-indications (symptoms or medical conditions that is a reason for a
            person to not
            receive the vaccination because it may be harmful) and I confirm that I have no such
            contra-indications.
            <br /><br />
            I understand that influenza vaccination may produce side-effects, most often mild and do not require
            treatment e.g.
            slight swelling and redness occurring at the injection site (this disappears in a few days). In rare
            instance more
            serious allergic or other sporadic reactions may occur requiring medical intervention.
            <br /><br />
            I have been informed that immunity takes time to develop after vaccination and that I may still be
            susceptible to
            contract influenza for at least 14 days after the date of vaccination.
            <br /><br />
            I have been given the opportunity to ask questions and explanation of any terminology that I could not
            understand.
            <br /><br />
            I acknowledge that it is my responsibility to provide truthful and accurate historical and current
            medical and health
            information, and to declare all relevant facts regarding my health status.
            <br /><br />
            I hereby CONSENT to the administration of the appropriate influenza vaccination by a Registered Nurse,
            and, in the
            unlikely event that a serious side-effect arises, to any other procedure/ test/ treatment that may
            have to be performed
            if it is deemed necessary to save my life or to prevent serious harm to my health.
            <br /><br />
            I understand that the information I provide, and information related to any vaccines administered,
            will be recorded
            electronically and/or in hard copy.
            <br /><br /><br />

            <b>To all the above I answer:</b>

          </span>
        </span>
      </div>
    </app-consent-base>
  </article>



  <!-- <article class="grid">
  <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>

  <div *ngIf="isLoading === false">
    <div class="grid__inner grid__inner--layout-12">
      <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
        <div class="mdc-card mdc-card__shadow-light">
          <div class="mdc-card__content">
            <span class="blank-line blank-l ine-2 d-block"></span>
            <h4 class="mdc-typography--headline5 text--align-center mb-3">Influenza Vaccination Consent</h4>
            <span class="blank-line blank-line-2 d-block"></span>
            <div>
              <span class="mdc-typography--body1">
                <span>I, <u>{{consentMapper.member_FullName}}</u>, {{consentMapper.member_IdNumberLabel}}
                  <u>{{consentMapper.member_IdNumber}}</u>,
                  have read and understood the information given to me regarding influenza vaccination, including the
                  risks and benefits.
                  I understand that there are contra-indications (symptoms or medical conditions that is a reason for a
                  person to not
                  receive the vaccination because it may be harmful) and I confirm that I have no such
                  contra-indications.
                  <br /><br />
                  I understand that influenza vaccination may produce side-effects, most often mild and do not require
                  treatment e.g.
                  slight swelling and redness occurring at the injection site (this disappears in a few days). In rare
                  instance more
                  serious allergic or other sporadic reactions may occur requiring medical intervention.
                  <br /><br />
                  I have been informed that immunity takes time to develop after vaccination and that I may still be
                  susceptible to
                  contract influenza for at least 14 days after the date of vaccination.
                  <br /><br />
                  I have been given the opportunity to ask questions and explanation of any terminology that I could not
                  understand.
                  <br /><br />
                  I acknowledge that it is my responsibility to provide truthful and accurate historical and current
                  medical and health
                  information, and to declare all relevant facts regarding my health status.
                  <br /><br />
                  I hereby CONSENT to the administration of the appropriate influenza vaccination by a Registered Nurse,
                  and, in the
                  unlikely event that a serious side-effect arises, to any other procedure/ test/ treatment that may
                  have to be performed
                  if it is deemed necessary to save my life or to prevent serious harm to my health.
                  <br /><br />
                  I understand that the information I provide, and information related to any vaccines administered,
                  will be recorded
                  electronically and/or in hard copy.
                  <br /><br /><br />

                  <b>To all the above I answer:</b>

                </span>
              </span>
            </div>
            <div>
              <div>
                <div class="button-height">
                  <mat-radio-group [ngClass]="{'red-border': showValidationErrors && consent == null}">
                    <mat-radio-button *ngFor="let l of agreeList" [value]="l.value" [checked]="l.value == consent"
                      [ngClass]="{'btn-red': !l.value, 'btn-green': l.value}" (change)="consent = $event.value">
                      {{l.name}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form [formGroup]="form" matNativeControl>
          <div class="mdc-card mdc-card__shadow-light">
            <div class="mdc-card__content">
              <span class="blank-line blank-line-2 d-block"></span>
              <section id="consentPaperForm" *ngIf="!form.get('consentPaperForm').value">
                <div>
                  <div>
                    <div class="sig-container" [ngClass]="{'red-border': sig1Border}" #sigContainer1>
                      <div fxLayout="row">
                        <button type="button" title="clear" class="mat-mini-fab" (click)="sigs.first.clear()"
                          [disabled]="!form.controls.signatureField1.valid">
                          <span class="mat-button-wrapper">
                            <i class="material-icons" role="img" aria-hidden="true">clear</i>
                          </span>
                        </button>
                      </div>

                      <div fxLayout="row">
                        <signature-field formControlName="signatureField1"></signature-field>
                      </div>
                    </div>
                  </div>
                  <mat-label>Signature of member</mat-label>
                </div>
                <div>
                  <div>
                    <div class="sig-container" [ngClass]="{'red-border': sig2Border}" #sigContainer2>
                      <div fxLayout="row">
                        <button type="button" title="clear" class="mat-mini-fab" (click)="sigs.last.clear()"
                          [disabled]="!form.controls.signatureField2.valid">
                          <span class="mat-button-wrapper">
                            <i class="material-icons" aria-label="Example icon-button with a delete icon" role="img"
                              aria-hidden="true">clear</i>
                          </span>
                        </button>
                      </div>
                      <div fxLayout="row">
                        <signature-field formControlName="signatureField2"></signature-field>
                      </div>
                    </div>
                  </div>
                  <mat-label>Signature of examiner</mat-label>
                </div>
              </section>
            </div>
          </div>
        </form>

        <section class="grid__inner">
          <div class="mb-1">
            <span class="blank-line blank-line-2 d-block"></span>
          </div>
          <div class="grid__cell--span-all">
            <div class="grid__inner grid__inner--layout-b8b">
              <div class="grid__cell grid__cell--justify-center">
                <div class="mdc-btn-container">
                  <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
                    (click)="submit(sectionOne)">Submit</button>
                </div>
                <div class="mdc-btn-container">
                  <mat-error class="text--align-center" *ngIf="showError">Please complete all required</mat-error>
                </div>
                <div class="mdc-btn-container">
                  <div class="grid__cell text--align-center">
                    <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                      (click)="back()">Back</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</article> -->
</div>