import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as Enums from '../enums';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicianService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService) { }

  getAllClinicians(): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_ALL_CLINICIANS, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get clinic records', undefined)));
  }

  resetPassword(email: string) {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_HEALTH_PRACTITIONER_RESET_PASSWORD + email, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to enable clinician', false)));
  }

  enableClinician(email: string, enable: boolean = true): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_HEALTH_PRACTITIONER_ENABLE, { email: email, active: enable }, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to enable clinician', false)));
  }

  getClinician(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_CLINICIAN + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get clinic records', undefined)));
  }

  getClinicianByKeycloakId(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_CLINICIAN_BY_KEYCLOAK_ID + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get clinic records', undefined)));
  }

  getClinicianByEmail(email: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_CLINICIAN_BY_EMAIL + email, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get clinic records', undefined)));
  }

  addClinician(body): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.ADD_CLINICIAN, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to add clinician.', undefined)));
  }

  updateClinicClinicianMap(body): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.UPDATE_CLINIC_CLINICIAN_MAP, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to update clinic-clinician-map.', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }
}
