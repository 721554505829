<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>


    <div class="grid__inner grid__inner--layout-b8b" *ngIf="isLoading === false">
      <div class="grid__cell grid__cell--align-center grid__cell--span-all">
        <app-questionnaire [consultationId]="consultationId" [questionnaireId]="questionnaireId" [reviewId]="reviewId"
          [testRequested]="testRequested" [consultationSelection]="consultationSelection" [showBackButton]="true"
          [allowSubmit]="true" (onSaved)="onSaved()" (onBackPressed)="back()">
        </app-questionnaire>
      </div>
    </div>
  </article>
</div>
