<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false">
      <span class="blank-line blank-line-2 d-block"></span>
      <ng-container *ngIf="showTitle">
        <section class="mdc-typography--body1">
          <h1 class="mdc-typography--headline2 text--align-center">
            {{hierarchicalQuestionnaire[0].questionnaireDisplayName}}</h1>
        </section>
        <span class="blank-line blank-line-2 d-block"></span>
      </ng-container>
      <app-wellness-info-header *ngIf="examinationType == 'WELLNESS'"></app-wellness-info-header>
      <app-adhoc-wellness-info-header *ngIf="examinationType == 'ADHOCWELLNESS'"></app-adhoc-wellness-info-header>
      <app-info-header-eap *ngIf="examinationType == 'EAP'"></app-info-header-eap>
      <div class="grid__inner grid__inner--layout-12">
        <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
          <!-- #region Examination Form -->
          <form name="examinationForm" matNativeControl [ngClass]="{'hiddenExaminationStatus': hideExaminationStatus}">
            <div [formGroup]="examinationFormGroup">
              <span class="blank-line blank-line-2 d-block"></span>
              <h5>Examination</h5>
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
                <mat-select formControlName="examinationResultName" placeholder="Select examination status" required
                  (selectionChange)="examinationSelection($event)">
                  <mat-option *ngFor="let exam of examinationOptions" [value]="exam.examinationOptionId">
                    {{exam.examinationOption.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="showContraindicated">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
                  <mat-select formControlName="contraindicatedSelection"
                    placeholder="Select applicable contraindication" required
                    (selectionChange)="contraindicatedSelection($event)">
                    <mat-option *ngFor="let contra of contraindicationList" [value]="contra.id">{{contra.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="showContraindicatedComment">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
                    <textarea matInput required formControlName="contraindicatedComment"
                      placeholder="Contraindication comment" type="text"
                      (keyup)="contraindicationCommentChange($event)"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="showQuestionnaireComment">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
                  <textarea matInput required formControlName="examinationComment"
                    placeholder="Reason for examination status" type="text"
                    (keyup)="examinationCommentChange($event)"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
          <!-- #endregion-->
          <form name="questionnaireForm" matNativeControl>
            <div *ngIf="showQuestionnaire">
              <div [formGroup]="questionnaireFormGroup">
                <ng-container *ngFor="let question of questions; let i = index">

                  <ng-container
                    *ngIf="question.groupName != null && question.groupName != undefined && question.groupName.trim().length > 0 && (i == 0 || (question.groupName != questions[i - 1].groupName)) && question.showHeader && !question.groupQuestionAssociationParentId">
                    <span class="blank-line blank-line-2 d-block"></span>
                    <h4 class="mdc-typography--headline5 text--align-center mb-3">{{question.groupName}}</h4>
                    <span class="blank-line blank-line-2 d-block"></span>
                  </ng-container>

                  <div class="question-section"
                    *ngIf="(question.isVisible == true || question.isPregnant==true || question.isMale == true) &&  question.questionTypeName.toLowerCase() !== 'groupstart' && question.questionTypeName.toLowerCase() !== 'groupend'"
                    [@fade]>
                    <div [style.margin-left]="question.questionIndentStyle"
                      [style.padding-top]="question.questionPaddingTopStyle"
                      [style.padding-left]="question.questionPaddingLeftStyle">
                      <div [ngClass]="{
                        'at-risk': question.atRisk,
                        'risk-notice': question.riskRating == -1,
                        'high-risk': question.riskRating == 2,
                        'mdc-card': question.questionTypeName.toLowerCase() !== 'grouplabel' && question.questionTypeName.toLowerCase() !== 'image',
                        'mdc-card__shadow-light':  question.questionTypeName.toLowerCase() !== 'grouplabel' && question.questionTypeName.toLowerCase() !== 'image'
                         }" [@fade]>
                        <div
                          [ngClass]="{ 'mdc-card__content': question.questionTypeName.toLowerCase() !== 'grouplabel' && question.questionTypeName.toLowerCase() !== 'image' }"
                          [@fade]>
                          <ng-container [ngTemplateOutlet]="getQuestionTemplate(question.questionType)"
                            [ngTemplateOutletContext]="{ $implicit: question }">
                          </ng-container>

                          <div class="at-risk-warning" [@fade]>
                            <a class="mat-button" title="Risk indicator" href="javascript:void(0)" tabindex="0"
                              aria-disabled="true" [tabIndex]="-1">
                              <span class="mat-button-wrapper">
                                <i class="material-icons" aria-label="At risk" role="img" aria-hidden="true">warning</i>
                              </span>
                            </a>
                          </div>

                          <div class="at-risk-reason" [@fade]>
                            <div *ngIf="question.atRiskReason" [@fade]>
                              <span>{{question.atRiskReason}}</span>
                            </div>
                            <div *ngFor="let abnormality of question.abnormalities" [@fade]>
                              <span class="at-risk-text"
                                [ngClass]="{ 'high-risk-text': abnormality.riskRating == 2 }">{{abnormality.message}}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
          <form name="examinationNotesForm" matNativeControl *ngIf="allowNotes">
            <div [formGroup]="examinationNotesFormGroup">
              <div *ngIf="!showQuestionnaireComment">
                <span class="blank-line blank-line-2 d-block"></span>
                <h4 class="mdc-typography--headline5 text--align-center mb-3">Notes</h4>
                <ng-container *ngFor="let note of examinationNotes; let i = index;" [@fade]>
                  <app-test-note [note]="note"></app-test-note>
                  <div class="grid__cell text--align-right" *ngIf="note.isNew">
                    <a class="motion-button--cancel" tabindex="0" title="Delete a note"
                      (click)="removeNote(i)">Delete</a>
                  </div>
                </ng-container>
                <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard">
                  <textarea matInput formControlName="examinationNotes" placeholder="Examination notes" type="text"
                    (ngModelChange)="onNoteTextChange($event)">
								</textarea>
                </mat-form-field>
                <button type="button" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--short"
                  [disabled]="!enableAddNote || !updateAllowed" (click)="addNote($event)">Add note</button>
                <span class="blank-line blank-line-1 d-block"></span>
              </div>
            </div>
          </form>
          <section class="grid__inner">
            <div class="mb-1">
              <span class="blank-line blank-line-2 d-block"></span>
            </div>
            <div class="grid__cell--span-all">
              <div class="grid__inner grid__inner--layout-b8b">
                <div class="grid__cell grid__cell--justify-center">
                  <div class="mdc-btn-container">
                    <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
                      [disabled]="submitAllowed" (click)="submit()">Submit</button>
                  </div>
                  <div class="mdc-btn-container">
                    <mat-error class="text--align-center" *ngIf="showError">Please complete all required fields marked
                      with a red *</mat-error>
                  </div>

                  <div class="mdc-btn-container" *ngIf="showBackButton">
                    <div class="grid__cell text--align-center">
                      <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                        (click)="back()">Back</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- #region Templates -->
    <ng-template #toggleAndCommentQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div *ngIf="question.questionTypeName.toLowerCase() === 'toggleandcomment'">
        <div class="mdc-list" aria-orientation="vertical">
          <mat-radio-group [formControlName]="question.questionName" [required]="question.questionRequired">
            <ng-container *ngFor="let opt of question.options">
              <ng-container *ngIf="opt.discriminator === 'SelectionOption'">
                <div class="mdc-list-item" *ngFor="let resp of opt.list.listItems">
                  <mat-radio-button color="primary" [value]="resp.id"
                    (change)="onChange(question, $event, question.questionTypeName)">{{resp.description}}</mat-radio-button>
                </div>
              </ng-container>
            </ng-container>
          </mat-radio-group>
          <ng-container [ngTemplateOutlet]="getQuestionCommentTemplate()"
            [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-template #multipleSelectionQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div class="mdc-list" aria-orientation="vertical">
        <ng-container *ngFor="let opt of question.options">
          <ng-container *ngIf="opt.discriminator === 'SelectionOption'">
            <div class="mdc-list-item " *ngFor="let resp of opt.list.listItems; let checkIndex = index">
              <mat-checkbox [checked]="itemIsChecked(question, resp.id)" labelPosition="after" color="primary"
                (change)="onChange(question, $event, question.questionTypeName, checkIndex)">
                {{resp.description}}
              </mat-checkbox>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #multipleSelectionAndCommentQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div>
        <div class="mdc-list" aria-orientation="vertical">
          <ng-container *ngFor="let opt of question.options">
            <ng-container *ngIf="opt.discriminator === 'SelectionOption'">
              <div class="mdc-list-item " *ngFor="let resp of opt.list.listItems; let checkIndex = index">
                <mat-checkbox [checked]="itemIsChecked(question, resp.id)" labelPosition="after" color="primary"
                  (change)="onChange(question, $event, question.questionTypeName, checkIndex)">
                  {{resp.description}}
                </mat-checkbox>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div *ngIf="question.commentAvailable">
          <ng-container [ngTemplateOutlet]="getQuestionCommentTemplate()"
            [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-template #multipleSelectionWithSubQuestionQuestion let-question [formGroup]="questionnaireFormGroup">
      <div class="mdc-list" aria-orientation="vertical">
        <!-- <div class="mdc-list-item "> -->
        <mat-checkbox [checked]="questionIsChecked(question)" labelPosition="after" color="primary"
          (change)="onChange(question, $event, question.questionTypeName, null)">
          {{question.questionPhrase}}
        </mat-checkbox>
        <!-- </div> -->
      </div>
    </ng-template>
    <ng-template #dateQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field date-question">
        <input matInput [formControlName]="question.questionName" [matDatepicker]="startpicker"
          placeholder="{{question.questionPlaceholder}}" [required]="question.questionRequired"
          [readonly]="question.questionReadOnly" (click)="startpicker.open()" [min]="question.questionMin"
          [max]="question.questionMax" readonly (ngModelChange)="onChange(question, $event, question.questionTypeName)">
        <mat-hint>YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #startpicker startView="year"></mat-datepicker>
        <ng-container [ngTemplateOutlet]="getErrorTemplate()"
          [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      </mat-form-field>
    </ng-template>
    <ng-template #numericQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{$implicit: question}"></ng-container>
      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
        <input matInput [formControlName]="question.questionName" placeholder="{{question.questionPlaceholder}}"
          type="number" pattern="{{question.questionPattern}}" step="{{question.questionStep}}"
          [readonly]="question.questionReadOnly" min="{{question.questionMin}}" max="{{question.questionMax}}"
          maxlength="{{question.questionMaxLength}}" [required]="question.questionRequired"
          (ngModelChange)="onChange(question, $event, question.questionTypeName)">
        <ng-container [ngTemplateOutlet]="getErrorTemplate()"
          [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      </mat-form-field>
    </ng-template>
    <ng-template #buttonQuestion let-question [formGroup]="questionnaireFormGroup">
      <button mat-button type="button" [disabled]="question.questionDisabled"
        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
        (click)="questionButtonClick(question.questionName, question, $event, question.questionTypeName)">
        {{question.questionPhrase}}
      </button>
    </ng-template>
    <ng-template #numericAndCommentQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div>
        <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
          <input matInput [formControlName]="question.questionName" placeholder="{{question.questionPlaceholder}}"
            type="number" pattern="{{question.questionPattern}}" step="{{question.questionStep}}"
            min="{{question.questionMin}}" max="{{question.questionMax}}" [readonly]="question.questionReadOnly"
            maxlength="{{question.questionMaxLength}}" [required]="question.questionRequired"
            (ngModelChange)="onChange(question, $event, question.questionTypeName)">
          <ng-container [ngTemplateOutlet]="getErrorTemplate()"
            [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
        </mat-form-field>
        <div *ngIf="question.commentAvailable">
          <ng-container [ngTemplateOutlet]="getQuestionCommentTemplate()"
            [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-template #decimalQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
        <input matInput [formControlName]="question.questionName" placeholder="{{question.questionPlaceholder}}"
          pattern="{{question.questionPattern}}" type="number" [readonly]="question.questionReadOnly"
          maxlength="{{question.questionMaxLength}}" [required]="question.questionRequired"
          step="{{question.questionStep}}" min="{{question.questionMin}}" max="{{question.questionMax}}"
          (ngModelChange)="onChange(question, $event, question.questionTypeName)">
        <ng-container [ngTemplateOutlet]="getErrorTemplate()"
          [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      </mat-form-field>
    </ng-template>
    <ng-template #errorTemplate let-question [formGroup]="questionnaireFormGroup">
      <mat-error
        *ngIf="questionnaireFormGroup.get(question.questionName).errors?.required && (questionnaireFormGroup.get(question.questionName).dirty || questionnaireFormGroup.get(question.questionName).touched)">
        An answer is required.
      </mat-error>
      <mat-error
        *ngIf="questionnaireFormGroup.get(question.questionName).errors?.max && (questionnaireFormGroup.get(question.questionName).dirty || questionnaireFormGroup.get(question.questionName).touched)">
        Value may not be greater than {{question.questionMax}}.;
      </mat-error>
      <mat-error
        *ngIf="questionnaireFormGroup.get(question.questionName).errors?.min && (questionnaireFormGroup.get(question.questionName).dirty || questionnaireFormGroup.get(question.questionName).touched)">
        Value may not be less than {{question.questionMin}}.
      </mat-error>
      <mat-error
        *ngIf="questionnaireFormGroup.get(question.questionName).errors?.pattern && (questionnaireFormGroup.get(question.questionName).dirty || questionnaireFormGroup.get(question.questionName).touched)">
        Invalid value.
      </mat-error>
    </ng-template>
    <ng-template #textQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
        <input matInput [formControlName]="question.questionName" placeholder="{{question.questionPhrase}}" type="text"
          [required]="question.questionRequired" [readonly]="question.questionReadOnly"
          maxlength="{{question.questionMaxLength}}"
          (ngModelChange)="onChange(question, $event, question.questionTypeName)">
        <ng-container [ngTemplateOutlet]="getErrorTemplate()"
          [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      </mat-form-field>
    </ng-template>
    <ng-template #multilineTextQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
        <textarea matInput [formControlName]="question.questionName" [required]="question.questionRequired"
          placeholder="{{question.questionPlaceholder}}" type="text" [readonly]="question.questionReadOnly"
          maxlength="{{question.questionMaxLength}}"
          (ngModelChange)="onChange(question, $event, question.questionTypeName)"></textarea>
      </mat-form-field>
    </ng-template>
    <ng-template #selectionQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard">
        <mat-label>{{question.questionPhrase}}</mat-label>
        <input *ngIf="question.questionReadOnly" matInput [formControlName]="question.questionName" [readonly]="true"
          [required]="question.questionRequired"
          [value]="getSelectedValueText(question.questionId, question.questionSelectedValue)">
        <mat-select *ngIf="!question.questionReadOnly" [formControlName]="question.questionName"
          (selectionChange)="onChange(question, $event, question.questionTypeName)"
          [required]="question.questionRequired" [value]="question.questionSelectedValue">
          <ng-container *ngFor="let opt of question.options">
            <ng-container *ngIf="opt.discriminator.toLowerCase() === 'selectionoption'">
              <mat-option class="mdc-list-item" *ngFor="let resp of opt.list.listItems; let checkIndex = index"
                [value]="resp.id">
                {{resp.description}}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
        <ng-container [ngTemplateOutlet]="getErrorTemplate()"
          [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      </mat-form-field>
    </ng-template>
    <ng-template #selectionAndCommentQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div>
        <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard">
          <mat-label>{{question.questionPhrase}}</mat-label>
          <mat-select [formControlName]="question.questionName"
            (selectionChange)="onChange(question, $event, question.questionTypeName)"
            [required]="question.questionRequired" [value]="question.questionSelectedValue">
            <ng-container *ngFor="let opt of question.options">
              <ng-container *ngIf="opt.discriminator.toLowerCase() === 'selectionoption'">
                <mat-option class="mdc-list-item" *ngFor="let resp of opt.list.listItems; let checkIndex = index"
                  [value]="resp.id">
                  {{resp.description}}
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
          <ng-container [ngTemplateOutlet]="getErrorTemplate()"
            [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
        </mat-form-field>
        <div *ngIf="question.commentAvailable">
          <ng-container [ngTemplateOutlet]="getQuestionCommentTemplate()"
            [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-template #sliderQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div>
        <mat-label class="labelBeforeSlide text--align-center"
          [ngClass]="{ 'invalid': question.hasIssue }">{{question.listItemLabel}}</mat-label>
        <mat-slider class="slider-question" showTickMarks tickInterval="1" step="1"
          [formControlName]="question.questionName" min="0" max="{{question.questionMax}}"
          [ngClass]="question.questionClass" [style.--sliderColor]="question.sliderColor"
          (ngModelChange)="onChange(question, $event, question.questionTypeName, $event.target, this, $this, $element)">
          <input matInput matSliderThumb [readonly]="question.questionReadOnly"
            [required]="question.questionRequired" />
        </mat-slider>
      </div>
    </ng-template>
    <ng-template #groupLabelQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
    </ng-template>
    <ng-template #imageQuestion let-question [formGroup]="questionnaireFormGroup">
      <img class="imageQuestion" title="Image" [src]="question.questionSrc" />
    </ng-template>
    <ng-template #toggleQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <div>
        <mat-label class="toggle-question-label"
          ngClass="labelBeforeSlide">{{question.listItems[0].description}}</mat-label>
        <mat-slide-toggle (change)="onChange(question, $event, question.questionTypeName)">
          {{question.listItems[1].description}}
        </mat-slide-toggle>
      </div>
    </ng-template>
    <ng-template #radioQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <input *ngIf="question.questionReadOnly" matInput [formControlName]="question.questionName" [readonly]="true"
        [required]="question.questionRequired"
        [value]="getSelectedValueText(question.questionId, question.questionSelectedValue)">
      <mat-radio-group [ngClass]="question.questionClass" *ngIf="!question.questionReadOnly"
        [formControlName]="question.questionName" [required]="question.questionRequired">
        <ng-container *ngFor="let opt of question.options">
          <ng-container *ngIf="opt.discriminator.toLowerCase() === 'selectionoption'">
            <mat-radio-button *ngFor="let resp of opt.list.listItems; let checkIndex = index" [value]="resp.id"
              [checked]="resp.id == question.responseOptionId"
              (change)="onChange(question, $event, question.questionTypeName)">
              {{resp.description}}
            </mat-radio-button>
          </ng-container>
        </ng-container>
      </mat-radio-group>
    </ng-template>
    <ng-template #gridQuestion let-question [formGroup]="questionnaireFormGroup">
      <ng-container [ngTemplateOutlet]="getQuestionPhraseTemplate()"
        [ngTemplateOutletContext]="{ $implicit: question }"></ng-container>
      <ag-grid-angular class="ag-theme-material grid-question" domLayout="autoHeight"
        [headerHeight]="question.questionGridDefinition.headerHeight" [rowData]="question.questionGridData"
        [editType]="fullRow" [immutableData]="true" [columnDefs]="question.questionGridDefinition.columnDefs"
        (gridReady)="onGridReady($event,question.questionGridDefinition.maxRows)"
        (cellValueChanged)="onChange(question, $event, question.questionTypeName)">
      </ag-grid-angular>
    </ng-template>
    <ng-template #questionPhrase let-question>
      <h5 class="text--align-left mdc-typography--subtitle1 mb-2">
        <span [innerHTML]="question.questionPhrase"></span>
        <span class="question-required-indicator">{{question.questionRequiredIndicator}}</span>
      </h5>
    </ng-template>
    <ng-template #questionComment let-question [formGroup]="questionnaireFormGroup">
      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
        <input matInput [formControlName]="question.questionNameComment" [readonly]="question.questionReadOnly"
          [required]="question.commentRequired" placeholder="Comment" pattern="{{question.questionCommentPattern}}"
          type="text" maxlength="{{question.questionMaxLength}}"
          (ngModelChange)="onChange(question, $event, 'comment')">
        <mat-error *ngIf="getErrorMessage(question.questionNameComment, false) != ''">
          {{getErrorMessage(question.questionNameComment, false)}}
        </mat-error>
      </mat-form-field>
    </ng-template>
    <!-- #endregion -->
  </article>
</div>