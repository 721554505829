import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { BaseConsentMapper, WellnessHivConsentMapper } from 'src/app/shared/interfaces/consentMapper';

import { SignatureFieldComponent } from '../../../shared/components/signature-field/signature-field.component';
import { PatientDetailObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

@Component({
  selector: 'app-corporate-wellness-hiv-consent',
  templateUrl: './hiv-consent.component.html',
  styleUrls: ['./hiv-consent.component.scss']
})
export class CorporateWellnessHIVConsentComponent implements OnInit {
  isLoading: boolean = false;
  consent: boolean = null;
  clientId: string = null;


  constructor(private route: ActivatedRoute, public consultationService: ConsultationService, private router: Router, private globalData: GlobalDataService
  ) {

  }

  consentMapper: WellnessHivConsentMapper;

  ngOnInit(): void {
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);
    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {
      /* console.log(result) */;
      this.consentMapper = {
        member_FullName: `${(result.patientDetail.fullNames || '')} ${(result.patientDetail.surname || '')}`,
        member_IdNumber: result.patientDetail.rsaidnumber || result.patientDetail.passportNo,
        member_IdNumberLabel: result.patientDetail.rsaidnumber && result.patientDetail.rsaidnumber.length > 10 ? " RSA ID Number" : " Passport Number",
        consultationDetailId: this.clientId,
        member_Signature: undefined,
        member_SignatureDate: undefined,
        examiner_Signature: undefined,
        examiner_SignatureDate: undefined,
        testPerformedId: undefined,
        consent: this.consent
      }
      this.isLoading = false;
    });
  }
  postSubmit(data) {
    this.consultationService.postWellnessHIVConsent(data).subscribe(result => {
      this.router.navigate(["../consultation"], { relativeTo: this.route })
    })
  }
}
