import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferralMapper } from '../../interfaces/consentMapper';
import { ConsultationService } from '../../services/consultation.service';
import { DocumentService } from '../../services/document.service';
import { GlobalDataService } from '../../services/global-data.service';
import { SignatureFieldComponent } from '../signature-field/signature-field.component';
//TODO: Add logged in user profile here.
// import { KeycloakProfile } from 'keycloak-js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConsultationObject, SchedulingDetailObject } from '../../interfaces/consultationMapper';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  isLoading = false;
  showError = false;
  // userProfile: KeycloakProfile;
  referralId: string = null;
  clientId: string = null;
  referralMapper: ReferralMapper = undefined;
  consultationDetail: ConsultationObject = null;
  schedulingDetail: SchedulingDetailObject = null;

  accounts = [
    { name: 'Company', id: 1, value: "company" },
    { name: 'COID', id: 0, value: "coid" },
    { name: 'Medical Aid', id: 2, value: "medicalAid" },
    { name: 'Members own account', id: 3, value: "own" }
  ];

  public form: FormGroup;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;
  disableSubmit: boolean = false;

  constructor(
    fb: FormBuilder,
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    public dialog: MatDialog,
    private documentService: DocumentService) {
    this.form = fb.group({
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required],
    });
    this.globalData.updateBackRoute('/stepper-overview');
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
    sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
  }

  public setOptions() {
    this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.sigs.last.signaturePad.set('penColor', 'rgb(255, 0, 0)');
  }

  public clear() {
    this.sigs.first.clear();
    this.sigs.last.clear();
  }

  public ngAfterViewInit() {
    if (this.sigs.length > 0 && (this.referralMapper.detailsSignature || this.referralMapper.nurseSignature)) {
      this.sigs.first.signaturePad.fromDataURL(this.referralMapper.nurseSignature);
      this.sigs.last.signaturePad.fromDataURL(this.referralMapper.detailsSignature);
    }
    this.beResponsive();
    this.setOptions();


  }

  ngOnInit(): void {
    this.globalData.consultationReferralIdVal.subscribe(result => this.referralId = result);
    // this.globalData.userProfileVal.subscribe(up => this.userProfile = up);
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);


    this.isLoading = true;
    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {
      this.consultationDetail = result as ConsultationObject;
      this.consultationService.getSchedulingDetailByConsultationId(this.clientId).subscribe(sd => {
        this.schedulingDetail = sd as SchedulingDetailObject;
        if (this.referralId) {
          this.documentService.getReferralLetter(this.referralId).subscribe(referral => {
            this.referralMapper = referral as ReferralMapper;
            this.isLoading = false;
          });
        }
        else {
          this.referralMapper = {
            id: null,

            detailsName: this.consultationDetail.patientDetail.fullNames,
            detailsSurname: this.consultationDetail.patientDetail.surname,
            detailsDepartment: this.consultationDetail.patientDetail.hierarchyLvl1,
            detailsDate: new Date(),
            detailsTo: '',
            detailsSite: this.schedulingDetail.clinicMapping.workflowName,
            detailsIDNumber: this.consultationDetail.patientDetail.rsaidnumber,
            detailsEmployeeNumber: this.consultationDetail.patientDetail.employeeNumber,
            detailsDateSignature: null,
            detailsFollowUpDate: null,
            detailsSignature: null,

            referralAccountPaymentType: null,

            nurseDate: null,
            nurseEmail: null,
            nurseFindings: null,
            nurseName: null,
            nurseSignature: null,
            nurseTel: null,

            createDate: null,
            createName: null,
            changeDate: null,
            changeName: null,

            active: true,
            document: null
          };
          this.isLoading = false;
        }
      });
    });
  }

  save(referralForm, submit: boolean = false): void {
    /* console.log(referralForm) */;
    Object.keys(referralForm.form.controls).forEach(key => {
      referralForm.form.controls[key].markAsTouched();
    });

    this.referralMapper.createDate = this.referralMapper.createDate || new Date();
    //TODO: Add logged in user details.
    this.referralMapper.createName = this.referralMapper.createName || /*this.userProfile.firstName + ' ' + this.userProfile.lastName*/'locum';
    this.referralMapper.nurseSignature = this.sigs.first.signaturePad.toDataURL();
    this.referralMapper.detailsDateSignature = this.referralMapper.nurseDate = new Date();
    this.referralMapper.detailsSignature = this.sigs.last.signaturePad.toDataURL();
    this.referralMapper.detailsFollowUpDate = new Date();
    if (referralForm.form.valid) {
      this.showError = false;
      this.isLoading = true;

      if (!this.referralMapper.id) {
        this.documentService.postReferralLetter(this.referralMapper).subscribe((result) => this.saveSuccess(result, submit));
      }
      else {
        this.documentService.putReferralLetter(this.referralMapper).subscribe((result) => this.saveSuccess(result, submit));
      }
    }
    else {
      this.showError = true;
    }
  }

  saveSuccess(result, submit: boolean) {
    this.referralMapper = result as ReferralMapper;
    this.isLoading = false;
    this.openNotifyDialog(submit);
  }

  openNotifyDialog(submit: boolean): void {
    const dialogRef = this.dialog.open(LORNotifyDialogComponent, {
      width: '550px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (submit) {
        this.openConfirmDialog();
      }
      else {
        this.router.navigate(['/stepper-overview']);
        this.isLoading = false;
      }
    });
  }

  openConfirmDialog(): void {
    this.isLoading = true;
    const dialogRef = this.dialog.open(LORConfirmDialogComponent, {
      width: '550px'
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.disableSubmit = true;
        this.documentService.createReferralLetter(this.referralMapper.id).subscribe((result) => {
          this.router.navigate(['/stepper-overview']);
          this.isLoading = false;
        });
      }
      else {
        this.isLoading = false;
      }
    });
  }

  submit(form) {
    this.save(form, true);
  }


  downloadPdf(base64String, fileName): void {
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  back(): void {
    this.router.navigate(['/stepper-overview']);
  }

  getFirstName(fullName): string {
    return fullName.substring(fullName.indexOf(',') + 2, fullName.length).trim();
  }

  setAccount(event): void {
    this.referralMapper.referralAccountPaymentType = event.value;
  }
}


@Component({
  selector: 'lor-notify-dialog',
  templateUrl: 'lor-notify-dialog.component.html',
})
export class LORNotifyDialogComponent {

  constructor(public dialogRef: MatDialogRef<LORNotifyDialogComponent>, private router: Router) { }

  ok() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'lor-confirm-dialog',
  templateUrl: 'lor-confirm-dialog.component.html'
})
export class LORConfirmDialogComponent {
  referralId: string;
  constructor(
    public dialogRef: MatDialogRef<LORConfirmDialogComponent>,
    private globalData: GlobalDataService,
    private router: Router,
    private documentService: DocumentService) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmitCLick() {
    this.dialogRef.close(true);
  }
}