<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false">
      <!-- #region Exmination Status -->
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
          <section class="mdc-typography--body1">
            <h1 class="mdc-typography--headline2 text--align-center">{{groupName}}</h1>
          </section>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region Questionnaires -->
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell grid__cell--align-stretch mb-4 section-spacing--tier-1">
          <form #questionForm="ngForm" matNativeControl>
            <div class="text--align-center" *ngFor="let q of mapper.reviews let qindex = index">
              <hr *ngIf="qindex > 0" />
              <span *ngIf="qindex > 0" class="blank-line blank-line-2 d-block"></span>
              <h3 class="mdc-typography--headline5 text--align-center mb-3" style="font-size: 1.3vw;">
                {{q.testRequested.testMapping.workflowName}}</h3>
              <div class="grid__cell grid__cell--span-all">
                <app-questionnaire [consultationId]="consultationId"
                  [questionnaireId]="q.testRequested.testMapping.questionnaireId" [testRequested]="q.testRequested"
                  [consultationSelection]="'Review'" [showBackButton]="false" [allowSubmit]="false"
                  (onSaved)="onSaved(q)" [reviewId]="q.review.reviewId" [showTitle]="false">
                </app-questionnaire>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region Navigation -->
      <section class="grid__inner">
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="back()">Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- #endregion -->
    </div>
    <!-- #region Templates-->
    <ng-template #submittedTemplate>
      Completed
    </ng-template>
    <!-- #endregion -->
  </article>
</div>
