<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false && showError === true">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-cent
    er mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="previousPage()">Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="!isLoading && !showError">
      <div class="mdc-typography--headline2">
        <p class="text--align-center">Appointments</p>
      </div>
      <span class="blank-line blank-line-2 d-block"></span>
      <div class="grid__inner">
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <div [formGroup]="appointmentSelect">
            <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
              <mat-label>Select a Clinic </mat-label>
              <mat-select (selectionChange)="selectOption($event)" formControlName=clinicSelect #clinicSelect>
                <mat-option *ngFor="let cl of clinics" [value]="cl.id">{{cl.workflowName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [formGroup]="appointmentPicker">
              <input matStartDate placeholder="Start date" #dateRangeStart formControlName="dateRangeStart"
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
              <input matEndDate placeholder="End date" #dateRangeEnd formControlName="dateRangeEnd"
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
            (click)="btnSearch_Click()">
            Search
          </button>
        </div>
      </div>
      <!-- ////////////////////////////////////////////////////////////////// -->
      <mat-form-field appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select [formControl]="resultTypes" multiple (selectionChange)="filterChange('type',$event)">
          <mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- ///////////////////////////////////////////////////////////////////////// -->
      <div>
        <mat-form-field *ngFor="let filter of filterSelectObj" style="margin-left: 15px;">
          <mat-label>Filter {{filter.name}}</mat-label>
          <select matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
            (change)="filterChange(filter,$event)">
            <option value="">-- Select {{filter.name}} --</option>
            <option [value]="item" *ngFor="let item of filter.options.sort()">{{item}}</option>
          </select>
        </mat-form-field>
        &nbsp;
        <button mat-flat-button color="warn" (click)="resetFilters()">Reset</button>
      </div>
      <table class="mdc-typography--body2" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
          <td mat-cell *matCellDef="let element">
            <div class="nowrap">{{element.date}}</div>
            <div class="nowrap">{{element.time}}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="consent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Consent </th>
          <td mat-cell *matCellDef="let element"> {{element.consent}} </td>
        </ng-container>
        <ng-container matColumnDef="procedure">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Procedure </th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.procedure}}  -->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="panel-title">
                  <div *ngFor="let item of element.procedures | slice:0:3; let i = index;" class="group-container">
                    <img src="assets/img/{{item.imageUrl}}.svg" class="group-container--status">
                    <span class="group-container--name">{{item.groupName}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content-container">
                <div *ngFor="let item of element.procedures | slice: 3:12; let i = index;" class="group-container">
                  <img src="assets/img/{{item.imageUrl}}.svg" class="group-container--status">
                  <span class="group-container--name">{{item.groupName}}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Employer </th>
          <td mat-cell *matCellDef="let element"> {{element.company}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element" style="text-align: center; position: relative;">
            <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
              style="top: 0; bottom: 0; left: 0; right: 0; margin: auto; position: absolute;"
              (click)="redirectStepper(element.consultationId)">View Details</button>
            <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
              style="visibility: hidden;">View Details</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
  </article>
</div>
