import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../shared/services/global-data.service';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {

  constructor(public globalData: GlobalDataService) {
    this.globalData.updateBackRoute('/landing');
  }

  ngOnInit(): void {
  }

}
