import { GeneralizedObject } from "./generalized-OBJECT";

export class EventSubmissionCoordinator{//user who makes booking for event
    ID: number; //RSA ID or user ID?
    FULLNAME: string;
    RANK:GeneralizedObject;
    TELEPHONE?: number;
    CELLPHONE: number;
    EMAIL: string;
    //extra variables for logged in user
    USERNAME:string;
    ROLE:string;
}