import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalDataService} from './global-data.service';
import {Observable, of} from 'rxjs';
import * as Enums from '../enums';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientDetailsService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
              private globalServ: GlobalDataService) { }



  getClientDetails(): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.CLIENT_DETAILS, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get client details', undefined)));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

}
