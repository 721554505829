import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalDataService } from '../../services/global-data.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {
  route: any = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalData: GlobalDataService,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.globalData.backRouteVal.subscribe(r => {
      this.route = r;
      this.changeDetector.detectChanges();
    });
  }

  back(): void {
    if (this.route) {
      let reroute = this.route;
      this.globalData.updateBackRoute();
      this.router.navigate(Array.isArray(reroute) ? reroute : [reroute]);
    }
  }

}
