import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-eap-close-out',
  templateUrl: './eap-close-out.component.html',
  styleUrls: ['./eap-close-out.component.scss']
})
export class EAPCloseOutComponent implements OnInit {
  isSubmit = false;
  isLoading = false;
  showError = false;

  // Variables for handling questionnaires
  consultationId: string = '8B135CD0-E0AD-4CF2-8A49-F0D113182166'; //Req
  questionnaireId: string = 'CCA6DA0E-3006-44A1-A99A-0552C99578B5'; //Req

  // Variables for handling reviews
  reviewId: string; //Req

  testRequested: TestRequestedObject = {
    id: '149F1782-CA80-4EFE-B88A-CD57978DC564',
    consultationId: '8B135CD0-E0AD-4CF2-8A49-F0D113182166',
    testMappingId: 'AC7D4833-657E-4904-8AC1-48A4FA9D81A6',
    sequenceNo: 5,
    active: true,
    status: 0,
    createDate: new Date('2022-07-19 12:44:30.980'),
    createName: 'Perigon Service',
    changeDate: null,
    changeName: null,
    groupName: 'EAP',
    notes: '',
    testsPerformed: [],
    testMapping: {
      id: 'AC7D4833-657E-4904-8AC1-48A4FA9D81A6',
      ocsaid: '00000000-0000-0000-0000-000000000000',
      ocsaname: null,
      workflowId: 'EAPCOR',
      workflowName: 'Close out report', 
      questionnaireId: 'CCA6DA0E-3006-44A1-A99A-0552C99578B5', 
      active: true,
      groupName: 'EAP',
      summary: 'Close out report',
      detail: 'Close out report',
      iconName: null
    }
  }; //Req
  consultationSelection: string = 'Questionnaire'; //Req

  constructor(private router: Router, private globalData: GlobalDataService, private questionnaireService: QuestionnaireService, private snackBar: MatSnackBar) {

    this.globalData.updateBackRoute('/eap');

  }

  ngOnInit(): void {

    // Get the consultation selection (indicates if a questionnaire of review was selected)

    this.isLoading = false;
  }

  onSaved(): void {
    this.globalData.setCloseOutCompleted(true);
    this.snackBar.open('Saved', null, { duration: 2500 }).afterDismissed().subscribe(() => this.router.navigate(['/eap']));
  }
}
