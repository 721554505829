import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CalculationService } from 'src/app/shared/services/calculation.service';

import { GlobalDataService } from '../../../shared/services/global-data.service';

@Component({
  selector: 'app-survey-completed',
  templateUrl: './survey-completed.component.html',
  styleUrls: ['./survey-completed.component.scss']
})
export class SurveyCompletedComponent implements OnInit {
  constructor(private globalData: GlobalDataService, private activatedRoute: ActivatedRoute, private router: Router, private calcultationService: CalculationService) {

  }

  risk: boolean;

  onSaved(): void {
    //this.router.navigate(['./survey-completed']);
  }

  ngOnInit(): void {
    let surveyScore = this.calcultationService.eapSurveyResult;
    if (surveyScore) {

    }
    if (surveyScore.toUpperCase() == "1BFD71AC-A57E-4CE0-B50E-54FB261323D3" || surveyScore.toUpperCase() == "A3C5FE2D-E847-436C-83E4-9B44F28783ED") {
      this.risk = false;
    }
    else {
      this.risk = true
    }
  }

}
