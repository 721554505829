import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ADHOCWELLNESSCONSENTSTATUS } from 'src/app/shared/enums';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';

import { ConsultationObject, ConsultationTestPerformedObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { FinalCheckMapper, WellnessConsentSummaryMapper } from '../../../shared/interfaces/dataMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

//TODO: Add logged in user profile here.
// import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-adhoc-wellness-consultation-summary',
  templateUrl: './consultation-summary.component.html',
  styleUrls: ['./consultation-summary.component.scss']
})
export class AdhocWellnessConsultationSummaryComponent implements OnInit {

  isLoading = false;
  consultationDetail: ConsultationObject;
  testObject: TestRequestedObject[] = [];
  clientId = '';
  isFailed = false;
  finalCheck: FinalCheckMapper;
  consent: WellnessConsentSummaryMapper;
  hivConsent: WellnessConsentSummaryMapper;
  consentRequired = false;
  consentTr: TestRequestedObject;
  vCounselTr: TestRequestedObject;
  hivConsentTr: TestRequestedObject;
  // userProfile: KeycloakProfile;
  eventId: string | null;
  eventTitle: string;
  sortOrder: {
    "ADHOCCONSENT": 0,
    "ADHOCHRA": 1,
    "ADHOCTB": 2,
    "ADHOCHIVCONSENT": 3,
    "ADHOCVCOUNCELLING": 4,
    "ADHOCCCVHIVT": 5,
    "ADHOCSTRESS": 6,
    "ADHOCMENTALWELLBEING": 7
  };
  hivVoluntary: any;
  isHealthPractitioner: boolean = false;

  constructor(private questionnaireService: QuestionnaireService, private route: ActivatedRoute, private consultationWorkflowService: ConsultationWorkflowService, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private ref: ChangeDetectorRef) {
    this.globalData.updateBackRoute('/wellness-event/adhoc/landing');

    this.globalData.finalCheckVal.subscribe(result => this.finalCheck = result);
    this.globalData.wellnessConsentVal.subscribe(result => this.consent = result);
    this.globalData.bookingEventTitleVal.subscribe(result => this.eventTitle = result);
    // this.userProfile = {
    //   "id": "b3502ad7-e723-4371-b762-27de8352152d",
    //   "username": "pjmotake",
    //   "firstName": "SAPS",
    //   "lastName": "User",
    //   "email": "sapsuser@perigon.net",
    //   "emailVerified": false,
    // };
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);
    this.globalData.userRolesVal.subscribe(roles => this.isHealthPractitioner = roles.indexOf('Health practitioner') > -1);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getData();
  }

  addWeekToDate(dt: Date) {
    dt.setDate(dt.getDate() + 7);
    return dt;
  }

  get consentStatus() {
    return {
      // HRA: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.HRA) == ADHOCWELLNESSCONSENTSTATUS.HRA
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT,

      // CHANGE HRAs to COMPACTHRA for below
      COMPACTHRA: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.COMPACTHRA) == ADHOCWELLNESSCONSENTSTATUS.COMPACTHRA
        // && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT,

      SNELLEN: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.SNELLEN) == ADHOCWELLNESSCONSENTSTATUS.SNELLEN
        // && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
        && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY,
      // && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT

      // HIVConsent: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.HIV) == ADHOCWELLNESSCONSENTSTATUS.HIV
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT,

      // MentalWellbeing: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.MENTALWELLBEING) == ADHOCWELLNESSCONSENTSTATUS.MENTALWELLBEING
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT,

      // TBQuestionnaire: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.TB) == ADHOCWELLNESSCONSENTSTATUS.TB
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT,

      // Stress: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.STRESS) == ADHOCWELLNESSCONSENTSTATUS.STRESS
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT,

      // HIVVoluntary: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.HIV) == ADHOCWELLNESSCONSENTSTATUS.HIV
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT
      //   ? (!this.hivConsent ? null : (this.hivConsent.status == 'Consent given' ? true : false))
      //   : false,

      // HIV: (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.HIV) == ADHOCWELLNESSCONSENTSTATUS.HIV
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC) == ADHOCWELLNESSCONSENTSTATUS.BIOMETRIC
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.INDEMNITY) == ADHOCWELLNESSCONSENTSTATUS.INDEMNITY
      //   && (this.consent.consentStatus & ADHOCWELLNESSCONSENTSTATUS.BENEFIT) == ADHOCWELLNESSCONSENTSTATUS.BENEFIT
      //   ? (!this.hivVoluntary ? null : (this.hivVoluntary.status == 'Completed' ? true : false))
      //   : false
    }
  }


  getData(): void {

    this.consultationService.getConsultationDetail(this.clientId).subscribe(returnValue => {
      const result = returnValue as ConsultationObject;
      localStorage.setItem('consultationDetail', JSON.stringify(result));
      this.eventId = result.adhocEvent.id;
      this.globalData.updateBackRoute(['/wellness-event/adhoc/registration', this.eventId, this.eventTitle]);
      if (result) {
        try {
          let tr = result
            .testsRequested
            .filter(d =>
              d.testMapping &&
              d.testMapping.groupName &&
              d.testMapping.summary &&
              //d.testMapping.detail &&
              d.testMapping.questionnaireId != "00000000-0000-0000-0000-000000000000");
          result.testsRequested = tr.sort((a, b) => b.sequenceNo > a.sequenceNo ? 1 : (a.sequenceNo > b.sequenceNo ? -1 : 0));
        }
        catch (e) {

        }
        this.consultationDetail = result;

        try {
          if (this.consultationDetail.testsRequested) {
            if (this.testObject.length === 0) {
            }

            for (let i = 0; i < this.consultationDetail.testsRequested.length; i++) {
              let tr2 = this.consultationDetail.testsRequested[i];
              let testMapping = tr2.testMapping || { workflowName: '', groupName: '', iconName: '', testMappingId: '', summary: '', workflowId: '', questionnaireId: '', detail: '' };
              let groupName = testMapping.groupName.toLowerCase();

              if (testMapping.workflowId == 'ADHOCHIVCONSENT') {
                this.hivConsentTr = tr2;
                if (this.hivConsentTr && this.hivConsentTr.status == "finished") {
                  if (this.hivConsentTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.hivConsentTr.testsPerformed[0];
                    this.hivConsent = {
                      notes: tp.resultValue == 6 ? "Consent given" : "Consent refused",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'HIV',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 6 ? "Consent given" : "Consent refused"
                    };
                  }
                }
              }

              if (testMapping.workflowId == 'ADHOCVCOUNCELLING') {
                this.vCounselTr = tr2;
                if (this.vCounselTr && this.vCounselTr.status == "finished") {
                  if (this.vCounselTr.testsPerformed.length > 0) {
                    let tp: ConsultationTestPerformedObject = this.vCounselTr.testsPerformed[0];
                    this.hivVoluntary = {
                      notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                      createdDate: tp.createDate,
                      disabled: false,
                      groupName: 'HIV',
                      consentStatus: tp.resultValue,
                      required: false,
                      status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                    };
                  }
                }

                if (testMapping.workflowId == 'ADHOCVCOUNCELLING') {
                  this.vCounselTr = tr2;
                  if (this.vCounselTr && this.vCounselTr.status == "finished") {
                    if (this.vCounselTr.testsPerformed.length > 0) {
                      let tp: ConsultationTestPerformedObject = this.vCounselTr.testsPerformed[0];
                      this.hivVoluntary = {
                        notes: tp.resultValue == 0 ? "Completed" : "Incomplete",
                        createdDate: tp.createDate,
                        disabled: false,
                        groupName: 'HIV',
                        consentStatus: tp.resultValue,
                        required: false,
                        status: tp.resultValue == 0 ? "Completed" : "Incomplete"
                      };

                    }
                  }
                }
              }

              if (testMapping.workflowId == 'ADHOCCONSENT') {
                this.consentRequired = true;
                this.consentTr = tr2;
                if (this.consentTr.testsPerformed.length > 0) {
                  let tp: ConsultationTestPerformedObject = this.consentTr.testsPerformed[0];
                  let consentStatus: ADHOCWELLNESSCONSENTSTATUS = tp.resultValue;

                  // let hivTest: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.HIV) == ADHOCWELLNESSCONSENTSTATUS.HIV;
                  // let stress: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.STRESS) == ADHOCWELLNESSCONSENTSTATUS.STRESS;
                  // let mentalWellbeing: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.STRESS) == ADHOCWELLNESSCONSENTSTATUS.MENTALWELLBEING;
                  // let tbQuestionnaire: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.TB) == ADHOCWELLNESSCONSENTSTATUS.TB;
                  // let hra: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.HRA) == ADHOCWELLNESSCONSENTSTATUS.HRA;
                  let chra: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.COMPACTHRA) == ADHOCWELLNESSCONSENTSTATUS.COMPACTHRA;
                  let snellen: boolean = (consentStatus & ADHOCWELLNESSCONSENTSTATUS.SNELLEN) == ADHOCWELLNESSCONSENTSTATUS.SNELLEN;
                  // let notes: string = hra || hivTest || stress || mentalWellbeing || tbQuestionnaire || chra || snellen ? 'Consent given' : 'Consent to some wellness tests to continue';
                  let notes: string = chra || snellen ? 'Consent given' : 'Consent to some wellness tests to continue';
                  // let status: string = hra || hivTest || stress || mentalWellbeing || tbQuestionnaire || chra || snellen ? 'finished' : 'refused';
                  let status: string = chra || snellen ? 'finished' : 'refused';
                  this.consent = {
                    notes: notes,
                    createdDate: tp.createDate,
                    disabled: false,
                    groupName: 'Consent',
                    consentStatus: consentStatus,
                    status: status,
                    required: false
                    // status: this.consentMapper.dataConsent === false || this.consentMapper.examConsent === false ? 'inProgress' : 'finished'
                  };
                }
              }
              else {
                if (tr2.testsPerformed.length < 1) {
                  this.testObject.unshift(tr2);
                } else {

                  this.testObject.push(tr2);
                }
              }

              this.ref.detectChanges();
            }

            if (this.consentRequired) {

            }
          }
        } catch (ex) {
          this.isLoading = false;

          this.isFailed = true;
        }

        // Filtering for the Tests we want on Consultation Summary
        let testIds: string[] = ['65E10D68-D8D5-4962-A546-A561AACCBE75', '0C6473F6-6B60-4B5A-ACF8-B73E06A79A4A', '64B00166-195E-49DD-8E1A-6AA560606829'];

        this.testObject = this.testObject.filter(x => testIds.includes(x.testMappingId.toUpperCase()));

        this.testObject.forEach(to => {

          to.testsPerformed.forEach(tp => {
            switch (tp.resultValue) {
              case 0:
                if (tp.questionnaireResult) {
                  if (tp.questionnaireResult == 0) {
                    tp.resultInEnglish = 'Normal';
                    if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                      tp.resultInEnglish = "Negative";
                    }
                  }
                  else {
                    if (tp.questionnaireResult == 1) {
                      tp.resultInEnglish = 'Abnormal';
                      if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                        tp.resultInEnglish = "Positive";
                      }
                    }
                    else {
                      tp.resultInEnglish = 'Normal';
                      if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                        tp.resultInEnglish = "Negative";
                      }
                    }
                  }
                }
                else {
                  tp.resultInEnglish = 'Normal';
                  if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                    tp.resultInEnglish = "Negative";
                  }
                }



                // Special processing for Respiratory, SHE history, External deployment history and pre-employment history questionnaire
                if (tp.testMapping.questionnaireId.toUpperCase() == 'ED35B3BE-9415-495C-A929-B37042D89961'
                  || tp.testMapping.questionnaireId.toUpperCase() == '76651826-F059-4D58-AAE6-E5C0F941B3C3'
                  || tp.testMapping.questionnaireId.toUpperCase() == 'BE788A56-55F4-4E0C-9026-96138F665292'
                  || tp.testMapping.questionnaireId.toUpperCase() == 'A7A5CB5A-B677-4572-9BB2-59E078108089'
                  || tp.testMapping.questionnaireId.toUpperCase() == '94B8C4DD-84FD-4881-92A0-AEA5A989D20E'
                  || tp.testMapping.questionnaireId.toUpperCase() == '8F730DAB-4B1D-4025-972A-8348C0120988') {
                  tp.resultInEnglish = 'Done';
                }
                break;
              case 1:
                tp.resultInEnglish = 'Abormal';
                if (tp.testMapping.workflowName == "Confidential and voluntary HIV test") {
                  tp.resultInEnglish = "Positive";
                }
                break;
              case 2:
                tp.resultInEnglish = 'Test Not Done';
                break;
              case 3:
                tp.resultInEnglish = 'Contraindicated';
                break;
              case 4:
                tp.resultInEnglish = 'Awaiting test results';
                break;
              case 5:
                tp.resultInEnglish = 'Not required';
                break;
              case 6:
                tp.resultInEnglish = 'Consent given';
                break;
              case 7:
                tp.resultInEnglish = 'Consent refused';
                break;
              case 8:
                tp.resultInEnglish = 'Test refused';
                break;
              case 9:
                tp.resultInEnglish = 'Member refused';
                break;
              default:
                tp.resultInEnglish = 'Unknown';
                break;
            }
          });

          if (to.testsPerformed.length) {
            var resultInEnglish = to.testsPerformed[0].resultInEnglish;
            var resultValue = to.testsPerformed[0].resultValue;
            var isVaccination = to.testMapping.groupName.toLowerCase().includes('vaccination');
            var isFailedResult = [1, 3, 7, 8].includes(resultValue);
            var isIntermediateResult = [2, 4, 9].includes(resultValue);
            var isPassResult = [5, 6].includes(resultValue);

            to.testStatusFailed = !isVaccination && (resultInEnglish == 'Abnormal' || resultInEnglish == "Positive" || isFailedResult);
            to.testStatusIntermediate = (isVaccination && resultInEnglish == 'Test Not Done') || (!isVaccination && isIntermediateResult);
            to.testStatusPass = (isVaccination && resultInEnglish == 'Done') || (!isVaccination && (resultInEnglish == 'Done' || resultInEnglish == 'Normal' || resultInEnglish == "Negative" || isPassResult));
            to.testStatusError = to.testsPerformed[0].result == null;
          }
        });

        this.isLoading = false;
      } else {
        this.isLoading = false;

        this.isFailed = true;

      }
    });

  }

  viewTest(): void {

  }

  previousPage(): void {
    this.isLoading = true;
    this.router.navigate(['/wellness-event/adhoc/landing'], { relativeTo: this.route });
  }

  back() {
    this.router.navigate(['/wellness-event/adhoc/registration', this.eventId, this.eventTitle], { relativeTo: this.route });
  }

  startTest(tests: TestRequestedObject): void {
    this.isLoading = true;
    console.log(tests);
    this.globalData.updateQuestionnaireClientData(tests);
    this.globalData.updateQuestionnaireId(tests.testMapping.questionnaireId);
    let route: String;
    switch (tests.testMapping.workflowId) {
      case "ADHOCHIVCONSENT":
        route = '../hiv-consent';
        break;
      default:
        route = '../questionnaire';
        break;
    }
    this.router.navigate([route], { relativeTo: this.route });
  }

  completeConsent(): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireClientData(this.consentTr);
    this.router.navigate(['/wellness-event/adhoc/consent'], { relativeTo: this.route });
  }

  reviewTest(tests): void {
    this.isLoading = true;
    this.globalData.updateQuestionnaireClientData(tests);
    this.globalData.updateQuestionnaireId(tests.testMapping.questionnaireId);
    this.globalData.updateReviewQuestionnaireId(tests.testsPerformed[0].reviewId);
    this.router.navigate(['/wellness-event/adhoc/questionnaire'], { relativeTo: this.route });
  }

  viewConsent(): void {

  }
}
