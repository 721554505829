<div class="container">
  <article class="grid">
    <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading"></three-bar-loader>
    <div class="grid__inner grid__inner--layout-b8b">
      <div class="grid__cell">
        <div class="text--align-center">
          <h1 class="mdc-typography--headline2">Admin assistant registration</h1>
        </div>
        <br />
        <br />
        <br />
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="mdc-list-item text--align-left">
                <strong>Admin assistant details</strong>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Full name</mat-label>
                  <input matInput type="text" formControlName="name" required placeholder="e.g. John">
                  <mat-error *ngIf="signupForm.get('name').invalid || signupForm.get('name').touched">Full name is
                    required.</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Surname</mat-label>
                  <input matInput type="text" formControlName="surname" required placeholder="e.g. Khumalo">
                  <mat-error *ngIf="signupForm.get('surname').invalid || signupForm.get('surname').touched">Surname is
                    required.</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>South African ID</mat-label>
                  <input matInput type="number" formControlName="idNumber" (input)="onInput($event)">
                  <mat-error *ngIf="signupForm.get('idNumber').errors?.required">ID is required.</mat-error>
                  <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidSouthAfricanIdNumber">ID is
                    invalid.</mat-error>
                  <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidIdLength">ID is invalid.</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Contact Number</mat-label>
                  <input matInput (input)="onInput($event)" formControlName="contactNumber" required>
                  <mat-error
                    *ngIf="signupForm.get('contactNumber').errors?.required || signupForm.get('contactNumber').touched">Contact
                    Number is required.</mat-error>
                  <mat-error *ngIf="signupForm.get('contactNumber').errors?.invalidCellphone">Contact number is
                    invalid.</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                  <mat-label>Email</mat-label>
                  <input matInput type="text" formControlName="email" required
                    placeholder="e.g. john.khumalo@gmail.com">
                  <mat-error *ngIf="signupForm.get('email').errors?.required">Email is required.</mat-error>
                  <mat-error *ngIf="signupForm.get('email').errors?.email">Email is required.</mat-error>
                </mat-form-field>
              </div>
              <div id="saveContainer">
                <button
                  [ngClass]="{ 'mdc-button--raised': signupForm.valid && signupForm.dirty, 'mdc-ripple-upgraded': signupForm.valid && signupForm.dirty, 'mdc-button__fill--darkblue': signupForm.valid && signupForm.dirty, 'mdc-button': signupForm.valid && signupForm.dirty, 'mat-raised-button': signupForm.invalid || signupForm.pristine }"
                  (click)="onSubmit()" #saveButton [disabled]="signupForm.invalid || signupForm.pristine"
                  id="saveButton" style="vertical-align: middle;">Save</button>
              </div>
            </form>
            <div class="grid__cell">
              <mat-error *ngIf="invalidForm">
                Invalid form submitted. Please fill in all required fields.
              </mat-error>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </article>
</div>
