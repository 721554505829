import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formDirective!: FormGroupDirective;
  isLoading: boolean = false;
  loginForm!: FormGroup;
  passType: string = "password";
  isText: boolean = false;
  linkText: string = "Show password";
  invalidForm: boolean = false;



  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
  ) {
    this.createForm();
  }

  ngOnInit(): void {

  }
  onSubmit() {
    if (this.loginForm.valid) {
      this.invalidForm = false;

    } else {
      this.invalidForm = true;
    }
  }
  hideShowPassword() {
    this.isText = !this.isText;
    this.isText ? this.linkText = "Hide password" : this.linkText = this.linkText = "Show password";
    this.isText ? this.passType = "text" : this.passType = "password";
  }
  createForm() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    })
  }
  resetForm() {
    this.loginForm.reset();
    this.formDirective.resetForm();
  }
  ToLanding() {
    this.isLoading = false;
    this.resetForm();
    //TODO:add path to landing here
  }
}
