// event-status-selector.component.ts

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-event-status-selector',
  templateUrl: './event-status-selector.component.html',
  styleUrls: ['./event-status-selector.component.scss']
})
export class EventStatusSelectorComponent implements OnInit, OnDestroy {
  isFormVisible: boolean = true;
  eventStatusOptions = [
    { label: 'All', value: 'All', id: 'All' },
    { label: 'Booked', value: 'event-booked', id: 'ddbb6282-ecaa-455b-9357-42ce9ca44325' },
    { label: 'Planned', value: 'event-planned', id: '256b8a8e-77fc-4e9d-ac3d-43302c442192' },
    { label: 'Executed', value: 'event-executed', id: '120ad178-d3c5-4361-9547-484d8e6b3a35' },
    { label: 'Cancelled', value: 'event-cancelled', id: '377d722b-7400-4160-98e8-f7084ce2a7e6' }
  ];
  selectedStatus: string[] = [];

  // Define the form group
  eventStatusForm: FormGroup;

  @Output() statusSelected = new EventEmitter<string[]>();

  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<EventStatusSelectorComponent>) { }

  ngOnInit() {
    // Initialize the form group and set up form controls
    this.eventStatusForm = this.formBuilder.group({
      status: [null, Validators.required],
    });
  }

  back() {
    this.dialogRef.close();
  }

  apply() {
    // Check if the form is valid before proceeding
    if (this.eventStatusForm.valid) {
      // Access the selected status from the form control
      this.selectedStatus.push(this.eventStatusForm.get('status').value);

      // Emit the selected status
      this.statusSelected.emit(this.selectedStatus);

      // Hide the form
      this.isFormVisible = false;

      // Reset the form after submission (if needed)
      // this.eventStatusForm.reset();
      this.dialogRef.close(this.eventStatusForm.get('status').value);
    } else {
      console.log('Form is not valid. Please check for errors.');
    }
  }

  ngOnDestroy() {
    // Emit an event when the component is destroyed
  }
}
3
