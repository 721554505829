import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import * as Enums from 'src/app/shared/enums'
import { RegisterPatientSearchParameters, WellnessPatientRegistrationModel } from 'src/app/shared/interfaces/patientMapper'
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper'
import { SouthAfricanId } from 'src/app/shared/interfaces/south-african-id'
import { ConsultationService } from 'src/app/shared/services/consultation.service'
import { GlobalDataService } from 'src/app/shared/services/global-data.service'
import { ListService } from 'src/app/shared/services/list.service'
import { PatientService } from 'src/app/shared/services/patient.service'
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator'
import { emailAddressValidator } from 'src/app/shared/validators/email-address.validator'
import { passportNumberValidator } from 'src/app/shared/validators/passport.validator'
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator'

@Component({
  selector: 'app-adhoc-wellness-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class AdhocWellnessRegistrationComponent implements OnInit, AfterViewChecked {

  // Element Refs
  @ViewChild('saveButton', { static: false, read: ElementRef }) saveButtonRef!: ElementRef;


  // Forms
  registrationForm: FormGroup;
  searchForm: FormGroup;

  // Lookups
  genders: ListItemObject[];
  maritalStatuses: ListItemObject[];
  languages: ListItemObject[];
  medicalAids: ListItemObject[];
  radioMedicalAids: ListItemObject[];
  dropdownMedicalAids: ListItemObject[];
  countries: ListItemObject[];

  // Loader Switches
  gendersLoaded: boolean = false;
  medicalAidsLoaded: boolean = false;
  maritalStatussesLoaded: boolean = false;
  languagesLoaded: boolean = false;
  searching: boolean = false;
  saving: boolean = false;
  consulting: boolean = false;

  // Model
  registrationData: WellnessPatientRegistrationModel = null;

  // Booking arguments
  bookingEventId: string = null;
  bookingEventTitle: string = null;
  saved: boolean = false;

  maxDate = new Date();
  startDate = new Date(new Date().getFullYear() - 20, 0, 1);
  passportSearchForm: any

  constructor(
    private formBuilder: FormBuilder,
    private listService: ListService,
    private patientService: PatientService,
    private router: Router,
    private globalServ: GlobalDataService,
    private consultationService: ConsultationService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.globalServ.userIdVal.subscribe(id => this.globalServ.updateBackRoute(['/wellness-event/adhoc/landing']));
    this.activatedRoute.paramMap.subscribe((params) => {
      this.bookingEventId = params.get('eventId');
      this.bookingEventTitle = params.get('eventTitle');
    });

    this.searchForm = this.formBuilder.group({
      searchType: [null, Validators.required],
      searchId: ['', Validators.compose([southAfricanIdNumberValidator])],
      country: [null],
      dob: [null],
      passport: [null, Validators.compose([passportNumberValidator])]
    });

    this.registrationForm = this.formBuilder.group({
      fullNames: ['', Validators.required],
      surname: ['', Validators.required],
      idNo: ['', null],
      persalNumber: ['', Validators.required],
      genderId: ['', Validators.required],
      languageId: ['', Validators.required],
      medicalAidId: ['', Validators.required],
      medicalAidNumber: ['', null],
      email: ['', emailAddressValidator],
      cellphone: ['', Validators.compose([Validators.required, cellNumberValidator])],
      maritalStatusId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.populateLookups()
  }

  ngAfterViewChecked(): void {
    const secondFormExists = document.getElementById('registrationForm') !== null;
    if (secondFormExists && this.saved) {
      this.saved = false;
      this.saveButtonRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  restrictInput(event: any) {
    const pattern = /[\.\+\#]/; // regular expression
    let inputChar = String.fromCharCode(event.charCode);

    if (pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  clinicianId: string;
  createConsultation(): void {
    this.consulting = true;

    this.globalServ.userIdVal.subscribe(val => { this.clinicianId = val })
    this.consultationService.getAdhocWellnessDayConsultation(this.bookingEventId, this.registrationData.id, this.clinicianId).subscribe(consultationId => {
      this.globalServ.updateSelectedClient(consultationId);
      this.globalServ.updateBookingEventTitle(this.bookingEventTitle);
      this.router.navigate(['/wellness-event/adhoc/consultation'], { relativeTo: this.activatedRoute });
    })
  }

  get searchFormValid() {
    return (this.searchForm.get('searchType').value === 'passport' && this.searchForm.get('passport').value && this.searchForm.get('country').value) ||
      (this.searchForm.get('searchType').value === 'id' && this.searchForm.get('searchId').valid && this.searchForm.get('searchId').value);
  }
  get searchFormInvalid() {
    return !this.searchFormValid;
  }
  search(): void {
    if (this.searchFormValid) {
      this.searching = true;
      let params: RegisterPatientSearchParameters = {
        searchType: this.searchForm.get('searchType').value
      };
      if (params.searchType === 'id') {
        params.idNumber = this.searchForm.get('searchId').value;
      }
      else if (params.searchType === 'passport') {
        params.passport = this.searchForm.get('passport').value;
        params.countryId = this.searchForm.get('country').value;
        params.dob = this.searchForm.get('dob').value;
      }
      this.patientService.search(params).subscribe((data) => {
        data = data || {
          id: "00000000-0000-0000-0000-000000000000",
          fullNames: null,
          surname: null,
          persalNumber: null,
          genderId: params.searchType == "id" ? new SouthAfricanId(this.searchForm.get('searchId').value).genderId : null,
          rankId: null,
          idNo: params.searchType == "id" ? this.searchForm.get('searchId').value : null,
          passportNo: params.searchType == "passport" ? this.searchForm.get('passport').value : null,
          countryId: params.searchType == "passport" ? this.searchForm.get('country').value : null,
          dob: params.searchType == "passport" ? this.searchForm.get('dob').value : null,
          languageId: null,
          medicalAidId: null,
          medicalAidNumber: null,
          email: null,
          cellphone: null,
          maritalStatusId: null
        };

        this.registrationData = data;
        this.registrationForm.markAsPristine();
        this.searching = false;
      })
    }
    else {
      this.searchForm.updateValueAndValidity();
    }
  }

  save() {
    if (this.registrationForm.valid) {
      this.saving = true;
      this.registrationData.fullNames = this.registrationData.fullNames.trim().replace(/\s+/, " ");
      this.registrationData.surname = this.registrationData.surname.trim().replace(/\s+/, " ");
      this.registrationData.persalNumber = this.registrationData.persalNumber.trim().replace(/\s+/, " ");
      //this.registrationData.
      this.patientService.updateWellnessPatient(this.registrationData).subscribe({
        next: (data) => {
          this.saved = true;
          this.saving = false;
          this.registrationData.id = data;
          this.registrationForm.markAsPristine();
          //this.saveButtonRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
          this.snackBar.open('Patient details saved', null, { duration: 2500, panelClass: 'app-notification-success' });
        },
        error: (e) => {
          this.snackBar.open('Submission Error', null, { duration: 2500, panelClass: 'app-notification-error' });
        }
      });
    }
    else {
      this.registrationForm.updateValueAndValidity();
    }
  }

  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  // markFormGroupTouched(formGroup: FormGroup) {
  //   Object.values(formGroup.controls).forEach((control) => {
  //     control.markAsTouched()

  //     if (control instanceof FormGroup) {
  //       this.markFormGroupTouched(control)
  //     }
  //   })
  // }

  isControlInvalid(controlName: string): boolean {
    const control = this.registrationForm.get(controlName) || this.searchForm.get(controlName);
    return control.invalid && control.touched;
  }

  populateLookups(): void {
    this.listService.get(Enums.LISTS.COUNTRIES).subscribe((data) => {
      this.countries = data.listItems
    });
    this.listService.get(Enums.LISTS.GENDERS).subscribe((data) => {
      this.genders = data.listItems
      this.gendersLoaded = true
    })
    this.listService.get(Enums.LISTS.MEDICAL_AIDS).subscribe((data) => {
      this.medicalAids = data.listItems
      this.radioMedicalAids = data.listItems.filter(x => x.code.toLowerCase() == 'radio')
      this.dropdownMedicalAids = data.listItems.filter(x => x.code.toLowerCase() !== 'radio')
      this.medicalAidsLoaded = true
    })
    this.listService.get(Enums.LISTS.MARITAL_STATUSES).subscribe((data) => {
      this.maritalStatuses = data.listItems
      this.maritalStatussesLoaded = true
    })
    this.listService.get(Enums.LISTS.LANGUAGES).subscribe((data) => {
      this.languages = data.listItems
      this.languagesLoaded = true
    })
  }

}
