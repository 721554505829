<article class="grid" *ngIf="!isLoading">
  <div class="grid__inner grid__inner--layout-12">
    <div class="grid__cell" style="background-color: #ffffff;padding: 14px;/*! margin: 15px; */">
      <div class="row" style="position: relative">
        <div style="vertical-align: top;/*! align-items: flex-start; *//*! float: left; */">
          <div class="eapImage">&nbsp;</div>
        </div>
        <div class="cell--align-bottom align-text-bottom wellnessHeaderGridContainer">
          <div class="wellnessHeaderGrid">
            <div>{{ companyText }}&nbsp;Wellness event</div>
            <div>{{eventDate | date: 'dd MMMM yyyy'}}</div>
            <div>{{eventStartTime | date: 'hh:mm a'}} - {{ eventEndTime | date: 'hh:mm a'}}</div>
            <div *ngIf="planned" class="susd-grid">
              <div>Setup starts:</div>
              <div>{{eventSetUpTime | date: 'hh:mm a'}}</div>
              <div>Strikedown ends:</div>
              <div>{{eventStrikeDownTime | date: 'hh:mm a'}}</div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</article>
