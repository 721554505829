<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false && showError === true">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-cent
    er mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="previousPage()">Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <div class="mdc-typography--headline2">
        <p class="text--align-center">Wellness event summary report</p>
      </div>
      <span class="blank-line blank-line-2 d-block"></span>
      <div class="grid__inner">
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <div *ngIf="useServiceProviderFilter">
            <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
              <mat-label>Select a service provider:</mat-label>
              <mat-select [(ngModel)]="selectedServiceProviderIds" (ngModelChange)="selectOption($event)" #clinicSelect
                multiple>
                <!--<mat-option [value]="null">All service providers</mat-option>-->
                <mat-option *ngFor="let cl of serviceProviders" [value]="cl.id">{{cl.workflowName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRangePicker" [min]="dateRangeLimit"
              [max]="today">
              <input matStartDate placeholder="Start date" #dateRangeStart formControlName="dateRangeStart"
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
              <input matEndDate placeholder="End date" #dateRangeEnd formControlName="dateRangeEnd"
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="grid__cell--span-5-desktop grid__cell--span-all">
          <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
            (click)="btnSearch_Click()">
            Search
          </button>
        </div>
        <div class="grid__cell text--align-right">
          <button class="mat-fab download-button mdc-button__fill--darkblue" (click)="downloadReport()">
            <span class="mat-button-wrapper">
              <i role="img" class="material-icons">download</i>
            </span>
          </button>
          <span>Download to Excel</span>
        </div>
      </div>
      <!-- ///////////////////////////////////////////////////////////////////////// -->
      <!-- <div>
      <mat-form-field *ngFor="let filter of filterSelectObj" style="margin-left: 15px;">
        <mat-label>Filter {{filter.name}}</mat-label>
        <select matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
          (change)="filterChange(filter,$event)">
          <option value="">-- Select {{filter.name}} --</option>
          <option [value]="item" *ngFor="let item of filter.options.sort()">{{item}}</option>
        </select>
      </mat-form-field>
      &nbsp;
      <button mat-flat-button color="warn" (click)="resetFilters()">Reset</button>
    </div> -->
      <table class="mdc-typography--body2" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="coordinator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Coordinator </th>
          <td mat-cell *matCellDef="let element">
            {{element.coordinator}}
          </td>
        </ng-container>
        <ng-container matColumnDef="serviceProvider">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Service provider </th>
          <td mat-cell *matCellDef="let element">
            {{element.serviceProvider}}
          </td>
        </ng-container>
        <ng-container matColumnDef="eventDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Event date </th>
          <td mat-cell *matCellDef="let element">
            <div class="nowrap">{{element.eventDate | date: 'dd-MM-yyyy' }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
          <td mat-cell *matCellDef="let element"> {{element.country}} </td>
        </ng-container>
        <ng-container matColumnDef="dateOfBirth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>
          <td mat-cell *matCellDef="let element"> {{element.dateOfBrith}} </td>
        </ng-container>
        <ng-container matColumnDef="employeeNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Employee # </th>
          <td mat-cell *matCellDef="let element"> {{element.employeeNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.surname}} {{element.fullNames}} </td>
        </ng-container>>
        <ng-container matColumnDef="idNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID number </th>
          <td mat-cell *matCellDef="let element"> {{element.idNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
        <ng-container matColumnDef="gender">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
          <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
        </ng-container>
        <ng-container matColumnDef="passportNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Passport number </th>
          <td mat-cell *matCellDef="let element"> {{element.rank}} </td>
        </ng-container>
        <ng-container matColumnDef="consents">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Consents </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <div>
                <div *ngFor="let item of element.consents; let i = index;" class="group-container">
                  <img src="assets/img/{{item.imageUrl}}.svg" class="group-container--status" title="picture">
                  <span class="group-container--name">{{item.testName}}</span>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="tests">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tests </th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.procedure}}  -->
            <div>
              <div>
                <div *ngFor="let item of element.tests; let i = index;" class="group-container">
                  <img src="assets/img/{{item.imageUrl}}.svg" class="group-container--status" title="picture">
                  <span class="group-container--name">{{item.testName}}</span>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"></mat-paginator>
    </div>
  </article>
</div>