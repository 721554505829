<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <div id="loginForm">
    <article class="grid">
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
            <h1 class="mdc-typography--headline2">Signup</h1><br /><br />
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Name</mat-label>
                <input matInput type="text" formControlName="name" required placeholder="e.g. Angel">
                <mat-error *ngIf="signupForm.get('name').invalid || signupForm.get('name').touched">
                  Name is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Surname</mat-label>
                <input matInput type="text" formControlName="surname" required placeholder="e.g. Mfanelo">
                <mat-error *ngIf="signupForm.get('surname').invalid || signupForm.get('surname').touched">
                  Surname is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Please enter South African ID</mat-label>
                <input matInput type="number" formControlName="idNumber" (input)="onInput($event)">
                <mat-error *ngIf="signupForm.get('idNumber').errors?.required">ID
                  is required.</mat-error>
                <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidSouthAfricanIdNumber">ID
                  is invalid.</mat-error>
                <mat-error *ngIf="signupForm.get('idNumber').errors?.invalidIdLength">ID
                  is
                  not long enough.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Contact Number</mat-label>
                <input matInput (input)="onInput($event)" formControlName="contactNumber" required>
                <mat-error
                  *ngIf="signupForm.get('contactNumber').errors?.required || signupForm.get('contactNumber').touched">
                  Contact Number is required.
                </mat-error>
                <mat-error *ngIf="signupForm.get('contactNumber').errors?.invalidCellphone">Contact
                  number is invalid.</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" required placeholder="e.g. angelmfanelo@gmail.com">
                <mat-error *ngIf="signupForm.get('email').errors?.required">
                  Email is required.
                </mat-error>
                <mat-error *ngIf="signupForm.get('email').errors?.email">
                  Email is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Password</mat-label>
                <input matInput [type]="passType" formControlName="password" required>
                <mat-error *ngIf="signupForm.get('password').errors?.required || signupForm.get('password').touched">
                  Password is required.
                </mat-error>
                <mat-error *ngIf="signupForm.get('password').errors?.minLength">
                  Password requires minimum length of 8 characters.
                </mat-error>
              </mat-form-field>
              <a (click)="hideShowPassword()" style="float:left">{{linkText}}</a>
            </div>
            <button mat-raised-button class="button" type="submit">
              Signup
            </button>
          </form>
        </div>
        <div class="grid__cell">
          <mat-error *ngIf="invalidForm">
            Invalid form submitted. Please fill in all required fields.
          </mat-error>
        </div>
        <div class="grid__cell">
          <article class="grid">
            <div class="grid__inner grid__inner--layout-d4d" style="padding-left:10%">
              <div class="grid__cell">
                <p>Already have account? <a style="color: rgb(12, 12, 91) !important"
                    [routerLink]="['/login']">Login</a>
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </article>
  </div>