import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalDataService } from './global-data.service';
//TODO: Is Token needed here?
// import { KeycloakService } from 'keycloak-angular';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as Enums from '../../shared/enums';
import { ListObject } from '../interfaces/questionnaireMapper';
import { SAPSWellnessEventBookingForm } from '../interfaces/wellness-event-booking';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }

  getById(listId: string): Observable<ListObject> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_LIST + listId, this.httpOptions).pipe(
      // tap(data => console.log('Ping')),
      catchError(this.handleError('Unable to get rankjs list', undefined))
    );
  }
  getByName(listName: string): Observable<ListObject> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_LIST_BY_NAME + listName, this.httpOptions).pipe(
      //tap(data => console.log(data)),
      catchError(this.handleError('Unable to get selected province stations list', undefined))
    );
  }

  get(listId: Enums.LISTS): Observable<ListObject> {
    return this.getById(listId);
  }

  createEventBooking(event: SAPSWellnessEventBookingForm): Observable<Boolean> {
    this.httpOptions = this.globalServ.getHttpOptions();
    console.log(Enums.API_PATH)
    console.log(Enums.API_PATHS.CREATE_WELLNESS_DAY_EVENT_BOOKING)
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.CREATE_WELLNESS_DAY_EVENT_BOOKING, event, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to create Event (POST)', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }
}
