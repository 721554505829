<div mat-dialog-content>
  <article class="grid">
    <div class="grid__inner grid__inner--layout-12">
      <div class="grid__cell">
        <h2 class="mdc-typography--headline3">Filter by status</h2>
        <form [formGroup]="eventStatusForm" (ngSubmit)="apply()">
          <div class="form-group">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Search for event</mat-label>
              <mat-select formControlName="status">
                <mat-option *ngFor="let value of eventStatusOptions" [value]="value.id">{{value.label}}</mat-option>
              </mat-select>
              <mat-error *ngIf="eventStatusForm.get('status').invalid">
                Cannot go forth without selection.
              </mat-error>
            </mat-form-field>
          </div>
        </form>
        <div class="grid__inner grid__inner--layout-66">
          <div class="grid__cell" id="center">
            <button mat-raised-button class="button" (click)="back()">Back</button>
          </div>
          <div class="grid__cell" id="center">
            <button mat-raised-button class="button" (click)="apply()">Apply filter</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
