<article class="grid">
  <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>

  <div *ngIf="isLoading === false">
    <form #examination="ngForm" matNativeControl>
      <span class="blank-line blank-line-2 d-block"></span>
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <img style="height: 10em; width: auto" src="assets/img/cof/saps.png">
        <img style="height: 10em; width: auto" src="assets/img/cof/logo.svg">
      </div>
      <span class="blank-line blank-line-2 d-block"></span>
      <div class="ui-content">
        <table cellpadding="0" cellspacing="0" class="empDetails">
          <thead>
            <tr>
              <th colspan="4">
                <span>Medical Certificate of Fitness</span>
              </th>
            </tr>
          </thead>
          <tbody class="mdc-typography--body2">
            <tr>
              <td>
                <table cellpadding="0" cellspacing="0">
                  <tr>
                    <td>
                      <div>
                        <span>To</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.to}}
                        <input type="hidden" name="to" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Employee Name</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.name}}
                        <input type="hidden" name="name" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Employee Surname</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.surname}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Department</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.department}}
                        <input type="hidden" name="department" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table cellpadding="0" cellspacing="0">
                  <tr>
                    <td>
                      <div>
                        <span>Date</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.date | date: 'dd MMMM yyyy'}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Company</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.company}}
                        <input type="hidden" name="company" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Pension / ID No</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.idNumber}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Comp / SAP No</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.employeeNumber}}
                        <input type="hidden" name="employeeNumber" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <span>Job Description</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{model.jobDescription}}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="mdc-list-group grid__cell">
                  <div class="mdc-list mdc-list--interactive" aria-orientation="horizontal" style="display: flex">
                    <div class="mdc-list-item" name="riskExposed" required style="flex: 1 1 max-content">
                      <mat-radio-button name="riskExposed" color="primary" [checked]="model.riskExposed === true" class="mdc-list-item__text" [value]="true" (change)="setRiskExposure(true)">
                        Risk Exposed
                      </mat-radio-button>
                    </div>
                    <div class="mdc-list-item" name="nonRiskExposed" required style="flex: 1 1 max-content">
                      <mat-radio-button name="riskExposed" color="primary" [checked]="model.riskExposed === false" class="mdc-list-item__text" [value]="false" (change)="setRiskExposure(false)">
                        Non-Risk Exposed
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div data-role="collapsible" data-content-theme="false" class="collapsibleSection" data-icon="" data-collapsed="false">
          <h4 class="mdc-typography--headline6 text--align-center noMarginHeading">
            FITNESS FOR DUTY RECOMMENDATION
            <span>(According to the available information the employee is)</span>
          </h4>
          <table cellspacing="0" cellpadding="0" class="wfill">
            <tr>
              <td>
                <div class="mdc-list" aria-orientation="vertical">
                  <div class="mdc-list-item" name="ffdr_fit" required>
                    <mat-radio-button name="fitnessRecommendation" color="primary" [value]="1" [checked]="model.fitnessRecommendation === 1" (change)="setFitnessRecommendation(1)">
                      Fit for duty as per job description
                    </mat-radio-button>
                  </div>
                  <div class="mdc-list-item" name="ffdr_fitExclusions" required>
                    <mat-radio-button name="fitnessRecommendation" color="primary" [value]="2" [checked]="model.fitnessRecommendation === 2" (change)="setFitnessRecommendation(2)">
                      Fit for duty - subject to specific exclusions / job modifications
                    </mat-radio-button>
                  </div>
                  <div class="mdc-list-item" name="ffdr_fitFurtherReview" required>
                    <mat-radio-button name="fitnessRecommendation" color="primary" [value]="3" [checked]="model.fitnessRecommendation === 3" (change)="setFitnessRecommendation(3)">
                      Fit for duty - subject to further review
                    </mat-radio-button>
                  </div>
                  <div class="mdc-list-item" name="ffdr_temporarilyUnFit" required>
                    <mat-radio-button name="fitnessRecommendation" color="primary" [value]="4" [checked]="model.fitnessRecommendation === 4" (change)="setFitnessRecommendation(4)">
                      Temporarily unfit for duty - subject to further review
                    </mat-radio-button>
                  </div>
                  <div class="mdc-list-item" name="ffdr_unFit" required>
                    <mat-radio-button name="fitnessRecommendation" color="primary" [value]="5" [checked]="model.fitnessRecommendation === 5" (change)="setFitnessRecommendation(5)">
                      Unfit for duty
                    </mat-radio-button>
                  </div>
                </div>
              </td>
              <td style="position: relative;">
                <mat-form-field style="width: 100%;" appearance="fill" class="whitneyFontClass unsetMarginPadding" hideRequiredMarker>
                  <textarea
                            matInput
                            name="fitnessComments"
                            ngModel
                            placeholder="Recommendation Comments"
                            #findingsControl="ngModel"
                            style="height: 200px; width: 100%"
                            [(ngModel)]="model.fitnessComments"></textarea>
                </mat-form-field>
              </td>
            </tr>
          </table>
        </div>
        <div data-role="collapsible" data-content-theme="false" class="collapsibleSection" data-icon="" data-collapsed="false">
          <h4 class="mdc-typography--headline6 text--align-center noMarginHeading">
            TESTS PERFORMED DURING MEDICAL SURVEILLANCE
          </h4>
          <table cellspacing="0" cellpadding="0" class="wfill">
            <tbody>
              <tr>
                <td>
                  <div class="mdc-list" aria-orientation="vertical">
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="audiometryTested"
                                    [checked]="model.audiometryTested"
                                    [(ngModel)]="model.audiometryTested"
                                    labelPosition="after"
                                    color="primary">
                        Audiometry Test
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="spirometryTested"
                                    [checked]="model.spirometryTested"
                                    [(ngModel)]="model.spirometryTested"
                                    labelPosition="after"
                                    color="primary">
                        Spirometry Test
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="VisionTested"
                                    [checked]="model.visionTested"
                                    [(ngModel)]="model.visionTested"
                                    labelPosition="after"
                                    color="primary">
                        Vision Testing
                      </mat-checkbox>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mdc-list" aria-orientation="vertical">
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="biologicalMonitoringTested"
                                    [checked]="model.biologicalMonitoringTested"
                                    [(ngModel)]="model.biologicalMonitoringTested"
                                    labelPosition="after"
                                    color="primary">
                        Biological Monitoring
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="xRayTested"
                                    [checked]="model.xRayTested"
                                    [(ngModel)]="model.xRayTested"
                                    labelPosition="after"
                                    color="primary">
                        X-Rays
                      </mat-checkbox>
                    </div>
                    <!--<mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding" hideRequiredMarker>
                      <input
                            matInput
                            name="testsOther"
                            ngModel
                            #inputControl="ngModel"
                            placeholder="Other"
                            type="text"
                            maxlength="255"
                            [(ngModel)]="model.testsOther">
                    </mat-form-field>-->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div data-role="collapsible" data-content-theme="false" class="collapsibleSection" data-icon="" data-collapsed="false">
          <h4 class="mdc-typography--headline6 text--align-center noMarginHeading">
            EXCLUSIONS
            <span>(Employee must be excluded from these areas)</span>
          </h4>
          <table cellspacing="0" cellpadding="0" class="wfill">
            <tbody>
              <tr>
                <td>
                  <div class="mdc-list" aria-orientation="vertical">
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="noExclusions"
                                    [checked]="model.noExclusions"
                                    [(ngModel)]="model.noExclusions"
                                    labelPosition="after"
                                    color="primary">
                        None
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="noiseZones"
                                    [checked]="model.noiseZones"
                                    [(ngModel)]="model.noiseZones"
                                    labelPosition="after"
                                    color="primary">
                        Noise Zones
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="respiratoryRiskZone"
                                    [checked]="model.respiratoryRiskZone"
                                    [(ngModel)]="model.respiratoryRiskZone"
                                    labelPosition="after"
                                    color="primary">
                        Respiratory Risk Zone
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="heatStressZone"
                                    [checked]="model.heatStressZone"
                                    [(ngModel)]="model.heatStressZone"
                                    labelPosition="after"
                                    color="primary">
                        Heat Stress Zone
                      </mat-checkbox>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mdc-list" aria-orientation="vertical">
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="drivingCodesAB"
                                    [checked]="model.drivingCodesAB"
                                    [(ngModel)]="model.drivingCodesAB"
                                    labelPosition="after"
                                    color="primary">
                        Driving – Codes (A1, A, B or EB)
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="drivingCodesCE"
                                    [checked]="model.drivingCodesCE"
                                    [(ngModel)]="model.drivingCodesCE"
                                    labelPosition="after"
                                    color="primary">
                        Driving – Codes (C1, C, EC1 or EC)
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="safetyCriticalWork"
                                    [checked]="model.safetyCriticalWork"
                                    [(ngModel)]="model.safetyCriticalWork"
                                    labelPosition="after"
                                    color="primary">
                        Safety Critical Work
                      </mat-checkbox>
                    </div>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding" hideRequiredMarker>
                      <input
                            matInput
                            name="exclusionsOther"
                            ngModel
                            #inputControl="ngModel"
                            placeholder="Other"
                            type="text"
                            maxlength="255"
                            [(ngModel)]="model.exclusionsOther">
                    </mat-form-field>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div data-role="collapsible" data-content-theme="false" class="collapsibleSection" data-icon="" data-collapsed="false">
          <h4 class="mdc-typography--headline6 text--align-center noMarginHeading">
            REFERRAL
            <span>(Employee was referred for further investigations to)</span>
          </h4>
          <table cellspacing="0" cellpadding="0" class="wfill">
            <tbody>
              <tr>
                <td>
                  <div class="mdc-list" aria-orientation="vertical">
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="ownHealthcareProvider"
                                    [checked]="model.ownHealthcareProvider"
                                    [(ngModel)]="model.ownHealthcareProvider"
                                    labelPosition="after"
                                    color="primary">
                        Own Healthcare Provider
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="specialist"
                                    [checked]="model.specialist"
                                    [(ngModel)]="model.specialist"
                                    labelPosition="after"
                                    color="primary">
                        Specialist
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="optometrist"
                                    [checked]="model.optometrist"
                                    [(ngModel)]="model.optometrist"
                                    labelPosition="after"
                                    color="primary">
                        Optometrist / Ophthalmologist
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="audiologist"
                                    [checked]="model.audiologist"
                                    [(ngModel)]="model.audiologist"
                                    labelPosition="after"
                                    color="primary">
                        Audiologist
                      </mat-checkbox>
                    </div>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding" hideRequiredMarker>
                      <input
                            matInput
                            name="referralsOther"
                            ngModel
                            #inputControl="ngModel"
                            placeholder="Other"
                            type="text"
                            maxlength="255"
                            [(ngModel)]="model.referralsOther">
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <div class="mdc-list" aria-orientation="vertical">
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="clinicReferral"
                                    [checked]="model.clinicReferral"
                                    [(ngModel)]="model.clinicReferral"
                                    labelPosition="after"
                                    color="primary">
                        Hospital / Municipal Clinic
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="socialWorker"
                                    [checked]="model.socialWorker"
                                    [(ngModel)]="model.socialWorker"
                                    labelPosition="after"
                                    color="primary">
                        Social Worker / EAP / Psychologist
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="radiologist"
                                    [checked]="model.radiologist"
                                    [(ngModel)]="model.radiologist"
                                    labelPosition="after"
                                    color="primary">
                        X-ray / Radiologist
                      </mat-checkbox>
                    </div>
                    <div class="mdc-list-item">
                      <mat-checkbox
                                    ngModel
                                    #toggleControl="ngModel"
                                    name="omp"
                                    [checked]="model.omp"
                                    [(ngModel)]="model.omp"
                                    labelPosition="after"
                                    color="primary">
                        OMP
                      </mat-checkbox>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div data-role="collapsible" data-content-theme="false" class="collapsibleSection" data-icon="" data-collapsed="false">
          <h4 class="over mdc-typography--headline6 text--align-center noMarginHeading">
            SPECIAL TESTS PERFORMED AND NOTES
          </h4>
          <table cellpadding="0" cellspacing="0" class="wfill">
            <tr>
              <td>
                <mat-form-field style="width: 100%;" appearance="fill" class="whitneyFontClass unsetMarginPadding" hideRequiredMarker>
                  <textarea
                            matInput
                            name="specialTests"
                            ngModel
                            placeholder="Special tests and notes"
                            #findingsControl="ngModel"
                            style="height: 400px; width: 100%"
                            [(ngModel)]="model.specialTests">
                  </textarea>
                </mat-form-field>
              </td>
            </tr>
          </table>
        </div>
        <form>
          <div [formGroup]="form" class="closing" data-role="fieldcontain">
            <p>
              I herewith give permission that the above information may be forwarded to my company management and
              acknowledge that this Certificate of Fitness has been handed to me to take to my Employer / Line
              Manager
            </p>
            <div class="ui-grid-c signatures mdc-typography--body2" style="display: flex;">
              <div class="ui-block-a" style="flex: 3 1 min-content;">
                <div class="ui-bar">
                  <div class="table fill signatureTable">
                    <div class="row">
                      <div class="cell">Name and Surname of employee from Synergy</div>
                    </div>
                    <div class="row">
                      <div class="cell" style="position: relative;">
                        <div class="sig-container cofSignature" #sigContainer1>
                          <div fxLayout="row">
                            <button type="button" (click)="sigs.first.clear()" [disabled]="!form.controls.signatureField1.valid">Clear</button>
                          </div>
                          <div fxLayout="row">
                            <signature-field formControlName="signatureField1"></signature-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell">
                        Signature of Employee
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-block-b" style="flex: 3 1 min-content;">
                <div class="ui-bar">
                  <div class="table fill signatureTable">
                    <div class="row">
                      <div class="cell">Name and Surname of OHNP / OMP</div>
                    </div>
                    <div class="row">
                      <div class="cell" style="position: relative;">
                        <div class="sig-container cofSignature" #sigContainer2>
                          <div fxLayout="row">
                            <button type="button" (click)="secondSig.clear()" [disabled]="!form.controls.signatureField2.valid">Clear</button>
                          </div>
                          <div fxLayout="row">
                            <signature-field formControlName="signatureField2"></signature-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell">
                        Signature of Examiner (OHNP/ OMP)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-block-c" style="flex: 3 1 min-content;">
                <div class="ui-bar">
                  <div class="table fill signatureTable">
                    <div class="row">
                      <div class="cell">Name and Surname of OMP</div>
                    </div>
                    <div class="row">
                      <div class="cell" style="position: relative;">
                        <div class="sig-container cofSignature" #sigContainer3>
                          <div fxLayout="row">
                            <button type="button" (click)="sigs.last.clear()" [disabled]="!form.controls.signatureField3.valid">Clear</button>
                          </div>
                          <div fxLayout="row">
                            <signature-field formControlName="signatureField3"></signature-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="cell">
                        Signature OMP
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="returnDate">
          <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
            <mat-label style="margin-left: 16px">Retest / Return date:</mat-label>
            <input
                  style="margin-left: 16px"
                  matInput
                  required
                  name="retestDate"
                  ngModel
                  #startDateControl="ngModel"
                  [matDatepicker]="startpicker"
                  placeholder="Start date"
                  (click)="startpicker.open()"
                  [(ngModel)]="model.retestDate" />
            <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #startpicker startView="year"></mat-datepicker>
          </mat-form-field>
          <div style="margin-left: 16px" class="center">
            If exposed to sound levels above 105dBA, the employee must return within 6 (six) months for audiometry testing
          </div>
        </div>

      </div>
      <div class="grid__cell--span-all">
        <div class="grid__inner grid__inner--layout-66">
          <div class="grid__cell--justify-center grid__cell--span-all" *ngIf="showError">
            <div class="mdc-btn-container">
              <mat-error class="text--align-center">Please complete all required</mat-error>
            </div>
          </div>
          <div class="grid__cell grid__cell--justify-center">
            <div class="mdc-btn-container">
              <input type="submit" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium" (click)="save(examination)" value="Save">
            </div>
          </div>
          <div class="grid__cell grid__cell--justify-center">
            <div class="mdc-btn-container">
              <input type="button" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium" [disabled]="disableSubmit" (click)="submit(examination)" value="Submit">
            </div>
          </div>
          <div class="grid__cell--justify-center grid__cell--span-all">
            <div class="mdc-btn-container">
              <div class="text--align-center">
                <a class="motion-button--cancel" tabindex="0" title="Return to previous page" (click)="back()">Back</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</article>
