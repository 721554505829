import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import * as Enums from 'src/app/shared/enums';
import { HealthPractitionerRegistration } from 'src/app/shared/interfaces/healthPractitionerRegistration';
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ListService } from 'src/app/shared/services/list.service';
import { UserService } from 'src/app/shared/services/user.service';
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator';
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator';

@Component({
  selector: 'app-sp-hp-creation',
  templateUrl: './create-health-practitioner.component.html',
  styleUrls: ['./create-health-practitioner.component.scss']
})
export class CreateHealthPractitionerComponent implements OnInit {

  formDirective!: FormGroupDirective;
  isLoading: boolean = false;
  signupForm!: FormGroup;
  invalidForm: boolean = false;
  selectedFile: string;
  certifications: ListItemObject[];
  healthPractitioner: HealthPractitionerRegistration = new HealthPractitionerRegistration();
  cert: string;
  loggedInServiceProviderId: string;
  clinicianDetailsObj: any;
  providerName: string;

  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private listService: ListService,
    private globalData: GlobalDataService,
    private app: AppComponent,
    private userService: UserService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.globalData.hideBookingCalendarButton();
    this.globalData.updateBackRoute(['/sp-landing']);
    this.listService.get(Enums.LISTS.HP_CERTIFICATION_TYPE).subscribe((data) => {
      this.certifications = data.listItems;
      this.isLoading = false;
    })
    this.globalData.userServiceProviderId$.subscribe((data) => {
      this.loggedInServiceProviderId = data;
    });
    this.globalData.updateBackRoute(['/sp-access-management']);
    this.globalData.userIdVal.subscribe(data =>
      this.userService.GetServiceProviderDetails(data).subscribe(response => {
        this.providerName = response.ClinicMapping?.WorkflowName;
      })
    )
  }

  onSubmit() {
    if (this.signupForm.valid) {
      var data = this.signupForm.value;
      this.invalidForm = false;
      this.isLoading = true;
      //
      this.healthPractitioner.serviceProviderId = this.loggedInServiceProviderId;
      this.healthPractitioner.name = data.name;
      this.healthPractitioner.surname = data.surname;
      this.healthPractitioner.idNumber = data.idNumber;
      this.healthPractitioner.contactNumber = data.contactNumber;
      this.healthPractitioner.email = data.email;
      this.healthPractitioner.PracticeName = data.PracticeName;
      this.healthPractitioner.PracticeNumber = data.PracticeNumber;
      this.healthPractitioner.certType = data.certificationType.certificationTypeID;
      this.healthPractitioner.certNumber = data.certNumber;
      this.healthPractitioner.certification = this.selectedFile;
      this.healthPractitioner.certDiscretion = data.discretionApproved;
      const headers = { 'Content-Type': 'application/json' };
      this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.POST_HEALTH_PRACTITIONER_REGISTRATION, this.healthPractitioner, { headers }).subscribe({
        next: data => {
          let snackBar = this.snackBar.open('Saved', null, { duration: 3000, panelClass: 'app-notification-success' });
          snackBar.afterDismissed().subscribe(() => this.backToLanding());
        },
        error: error => {
          if (error.error == null) {
            let snackBar = this.snackBar.open('Submission error', null, { duration: 3000, panelClass: 'app-notification-success' });
            //snackBar.afterDismissed().subscribe(() => );
          } else {
            let snackBar = this.snackBar.open(error.error, null, { duration: 3000, panelClass: 'app-notification-success' });
            //snackBar.afterDismissed().subscribe(() => this.backToLanding());
          }
        }
      })
    } else {
      this.invalidForm = true;
    }
  }
  createForm() {
    this.signupForm = this.fb.group({
      id: null,
      name: [null, Validators.required],
      surname: [null, Validators.required],
      idNumber: [null, [Validators.required, southAfricanIdNumberValidator]],
      contactNumber: [null, [Validators.required, cellNumberValidator]],
      email: [null, [Validators.required, Validators.email]],
      PracticeName: [null],
      PracticeNumber: [null],
      certificationType: this.fb.group({
        certificationTypeID: [null, Validators.required],
        certificationTypeName: ''
      }),
      certNumber: [null, Validators.required],
      certification: [null, Validators.required],
      discretionApproved: [null, Validators.required]
    })
  }
  backToLanding() {
    this.healthPractitioner = new HealthPractitionerRegistration();
    this.isLoading = false;
    this.signupForm.reset();
    // this.formDirective.resetForm();
    this.router.navigate(['sp-landing']);
  }
  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }
  resetForm() {
    this.signupForm.reset();
    //this.formDirective.resetForm();
  }
  ToLogin() {
    this.isLoading = false;
    this.resetForm();
    this.router.navigate(['login'])
  }
  onFileSelect(event: Event) {
    this.isLoading = true;
    const file = (event.target as HTMLInputElement).files[0];
    //error changes
    if (file) {
      if (file) {

        const reader = new FileReader();

        reader.onload = (e: any) => {

          const base64 = e.target.result.split(',')[1];

          this.selectedFile = base64;
        };

        reader.readAsDataURL(file);
        this.isLoading = false;
      }
    }
  }
  onCertificationSelection(event) {
    //SANC selected
    if (event.value == "f6ff76d6-9158-466f-90e8-ca8e3449869c") {
      this.cert = "SANC";
    }
    //HPCSA selected
    if (event.value == "893a2f84-3c1f-4502-adf2-6bdf63afd322") {
      this.cert = "HPCSA";
    }
    //SACSSP selected
    if (event.value == "8cd1ffc4-a5d2-4f16-ad7b-abee8cf85cec") {
      this.cert = "SACSSP";
    }
  }
}
