<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-screen [loading]="isLoading === true"></three-bar-screen>
    <div *ngIf="isFailed === true">
      <section class="mdc-typography--body1">
        <app-back-button [route]="appointment"></app-back-button>
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-center mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="previousPage()">
                    Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="isFailed === false">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Consultation summary</h1>
      </section>
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <span class="blank-line blank-line-2 d-block"></span>
          <app-wellness-info-header></app-wellness-info-header>
        </div>
        <div class="grid__cell">
          <div *ngIf="consent.status == 'finished' || !consentRequired" class="test-list">
            <div *ngFor="let tests of testObject; let i = index"
              class="test-{{tests.testMapping.groupName.toLowerCase()}}">
              <ng-container *ngIf="consentStatus[tests.testMapping.ocsaname] === true">
                <div class="row">
                  <div class="grid__cell" style="text-align: -webkit-center">
                    <img *ngIf="tests.status == 'pending'" class="" style="height: 40px"
                      src="assets/img/consultation/not_completed.svg" alt="ico">
                    <img *ngIf="tests.status == 'finished'" class="" style="height: 40px"
                      src="assets/img/consultation/completed.svg" alt="ico">
                    <img *ngIf="tests.status == 'inProgress'" class="" style="height: 40px"
                      src="assets/img/consultation/in_progress.svg" alt="ico">
                    <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                  </div>
                  <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                    <div class="row">
                      <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                        <img class="" style="height: 40px"
                          src="assets\img\consultation\{{tests.testMapping.iconName}}.svg" alt="ico">
                      </div>
                      <div style="width: 90%" class="grid__cell">
                        <div class="grid__cell mdc-typography--subtitle1">
                          <span *ngIf="tests.testMapping.summary != null">{{tests.testMapping.summary}}</span>
                          <span *ngIf="tests.testMapping.summary == null">{{tests.testMapping.groupName}}</span>
                        </div>
                        <div *ngIf="tests.testsPerformed.length > 0" class="grid__cell mdc-typography--body2">
                          {{ tests.testsPerformed[0].createDate | date: 'dd MMMM yyyy' }} at {{
                          tests.testsPerformed[0].createDate | date: 'HH:mm' }}
                        </div>
                        <div *ngIf="tests.testsPerformed.length < 1" class="grid__cell mdc-typography--body2">
                          Not started
                        </div>
                        <div *ngIf="tests.testsPerformed.length > 0" class="grid__cell mdc-typography--body2">
                          Completed by: {{tests.testsPerformed[0].createName}}</div>
                      </div>
                      <div style="width: 90%" class="grid__cell">
                        <div class="grid__cell mdc-typography--subtitle1">
                          <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
                            *ngIf="tests.status != 'pending' && tests.testsPerformed.length > 0"
                            style="margin-left: 30px"
                            [ngClass]="{ 'progressButton_failed': tests.testStatusFailed, 'progressButton_intermediate': tests.testStatusIntermediate, 'progressButton_pass': tests.testStatusPass, 'progressButton_error' : tests.testStatusError}">
                            {{tests.testsPerformed[0].resultInEnglish}}
                          </button>
                        </div>
                      </div>
                      <div
                        *ngIf="tests.status == 'pending' && consentStatus[tests.testMapping.ocsaname] && tests.testMapping.workflowId != 'HIVCONSENT' && isHealthPractitioner"
                        class="grid__cell" style="width: 20%">
                        <button style="width: 150px;"
                          class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                          (click)="startTest(tests)">Start test</button>
                      </div>
                      <div
                        *ngIf="tests.status == 'pending' && consentStatus[tests.testMapping.ocsaname] && tests.testMapping.workflowId == 'HIVCONSENT'"
                        class="grid__cell" style="width: 20%">
                        <button style="width: 150px;"
                          class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                          (click)="startTest(tests)">Consent</button>
                      </div>
                      <div *ngIf="tests.status == 'pending' && !consentStatus[tests.testMapping.ocsaname]"
                        class="grid__cell" style="width: 20%">
                        <button disabled style="width: 150px;"
                          class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small">Consent
                          refused</button>
                      </div>
                      <div *ngIf="tests.status != 'pending'" (click)="viewTest()" class="grid__cell mouse-cursor">
                        <a (click)="viewTest()">
                          <mat-icon class="material-icons-outlined pt-2 mouse-cursor"
                            (click)="reviewTest(tests)">chevron_right</mat-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="blank-line blank-line-2 d-block"></span>
              </ng-container>
              <ng-container *ngIf="consentStatus[tests.testMapping.ocsaname] === null">
                <div class="row">
                  <div class="grid__cell" style="text-align: -webkit-center">
                    <img *ngIf="tests.status == 'pending'" class="" style="height: 40px"
                      src="assets/img/consultation/not_completed.svg" alt="ico">
                    <img *ngIf="tests.status == 'finished'" class="" style="height: 40px"
                      src="assets/img/consultation/completed.svg" alt="ico">
                    <img *ngIf="tests.status == 'inProgress'" class="" style="height: 40px"
                      src="assets/img/consultation/in_progress.svg" alt="ico">
                    <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                  </div>
                  <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                    <div class="row">
                      <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                        <img class="" style="height: 40px"
                          src="assets\img\consultation\{{tests.testMapping.iconName}}.svg" alt="ico">
                      </div>
                      <div style="width: 90%" class="grid__cell">
                        <div class="grid__cell mdc-typography--subtitle1">
                          <span *ngIf="tests.testMapping.summary != null">{{tests.testMapping.summary}}</span>
                          <span *ngIf="tests.testMapping.summary == null">{{tests.testMapping.groupName}}</span>
                        </div>
                        <div *ngIf="tests.testsPerformed.length < 1" class="grid__cell mdc-typography--body2">Not
                          started
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="blank-line blank-line-2 d-block"></span>
              </ng-container>
            </div>
          </div>
          <div *ngIf="consentRequired">
            <div class="row">
              <div class="grid__cell" style="text-align: -webkit-center">
                <img *ngIf="consent.status == 'pending'" class="" style="height: 40px"
                  src="assets/img/consultation/not_completed.svg" alt="ico">
                <img *ngIf="consent.status == 'finished'" class="" style="height: 40px"
                  src="assets/img/consultation/completed.svg" alt="ico">
                <img *ngIf="consent.status == 'inProgress'" class="" style="height: 40px"
                  src="assets/img/consultation/in_progress.svg" alt="ico">
                <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
              </div>
              <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                <div class="row">
                  <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                    <img class="" style="height: 40px" src="assets\img\consultation\consent.svg" alt="ico" />
                  </div>
                  <div style="width: 90%" class="grid__cell">
                    <div class="grid__cell mdc-typography--subtitle1">{{consent.groupName}}</div>
                    <div class="grid__cell mdc-typography--body2">{{ consent.createdDate | date: 'dd MMMM yyyy'}}</div>
                    <div *ngIf="consent.status !== 'pending'" class="grid__cell mdc-typography--body2">Notes:
                      {{consent.notes}}</div>
                  </div>
                  <div style="width: 90%" class="grid__cell">
                    <div class="grid__cell mdc-typography--subtitle1">
                      <button *ngIf="consent.status == 'finished'" style="margin-left: 30px"
                        class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short progressButton_pass">Consent
                        given</button>
                      <button *ngIf="consent.status == 'refused'" style="margin-left: 30px"
                        class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short progressButton_failed">Consent
                        refused</button>
                    </div>
                  </div>
                  <div *ngIf="consent.status != 'finished' && consent.status != 'refused'" class="grid__cell"
                    style="width: 20%">
                    <button style="width: 150px;"
                      class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                      (click)="completeConsent()">
                      Consent
                    </button>
                  </div>
                  <div *ngIf="consent.status == 'finished'" (click)="viewTest()" class="grid__cell mouse-cursor">
                    <!--<a (click)="viewTest()">
                                      <mat-icon class="material-icons-outlined pt-2 mouse-cursor" (click)="viewConsent()">chevron_right</mat-icon>
                                    </a>-->
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="consent.status != 'finished' && consent.status != 'refused'">
              <span class="blank-line blank-line-2 d-block"></span>
              <ng-container *ngFor="let tests of testObject; let i = index">
                <div class="row">
                  <div class="grid__cell" style="text-align: -webkit-center">
                    <img *ngIf="tests.status == 'pending'" class="" style="height: 40px"
                      src="assets/img/consultation/not_completed.svg" alt="ico">
                    <img *ngIf="tests.status == 'finished'" class="" style="height: 40px"
                      src="assets/img/consultation/completed.svg" alt="ico">
                    <img *ngIf="tests.status == 'inProgress'" class="" style="height: 40px"
                      src="assets/img/consultation/in_progress.svg" alt="ico">
                    <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                  </div>
                  <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                    <div class="row">
                      <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                        <img class="" style="height: 40px"
                          src="assets\img\consultation\{{tests.testMapping.iconName}}.svg" alt="ico">
                      </div>
                      <div style="width: 90%" class="grid__cell">
                        <div class="grid__cell mdc-typography--subtitle1">
                          <span *ngIf="tests.testMapping.summary != null">{{tests.testMapping.summary}}</span>
                          <span *ngIf="tests.testMapping.summary == null">{{tests.testMapping.groupName}}</span>
                        </div>
                        <div *ngIf="tests.testsPerformed.length < 1" class="grid__cell mdc-typography--body2">Not
                          started
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="blank-line blank-line-2 d-block"></span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <section class="grid__inner">
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Back to calendar" (click)="back()">Back to member
                    register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </article>
</div>
