<article class="grid">

<div class="grid__inner grid__inner--layout-b8b">

  <div class="grid__cell">
        <div class="row">
        <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
          <div class="row">
            <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
              <img class="" style="height: 40px" src="assets\img\consultation\{{data.icon}}.svg" alt="ico">
            </div>
            <div style="width: 90%" class="grid__cell">
              <div class="grid__cell mdc-typography--subtitle1">
                <span *ngIf="data.title != null">{{data.title}}</span>

              </div>
        <!--      <div *ngIf="data.description.length > 0" class="grid__cell mdc-typography&#45;&#45;body2">
                {{data.testsPerformed[0].createDate | date: 'dd MMMM yyyy'}} at {{data.testsPerformed[0].createDate | date: 'HH:mm'}}
              </div>-->
              <div *ngIf="data.description.length < 1" class="grid__cell mdc-typography--body2">
                {{data.description}}
              </div>
    <!--          <div *ngIf="data.result == 'finished' && data.testsPerformed.length > 0" class="grid__cell mdc-typography&#45;&#45;body2">
                Completed by: {{data.testsPerformed[0].clinician.name}}
              </div>-->

            </div>
            <!--<div style="width: 90%" class="grid__cell">
              <div class="grid__cell mdc-typography&#45;&#45;subtitle1">
                <button *ngIf="data.status != 'pending' && data.result.length > 0" style="margin-left: 30px"
                        [ngClass]="{
                            'progressButton_failed': (data.result) == 'failed',
                            'progressButton_pass': (data.result) == 'passed',
                            'progressButton_error' : (data.result) == null}"
                        class="mdc-button mdc-button&#45;&#45;outlined mdc-ripple-upgraded motion-button&#45;&#45;short">{{data.result}}</button>
              </div>
            </div>-->

            <div class="grid__cell" style="width: 20%" >
              <button style="width: 150px;" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"  (click)="doActionButton(data.action)">View</button>
            </div>
          <!--  <div *ngIf="data.status != 'pending'" (click)="doAction()"  class="grid__cell mouse-cursor">
              <a (click)="doAction()"><mat-icon class="material-icons-outlined pt-2 mouse-cursor" (click)="doAction(data)">chevron_right</mat-icon></a>
            </div>-->
          </div>
        </div>
      </div>
      <span class="blank-line blank-line-2 d-block"></span>

    </div>
  </div>
</article>
