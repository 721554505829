import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import * as Enums from '../../shared/enums'
import { AdhocWellnessPatientRegistrationModel, PatientObject, RegisterPatientSearchParameters, RequestBenefitCheck, WellnessPatientRegistrationModel } from '../interfaces/patientMapper'
import { ListObject } from '../interfaces/questionnaireMapper'
import { GlobalDataService } from './global-data.service'

//TODO: Is Token needed here?
// import { KeycloakService } from 'keycloak-angular'






@Injectable({
  providedIn: 'root',
})
export class PatientService {
  httpOptions: any = {}

  constructor(
    public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }

  search(params: RegisterPatientSearchParameters): Observable<WellnessPatientRegistrationModel> {
    this.httpOptions = this.globalServ.getHttpOptions()
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.SEARCH_PATIENT, params, this.httpOptions).pipe(
      tap((data) => console.log('Ping')),
      catchError(this.handleError('Unable to search patient', undefined))
    )
  }

  insertInBenfitCheck(params: RequestBenefitCheck): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.Benefit_Check, params, this.httpOptions).pipe(
      tap((data) => console.log('Ping')),
      catchError(this.handleError('Unable to search patient', undefined))
    )
  }

  deepSearch(id: any) {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_DEEP_SEARCH + id, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get patient detail', undefined))
      )
  }

  updateWellnessPatient(registrationData: WellnessPatientRegistrationModel): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.REGISTER_PATIENT, registrationData, { headers }).pipe(
      tap((data) => console.log('Ping')),
      catchError(this.handleError('Unable to get patient detail', undefined))
    )
  }
  getPatient(id): Observable<PatientObject> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_PATIENT_DETAIL + id, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get patient detail', undefined))
      )
  }

  getRanks(): Observable<ListObject> {
    this.httpOptions = this.globalServ.getHttpOptions()
    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_LIST_RANKS, this.httpOptions)
      .pipe(
        tap((data) => console.log(data)),
        catchError(this.handleError('Unable to get rankjs list', undefined))
      )
  }

  registerPatient(patient: PatientObject): Observable<Boolean> {
    let location = Enums.API_PATH + Enums.API_PATHS.REGISTER_PATIENT
    return this.post<Boolean>(location, patient)
  }

  post<T>(url: string, entry: any): Observable<T> {
    this.httpOptions = this.globalServ.getHttpOptions()
    return this.http.post(url, entry, this.httpOptions).pipe(
      tap((data) => console.log(data)),
      catchError(this.handleError('Unable to process POST', undefined))
    )
  }

  getPatientFromConsultation(id): Observable<PatientObject> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(
        Enums.API_PATH + Enums.API_PATHS.GET_PATIENT_DETAIL_FROM_CONSULTATION + id,
        this.httpOptions
      )
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get patient detail', undefined))
      )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error)
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T)
    }
  }

  private log(message: string) {
    console.log(`${message}`)
  }
}
