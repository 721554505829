<div class="container">
  <article class="grid mdc-typography mdc-theme--background">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>

    <div *ngIf="isLoading === false">
      <div class="grid__inner grid__inner--layout-12 text--align-center">
        <div class="grid__cell">
          <h2 class="mdc-typography mdc-typography--headline3">Conference Portal</h2>
          <span class="blank-line blank-line-2 d-block"></span>
        </div>
      </div>
      <div class="grid__inner grid__inner--layout-3333-44 text--align-center">
        <div class="grid__cell">
          <div class="mdc-card ">
            <div class="mdc-card__media mdc-card__media--2-1 img_appointment"></div>
            <div class="mdc-card__content">
              <div class="mdc-typography--headline5">
                <h3 class="mdc-typography--headline5">Registration</h3>
              </div>
              <p class="mdc-typography--overline text--align-center">Conferencing Registration<br><br></p>
              <p></p>
              <div class="mdc-btn-container mdc-btn-container--column-stack">
                <a [routerLink]="['../app-registration']"
                  class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                  aria-label="Click here to open">
                  <span class="mdc-button__text">Click here to open</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="grid__cell" style="position: relative">
          <div class="grid__cell">
            <div class="mdc-card ">
              <div class="mdc-card__media mdc-card__media--2-1 img_filereview"></div>
              <div class="mdc-card__content">
                <div class="mdc-typography--headline5">
                  <h3 class="mdc-typography--headline5">Reports</h3>
                </div>
                <p class="mdc-typography--overline text--align-center">Conference
                  Registration Report<br><br></p>
                <p></p>
                <div class="mdc-btn-container mdc-btn-container--column-stack">
                  <a (click)="downloadReport()"
                    class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                    aria-label="Click here to download">
                    <span style="color: white;" class="mdc-button__text">Click here to download</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>