import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-icon',
  templateUrl: './online-icon.component.html',
  styleUrls: ['./online-icon.component.scss']
})
export class OnlineIconComponent implements OnInit {
  isOffline: boolean;

  constructor() {
    this.isOffline = !navigator.onLine; // Initial status check
  }

  ngOnInit(): void {
    window.addEventListener('online', this.onNetworkStatusChange.bind(this));
    window.addEventListener('offline', this.onNetworkStatusChange.bind(this));
  }

  onNetworkStatusChange(): void {
    this.isOffline = !navigator.onLine;
    console.log('offline ' + this.isOffline);
  }

  ngOnDestroy(): void {
    window.removeEventListener('online', this.onNetworkStatusChange);
    window.removeEventListener('offline', this.onNetworkStatusChange);
  }
}