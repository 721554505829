import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
//TODO: Is Token needed here?
// import { KeycloakService } from 'keycloak-angular'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import * as Enums from '../../shared/enums'
import {
  ConsultationWorkflowObject,
  WorkflowStatusObject,
} from '../../shared/interfaces/consultationWorkflowMapper'
import { GlobalDataService } from './global-data.service'

@Injectable({
  providedIn: 'root',
})
export class ConsultationWorkflowService {
  httpOptions: any = {}

  constructor(
    public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }

  setConsultationDetailWorkflow(
    consultationId: string,
    workflowStatus: number,
    userName: string,
    userID: string = null
  ): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()
    let params =
      '?consultationId=' +
      consultationId +
      '&workflowStatus=' +
      workflowStatus +
      '&userName=' +
      userName
    if (userID) {
      params += '&userID=' + userID
    }

    return this.http
      .get(
        Enums.API_PATH + Enums.API_PATHS.SET_CONSULTATION_DETAIL_WORKFLOW + params,
        this.httpOptions
      )
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get file review records', undefined))
      )
  }

  getCurrentWorkflowStatus(id): Observable<WorkflowStatusObject> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(
        Enums.API_PATH + Enums.API_PATHS.GET_CURRENT_WORKFLOW_STATUS + id,
        this.httpOptions
      )
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get current workflow status.', undefined))
      )
  }

  getWorkflowStatusHistory(
    id,
    statusId?: null | 3 | 4 | 5
  ): Observable<ConsultationWorkflowObject[]> {
    this.httpOptions = this.globalServ.getHttpOptions()

    if (statusId) {
      this.httpOptions.params = new HttpParams()
      this.httpOptions.append('statusId', statusId.toString())
    }
    return this.http
      .get(
        Enums.API_PATH + Enums.API_PATHS.GET_WORKFLOW_STATUS_HISTORY + id,
        this.httpOptions
      )
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get workflow status history.', undefined))
      )
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error)
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T)
    }
  }

  private log(message: string) {
    console.log(`${message}`)
  }
}
