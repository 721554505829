import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateService } from '../services/date.service';

export const minDateValidator = (dateService: DateService): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const minDateStr = control.get('min')?.value; // Get the "min" attribute value from the control
    const selectedDateStr = control.value; // Get the selected date from the control

    if (!minDateStr || !selectedDateStr) {
      return null; // If either date is not provided, don't perform the validation
    }

    const minDate = dateService.formatDate(new Date(minDateStr));
    const selectedDate = dateService.formatDate(new Date(selectedDateStr));

    if (selectedDate < minDate) {
      return { minDate: true }; // Validation error if selected date is earlier than the specified minDate
    }

    return null;
  };
