import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad';
import { SignatureFieldComponent } from '../../../shared/components/signature-field/signature-field.component';
import { ConsentMapper } from '../../../shared/interfaces/consentMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
//TODO: Is logged in user profile needed here?
// import { KeycloakService } from 'keycloak-angular';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

@Component({
  selector: 'app-oh-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class OHConsentComponent implements OnInit {
  public form: FormGroup;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  // for convenience as we don't have a QueryList.index
  public secondSig: SignatureFieldComponent;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;
  @ViewChildren('sigContainer3') public sigContainer3: QueryList<ElementRef>;

  @ViewChildren('sig1border') public sig1border = 'transparent';
  @ViewChildren('sig2border') public sig2border = 'transparent';
  @ViewChildren('sig3border') public sig3border = 'transparent';

  constructor(fb: FormBuilder, private snackBar: MatSnackBar, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private questionnaireService: QuestionnaireService) {
    this.form = fb.group({
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required],
      signatureField3: ['', Validators.required]
    });
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    //console.log('Resizing signature pad canvas to suit container size');
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.secondSig);
    this.size(this.sigContainer3.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
    sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
  }

  public setOptions() {
    this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.secondSig.signaturePad.set('penColor', 'rgb(255, 255, 0)');
    this.secondSig.signaturePad.set('backgroundColor', 'rgb(0, 0, 255)');
    this.secondSig.signaturePad.clear();
    // clearing is needed to set the background colour
  }

  public clear() {
    this.sigs.first.clear();
    this.secondSig.clear();
    this.sigs.last.clear();
  }

  signatureImg: string;
  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 250,
    canvasHeight: 200
  };

  consentList = [
    { name: 'consent', id: 0 },
    { name: 'refuse consent', id: 1 }];


  isLoading = false;
  showError = false;
  clientID = '';
  userRoles: any[] = [];
  userRole: string = '';
  consentMapper: ConsentMapper;
  testRequested: TestRequestedObject;
  testPerformed: any;
  urVal = {
    Clinician: 1,
    Technician: 2,
    Manager: 3,
    Reception: 4,
    Generic: 5
  };
  showDownload = false;
  downloadButton = null;
  href = "";
  download = "";

  public getSecondSig() {
    if (this.sigs && this.sigs.length) {
      this.secondSig = this.sigs.find((sig, index) => index === 1);
      this.beResponsive();
      this.setOptions();
    }
    else {
      setTimeout(this.getSecondSig.bind(this), 3000);
    }
  }


  public ngAfterViewInit() {
    this.getSecondSig();
  }

  ngOnInit(): void {
    this.globalData.userRolesVal.subscribe(result => {
      this.userRoles = result as any[];
      this.userRole = this.userRoles.filter(r => r.indexOf("Perigon_") > -1).map(r => r.replace("Perigon_", "")).sort((a, b) => this.urVal[a] === this.urVal[b] ? 0 : (this.urVal[a] < this.urVal[b] ? -1 : 1))[0];
    });
    this.globalData.updateBackRoute('/oh-consultation-summary');
    this.globalData.selectedClientVal.subscribe(result => this.clientID = result);

    this.globalData.questionnaireClientDataVal.subscribe(tests => { this.testRequested = tests; });
    this.isLoading = true;
    this.consultationService.getConsultationDetail(this.clientID).subscribe(result => {
      ///* console.log(result) */;

      if (result) {

        this.consentMapper = {
          details_FullName: result.patientDetail.fullNames != null ? result.patientDetail.fullNames : '',
          details_IDNumber: result.patientDetail.rsaidnumber,
          exam_Consent: false,
          exam_Client_Signature: undefined,
          exam_Client_DateSignature: undefined,
          exam_Witness_Designation: this.userRole,
          exam_Witness_Surname: undefined,
          exam_Witness_Initials: undefined,
          exam_Witness_Signature: undefined,
          exam_Witness_DateSignature: undefined,
          data_Consent: false,
          data_Client_Signature: undefined,
          data_Client_DateSignature: undefined
        };
      }
      this.isLoading = false;
    });

  }


  drawComplete(sig): void {
    // console.log(this.signaturePad.toDataURL());

    if (sig.includes('emp1')) {
      this.consentMapper.exam_Witness_Signature = this.signaturePad.toDataURL();
      this.consentMapper.exam_Witness_DateSignature = this.consentMapper.exam_Client_DateSignature = this.consentMapper.data_Client_DateSignature = new Date();

    } else if (sig.includes('client')) {
      this.consentMapper.exam_Client_Signature = this.signaturePad.toDataURL();
      this.consentMapper.exam_Client_DateSignature = new Date();

    } else if (sig.includes('emp2')) {
      this.consentMapper.data_Client_Signature = this.signaturePad.toDataURL();
      this.consentMapper.data_Client_DateSignature = new Date();
    }
  }

  drawStart(): void {
    //('begin drawing');
  }

  clearSignature(): void {
    this.signaturePad.clear();
  }

  savePad(): void {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }


  submit(sectionOne, sectionTwo): void {

    this.sig1border = "transparent";
    this.sig2border = "transparent";
    this.sig3border = "transparent";

    Object.keys(sectionOne.form.controls).forEach(key => {
      sectionOne.form.controls[key].markAsTouched();
    });

    Object.keys(sectionTwo.form.controls).forEach(key => {
      sectionTwo.form.controls[key].markAsTouched();
    });

    sectionOne.form.updateValueAndValidity();
    sectionTwo.form.updateValueAndValidity();

    this.consentMapper.exam_Witness_DateSignature = this.consentMapper.exam_Witness_DateSignature || new Date();
    this.consentMapper.exam_Client_DateSignature = this.consentMapper.exam_Client_DateSignature || new Date();
    this.consentMapper.data_Client_DateSignature = this.consentMapper.data_Client_DateSignature || new Date();
    this.consentMapper.exam_Client_Signature = this.sigs.first.signaturePad.toDataURL();
    this.consentMapper.exam_Witness_Signature = this.secondSig.signaturePad.toDataURL();
    this.consentMapper.data_Client_Signature = this.sigs.last.signaturePad.toDataURL();

    if (sectionOne.form.valid
      && sectionTwo.form.valid
      && !this.secondSig.signaturePad.isEmpty()
      && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()) {
      this.showError = false;
      let options: MatSnackBarConfig = { duration: 2500 };
      let snackBarRef = this.snackBar.open('Saving consent...', null, options);
      //console.log(this.consentMapper);
      const clinicianId = localStorage && localStorage["Clinician"] && JSON.parse(localStorage.Clinician) ? JSON.parse(localStorage.Clinician).id : null;
      this.testPerformed = {
        active: true,
        clinicianId: clinicianId,
        completed: true,
        consultationId: this.clientID,
        createDate: new Date(),
        createName: 'Perigon-UI',
        notes: '',
        result: this.consentMapper.data_Consent && this.consentMapper.exam_Consent ? 6 : 7,
        reviewId: '00000000-0000-0000-0000-000000000000',
        testMappingId: this.testRequested.testMappingId,
        testRequestedId: this.testRequested.id,
        changeDate: null,
        changeName: null,
        subTestMappingId: null
      };

      this.consentMapper.exam_Witness_DateSignature = this.consentMapper.exam_Witness_DateSignature || new Date();
      this.consentMapper.exam_Client_DateSignature = this.consentMapper.exam_Client_DateSignature || new Date();
      this.consentMapper.data_Client_DateSignature = this.consentMapper.data_Client_DateSignature || new Date();
      this.consentMapper.exam_Client_Signature = this.secondSig.signaturePad.toDataURL();
      this.consentMapper.exam_Witness_Signature = this.sigs.first.signaturePad.toDataURL();
      this.consentMapper.data_Client_Signature = this.sigs.last.signaturePad.toDataURL();
      this.isLoading = true;
      this.consultationService.postConsent(this.consentMapper).subscribe(result => {
        let snackBarRef2 = this.snackBar.open('Consent saved. Downloading document...', null, options);
        snackBarRef2.afterDismissed().subscribe(() => this.downloadPdf(result.fileData, result.fileName));

        this.questionnaireService.postTestPerformed(this.testPerformed).subscribe(postResult => {
          this.testRequested.status = 3;
          this.testRequested.changeDate = new Date();
          this.testRequested.changeName = 'Perigon-UI';
          this.questionnaireService.putTestStatus(this.testRequested).subscribe(updateStatus => {
            this.isLoading = false;
            ///* console.log(updateStatus) */
          });


          this.router.navigate(['/oh-consultation-summary']);
        });
      });

    } else {
      this.showError = true;

      if (this.sigs.first.signaturePad.isEmpty()) {
        this.sig1border = "red";
      }

      if (this.secondSig.signaturePad.isEmpty()) {
        this.sig2border = "red";
      }

      if (this.sigs.last.signaturePad.isEmpty()) {
        this.sig3border = "red";
      }
    }
  }


  downloadPdf(base64String, fileName): void {

    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }


  updateConsent(type, data): void {
    if (data.isUserInput) {
      let
        refusal = !data.source.value.toLowerCase().includes("refuse"),
        property = type + "_Consent";
      this.consentMapper[property] = refusal;
    }
  }


  back(): void {
    this.router.navigate(['/oh-consultation-summary']);
  }
}
