export class SouthAfricanId {
  idNumber: string;
  dateOfBirth: Date;
  gender: string;
  citizenship: string;

  constructor(idNumber: string) {
    this.idNumber = idNumber;
    this.parseId();
  }

  get genderId() {
    if (this.gender) {
      return this.gender == "Male" ? "005b4fdb-cce1-4cbf-8c98-546318bfb35a" : "3aeb5570-8c4a-421a-b20d-0ff12d3f6e0f";
    }
    return null;
  }

  private parseId(): void {
    const year = this.idNumber.substring(0, 2);
    const month = this.idNumber.substring(2, 2);
    const day = this.idNumber.substring(4, 2);
    const genderDigit = this.idNumber.charAt(6);
    const citizenshipDigit = this.idNumber.charAt(10);

    // Parse the date of birth
    const birthYear = parseInt(year, 10) >= 22 ? `19${year}` : `20${year}`;
    const birthMonth = parseInt(month, 10).toString();
    const birthDay = parseInt(day, 10).toString();
    this.dateOfBirth = new Date(`${birthYear}-${birthMonth.padStart(2, '0')}-${birthDay.padStart(2, '0')}`);

    // Parse the gender
    this.gender = parseInt(genderDigit, 10) >= 5 ? 'Male' : 'Female';

    // Parse the citizenship
    this.citizenship = parseInt(citizenshipDigit, 10) === 0 ? 'South African citizen' : 'Permanent resident';
  }
}
