import { Component, Input, OnInit, NgModule } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ConsultationObject, TestRequestedObject, ConsultationTestPerformedObject } from 'src/app/shared/interfaces/consultationMapper';
import { AppointmentsService } from 'src/app/shared/services/appointments.service';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-final-check-test',
  templateUrl: './final-check-test.component.html',
  styleUrls: ['./final-check-test.component.scss']
})
export class FinalCheckTestComponent implements OnInit {
  selected = 0;

  @Input()
  customTitle: string;
  clientId = '';
  consultationDetail: ConsultationObject;
  reviewId: string = "" ;
  testRequestedList: TestRequestedObject[];
  filteredTestRequestedList: TestRequestedObject[] = [];
  isFinalized: boolean = false;
  verySelect: any; 
  routeBack: string = '/oh-consultation-summary';
  formGroup = new FormGroup({
    verifySelect: new FormControl()
  });

  //stepHeader: string  = '';
  constructor(private consultationService: ConsultationService, private globalData: GlobalDataService, private appointmentLeads: AppointmentsService, private router: Router, public stepper: MatStepper) { 
    // this.globalData.backRouteVal.subscribe(r => { 
    //   /* console.log(r) */; 
    //   this.routeBack = r || this.routeBack;
    //   this.globalData.updateBackRoute(this.routeBack);
    //   }).unsubscribe();
  }

  ngOnInit(): void {

    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);


    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {
      if (result) {
        this.consultationDetail = result;
        this.isFinalized = this.consultationDetail.consultationWorkflowStatus == 5;
        if (this.isFinalized) {
          this.formGroup.controls["verifySelect"].disable();
        }
      }
      else {
        this.reviewId = '';
      }
    });
  }

  btnViewResults_Click() {    
    var isVaccination = this.customTitle.toLowerCase().includes('vaccination');
    this.testRequestedList = this.consultationDetail.testsRequested;

    this.testRequestedList.forEach(childObj => {
      if (childObj.testMapping.groupName.toLowerCase() == this.customTitle.toLowerCase()) {
        this.filteredTestRequestedList.push(childObj);
      }
    });

    this.filteredTestRequestedList.forEach(filterchild => {
      filterchild.testsPerformed.forEach(tperformedChild => {
        this.reviewId = this.reviewId + tperformedChild.reviewId + "," ;
      })
    });

    var n =this.reviewId.lastIndexOf(",");
    this.reviewId = this.reviewId.substring(0,n) 

   // this.reviewId = this.reviewId.trim(',');

    if (this.reviewId !== '' || this.reviewId !== undefined) {
      this.globalData.updateReviewQuestionnaireId(this.reviewId);
      this.globalData.updateSelectedGroupName(this.customTitle);
      //this.globalData.updateReviewQuestionnaireId('f2b4e07d-718b-4d1e-9c77-92d4ef660eb1');
      if (isVaccination) { 
        this.globalData.updateBackRoute('/stepper-overview');  
        this.router.navigate(['/review-vaccine']);
      }
      else {
        this.router.navigate(['/review-questionnaire']);
      }
      //window.open('/review-questionnaire', '_blank');
    }
  }

  btnSubmit_Click() {
    this.filteredTestRequestedList.forEach(childObject => {

      let tstPerformedList = childObject.testsPerformed as ConsultationTestPerformedObject[];

      tstPerformedList.forEach(tp => {

        tp.result = this.verySelect;

        this.appointmentLeads.putTestPerformed(tp).subscribe(result => {
          ///* console.log(result) */;

        });
      });

      alert("Data Saved");


    });

  }

  selectOption(vId) {
    this.verySelect = vId.value;
  }

  goBack(step: MatStepper){

    if(step.selectedIndex == 0)
    {
      
      
      this.router.navigate([this.routeBack]);
      this.globalData.updateBackRoute(null);
    }

    this.stepper.previous();
    let i = step.selectedIndex;
    this.globalData.updateSelectedStep(i);    
   
    //this.currentStep = step.selectedIndex;

  }

  goForward(step: MatStepper){

    this.stepper.next();
    let i = step.selectedIndex;
    this.globalData.updateSelectedStep(i);    
   
    //this.currentStep = step.selectedIndex;
  }


}


