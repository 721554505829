<div class="container">
  <article class="grid">
    <div class="position: relative">
      <div class="mdc-typography--headline3">
        <p class="text--align-center">FINAL CHECK</p>
      </div>
      <span class="blank-line blank-line-2 d-block"></span>
      <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
      <three-bar-screen [loading]="isLoading"></three-bar-screen>
      <div>
        <span class="blank-line blank-line-1 d-block"></span>
        <app-info-header></app-info-header>
        <div class="grid__inner grid__inner--layout-c6c">
          <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
            <h3 class="mdc-list-group__subheader" style="padding-left: unset;">Job summary</h3>
            <p class="text--align-left"> </p>
            <p class="text--align-left"><strong>{{consultationDetail?.patientDetail?.jobTitle}}</strong> </p>
          </div>
        </div>
        <div class="grid__inner grid__inner--layout-c6c" *ngIf="consultationDetail">
          <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
            <h3 class="mdc-list-group__subheader" style="padding-left: unset;">Consultation Notes</h3>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label></mat-label>
              <textarea [disabled]="isFinalized" matInput placeholder="Type here..." ngModel
                [(ngModel)]="consultationDetail.captureCompleteComments"></textarea>
            </mat-form-field>
            <div class="text--align-center">
              <button [disabled]="isFinalized" (click)="btnSaveNotes_Click()"
                class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">SAVE</button>
            </div>
          </div>
        </div>
        <!-- /////////////////////////////////////////////// -->

        <div class="grid__inner grid__inner--layout-b8b">
          <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
            <h3 class="mdc-list-group__subheader" style="padding-left: unset;">Interventions required:</h3>
            <div class="mdc-list mdc-list--interactive" aria-orientation="vertical">

              <mat-accordion>

                <!-- #region Health Eduction -->
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mdc-list-item text--align-left">
                      Health Education
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <!-- ////////////////////////////////////////////////////////////////////////////////////// -->

                  <div>
                    <table mat-table [dataSource]="healthEducationDatasource" class="mat-elevation-z8">

                      <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                      </ng-container>

                      <ng-container matColumnDef="healthEducation">
                        <th mat-header-cell *matHeaderCellDef> Health Education </th>

                        <td mat-cell *matCellDef="let element">
                          <mat-checkbox ngModel #toggleControl="ngModel" name="heCheckbox" [checked]="element.selected"
                            [(ngModel)]="element.selected" labelPosition="after" [disabled]="isFinalized"
                            color="primary" (change)="healtEducationSelectChange($event,element.id)">
                            {{element.healthEducation}}
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="selected">
                        <th mat-header-cell *matHeaderCellDef> Select </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left">
                          <mat-checkbox ngModel #toggleControl="ngModel" name="heCheckbox" [checked]="element.selected"
                            [(ngModel)]="element.selected" labelPosition="after" [disabled]="isFinalized"
                            color="primary" (change)="healtEducationSelectChange($event,element.id)">
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="heDisplayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: heDisplayedColumns;"></tr>
                    </table>
                  </div>



                  <!-- <div style="display: flex; flex-direction: column; width: 100%; align-items: flex-end;"> -->

                  <!-- /////////////////////////////////////////////////////////////////////////////////////////////// -->
                </mat-expansion-panel>
                <!-- #endregion -->

                <!-- #region Referrals -->
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mdc-list-item text--align-left">
                      Referred
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>


                  <!-- ///////////////////////////////////////////////////////////////////////////////////////// -->
                  <div>
                    <table mat-table [dataSource]="referralDatasource" class="mat-elevation-z8">

                      <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="referralItem">
                        <th mat-header-cell *matHeaderCellDef> Referral </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left">
                          <!-- <mat-checkbox #refcheckBox ngModel [(ngModel)]="element.selected" [value]="" [Checked]="element.selected"></mat-checkbox> -->

                          <mat-checkbox ngModel name="refCheckbox" [checked]="element.active"
                            [(ngModel)]="element.active" labelPosition="after" color="primary"
                            [disabled]="!finalizedAllowed"
                            (change)="referralSelectChange($event, element)">{{element.referralItem}}
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <!-- Symbol Column -->
                      <ng-container matColumnDef="requireDocument">
                        <th mat-header-cell *matHeaderCellDef> Letter </th>
                        <td mat-cell *matCellDef="let element" style="text-align:center">
                          <button *ngIf="!element.hasDocument"
                            class="mat-stroked-button motion-button--medium mat-primary"
                            (click)="btnRouteReferral_Click(element)"
                            [disabled]="!finalizedAllowed && !element.active">Letter</button>
                          <button *ngIf="element.hasDocument"
                            class="mat-stroked-button motion-button--medium mat-primary"
                            (click)="downloadReferralPdf(element)" [disabled]="!element.active">Download Letter</button>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="refDisplayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: refDisplayedColumns;"></tr>
                    </table>

                  </div>
                </mat-expansion-panel>
                <!-- #endregion -->


                <mat-expansion-panel *ngIf="consultationDetail">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="mdc-list-item">
                      Follow Up
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
                    <input style="margin-left: 16px" matInput required name="followUpDate" ngModel
                      #startDateControl="ngModel" [matDatepicker]="startpicker" placeholder="Follow up date"
                      [disabled]="isFinalized" (click)="startpicker.open()"
                      [(ngModel)]="consultationDetail.followUpDate" />
                    <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                    <mat-datepicker [disabled]="isFinalized" #startpicker startView="year"></mat-datepicker>
                  </mat-form-field>

                </mat-expansion-panel>

                <div class="mdc-list-item" style="width: 94%" *ngIf="consultationDetail">
                  <mat-checkbox ngModel #toggleControl="ngModel" labelPosition="after" color="primary"
                    [(ngModel)]="consultationDetail.medicationPrescribed"
                    [checked]="consultationDetail.medicationPrescribed" [disabled]="isFinalized"
                    name="medicationPrescribed">
                    Medication Prescribed
                  </mat-checkbox>
                </div>
                <div class="mdc-list-item" style="width: 94%" *ngIf="consultationDetail">
                  <mat-checkbox ngModel #toggleControl="ngModel" labelPosition="after" color="primary"
                    [disabled]="isFinalized" [(ngModel)]="consultationDetail.medicationAdministered"
                    [checked]="consultationDetail.medicationAdministered" name="medicationAdministered">
                    Medication Administered On Site
                  </mat-checkbox>
                </div>
              </mat-accordion>

              <span class="blank-line blank-line-1 d-block"></span>

              <div class="text--align-center">
                <button (click)="btnSave_Click()" [disabled]="isFinalized"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">SAVE</button>
              </div>
            </div>
            <!-- /////////////////////////////////////////////// -->
            <div *ngIf="false">

              <span class="blank-line blank-line-1 d-block"></span>



              <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
                <mat-label>Certificate Of Fitness</mat-label>
                <mat-select [(value)]="cof" ngModel #cofControl="ngModel" name="cof" required
                  (valueChange)="getFollowQuestions()">
                  <mat-option *ngFor="let cof of cofOption" [value]="cof.name">{{cof.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="cofControl.invalid">Please select</mat-error>
              </mat-form-field>

              <mat-form-field *ngIf="cofFollowUp.type === 'multiple'" class="whitneyFontClass motion-form-field"
                appearance="standard" hideRequiredMarker>
                <mat-label>Select</mat-label>
                <mat-select [(value)]="cofFollow" multiple (valueChange)="getValue()">
                  <mat-option *ngFor="let cof of cofFollowUp.questions" [value]="cof.name">{{cof.name}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="cofFollowUp.type === 'single'" class="whitneyFontClass motion-form-field"
                appearance="standard" hideRequiredMarker>
                <mat-label>Select</mat-label>
                <mat-select [(value)]="cofFollow" single>
                  <mat-option *ngFor="let cof of cofFollowUp.questions" [value]="cof.name">{{cof.name}}</mat-option>
                </mat-select>
              </mat-form-field>


              <div *ngIf="cofFollow.length > 0">
                <mat-form-field *ngIf="cofFollow.includes('Other')" appearance="legacy"
                  class="whitneyFontClass motion-form-field" hideRequiredMarker>
                  <input matInput name="option" ngModel #optionControl="ngModel" placeholder="Other reason"
                    pattern="[A-Za-z -]+" type="text" [(ngModel)]="option" required maxlength="20">
                  <mat-error *ngIf="optionControl.invalid">Please enter a valid reason</mat-error>
                </mat-form-field>
              </div>
            </div>

            <span class="blank-line blank-line-1 d-block"></span>

            <!-- <mat-checkbox labelPosition="after" color="primary">Complete COF</mat-checkbox> -->
            <div class="text--align-center">
              <button *ngIf="allowEditing" (click)="btnComplete_Click()" [disabled]="isFinalized"
                class="mdc-button mdc-button--stroked mdc-ripple-upgraded motion-button--medium">Certificate of
                fitness</button>
              <button *ngIf="!allowEditing" (click)="downloadPdf()"
                class="mdc-button mdc-button--stroked mdc-ripple-upgraded motion-button--medium">Download COF</button>
            </div>

            <!-- <span class="blank-line blank-line-1 d-block"></span>
        <span class="blank-line blank-line-1 d-block"></span>

        <div class="text--align-center">
          <button (click)="openDialog()" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">SUBMIT</button>
        </div> -->
          </div>

        </div>
      </div>

      <section class="grid__inner">

        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">

              </div>
              <div class="text--align-center">
                <button (click)="btnSumbitOHNP()" [disabled]="isFinalized || !OHNPAllowed"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">OHNP File
                  Review</button>

                <button (click)="btnSubmitOMP()" [disabled]="isFinalized || !OMPAllowed"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">OMP File
                  Review</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="grid__inner">

        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">

              </div>
              <div class="text--align-center">
                <button (click)="openDialog()" [disabled]="isFinalized || !finalizedAllowed"
                  class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">FINALIZE</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="grid__inner">
      <div class="mb-1">
        <span class="blank-line blank-line-2 d-block"></span>
      </div>
      <div class="grid__cell--span-all">
        <div class="grid__inner grid__inner--layout-b8b">
          <div class="grid__cell grid__cell--justify-center">
            <div class="mdc-btn-container">
            </div>
            <div class="mdc-btn-container">
              <div class="grid__cell text--align-center"><a class="motion-button--cancel" tabindex="0"
                  title="Return to previous page" (click)="goBack(stepper)">Back</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</div>
