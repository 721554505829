import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TestRequestedObject } from 'src/app/shared/interfaces/consultationMapper';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';

import { GlobalDataService } from '../../../shared/services/global-data.service';

@Component({
  selector: 'app-corporate-wellness-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class CorporateWellnessReviewComponent implements OnInit {
  isSubmit = false;
  isLoading = true;
  showError = false;

  // Variables for handling questionnaires
  consultationId: string; //Req
  questionnaireId: string = '83B663AE-5762-4603-97DE-CCF4400EC3B6'; //Req

  // Variables for handling reviews
  reviewId: string; //Req

  testRequested: TestRequestedObject; //Req
  consultationSelection: string = ''; //Req

  constructor(private router: Router, private route: ActivatedRoute, private globalData: GlobalDataService, private questionnaireService: QuestionnaireService, private snackBar: MatSnackBar) {

    this.globalData.updateBackRoute('/wellness-event/corporate/consultation');

  }

  ngOnInit(): void {

    // Get the consultation selection (indicates if a questionnaire of review was selected)
    this.globalData.consultationSelectionVal.subscribe(result => { this.consultationSelection = result });

    // Get the consultation Id
    this.globalData.selectedClientVal.subscribe(result => { this.consultationId = result; });

    // Get the selected questionnaire Id
    this.globalData.questionnaireIdVal.subscribe(qId => { this.questionnaireId = qId; });

    // Get the review Id
    this.globalData.reviewQuestionnaireIdVal.subscribe(result => { this.reviewId = result; });

    // Get list of tests requested
    this.globalData.questionnaireClientDataVal.subscribe(test => { this.testRequested = test; });

    this.isLoading = false;
  }

  onSaved(): void {
    this.snackBar.open('Saved', null, { duration: 2500 }).afterDismissed().subscribe(() => this.router.navigate(['../consultation'], { relativeTo: this.route }));
  }

  back(): void {
    this.router.navigate(['/wellness-event/corporate/consultation']);
  }


}
