import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator';
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})


export class SignupComponent implements OnInit {

  formDirective!: FormGroupDirective;
  isLoading: boolean = false;
  signupForm!: FormGroup;
  linkText: string = "Hide password";
  passType: string = "text";
  isPassword: boolean = false;
  invalidForm: boolean = false;

  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router) {
    this.createForm();
  }

  ngOnInit(): void {
  }
  onSubmit() {
    if (this.signupForm.valid) {
      this.invalidForm = false;
    } else {
      this.invalidForm = true;
    }
  }
  createForm() {
    this.signupForm = this.fb.group({
      id: null,
      name: [null, Validators.required],
      surname: [null, Validators.required],
      idNumber: [null, [Validators.required, southAfricanIdNumberValidator]],
      contactNumber: [null, [Validators.required, cellNumberValidator]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
    })
  }
  hideShowPassword() {
    this.isPassword = !this.isPassword;
    this.isPassword ? this.linkText = "Show password" : this.linkText = "Hide password";
    this.isPassword ? this.passType = "password" : this.passType = "text";
  }
  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }
  resetForm() {
    this.signupForm.reset();
    //this.formDirective.resetForm();
  }
  ToLogin() {
    this.isLoading = false;
    this.resetForm();
    this.router.navigate(['login'])
  }
}
