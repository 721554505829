<div class="container">
  <article class="grid">
    <div class="grid__inner grid__inner--layout-b8b">
      <div class="grid__cell">
        <div class="mdc-typography--headline2">
          <p class="text--align-center">Perigon Event Summary</p>
        </div>
      </div>
      <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
      <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
      <div *ngIf="isLoading === false && showError === true">
        <span class="blank-line blank-line-2 d-block"></span>
        <p class="text--align-center mdc-typography--body1">An error occurred.</p>
        <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
        <section class="grid__inner">
          <div class="mb-1">
            <span class="blank-line blank-line-4 d-block"></span>
          </div>
          <div class="grid__cell grid__cell--justify-center">
            <div class="mdc-btn-container">
              <div class="grid__cell text--align-center">
                <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                  (click)="previousPage()">Back</a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section *ngIf="!isLoading && !showError">
        <div class="mdc-typography--headline2">
          <p class="text--align-center">Perigon Event Summary</p>
        </div>
        <div class="execute_report">
          <div class="grid__cell--span-3-desktop grid__cell--span-all">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Please select a province</mat-label>
              <mat-select required formControlName="provinceID" #selectedProvince
                (selectionChange)="onSelectedProvince(selectedProvince.value)">
                <mat-option value="" disabled selected>e.g. Head Office</mat-option>
                <mat-option *ngFor="let province of provinces"
                  [value]="province.id">{{province.description}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="grid__cell--span-3-desktop grid__cell--span-all">
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Please select a service provider</mat-label>
                <mat-select required formControlName="serviceProviderId" #selectedServiceProvider>
                  <mat-option *ngFor="let serviceProvider of serviceProviders"
                    [value]="serviceProvider.id">{{serviceProvider.workflowName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="grid__cell--span-3-desktop grid__cell--span-all">
            <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker" [formGroup]="reportCreator">
                <input matStartDate placeholder="Start date" #dateRangeStart formControlName="dateRangeStart"
                  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                <input matEndDate placeholder="End date" #dateRangeEnd formControlName="dateRangeEnd"
                  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
        //TODO: Complete
        <div>
          <div class="grid__cell--span-3-desktop grid__cell--span-all" layout-align="center center">
            <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short">
              Generate Report
            </button>
          </div>
        </div>
      </section>
    </div>
  </article>
</div>
