<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false">
      <span class="blank-line blank-line-1 d-block"></span>
      <div class="grid__inner grid__inner--layout-c6c">
        <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
          <h3 class="mdc-list-group__subheader" style="padding-left: unset;">Interventions required:</h3>
          <div class="mdc-list mdc-list--interactive" aria-orientation="vertical">
            <div *ngFor="let rqs of nurseInterventions">
              <a class="mdc-list-item" style="justify-content: space-between; padding-left: unset; padding-right: unset"
                href="javascript:void(0)">
                <span class="mdc-list-item__text">{{rqs.name}}</span>
                <mat-checkbox labelPosition="before" color="primary"></mat-checkbox>
              </a>
              <div class="mdc-list-divider" role="separator"></div>
            </div>
          </div>
          <span class="blank-line blank-line-1 d-block"></span>
          <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
            <mat-label>Complete COF</mat-label>
            <mat-select [(value)]="cof" ngModel #cofControl="ngModel" name="cof" required
              (valueChange)="getFollowQuestions()">
              <mat-option *ngFor="let cof of cofOption" [value]="cof.name">{{cof.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="cofControl.invalid">Please select</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="cofFollowUp.type === 'multiple'" class="whitneyFontClass motion-form-field"
            appearance="standard" hideRequiredMarker>
            <mat-label>Select</mat-label>
            <mat-select [(value)]="cofFollow" multiple (valueChange)="getValue()">
              <mat-option *ngFor="let cof of cofFollowUp.questions" [value]="cof.name">{{cof.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="cofFollowUp.type === 'single'" class="whitneyFontClass motion-form-field"
            appearance="standard" hideRequiredMarker>
            <mat-label>Select</mat-label>
            <mat-select [(value)]="cofFollow" single>
              <mat-option *ngFor="let cof of cofFollowUp.questions" [value]="cof.name">{{cof.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="cofFollow.length > 0">
            <mat-form-field *ngIf="cofFollow.includes('Other')" appearance="legacy"
              class="whitneyFontClass motion-form-field" hideRequiredMarker>
              <input matInput name="option" ngModel #optionControl="ngModel" placeholder="Other reason"
                pattern="[A-Za-z -]+" type="text" [(ngModel)]="option" required maxlength="20" />
              <mat-error *ngIf="optionControl.invalid">Please enter a valid reason</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>
