import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { ConsultationObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { MatStepper } from '@angular/material/stepper';
import { delay } from 'rxjs/operators';
import { GLOBAL_VARS } from 'src/app/shared/enums';

@Component({
  selector: 'app-stepper-overview',
  templateUrl: './stepper-overview.component.html',
  styleUrls: ['./stepper-overview.component.scss']
})
export class StepperOverviewComponent implements OnInit {
  // appProgress: number;
  currentStep: number;

  stepIndex: number;

  clientId = '';
  stepperMap = new Map<number, string>();

  Audiometry = false;
  Consent = false;
  Diving = false;
  Pathology = false;
  Physiotherapy = false;
  Questionnaires = false;
  Radiology = false;
  Spirometry = false;
  Vaccination = false;
  VisionScreening = false;

  Administration = false;
  Cardiovascular = false;
  VitalsRapids = false;
  NurseExam = false;

  stepperList = [];

  testRequestedList: TestRequestedObject[] = [];



  consultationDetail: ConsultationObject;

  @ViewChild('stepper') stepper: MatStepper;

  constructor(private globalDataService: GlobalDataService, private consultationService: ConsultationService) {
    // this.globalDataService.appProgressVal.subscribe(result => this.appProgress = result);



  }

  ngOnInit(): void {

    this.globalDataService.selectedClientVal.subscribe(result => this.clientId = result);

    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {

      if (result) {
        this.consultationDetail = result;

        this.testRequestedList = this.consultationDetail.testsRequested;

        GLOBAL_VARS.CORRECT_GROUP_NAMES.forEach(g => { 
          if (this.testRequestedList.find(t => t.testMapping.groupName.toLowerCase() == g.toLowerCase())) {
            this.stepperList.push(g);
          }
        });

        this.stepperList.push("Completion");   

      }

    });

    this.setStepper();

  }

  ngAfterViewInit(): void {


  }

  setStepper() {
    this.currentStep = 0;

    this.globalDataService.stepperHeadVal.subscribe(xresult => {
      setTimeout(() => {

        this.currentStep = xresult
        if (this.currentStep !== 0) {         
         // alert("setting stepperIndex to " + this.currentStep + ". Stepper length = " + this.stepper.steps.length);

          for (let i = 0; i < this.currentStep ; i++) {
            //this.stepper.selectedIndex = this.currentStep;
            this.stepper.next();
          }
          
        }
      }, 1000);
      ;
    }).unsubscribe();
    //}).unsubscribe(); 

  }

}




