<article class="grid">
  <three-bar-screen [loading]="isLoading"></three-bar-screen>

  <form #examination=ngForm matNativeControl>
    <div class="grid__inner grid__inner--layout-12">
      <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
        <span class="blank-line blank-line-2 d-block"></span>
        <div class="columnItem">
          <div class="itemBorderHeader mdc-typography--overline header-background"
            style="text-align: center; font-size: 1rem;">
            Referral Letter
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">To</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="to" placeholder="To" ngModel #inputControl="ngModel" type="text" required
                  maxlength="255" [(ngModel)]="referralMapper.detailsTo" />
              </mat-form-field>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-heading-background">Member Details</div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Clinic/Screening Site</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="clinicSite" ngModel #inputControl="ngModel" placeholder="Site" type="text"
                  required maxlength="255" [(ngModel)]="referralMapper.detailsSite">
              </mat-form-field>
            </div>
            <div class="itemBorder item-background">Date</div>
            <div class="itemBorder">
              {{ referralMapper.detailsDate | date: 'dd MMMM yyyy HH:mm' }}

            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Name</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="firstName" placeholder="Name" ngModel #inputControl="ngModel" type="text" required
                  maxlength="255" [(ngModel)]="referralMapper.detailsName">
              </mat-form-field>
            </div>
            <div class="itemBorder item-background">Surname</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="surname" placeholder="Surname" ngModel #inputControl="ngModel" type="text"
                  required maxlength="255" [(ngModel)]="referralMapper.detailsSurname">
              </mat-form-field>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">ID Number</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="idNumber" placeholder="ID Number" ngModel #inputControl="ngModel" type="text"
                  required maxlength="255" [(ngModel)]="referralMapper.detailsIDNumber">
              </mat-form-field>
            </div>
            <div class="itemBorder item-background">Company number</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="companyNumber" placeholder="Company Number" ngModel #inputControl="ngModel"
                  type="text" required maxlength="255" [(ngModel)]="referralMapper.detailsEmployeeNumber">
              </mat-form-field>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Cluster/Division</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="clusterControl" ngModel placeholder="Division" #inputControl="ngModel" type="text"
                  required [(ngModel)]="referralMapper.detailsDepartment">
              </mat-form-field>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-heading-background">Accounts to be submitted to</div>
            <div class="itemBorder">
              <div class="mdc-list-group">
                <div class="mdc-list mdc-list--interactive" aria-orientation="vertical" style="display: flex"
                  *ngFor="let account of accounts; let i = index">
                  <div class="mdc-list-item" required style="flex: 1 1 max-content">
                    <mat-radio-button name="accounts" color="primary" class="mdc-list-item__text" required
                      [checked]="referralMapper.referralAccountPaymentType == account.value" [value]="account.id"
                      (change)="setAccount($event)">
                      {{account.name}}
                    </mat-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background" style="padding-bottom: 10px">
              During examination, the following were detected. Kindly investigate, treat and make recommendations.
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder" style="height: 200px">
              <mat-form-field style="width: 100%;" appearance="legacy"
                class="whitneyFontClass motion-form-field unsetMarginPadding" hideRequiredMarker>
                <textarea matInput name="findings" placeholder="Findings" ngModel #inputControl="ngModel"
                  style="height: 200px; width: 100%" [(ngModel)]="referralMapper.nurseFindings">
                </textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Registered Nurse Practitioner</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass unsetMarginPadding motion-form-field"
                hideRequiredMarker>
                <input matInput name="nursePractitioner" ngModel #inputControl="ngModel" placeholder="Nurse" type="text"
                  required maxlength="255" [(ngModel)]="referralMapper.nurseName">
              </mat-form-field>
            </div>


            <div class="itemBorder item-background">Signature</div>
            <div class="itemBorder">
              <div class="signatureTable" [formGroup]="form">
                <div class="row">
                  <div class="cell" style="position: relative;">
                    <div class="sig-container cofSignature" #sigContainer1>
                      <div fxLayout="row">
                        <button type="button" (click)="sigs.first.clear()"
                          [disabled]="!form.controls.signatureField1.valid">Clear</button>
                      </div>
                      <div fxLayout="row">
                        <signature-field formControlName="signatureField1"></signature-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Tel no</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="referredByTelNo" ngModel #inputControl="ngModel" placeholder="Telephone Number"
                  type="text" required [(ngModel)]="referralMapper.nurseTel">
              </mat-form-field>
            </div>

            <div class="itemBorder item-background">Fax No/Email</div>
            <div class="itemBorder">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                hideRequiredMarker>
                <input matInput name="faxEmailNumber" ngModel #inputControl="ngModel" placeholder="Email" type="text"
                  required [(ngModel)]="referralMapper.nurseEmail">
              </mat-form-field>
            </div>
          </div>


          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder"><span><b>Medical Practitioner's Findings</b></span>
              <br>
              <span>
                Send your findings back to the referring practitioner
              </span>
            </div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Date</div>
            <div class="itemBorder"></div>
          </div>


          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background" style="padding-bottom: 10px">Diagnosis, treatment and
              recommendation.</div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder" style="height: 200px"></div>
          </div>


          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Commence duty on</div>
            <div class="itemBorder"></div>

            <div class="itemBorder item-background">Report back for follow-up on</div>
            <div class="itemBorder"></div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Medical Practitioner</div>
            <div class="itemBorder"></div>

            <div class="itemBorder item-background">Signature</div>
            <div class="itemBorder"></div>
          </div>

          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder item-background">Tel No</div>
            <div class="itemBorder"></div>
            <div class="itemBorder item-background">Fax No/E-Mail</div>
            <div class="itemBorder"></div>
          </div>
          <div class="rowItem mdc-typography--body2">
            <div class="itemBorder">
              <span>
                <br>
                I acknowledge that this has been explained to me and that I will submit feedback to the SAPS Clinic.
              </span>

              <div>
                <span class="blank-line blank-line-2 d-block"></span>
                <div style="width: 250px; border-bottom: 1px solid dimgrey">
                  <div class="signatureTable" [formGroup]="form">
                    <div class="row">
                      <div class="cell" style="position: relative;">
                        <div class="sig-container cofSignature" #sigContainer2>
                          <div fxLayout="row">
                            <button type="button" (click)="sigs.last.clear()"
                              [disabled]="!form.controls.signatureField2.valid">Clear</button>
                          </div>
                          <div fxLayout="row">
                            <signature-field formControlName="signatureField2"></signature-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-label>Signature of Member</mat-label>
                <span class="blank-line blank-line-2 d-block"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__cell--span-all">
      <div class="grid__inner grid__inner--layout-66">
        <div class="grid__cell--justify-center grid__cell--span-all" *ngIf="showError">
          <div class="mdc-btn-container">
            <mat-error class="text--align-center">Please complete all required</mat-error>
          </div>
        </div>
        <div class="grid__cell grid__cell--justify-center">
          <div class="mdc-btn-container">
            <input type="submit" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
              (click)="save(examination)" value="Save">
          </div>
        </div>
        <div class="grid__cell grid__cell--justify-center">
          <div class="mdc-btn-container">
            <input type="button" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
              [disabled]="disableSubmit" (click)="submit(examination)" value="Submit">
          </div>
        </div>
        <div class="grid__cell--justify-center grid__cell--span-all">
          <div class="mdc-btn-container">
            <div class="text--align-center">
              <a class="motion-button--cancel" tabindex="0" title="Return to previous page" (click)="back()">Back</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</article>
