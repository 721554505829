<div class="container">
  <h1 mat-dialog-title class="text--align-center">Success</h1>
  <div mat-dialog-content class="text--align-center">
    <p>COF saved.</p>
  </div>
  <div class="text--align-center">
    <div mat-dialog-actions>
      <button mat-button (click)="ok()" cdkFocusInitial
        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">OK</button>
    </div>
  </div>
</div>
