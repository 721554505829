import {Component, Input, OnInit} from '@angular/core';
import {ClientMapper, InfoBlockMapper} from '../../../shared/interfaces/InfoBlockMapper';
import {GlobalDataService} from '../../../shared/services/global-data.service';

@Component({
  selector: 'app-client-profile-detail',
  templateUrl: './client-profile-detail.component.html',
  styleUrls: ['./client-profile-detail.component.scss']
})
export class ClientProfileDetailComponent implements OnInit {

  data: ClientMapper;

  isLoading = false;
  isFailed = false;

  listItems: InfoBlockMapper[] = [];

  constructor(private globalData: GlobalDataService) { }

  ngOnInit(): void {
    this.globalData.clientInfoVal.subscribe(result => this.data = result);

    this.setBlockItems();
  }

  previousPage() {
    window.history.back();
  }

  private setBlockItems(): void {
    this.listItems.push({
      status: 'inProgress',
      title: 'Client health and wellness interactions',
      description: '',
      result: 'passed',
      icon: 'patient-profile',
      action: ''
    });

    this.listItems.push({
      status: 'finished',
      title: 'Client risk profile and exposure',
      description: '',
      result: 'passed',
      icon: 'patient-profile',
      action: ''
    });

    this.listItems.push({
      status: 'pending',
      title: 'Client medical and occupational history',
      description: '',
      result: 'passed',
      icon: 'patient-profile',
      action: ''
    });
  }
}
