import { Component, OnInit } from '@angular/core';

import { QuestionnaireSummary } from '../../shared/interfaces/questionnaireMapper';
import { GlobalDataService } from '../../shared/services/global-data.service';
import { QuestionnaireService } from '../../shared/services/questionnaire.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  isLoading = true;
  showError = false;

  storedId: string = null;
  questionnaireId: string = '94B8C4DD-84FD-4881-92A0-AEA5A989D20E'; //Req
  questionnaireList: Array<QuestionnaireSummary>;

  constructor(private globalData: GlobalDataService, private questionnaireService: QuestionnaireService) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.questionnaireService.getQuestionnaireNameList().subscribe(data => {
      this.questionnaireList = data;
      this.isLoading = false;
    });
  }

  onSaved(): void {
    alert('Saved successfully');
  }

  questionnaireSelection(event): void {
    this.storedId = event.value;
  }

  previewOrClear(): void {
    this.questionnaireId = this.questionnaireId ? null : this.storedId;
  }
}
