import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { WellnessReport } from 'src/app/shared/interfaces/WellnessReport';

import * as Enums from '../enums'
import { GlobalDataService } from './global-data.service'

//TODO Is Tken needed here?
// import { KeycloakService } from 'keycloak-angular'

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  httpOptions: any = {}

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }


  getWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.GET_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  getTransactionalSummary(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], paymentIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.GET_Transactional_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProvider: serviceProviderIds, paymentIds: paymentIds, companyName: eventIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  getAdhocTransactionalSummary(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], paymentIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.Get_Adhoc_TransactionalReport, { dateFrom: startDate, dateTo: endDate, serviceProvider: serviceProviderIds, paymentIds: paymentIds, companyName: eventIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }


  getCorporateTransactionalReport(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.GET_CORPORATE_Transactional, { dateFrom: startDate, dateTo: endDate, serviceProvider: serviceProviderIds, companyName: eventIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  getAdhocTransactionalReport(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.Download_Adhoc_TransactionalReport, { dateFrom: startDate, dateTo: endDate, serviceProvider: serviceProviderIds, companyName: eventIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }


  getAdhocWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.GET_ADHOC_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  getCorporateWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], paymentIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.GET_CORPORATE_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds, companyName: eventIds, paymentIds: paymentIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  downloadWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.POST_DOWNLOAD_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping -  getCorporateWDEEventSummary', data)),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  //Download adhoc wellness event summary report
  downloadAdhocWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.POST_ADHOC_DOWNLOAD_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds }, this.httpOptions)
      .pipe(
        tap((data) => { console.log('Ping') }),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  downloadCorporateWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], paymentIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_CORPORATE_DOWNLOAD_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds, companyName: eventIds, paymentIds: paymentIds, coordinatorName: coordinator }, this.httpOptions).pipe(
      tap((data) => console.log('Ping')),
      catchError(this.handleError('Unable to get event summary', undefined))
    )
  }

  //Eapa conference report
  downloadEAPAConferenceReport(): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.DownloadEAPAreport, {}, this.httpOptions).pipe(
      tap((data) => console.log('Ping')),
      catchError(this.handleError('Unable to get EAPA Conference report', undefined))
    )
  }

  //Download Cooporate wellness event summary report
  // downloadCorporateWDEEventSummary(serviceProviderIds: string[], startDate: string, endDate: string, eventNames: string[], coordinator: string): Observable<any> {
  //   this.httpOptions = this.globalServ.getHttpOptions()

  //   return this.http
  //     .post(Enums.API_PATH + Enums.API_PATHS.POST_CORPORATE_DOWNLOAD_WDE_EVENT_SUMMARY, { dateFrom: startDate, dateTo: endDate, serviceProviderIds: serviceProviderIds, coordinatorName: coordinator, companyName: eventNames }, this.httpOptions)
  //     .pipe(
  //       tap((data) => console.log('Ping')),
  //       catchError(this.handleError('Unable to get Coorporate wellness event summary', undefined))
  //     )
  // }

  getWellnessDayEventBooking(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_WELLNESS_DAY_EVENT_BOOKING + id, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event booking', undefined))
      )
  }

  getAllWellnessDayEvents(clinicianId): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .get(Enums.API_PATH + Enums.API_PATHS.GET_ALL_WELLNESS_DAY_EVENTS + clinicianId, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get all event bookings', undefined))
      )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error)
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T)
    }
  }

  private log(message: string) {
    console.log(`${message}`)
  }
}
