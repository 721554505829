<!--*ngIf="ToggleForm" set form toggle after form completion-->
<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <div id="fullCalendar" *ngIf="showEvents">
    <h2 class="mdc-typography--headline4">Wellness event {{bookingStage == 'manage' ? "execution" : "management"}}</h2>
    <full-calendar [options]="calendarOptions" #calendarEl
      [customButtons]="bookingStage == 'manage' ? calendarOptions.customButtons : null" style="padding-top:10px">
    </full-calendar>
  </div>
  <div id="eventForm">
    <article class="grid">
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <mat-accordion>
            <form [formGroup]="eventFormMain" (ngSubmit)="onSubmit()">

              <h1 class="mdc-typography--headline2">Wellness event booking form</h1>
              <app-wellness-event-header [setUpTime]="eventSetUpTimes" [strikeDownTime]="eventStrikeDownTimes"
                [eventDate]="eventDates" [startTime]="eventStartTime" [endTime]="eventEndTime"
                [eventStatusId]="eventStatusId" [town]="" [province]="">
              </app-wellness-event-header>

              <mat-expansion-panel #eventPlanningPanel *ngIf="eventFormMain.get('id').value !== null"
                (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Event planning</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div formGroupName="eventPlanning">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a service provider</mat-label>
                      <mat-select formControlName="serviceProviderId" #selectedServiceProvider>
                        <mat-option *ngFor="let serviceProvider of serviceProviders"
                          [value]="serviceProvider.id">{{serviceProvider.workflowName}}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.serviceProviderId').invalid && eventFormMain.get('eventPlanning.serviceProviderId').touched">
                        Service provider is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Type of health practitioner required *</mat-label>
                      <mat-select formControlName="healthPractitionerTypeId">
                        <mat-option *ngFor="let hpType of healthPractitionerTypes"
                          [value]="hpType.id">{{hpType.description}}</mat-option>
                      </mat-select>
                      <!-- <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.healthPractitionerTypeId').invalid && eventFormMain.get('eventPlanning.healthPractitionerTypeId').touched">
                        Type of health practitioner is required.
                      </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Number of health practitioners required *</mat-label>
                      <input matInput type="number" formControlName="numberOfHealthPractitioners"
                        placeholder="Recommended 1 health practitioner for every 10 members" min="1">
                      <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.numberOfHealthPractitioners').invalid && eventFormMain.get('eventPlanning.numberOfHealthPractitioners').touched">
                        Number of health practitioners is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventPlanning.numberOfHealthPractitioners').errors?.min">
                        Minimum number of health practitioners required is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Marketing Campaign Date</mat-label>
                      <input matInput type="date" formControlName="marketingCampaignDate" required>
                      <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.marketingCampaignDate').invalid && eventFormMain.get('eventPlanning.marketingCampaignDate').touched">
                        Marketing Campaign Date is required.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="grid__inner grid__inner--layout-66">
                    <div class="grid__cell">
                      <div class="form-group">
                        <mat-form-field appearance="legacy"
                          class="whitneyFontClass motion-form-field unsetMarginPadding">
                          <mat-label>Set up time needed *</mat-label>
                          <input matInput type="text" formControlName="setUpHours" #suHours appCustomNumberFormat
                            required min="00" max="23" class="timeDigits">
                          <span>:</span>
                          <input matInput type="text" formControlName="setUpMinutes" #suMinutes appCustomNumberFormat
                            required min="00" max="59" class="timeDigits">
                          <input type="hidden" formControlName="setUpTimes" #eventSelectedSetupTimes
                            [value]="suHours.value + ':' + suMinutes.value">
                          <mat-error
                            *ngIf="eventFormMain.get('eventPlanning.setUpTimes').invalid && eventFormMain.get('eventPlanning.setUpTimes').touched">
                            Event set up time needed is required.
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="grid__cell">
                      <div class="form-group" *ngIf="eventSelectedSetupTimes.value !== null">
                        <mat-form-field appearance="legacy"
                          class="whitneyFontClass motion-form-field unsetMarginPadding">
                          <mat-label>Strike down time needed *</mat-label>
                          <input matInput type="text" formControlName="strikeDownHours" #sdHours appCustomNumberFormat
                            required min="00" max="23" class="timeDigits">
                          <span>:</span>
                          <input matInput type="text" formControlName="strikeDownMinutes" #sdMinutes
                            appCustomNumberFormat required min="00" max="59" class="timeDigits">
                          <input type="hidden" formControlName="strikeDownTimes" #eventSelectedStrikeDownTimes
                            [value]="sdHours.value + ':' + sdMinutes.value">
                          <mat-error
                            *ngIf="eventFormMain.get('eventPlanning.strikeDownTimes').invalid && eventFormMain.get('eventPlanning.setUpTimes').touched">
                            Event strike down time needed is required.
                          </mat-error>
                          <!-- <mat-error *ngIf="eventFormMain.get('eventPlanning.strikeDownTimes').touched">
                          Event Strike Down Time is required.
                        </mat-error>
                        <mat-error *ngIf="eventFormMain.get('eventPlanning.strikeDownTimes').invalid">
                          Invalid time entries entered.
                        </mat-error> -->
                        </mat-form-field>
                      </div>
                    </div>

                  </div>

                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Allocate Equipment and Stock to the Event</mat-label>
                      <mat-select formControlName="allocatedEquipment" multiple>
                        <mat-option *ngFor="let allocated of allocatedEquipment"
                          [value]="allocated.code">{{allocated.description}}</mat-option>
                        <!-- <mat-option value="accutrendTestStrips">Accutrend Test Strips</mat-option>
                        <mat-option value="scale">Scale</mat-option>
                        <mat-option value="tabletMobileElectronicDevice">Tablet (Mobile Electronic Device)</mat-option>
                        <mat-option value="table">Table</mat-option> -->
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.allocatedEquipment').invalid && eventFormMain.get('eventPlanning.allocatedEquipment').touched">
                        Please select at least one item to allocate.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #eventDetailsPanel (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left">
                    <strong>Event details</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>


                <!-- <div class="form-group">
                    <mat-label>Event status: </mat-label>
                    <mat-label></mat-label>
                </div> -->

                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Event date</mat-label>
                    <input matInput type="date" #eventDate formControlName="date" required>
                    <mat-error *ngIf="eventFormMain.get('date').invalid && eventFormMain.get('date').touched">
                      Event date is required.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div formGroupName="eventProvince">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a province</mat-label>
                      <mat-select required formControlName="provinceID" #selectedProvince
                        (selectionChange)="onSelectedProvince(selectedProvince.value)">
                        <mat-option value="" disabled selected>e.g. Head Office</mat-option>
                        <mat-option *ngFor="let province of provinces"
                          [value]="province.id">{{province.description}}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('eventProvince.provinceID').invalid && eventFormMain.get('eventProvince.provinceID').touched">
                        Province is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group" *ngIf="showTown" id="paddedField">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please select a Station, Town</mat-label>
                    <mat-select required formControlName="eventTownAndStationID" #selectedTown>
                      <!-- Input Field Container -->
                      <div class="search-container">
                        <input matInput type="text" #searchTown (input)="filterTownsAndStations(searchTown.value)"
                          autocomplete="off" placeholder="Please search by typing here or select a Station, Town below">
                      </div>

                      <!-- Options Container -->
                      <div class="options-container">
                        <mat-divider></mat-divider>
                        <mat-option value="" disabled selected>e.g. Hilbrow station,
                          Hilbrow</mat-option>
                        <mat-option *ngFor="let station of filteredOptions" [value]="station.id">
                          {{ station.description }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div formGroupName="serviceType">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a service type</mat-label>
                      <mat-select required formControlName="serviceTypeID">
                        <mat-option value="" selected disabled>e.g. SAPS wellness
                          events</mat-option>
                        <mat-option *ngFor="let service of serviceTypes"
                          [value]="service.id">{{service.description}}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('serviceType.serviceTypeID').invalid && eventFormMain.get('serviceType.serviceTypeID').touched">
                        Event service type is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="grid__inner grid__inner--layout-66">
                  <div class="grid__cell">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Event Start Time</mat-label>
                        <input matInput type="time" formControlName="eventStartTime" required #eventSelectedStartTime
                          (input)="timeValidation(eventSelectedStartTime.value,eventFormMain.get('eventEndTime').value,eventTimeSelection)">
                        <mat-error *ngIf="eventFormMain.get('eventStartTime').invalid &&
                                                    eventFormMain.get('eventStartTime').touched">
                          Event start time is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="grid__cell">
                    <div class="form-group" *ngIf="eventFormMain.get('eventStartTime').value !== null">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Event End Time</mat-label>
                        <input matInput type="time" formControlName="eventEndTime" required #eventSelectedEndTime
                          (input)="timeValidation(eventSelectedStartTime.value,eventSelectedEndTime.value,eventTimeSelection)">
                        <mat-error *ngIf="eventFormMain.get('eventEndTime').touched">
                          Event end time is required.
                        </mat-error>
                        <mat-error *ngIf="eventFormMain.get('eventEndTime').invalid">
                          Invalid time entries entered.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="grid__cell">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Shift Worker Start Time</mat-label>
                        <input matInput type="time" formControlName="shiftWorkersStartTime"
                          #shiftWorkerSelectedStartTime (input)="onShiftWorkerTimeSelection()">
                        <mat-error
                          *ngIf="(eventFormMain.get('shiftWorkersStartTime').invalid && eventFormMain.get('shiftWorkersStartTime').touched)">
                          Shift worker start time is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="grid__cell">
                    <div class="form-group" *ngIf="eventFormMain.get('shiftWorkersStartTime').value !== null">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>ShiftWorker End Time</mat-label>
                        <input matInput type="time" formControlName="shiftWorkersEndTime" #shiftWorkerSelectedEndTime
                          (input)="timeValidation(shiftWorkerSelectedStartTime.value,shiftWorkerSelectedEndTime.value,shiftWorkerTimeSelection)">
                        <mat-error *ngIf="eventFormMain.get('shiftWorkersEndTime').touched">
                          Shift worker end time is required.
                        </mat-error>
                        <mat-error *ngIf="eventFormMain.get('shiftWorkersEndTime').invalid">
                          Invalid time entries entered.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <button *ngIf="eventFormMain.get('shiftWorkersStartTime').value !== null" mat-raised-button
                  class="button clear-button" (click)="clearShiftWorkerTimeEntries()">
                  Clear shift entries
                </button>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter event complete address</mat-label>
                    <textarea formControlName="address" matInput (change)="setAddressValidation('address')"
                      placeholder="e.g. 365 Redington Street Erasmusrand"></textarea>
                    <mat-error *ngIf="eventFormMain.get('address').invalid && eventFormMain.get('address').touched">
                      Address or special landmarks is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any special landmarks</mat-label>
                    <input matInput type="text" formControlName="specialLandmarks"
                      (input)="setAddressValidation('specialLandmarks')"
                      placeholder="e.g. 895623 - 500m North on N1 after Kameeldoring junction">
                    <mat-error
                      *ngIf="eventFormMain.get('specialLandmarks').invalid && eventFormMain.get('specialLandmarks').touched">
                      Address or special landmarks is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div formGroupName="employeeDetails">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of employees on site</mat-label>
                      <input type="number" matInput formControlName="amountOfEmployeesOnSite" #numberOfEmployees
                        required placeholder="e.g. 12" min="1" (input)="onInput($event)">
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').invalid && eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').touched">
                        Number of employees on site is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').errors?.min">
                        Minimum number of employees on site is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of expected participants</mat-label>
                      <input matInput min="1" formControlName="amountOfExpectedEventParticipants" required
                        placeholder="e.g. 66" (input)="onInput($event)" type="number" #expectedParticipants
                        [max]="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').value">
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').invalid && eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').touched">
                        Number of expected participants is required.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').errors?.min">
                        Minimum number of expected participants is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #eventVenuePanel (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Event venue</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div formGroupName="eventAtmosphere">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select an event type</mat-label>
                      <mat-select required formControlName="atmosphereID" #selectedEventType
                        (selectionChange)="onVenueSelection(selectedEventType.value)">
                        <mat-option value="" disabled selected>e.g. Outside</mat-option>
                        <mat-option *ngFor="let type of eventTypes" [value]="type.id">{{type.description}}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('eventAtmosphere.atmosphereID').invalid && eventFormMain.get('eventAtmosphere.atmosphereID').touched">
                        Event type is required.
                      </mat-error>
                      <!-- <mat-hint class="hint">Atmosphere of event venue.</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group"
                    *ngIf="selectedEventType.value == '69d34741-ddf0-4025-b2b8-916cb017b0df' || selectedEventType.value == 'a423a72a-5552-469c-a64b-68cf9bce6e91'">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of private rooms available</mat-label>
                      <input matInput min="1" (input)="onInput($event)" formControlName="privateRoomsAvailable"
                        placeholder="e.g. 6">
                      <mat-error
                        *ngIf="eventFormMain.get('eventAtmosphere.privateRoomsAvailable').invalid && eventFormMain.get('eventAtmosphere.privateRoomsAvailable').touched">
                        Private rooms amount is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventAtmosphere.privateRoomsAvailable').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventAtmosphere.privateRoomsAvailable').errors?.min">
                        Minimum private rooms amount is 1.
                      </mat-error>
                      <!-- <mat-hint class="hint">Inside: Private rooms total for
                                                consultations.</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group"
                    *ngIf="selectedEventType.value == '69d34741-ddf0-4025-b2b8-916cb017b0df' || selectedEventType.value == 'a423a72a-5552-469c-a64b-68cf9bce6e91'">
                    <mat-label>Is the venue open plan? *</mat-label>
                    <mat-radio-group formControlName="venueOpenPlan">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error
                      *ngIf="eventFormMain.get('eventAtmosphere.venueOpenPlan').invalid && invalidForm && (eventFormMain.get('eventAtmosphere.venueOpenPlan').pristine || eventFormMain.get('eventAtmosphere.venueOpenPlan').touched)">
                      Venue type is required.
                    </mat-error>
                  </div>
                  <div class="form-group"
                    *ngIf="selectedEventType.value == '9a859b37-8f61-497b-b0b0-771ea4f1fac5' || selectedEventType.value == 'a423a72a-5552-469c-a64b-68cf9bce6e91'">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of gazebos required</mat-label>
                      <input matInput type="number" formControlName="numberOfGazebosRequired" placeholder="e.g. 5"
                        (input)="onInput($event)">
                      <mat-error
                        *ngIf="eventFormMain.get('eventAtmosphere.numberOfGazebosRequired').invalid && eventFormMain.get('eventAtmosphere.numberOfGazebosRequired').touched">
                        Gazebos amount is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventAtmosphere.numberOfGazebosRequired').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <!-- <mat-hint class="hint">Outside: For consultations and reception.</mat-hint> -->
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any special requirements</mat-label>
                    <input matInput type="text" formControlName="specialRequirements"
                      placeholder="e.g. 100m after building ruins">
                    <!-- <mat-hint class="hint">Example: 500 meters North of water reservoir.</mat-hint> -->
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any venue restrictions</mat-label>
                    <input matInput type="text" formControlName="venueRestrictions" placeholder="e.g. no power">
                    <!-- <mat-hint class="hint">Example: Venue only accessable with field
                                            vechiles.</mat-hint> -->
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any drop off facility details</mat-label>
                    <input matInput type="text" formControlName="dropOffFacilityDetails" required
                      placeholder="e.g. Parking area">
                    <mat-error
                      *ngIf="eventFormMain.get('dropOffFacilityDetails').invalid && eventFormMain.get('dropOffFacilityDetails').touched">
                      Drop off facility details is required.
                    </mat-error>
                    <!-- <mat-hint class="hint">Example: Parking lot.</mat-hint> -->
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #provincialCoordinatorPanel (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      EHW provincial coordinator details</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter provincial coordinator's ID</mat-label>
                    <input matInput type="number" formControlName="provincialCoordinatorID" (input)="onInput($event)">
                    <mat-error
                      *ngIf="eventFormMain.get('provincialCoordinatorID').errors?.invalidSouthAfricanIdNumber">ID
                      is invalid.</mat-error>
                    <mat-error *ngIf="eventFormMain.get('provincialCoordinatorID').errors?.invalidIdLength">ID
                      is
                      invalid.</mat-error>
                    <!-- <mat-hint class="hint">Only valid South African ID's are accepted.</mat-hint> -->
                  </mat-form-field>
                </div>
                <div formGroupName="provincialCoordinator">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter provincial coordinator's first name</mat-label>
                      <input matInput type="text" formControlName="provincialCoordinatorFirstName" required
                        placeholder="e.g. John">
                      <mat-error
                        *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorFirstName').invalid && eventFormMain.get('provincialCoordinator.provincialCoordinatorFirstName').touched">
                        Provincial coordinator's first name is required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter provincial coordinator's surname</mat-label>
                      <input matInput type="text" formControlName="provincialCoordinatorSurname" required
                        placeholder="e.g. Khumalo">
                      <mat-error
                        *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorSurname').invalid && eventFormMain.get('provincialCoordinator.provincialCoordinatorSurname').touched">
                        Provincial coordinator's surname is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div formGroupName="provincialCoordinatorRank">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Please select a rank</mat-label>
                        <mat-select required formControlName="provincialCoordinatorRankID">
                          <mat-option value="" disabled selected>e.g. Colonel</mat-option>
                          <mat-option *ngFor="let rank of ranks" [value]="rank.id">{{rank.description}}</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankID').invalid && eventFormMain.get('provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankID').touched">
                          Provincial Coordinator's rank is required.
                        </mat-error>

                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-group" style="display: none">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter provincial coordinator's telephone</mat-label>
                      <input matInput (input)="onInput($event)" formControlName="provincialCoordinatorTelephone">
                      <mat-error
                        *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorTelephone').errors?.invalidCellphone">Telephone
                        number is invalid.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter provincial coordinator's contact number</mat-label>
                      <input matInput formControlName="provincialCoordinatorCellphone" required>
                      <mat-error
                        *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorCellphone').errors?.required && eventFormMain.get('provincialCoordinator.provincialCoordinatorCellphone').touched">
                        Provincial coordinator's contact number is required.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorCellphone').errors?.invalidCellphone">Contact
                        number is invalid.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter provincial coordinator's email address</mat-label>
                      <input matInput type="text" formControlName="provincialCoordinatorEmail" required
                        placeholder="e.g. example@gmail.com">
                      <mat-error
                        *ngIf="(eventFormMain.get('provincialCoordinator.provincialCoordinatorEmail')).errors?.required">
                        Provincial coordinator's email address is required.
                      </mat-error>
                      <mat-error
                        *ngIf="(eventFormMain.get('provincialCoordinator.provincialCoordinatorEmail')).errors?.email">
                        Please enter valid email.
                      </mat-error>
                      <!-- <mat-hint class="hint">Only valid email addresses are accepted.</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group">

                    <mat-label>Provincial coordinator signature *</mat-label><br />
                    <mat-checkbox required formControlName="provincialCoordinatorSignature">Approval
                      given on
                      paper
                      form</mat-checkbox>
                    <mat-error
                      *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorSignature').invalid && (eventFormMain.get('provincialCoordinator.provincialCoordinatorSignature').touched || eventFormMain.get('provincialCoordinator.provincialCoordinatorSignature').untouched)">
                      Provincial coordinator's approval is required.
                    </mat-error><br />
                    <mat-label *ngIf="eventFormMain.get('provincialCoordinator.provincialCoordinatorSignature').value"
                      style="color:red"><strong>IMPORTANT!</strong>&nbsp;Keep hardcopy of event
                      form.</mat-label>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #siteCoordinatorPanel (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Site coordinator details</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div formGroupName="siteCoordinator">
                  <div class="form-group">
                    <!-- <mat-form-field appearance="legacy"
                                            class="whitneyFontClass motion-form-field unsetMarginPadding">
                                            <mat-label>Please enter site coordinator's full name</mat-label>
                                            <input matInput type="text" formControlName="siteCoordinatorFullName"
                                                (change)="onSiteCoordinatorEntry()" placeholder="e.g Thomas Johnsons">
                                            <mat-error
                                                *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorFullName').errors?.required">
                                                Site coordinator's full name is required.
                                            </mat-error>
                                        </mat-form-field> -->
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter site coordinator's first name</mat-label>
                      <input matInput type="text" formControlName="siteCoordinatorFirstName"
                        (input)="onSiteCoordinatorEntry()" placeholder="e.g Thomas"
                        required="{{siteCoordinatorValidation}}">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorFirstName').errors?.required">
                        Site coordinator's first name is required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter site coordinator's surname</mat-label>
                      <input matInput type="text" formControlName="siteCoordinatorSurname"
                        (input)="onSiteCoordinatorEntry()" placeholder="e.g Johnsons"
                        required="{{siteCoordinatorValidation}}">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorSurname').errors?.required">
                        Site coordinator's surname is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div formGroupName="siteCoordinatorRank">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Please select a rank</mat-label>
                        <mat-select formControlName="siteCoordinatorRankID" required="{{siteCoordinatorValidation}}"
                          (selectionChange)="onSiteCoordinatorEntry()">
                          <mat-option value="" disabled selected>e.g. Captain</mat-option>
                          <mat-option *ngFor="let rank of ranks" [value]="rank.id">{{rank.description}}</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID').errors?.required && eventFormMain.get('siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID').value == null">
                          Site coordinator's rank is required.
                        </mat-error>
                        <!-- <mat-hint class="hint">Public service is available in
                                                    dropdown.</mat-hint> -->
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-group" style="display: none">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter site coordinator's telephone</mat-label>
                      <input matInput (input)="onInput($event)" formControlName="siteCoordinatorTelephone">
                      <mat-error
                        *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorTelephone').errors?.invalidCellphone">Telephone
                        number is invalid.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter site coordinator's contact number</mat-label>
                      <input matInput formControlName="siteCoordinatorCellphone" (input)="onSiteCoordinatorEntry()"
                        required="{{siteCoordinatorValidation}}">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').errors?.required">
                        Site coordinator's contact number is required.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').errors?.invalidCellphone">Contact
                        number is invalid.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter site coordinator's email address</mat-label>
                      <input matInput type="text" formControlName="siteCoordinatorEmail"
                        (input)="onSiteCoordinatorEntry()" required="{{siteCoordinatorValidation}}"
                        email="{{siteCoordinatorValidation}}" placeholder="e.g. example@gmail.com">
                      <mat-error *ngIf="(eventFormMain.get('siteCoordinator.siteCoordinatorEmail')).errors?.required">
                        Site coordinator's email address is required.
                      </mat-error>
                      <mat-error *ngIf="(eventFormMain.get('siteCoordinator.siteCoordinatorEmail')).errors?.email">
                        Please enter valid email.
                      </mat-error>
                      <!-- <mat-hint class="hint">Only valid email addresses are accepted.</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <button
                    *ngIf="
                                        (eventFormMain.get('siteCoordinator.siteCoordinatorFirstName').value !== null || eventFormMain.get('siteCoordinator.siteCoordinatorFirstName').invalid) || (eventFormMain.get('siteCoordinator.siteCoordinatorSurname').value !== null || eventFormMain.get('siteCoordinator.siteCoordinatorSurname').invalid) ||
                                        (eventFormMain.get('siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID').value !== null || eventFormMain.get('siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID').invalid) ||
                                        (eventFormMain.get('siteCoordinator.siteCoordinatorTelephone').value !== null || eventFormMain.get('siteCoordinator.siteCoordinatorTelephone').invalid) ||
                                        (eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').value !== null || eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').invalid) ||
                                        (eventFormMain.get('siteCoordinator.siteCoordinatorEmail').value !== null || eventFormMain.get('siteCoordinator.siteCoordinatorEmail').invalid)"
                    mat-raised-button class="button clear-button" (click)="clearSiteCoordinatorEntry()">
                    Clear site coordinator entry
                  </button>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #activityPanel *ngIf="eventFormMain.get('id').value !== null"
                (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Booking activity</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <app-event-activity [eventId]="eventFormMain.get('id').value"></app-event-activity>
              </mat-expansion-panel>
              <button *ngIf="cancellable" type="button" class="button cancelButton" (click)="cancelBooking()"
                [disabled]="this.eventFormMain.dirty">
                <div>Cancel booking</div>
              </button>

              <button mat-raised-button type="button" (click)="backToCalendarAttempt()">Back</button>
              <button *ngIf="!editMode" mat-raised-button class="button" type="submit">Submit</button>
              <button *ngIf="editMode" mat-raised-button class="button" type="submit">Save</button>
              <mat-error *ngIf="invalidForm">Invalid form submitted. Please fill in all required fields.</mat-error>
            </form>
          </mat-accordion>
        </div>
      </div>
    </article>
  </div>
</div>