import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { QuestionnaireObject } from '../../../shared/interfaces/questionnaireMapper';
import { EXAMINATIONRESULT as EXAMINATIONRESULT } from '../../../shared/enums';
import { ResponseItemObject, ResponseObject, TestPerformedObject } from '../../../shared/interfaces/responseMapper';
import { ConsultationObject, TestRequestedObject, ConsultationTestPerformedObject } from '../../../shared/interfaces/consultationMapper';
import { VaccinationConsentSummaryMapper as VaccinationSummaryMapper, VaccinationTestObject } from '../../../shared/interfaces/vaccinationMapper';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { ArrayFunctions } from '../../../shared/array-functions';
import { SignaturePad } from 'angular2-signaturepad';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignatureFieldComponent } from 'src/app/shared/components/signature-field/signature-field.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vaccines',
  templateUrl: './vaccines.component.html',
  styleUrls: ['./vaccines.component.scss']
})
export class VaccinesComponent implements OnInit {
  public form: FormGroup;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;

  @ViewChildren('sig1border') public sig1border = 'transparent';
  @ViewChildren('sig2border') public sig2border = 'transparent';

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    console.log('Resizing signature pad canvas to suit container size');
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    if (sig && sig.signaturePad) {
      sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
      sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
    }
  }

  public setOptions() { 
    if (this.sigs && this.sigs.first && this.sigs.first.signaturePad) {
      this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    }
  }

  public clear() {
    this.sigs.first.clear();
    this.sigs.last.clear();
  }

  //@ViewChild(SignaturePad) signaturePad: SignaturePad;
  signatureImg: string;
  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 200,
    canvasHeight: 130
  };

  consentList = [
    { name: 'consent', id: true },
    { name: 'refuse consent', id: false }
  ];

  summaryMapper: VaccinationSummaryMapper = {
    examinationStatus: undefined,
    consentForm: {
      vaccinations: {},

      omp_DateSignature: undefined,
      omp_Signature: undefined,

      recipient_DateSignature: undefined,
      recipient_FullName: undefined,
      recipient_IDNumber: undefined,
      recipient_Signature: undefined,

      check_Indemnify: undefined,
      check_Understands: undefined,
      check_Responsible: undefined,
      check_Allergies: undefined,

      consent_Given: undefined
    },
    vaccinations: []
  };

  isReview: boolean = false;
  isLoading = true;
  showConsent = false;
  amount: number;
  consultationId: string;
  formConsent: FormGroup;


  questionnaires: QuestionnaireObject[] = [];
  responseObject: ResponseObject;
  responseItem: ResponseItemObject[] = [];
  consultation: ConsultationObject;
  testPerformed: ConsultationTestPerformedObject;
  testsRequested: TestRequestedObject[];
  questionnaireId = "6A8FED92-BBE8-4DF1-AF3B-8FB4B35C5212";
  showError = false;
  showQuestionnaire = false;
  examinationOptions = [
    { id: 0, name: 'Done' },
    { id: 1, name: 'Referred to Lab' },
    { id: 2, name: 'Refused' }
  ];

  consentAgreed = false;
  consentDirty: boolean = false;


  constructor(fb: FormBuilder, 
  private questionnaireService: QuestionnaireService, 
  private router: Router, 
  private globalData: GlobalDataService, 
  private consultationService: ConsultationService,
  private snackBar: MatSnackBar) {
    //this.globalData.updateBackRoute('/oh-consultation-summary');
    this.form = fb.group({
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required]
    });

  }


  public ngAfterViewInit() {
    this.beResponsive();
    this.setOptions();
  }

  ngOnInit(): void {
    this.globalData.selectedClientVal.subscribe(result => {
      this.consultationId = result;
      //this.consultationId = "9bcef4c6-9623-4c44-870b-b30b9c480d75";//result || "6A8FED92-BBE8-4DF1-AF3B-8FB4B35C5212";
      this.consultationService.getConsultationDetail(this.consultationId).subscribe(result => {
        this.consultation = result as ConsultationObject;
        if (result) {

          this.summaryMapper.consentForm.recipient_FullName = this.consultation.patientDetail.fullNames || '';
          this.summaryMapper.consentForm.recipient_IDNumber = this.consultation.patientDetail.rsaidnumber;

          let tests: Array<TestRequestedObject> = this.consultation.testsRequested.filter(e => e.testMapping?.groupName.toLowerCase().indexOf("vaccination") > -1);

          this.testsRequested = tests;

          this.isReview = ArrayFunctions.any<TestRequestedObject>((obj) => obj.testsPerformed.length > 0, this.testsRequested);
          this.consentDirty = !this.isReview;
          this.summaryMapper.consentForm.consent_Given = this.isReview;
          this.showQuestionnaire = this.isReview;
          this.showConsent = !this.isReview;

          /* console.log(tests) */;

          for (let i = 0; i < tests.length; i++) {
            let test: TestRequestedObject = tests[i];
            let vaccination: VaccinationTestObject =
            this.isReview ? ({
              examinationStatus: undefined,
              consent: ArrayFunctions.any<ConsultationTestPerformedObject>((obj) => obj.resultValue == EXAMINATIONRESULT.TESTREFUSED, test.testsPerformed) ? false : true,
              originalConsent: ArrayFunctions.any<ConsultationTestPerformedObject>((obj) => obj.resultValue == EXAMINATIONRESULT.TESTREFUSED, test.testsPerformed) ? false : true,
              notes: undefined,
              testRequested: test,
              testsPerformed: test.testsPerformed,
              questionnaire: null,
              submitted: false,
              responseObject: {
                reviewId: ArrayFunctions.first<ConsultationTestPerformedObject>((tp) => tp.reviewId ? true : false, test.testsPerformed)?.reviewId,
                reviewDate: new Date(),
                questionnaireId: test.testMapping.questionnaireId,
                responseItems: []
              }
            }) : ({
              examinationStatus: undefined,
              consent: undefined,
              originalConsent: undefined,
              notes: undefined,
              testRequested: test,
              testsPerformed: [],
              questionnaire: null,
              submitted: false,
              responseObject: {
                reviewId: null,
                reviewDate: new Date(),
                questionnaireId: test.testMapping.questionnaireId,
                responseItems: []
              }
            });
            this.summaryMapper.vaccinations.push(vaccination);
          }
        }
      },
        error => console.log("Ping"),
        () => this.isLoading = false);
    });
  }

  makeAMess(event): void {
    if (this.isReview) {
      this.consentDirty = true;
      this.consentAgreed = this.showQuestionnaire = this.summaryMapper.consentForm.consent_Given = false;
    }
  }

  updateConsent(type, data): void {
    if (data.isUserInput) {
      let
        refusal = !data.source.value.toLowerCase().includes("refuse"),
        property = type + "_Consent";
      this.summaryMapper[property] = refusal;
    }
  }

  onInputChange($event): void {
    this.amount = $event.value;
  }

  back(): void {
    var route: string = null;

    this.globalData.backRouteVal.subscribe(r => route = r);

    if (route) {
      let reroute = route;
      this.globalData.updateBackRoute();
      this.router.navigate([reroute]);
    }
  }

  examinationSelection(event): void {
    /* console.log(event) */;
    this.summaryMapper.examinationStatus = event.value;
    this.showConsent = event.value == "Done";
    this.showQuestionnaire = event.value == 'Done';
  }

  submitConsent(formConsent): void {
    this.sig1border = "transparent";
    this.sig2border = "transparent";

    Object.keys(formConsent.form.controls).forEach(key => {
        formConsent.form.controls[key].markAsTouched();
    });
    formConsent.form.updateValueAndValidity();

    if (formConsent.valid
     && !this.sigs.first.signaturePad.isEmpty() 
     && !this.sigs.last.signaturePad.isEmpty()) {
      this.summaryMapper.consentForm.consent_Given = true;
      this.summaryMapper.vaccinations.forEach(v => this.summaryMapper.consentForm.vaccinations[v.testRequested.testMapping.workflowName] = v.consent);

      this.summaryMapper.consentForm.recipient_DateSignature = this.summaryMapper.consentForm.omp_DateSignature = new Date();
      this.summaryMapper.consentForm.recipient_Signature = this.sigs.first.signaturePad.toDataURL();
      this.summaryMapper.consentForm.omp_Signature = this.sigs.last.signaturePad.toDataURL();
      this.showError = false;
      this.isLoading = true;
      this.consultationService.postVacConsent(this.summaryMapper.consentForm).subscribe((result) => {
        this.downloadPdf(result.fileData, result.fileName);
        this.consentDirty = this.isLoading = false;
        this.consentAgreed = this.showQuestionnaire = this.summaryMapper.consentForm.consent_Given = true;
      });
    } else {
      this.showError = true;

      if (this.sigs.first.signaturePad.isEmpty()) {
        this.sig1border = "red";
      }

      if (this.sigs.last.signaturePad.isEmpty()) {
        this.sig2border = "red";
      }
    }
  }

  downloadPdf(base64String, fileName): void {
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  onSaved(tp: any, vaccination: VaccinationTestObject): void {
    vaccination.consent = vaccination.originalConsent = tp.resultValue != EXAMINATIONRESULT.TESTREFUSED;
    vaccination.submitted = true;
    let options: MatSnackBarConfig = {
      duration: 2500
    };
    let snackBarRef = this.snackBar.open('Submitted.', null, options);
    snackBarRef.afterDismissed().subscribe(() => {
      if (ArrayFunctions.all(v => v.submitted, this.summaryMapper.vaccinations)) { 
        this.isLoading = true;
        this.router.navigate(['/oh-consultation-summary']); 
      }
    });
  }
}
