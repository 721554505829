import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseObject } from 'src/app/shared/interfaces/responseMapper';

import { QuestionnaireSummary } from '../../shared/interfaces/questionnaireMapper';
import { AdhocService } from '../../shared/services/adhoc.service';
import { GlobalDataService } from '../../shared/services/global-data.service';
import { QuestionnaireService } from '../../shared/services/questionnaire.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  isLoading = false;
  showError = false;

  eventId: string = null;
  questionnaireId: string = '287DCF35-7B57-451F-A958-6138ADBF01F6'; //Req

  constructor(private globalData: GlobalDataService, private adhoc: AdhocService, private activatedRoute: ActivatedRoute, private router: Router) {
    /* console.log('constructor') */;
    globalData.updateBackRoute(null);
    activatedRoute.paramMap.subscribe((params) => {
      this.eventId = params.get('id');
      let questionnaireId = params.get('questionnaireId');
      if (questionnaireId) {
        this.questionnaireId = questionnaireId;
      }
    });
  }

  onSaved(): void {
    this.router.navigate(['./survey-completed']);
  }

  ngOnInit(): void {
    /* console.log('OnInit') */;
    this.isLoading = true;
    if (this.eventId) {
      this.adhoc.getWCG(this.eventId).subscribe(r => {
        if (r) {
          this.router.navigate(['./survey-completed']);
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
    }
  }

}
