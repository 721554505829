<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <h5>Counselling intake assessment</h5>

    <div class="grid__inner grid__inner--layout-b8b" *ngIf="isLoading === false">
      <div class="grid__cell grid__cell--align-center grid__cell--span-all">
        <app-q [consultationId]="consultationId" [showTitle]="false" [questionnaireId]="questionnaireId"
          [testRequested]="testRequested" [consultationSelection]="consultationSelection" [showBackButton]="false"
          [allowSubmit]="true" [forcedExaminationStatus]="0" (onSaved)="onSaved()">
        </app-q>
      </div>
    </div>
  </article>
  <div>
