<div class="container">
  <article class="grid">
    <span *ngIf="(!ranksLoaded ||
                            !employmentActsLoaded ||
                            !gendersLoaded ||
                            !medicalAidsLoaded ||
                            !maritalStatussesLoaded ||
                            !languagesLoaded ||
                            !gendersLoaded ||
                            searching ||
                            saving ||
                            consulting)" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="(!ranksLoaded ||
                            !employmentActsLoaded ||
                            !gendersLoaded ||
                            !medicalAidsLoaded ||
                            !maritalStatussesLoaded ||
                            !languagesLoaded ||
                            !gendersLoaded ||
                            searching ||
                            saving ||
                            consulting)"></three-bar-loader>

    <div *ngIf="!(!ranksLoaded ||
                            !employmentActsLoaded ||
                            !gendersLoaded ||
                            !medicalAidsLoaded ||
                            !maritalStatussesLoaded ||
                            !languagesLoaded ||
                            !gendersLoaded ||
                            searching ||
                            saving ||
                            consulting)">
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <mat-accordion>
            <h1 class="mdc-typography--headline2 text--align-center">
              <span *ngIf="foundMember === false;">New member </span>
              <span *ngIf="foundMember == null || foundMember == true;">Member </span>
              <span>registration</span>
            </h1>
            <app-wellness-info-header [mode]="event"></app-wellness-info-header>
            <br />
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="mdc-list-item text--align-left">
                  <strong>Member details</strong>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <form [formGroup]="searchForm" class="search-form" (submit)="search()">
                <div class="grid__inner">
                  <div class="grid__cell grid__cell--span-10-desktop grid__cell--span-4-phone">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Please enter ID number</mat-label>
                        <input matInput autofocus placeholder="e.g. 8608112513087" formControlName="searchId" required
                          (keypress.enter)="search()" pattern="[0-9]*" (keydown)="restrictInput($event)">
                        <mat-error *ngIf="isControlInvalid('searchId')">ID is required</mat-error>
                        <mat-error *ngIf="searchForm.get('searchId').errors?.invalidSouthAfricanIdNumber">ID is
                          invalid.</mat-error>
                        <mat-error *ngIf="searchForm.get('searchId').errors?.invalidIdLength">ID is invalid.</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="grid__cell grid__cell--span-2-desktop grid__cell--span-4-phone">
                    <input type="submit"
                      [ngClass]="{ 'mdc-button--raised': searchForm.valid, 'mdc-ripple-upgraded': searchForm.valid, 'mdc-button__fill--darkblue': searchForm.valid, 'mdc-button': searchForm.valid, 'mat-raised-button': searchForm.invalid }"
                      [disabled]="searchForm.invalid" id="searchButton" value="Search">
                  </div>
                </div>
              </form>
              <hr style="padding-top: 10px;" />
              <form [formGroup]="registrationForm" class="registration-form" *ngIf="registrationData !== null">
                <div class="form-group" style="text-align: right;">
                  <button
                    [ngClass]="{ 'mdc-button--raised': registrationForm.valid && registrationForm.pristine, 'mdc-ripple-upgraded': registrationForm.valid && registrationForm.pristine, 'mdc-button__fill--darkblue': registrationForm.valid && registrationForm.pristine, 'mdc-button': registrationForm.valid && registrationForm.pristine, 'mat-raised-button': registrationForm.invalid || registrationForm.dirty }"
                    style="margin-right: 30px; vertical-align: middle" #consultationButton
                    [disabled]="registrationForm.invalid || registrationForm.dirty" *ngIf="bookingEventId"
                    (click)="createConsultation()">Consultation</button>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter name</mat-label>
                    <input matInput placeholder="e.g. Joe" formControlName="fullNames"
                      [(ngModel)]="registrationData.fullNames" required>
                    <mat-error *ngIf="isControlInvalid('fullNames')">Name is required</mat-error>
                  </mat-form-field>
                </div>


                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter surname</mat-label>
                    <input matInput placeholder="e.g. Smith" formControlName="surname"
                      [(ngModel)]="registrationData.surname" required>
                    <mat-error *ngIf="isControlInvalid('surname')">Surname is required</mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                    style="display:inherit">
                    <mat-label>Please enter persal number</mat-label>
                    <input matInput placeholder="e.g. 12345678" formControlName="persalNumber" required
                      [(ngModel)]="registrationData.persalNumber">
                    <mat-error *ngIf="isControlInvalid('persalNumber')">Persal number is required</mat-error>
                  </mat-form-field>

                </div>

                <div class="form-group">
                  <h4>Gender *</h4>
                  <div class="form-group">
                    <mat-radio-group formControlName="genderId" required [(ngModel)]="registrationData.genderId">
                      <mat-radio-button *ngFor="let gender of genders" [disabled]="true"
                        [value]="gender.id">{{gender.description}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="isControlInvalid('genderId')">Please select gender</mat-error>
                  </div>
                </div>

                <div class="form-group marital-status">
                  <h4>Marital status *</h4>
                  <div>
                    <mat-radio-group [(ngModel)]="registrationData.maritalStatusId" formControlName="maritalStatusId">
                      <mat-radio-button *ngFor="let li of maritalStatuses"
                        [value]="li.id">{{li.description}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="form-group rank">
                  <h4>Rank *</h4>
                  <div>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a rank</mat-label>
                      <mat-select formControlName="rankId" required [(ngModel)]="registrationData.rankId">
                        <mat-option [value]="" disabled selected>Please select a rank</mat-option>
                        <mat-option *ngFor="let li of ranks" [value]="li.id">{{li.description}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="isControlInvalid('rankId')">Please select a rank</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="form-group">
                  <h4>Employment act *</h4>
                  <div class="form-group">
                    <mat-radio-group formControlName="employmentAct" [(ngModel)]="employmentActValue">
                      <mat-radio-button *ngFor="let ea of employmentActs" [value]="ea.id" [disabled]="true"
                        [selected]="ea.id.toLowerCase() == (employmentActValue || '').toLowerCase()">{{ea.description}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="form-group medical-aid">
                  <h4>Medical aid *</h4>
                  <div>
                    <mat-radio-group [(ngModel)]="registrationData.medicalAidId" formControlName="medicalAidId">
                      <mat-radio-button *ngFor="let li of medicalAids | slice:0:4;"
                        [value]="li.id">{{li.description}}</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Select other medical aid</mat-label>
                    <mat-select formControlName="medicalAidId" [(ngModel)]="registrationData.medicalAidId">
                      <mat-option [value]="" disabled selected>Other</mat-option>
                      <mat-option *ngFor="let li of medicalAids | slice:5:21;"
                        [value]="li.id">{{li.description}}</mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="isControlInvalid('medicalAidId')">Please select a medical aid</mat-error> -->
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter a medical aid number</mat-label>
                    <input matInput placeholder="e.g. 40021789" formControlName="medicalAidNumber"
                      [(ngModel)]="registrationData.medicalAidNumber">
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter an email address</mat-label>
                    <input matInput placeholder="e.g. joe.smith@gmai.com" formControlName="email"
                      [(ngModel)]="registrationData.email">
                    <mat-error *ngIf="isControlInvalid('email')">Email address is required</mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter a contact number</mat-label>
                    <input matInput (onInput)="onInput" placeholder="e.g. 012 331 3522" type="tel"
                      formControlName="cellphone" [(ngModel)]="registrationData.cellphone" required>
                    <!-- <mat-error *ngIf="isControlInvalid('cellphone')">Contact number is required</mat-error> -->
                    <mat-error
                      *ngIf="registrationForm.get('cellphone').errors?.required && registrationForm.get('cellphone').touched">
                      Contact number is required.
                    </mat-error>
                    <mat-error *ngIf="registrationForm.get('cellphone').errors?.invalidCellphone">Contact number is
                      invalid.</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please select a language</mat-label>
                    <mat-select formControlName="languageId" required [(ngModel)]="registrationData.languageId">
                      <mat-option [value]="" disabled selected>Please select a language</mat-option>
                      <mat-option *ngFor="let li of languages" [value]="li.id">{{li.description}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="isControlInvalid('languageId')">Please select a language</mat-error>
                  </mat-form-field>
                </div>

                <div id="saveContainer">
                  <button
                    [ngClass]="{ 'mdc-button--raised': registrationForm.valid && registrationForm.dirty, 'mdc-ripple-upgraded': registrationForm.valid && registrationForm.dirty, 'mdc-button__fill--darkblue': registrationForm.valid && registrationForm.dirty, 'mdc-button': registrationForm.valid && registrationForm.dirty, 'mat-raised-button': registrationForm.invalid || registrationForm.pristine }"
                    (click)="save()" #saveButton [disabled]="registrationForm.invalid || registrationForm.pristine"
                    id="saveButton" style="vertical-align: middle;">Save</button>
                </div>
              </form>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </article>
</div>
