<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false">
      <!-- #region Consent-->
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell grid__cell--align-stretch msotion-form-body mb-4 section-spacing--tier-1">
          <form #formConsent=ngForm matNativeControl>
            <span class="blank-line blank-line-2 d-block" *ngIf="isReview || !isReview && !consentAgreed"></span>
            <h4 class="mdc-typography--headline5 text--align-center mb-3"
              *ngIf="isReview || !isReview && !consentAgreed">Vaccination consent</h4>
            <span class="blank-line blank-line-2 d-block" *ngIf="isReview || !isReview && !consentAgreed"></span>
            <p class="mdc-typography--body1" *ngIf="isReview && consentDirty || !isReview && !consentAgreed">
              <span class="unsetMarginPadding" style="display: inline;">I,&nbsp;</span>
              <span class="unsetMarginPadding nameWidth" style="display: inline-block;">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                  hideRequiredMarker>
                  <input matInput name="name" ngModel #inputControl="ngModel" placeholder="name and surname" type="text"
                    required maxlength="255" [(ngModel)]="summaryMapper.consentForm.recipient_FullName">
                </mat-form-field>
              </span>
              <span class="unsetMarginPadding" style="display: inline;">,&nbsp;ID Number,&nbsp;</span>
              <span class="unsetMarginPadding idWidth" style="display: inline-block">
                <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                  hideRequiredMarker>
                  <input matInput name="idnumber" ngModel #inputControl="ngModel" placeholder="ID Number"
                    pattern="\d{1,13}" type="text" required maxlength="13"
                    [(ngModel)]="summaryMapper.consentForm.recipient_IDNumber">
                </mat-form-field>
              </span>
              <span class="unsetMarginPadding" style="display: inline;">&nbsp;hereby CONSENT to the administration of
                the vaccine/s indicated below by intramuscular injection:</span>
            </p>
            <div class="mdc-list-group grid__cell" *ngIf="isReview || !isReview && !consentAgreed">
              <h3 class="mdc-list-group__subheader">Vaccinations</h3>
              <div class="mdc-list mdc-list--interactive" aria-orientation="vertical">
                <a class="mdc-list-item" href="javascript:void(0)"
                  *ngFor="let vac of summaryMapper.vaccinations; let qindex = index">
                  <span class="mdc-list-item__text">{{vac.testRequested.testMapping.workflowName}}</span>
                  <span class="mdc-list-item__meta" aria-hidden="true">
                    <mat-form-field appearance="standard" class="whitneyFontClass motion-form-field unsetMarginPadding"
                      hideRequiredMarker>
                      <mat-label>Select Consent</mat-label>
                      <mat-select ngModel #dropdownControl="ngModel" [(ngModel)]="vac.consent" required
                        [disabled]="vac.originalConsent === true"
                        name="consent_{{vac.testRequested.testMapping.questionnaireId}}"
                        (selectionChange)="makeAMess($event)">
                        <mat-option *ngFor="let consent of consentList" [value]="consent.id">
                          {{consent.name.toUpperCase()}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </a>
              </div>
            </div>
            <div class="mdc-list" style="font-size: 0.775rem !important" *ngIf="!consentAgreed && consentDirty">
              <div class="mdc-list-item">
                <mat-checkbox ngModel #toggleControl="ngModel" [(ngModel)]="summaryMapper.consentForm.check_Allergies"
                  name="allergies" [checked]="summaryMapper.consentForm.check_Allergies" labelPosition="after" required
                  color="primary">
                  I do not have any known allergies or known reactions to the substance being administered.
                </mat-checkbox>
              </div>
              <div class="mdc-list-item">
                <mat-checkbox ngModel #toggleControl="ngModel" [(ngModel)]="summaryMapper.consentForm.check_Understands"
                  name="understands" [checked]="summaryMapper.consentForm.check_Understands" labelPosition="after"
                  required color="primary">
                  The immunisation schedule and possible side effects of these vaccinations have been explained to me.
                </mat-checkbox>
              </div>
              <div class="mdc-list-item">
                <mat-checkbox ngModel #toggleControl="ngModel" [(ngModel)]="summaryMapper.consentForm.check_Responsible"
                  name="responsible" [checked]="summaryMapper.consentForm.check_Responsible" labelPosition="after"
                  required color="primary">
                  I am responsible for booking my follow up vaccination as explained by the Nurse.
                </mat-checkbox>
              </div>
              <div class="mdc-list-item">
                <mat-checkbox ngModel #toggleControl="ngModel" [(ngModel)]="summaryMapper.consentForm.check_Indemnify"
                  name="indemnify" [checked]="summaryMapper.consentForm.check_Indemnify" labelPosition="after" required
                  color="primary">
                  I indemnify and hold Momentum Wellness harmless against any claim which I or any other person (natural
                  or juristic) may now or in the future, have against them arising out of the vaccination or side
                  effects I may suffer as a result thereof.
                </mat-checkbox>
              </div>
            </div>
            <span class="blank-line blank-line-2 d-block"></span>
            <section style="display: flex; flex-direction: row; justify-content: space-between"
              *ngIf="!consentAgreed && consentDirty">
              <div>
                <div [formGroup]="form">
                  <div class="sig-container" [ngStyle]="{'border-color': sig1border}" #sigContainer1>
                    <div fxLayout="row">
                      <button class="mat-mini-fab" (click)="sigs.first.clear()"
                        [disabled]="!form.controls.signatureField1.valid">
                        <span class="mat-button-wrapper">
                          <i class="material-icons" aria-label="Example icon-button with a delete icon" role="img"
                            aria-hidden="true">clear</i>
                        </span>
                      </button>
                    </div>
                    <div fxLayout="row">
                      <signature-field formControlName="signatureField1"></signature-field>
                    </div>
                  </div>
                </div>
                <mat-label>Signature of client</mat-label>
              </div>
              <div [formGroup]="form">
                <div class="sig-container" [ngStyle]="{'border-color': sig2border}" #sigContainer2>
                  <div fxLayout="row">
                    <button class="mat-mini-fab" (click)="sigs.last.clear()"
                      [disabled]="!form.controls.signatureField2.valid">
                      <span class="mat-button-wrapper">
                        <i class="material-icons" aria-label="Example icon-button with a delete icon" role="img"
                          aria-hidden="true">clear</i>
                      </span>
                    </button>
                  </div>
                  <div fxLayout="row">
                    <signature-field formControlName="signatureField2"></signature-field>
                  </div>
                </div>
                <mat-label>Signature of OMP</mat-label>
              </div>
            </section>
            <!--<div class="ui-grid-c signatures mdc-typography--body2" [formGroup]="form" *ngIf="!consentAgreed && consentDirty">
            <div class="ui-block-a">
              <div class="ui-bar">
                <div class="table fill signatureTable">
                  <div class="row">
                    <div class="cell" style="position: relative;">
                      <div class="sig-container cofSignature" [ngStyle]="{'border-color': sig1border}" #sigContainer1>
                        <div fxLayout="row">
                          <button type="button" (click)="sigs.first.clear()" [disabled]="!form.controls.signatureField1.valid">Clear</button>
                        </div>
                        <div fxLayout="row">
                          <signature-field formControlName="signatureField1"></signature-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="cell">
                      Signature of Client
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-block-b">
              <div class="ui-bar">
                <div class="table fill signatureTable">
                  <div class="row">
                    <div class="cell" style="position: relative;">
                      <div class="sig-container cofSignature" [ngStyle]="{'border-color': sig2border}" #sigContainer2>
                        <div fxLayout="row">
                          <button type="button" (click)="sigs.last.clear()" [disabled]="!form.controls.signatureField2.valid">Clear</button>
                        </div>
                        <div fxLayout="row">
                          <signature-field formControlName="signatureField2"></signature-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="cell">
                      Signature OMP
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
            <div class="grid__cell grid__cell--align-center grid__cell--span-all">
              <div class="mdc-btn-container">
                <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
                  *ngIf="!consentAgreed || consentDirty" (click)="submitConsent(formConsent)">Submit</button>
              </div>
              <div class="mdc-btn-container">
                <mat-error class="text--align-center" *ngIf="showError">Please complete all required</mat-error>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region Questionnaires -->
      <div class="grid__inner grid__inner--layout-b8b" *ngIf="summaryMapper.consentForm.consent_Given">
        <div class="grid__cell grid__cell--align-stretch mb-4 section-spacing--tier-1">
          <form #questionForm="ngForm" matNativeControl>
            <div class="text--align-center" *ngFor="let vac of summaryMapper.vaccinations; let qindex = index">
              <hr *ngIf="qindex > 0" />
              <span *ngIf="qindex > 0" class="blank-line blank-line-2 d-block"></span>
              <ng-container *ngIf="!vac.submitted">
                <h3 class="mdc-typography--headline5 text--align-center mb-3" style="font-size: 1.3vw;">
                  {{vac.testRequested.testMapping.workflowName}}</h3>
                <div class="grid__cell grid__cell--span-all">
                  <ng-container>
                    <app-questionnaire [consultationId]="consultationId"
                      [questionnaireId]="vac.responseObject.questionnaireId" [testRequested]="vac.testRequested"
                      [forcedExaminationStatus]="vac.consent ? -1 : 8"
                      [consultationSelection]="isReview ? 'Review' : 'Questionnaire'" [showBackButton]="false"
                      (onSaved)="onSaved($event, vac)" [reviewId]="vac.responseObject.reviewId">
                    </app-questionnaire>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region Navigation -->
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-2 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="back()">Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- #endregion -->
    </div>
  </article>
</div>
