<div class="container">
  <article class="grid mdc-typography mdc-theme--background">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>

  <div *ngIf="isLoading === false">
    <div class="grid__inner grid__inner--layout-12 text--align-center">
      <div class="grid__cell">
        <h2 class="mdc-typography mdc-typography--headline3">{{accName}} Provider portal</h2>
        <span class="blank-line blank-line-2 d-block"></span>
      </div>
    </div>
    <div class="grid__inner grid__inner--layout-3333-44 text--align-center">
      <div class="grid__cell">
        <div class="mdc-card ">
          <div class="mdc-card__media mdc-card__media--2-1 img_profile"></div>
          <div class="mdc-card__content">
            <div class="mdc-typography--headline5">
              <h3 class="mdc-typography--headline5">Access management</h3>
            </div>
            <p class="mdc-typography--overline text--align-center">Perigon account management<br><br></p>
            <p></p>
            <div class="mdc-btn-container mdc-btn-container--column-stack">
              <a [routerLink]="['/sp-access-management']"
                class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                aria-label="Click here to open">
                <span class="mdc-button__text">Click here to open</span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="!userManagementAccess" class="grayscale"></div>
      </div>
      <div class="grid__cell" style="position: relative">
        <div class="mdc-card ">
          <div class="mdc-card__media mdc-card__media--2-1 img_filereview"></div>
          <div class="mdc-card__content">
            <div class="mdc-typography--headline5">
              <h3 class="mdc-typography--headline5">Service management</h3>
            </div>
            <p class="mdc-typography--overline text--align-center">Service delivery<br><br></p>
            <p></p>
            <div class="mdc-btn-container mdc-btn-container--column-stack"><a
                class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                aria-label="Click here to open"><span class="mdc-button__text">Click here to
                  open</span></a></div>
          </div>
        </div>
        <div class="grayscale"></div>
        <!-- <div *ngIf="!reportAccess" class="grayscale"></div> -->
      </div>
      <div class="grid__cell" style="position: relative">
        <div class="mdc-card ">
          <div class="mdc-card__media mdc-card__media--2-1 img_appointment"></div>
          <div class="mdc-card__content">
            <div class="mdc-typography--headline5">
              <h3 class="mdc-typography--headline5">Finance management</h3>
            </div>
            <p class="mdc-typography--overline text--align-center">Invoicing and payment history<br><br></p>
            <p></p>
            <div class="mdc-btn-container mdc-btn-container--column-stack"><a
                class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                aria-label="Click here to open"><span class="mdc-button__text">Click here to
                  open</span></a></div>
          </div>
        </div>
        <div class="grayscale"></div>
        <!-- <div *ngIf="!reportAccess" class="grayscale"></div> -->
      </div>
      <!-- <div class="grid__cell">
        <div class="mdc-card ">
          <div class="mdc-card__media mdc-card__media--2-1 img_client"></div>
          <div class="mdc-card__content">
            <div class="mdc-typography--headline5">
              <h3 class="mdc-typography--headline5">Execution</h3>
            </div>
            <p class="mdc-typography--overline text--align-center">Member registration and consultation<br><br></p>
            <p></p>
            <div class="mdc-btn-container mdc-btn-container--column-stack">
              <a [routerLink]="['/wellness-event-booking']" [state]="{mode:'manage'}"
                class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded"
                aria-label="Click here to open"><span class="mdc-button__text">Click here to open</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="grid__cell" style="position: relative">
        <div class="mdc-card ">
          <div class="mdc-card__media mdc-card__media--2-1 img_filereview"></div>
          <div class="mdc-card__content">
            <div class="mdc-typography--headline5">
              <h3 class="mdc-typography--headline5">Verification</h3>
            </div>
            <p class="mdc-typography--overline text--align-center">Quality assurance and completion<br><br></p>
            <p></p>
            <p></p>
            <div class="mdc-btn-container mdc-btn-container--column-stack">
              <a class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                aria-label="Click here to open">
                <span class="mdc-button__text">Click here to open</span>
              </a>
            </div>
          </div>
        </div>
        <div class='grayscale'></div>
      </div>
      <div class="grid__cell" style="position: relative">
        <div class="mdc-card ">
          <div class="mdc-card__media mdc-card__media--2-1 img_reports"></div>
          <div class="mdc-card__content">
            <div class="mdc-typography--headline5">
              <h3 class="mdc-typography--headline5">Reports</h3>
            </div>
            <p class="mdc-typography--overline text--align-center">Event reporting<br><br></p>
            <p></p>
            <div class="mdc-btn-container mdc-btn-container--column-stack"><a
                class="mdc-button mdc-button--raised motion-button--medium mdc-ripple-upgraded" target="_self"
                aria-label="Click here to open"><span class="mdc-button__text">Click here to
                  open</span></a></div>
          </div>
        </div>
        <div class='grayscale'></div>
      </div> -->
    </div>
  </div>
</article>