import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passportNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const passportNo: string = control.value;
    if (!passportNo) {
      return null; // Return null if the control value is empty
    }
    const passportNumberRegex = /^[A-Z]{2}\d{7}$/;
    const isValid = passportNumberRegex.test(passportNo);
    return isValid ? null : { 'invalidPassportNumber': true };
  };
}
