<article class="grid">
  <section class="grid__inner grid__inner--layout-12">
    <div class="grid__cell text--align-left note__border">
      <h6 class="mdc-typography--subtitle2">
        <div style="float: left;">{{note.dateTime | date: "dd MMMM yyyy h:mm a"}}</div>
        <div style="float: right;">{{note.user}}</div>
      </h6>
      <p class="mdc-typography--body2">{{note.notes}}</p>
    </div>
  </section>
</article>