export class PatientObject {
  id: string
  OCSAId: string
  workflowID: string
  persalNumber: string
  rankId: string
  companyMappingID: string
  employeeNumber: string
  employeeType: string
  RSAIdNumber: string
  rsaidnumber: string
  surname: string
  fullNames: string
  initials: string
  hierarchyLvl1: string
  hierarchyLvl2: string
  hierarchyLvl3: string
  hierarchyLvl4: string
  hierarchyLvl5: string
  employeeCategory: string
  race: string
  gender: string
  genderId: string
  createDate: Date
  createName: string
  changeDate: Date
  changeName: string
  active: boolean
  jobTitle: string
  dateOfBirth: Date
  medicalAidId: string
  get age() {
    return new Date().getFullYear() - this.dateOfBirth.getFullYear()
  }
}

export class WellnessPatientRegistrationModel {
  id: string | null
  fullNames: string | null
  surname: string | null
  persalNumber: string | null
  genderId: string | null
  rankId: string | null
  idNo: string | null
  languageId: string | null
  medicalAidId: string | null
  medicalAidNumber: string | null
  email: string | null
  cellphone: string | null
  maritalStatusId: string | null
  passportNo: string | null
  dob: Date | null
  countryId: string | null
}

export class AdhocWellnessPatientRegistrationModel {
  id: string | null
  fullNames: string | null
  surname: string | null
  employeeNumber: string | null
  genderId: string | null
  idNo: string | null
  languageId: string | null
  medicalAidId: string | null
  medicalAidNumber: string | null
  email: string | null
  cellphone: string | null
  maritalStatusId: string | null
}

export class RegisterPatientSearchParameters {
  searchType: "id" | "passport"
  idNumber?: string
  countryId?: string
  dob?: Date
  passport?: string
}

export class RequestBenefitCheck {

  rsaidnumber?: string
  passportNo?: string
  benefitStatus?: string
  benefitMessage?: string
  schemeCode?: string
  memberNumber?: string
  dependentCode?: string
  dateOfBirth?: string
}

export class PatientObjectFactory {
  static default(): PatientObject {
    return {
      id: 'string',
      OCSAId: 'string',
      workflowID: 'string',
      companyMappingID: 'string',
      employeeNumber: 'string',
      employeeType: 'string',
      RSAIdNumber: 'string',
      surname: 'string',
      fullNames: 'string',
      initials: 'string',
      hierarchyLvl1: 'string',
      hierarchyLvl2: 'string',
      hierarchyLvl3: 'string',
      hierarchyLvl4: 'string',
      hierarchyLvl5: 'string',
      employeeCategory: 'string',
      race: 'string',
      gender: 'male',
      createDate: new Date(),
      createName: 'string',
      changeDate: new Date(),
      changeName: 'string',
      active: true,
      jobTitle: 'string',
      dateOfBirth: new Date(),
    } as PatientObject
  }
}
