<span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
<three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
<div mat-dialog-content>
  <article class="grid">
    <div class="grid__inner grid__inner--layout-12">
      <div class="grid__cell">
        <h2 class="mdc-typography--headline3">{{parentData.title}}</h2>
        <form [formGroup]="form">
          <div class="form-group" *ngIf="parentData.eventSearch">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Search for event</mat-label>
              <mat-select formControlName="selectedSearchDropdownID">
                <input matInput type="text" #searchValue (input)="filterSearchDropdownValues(searchValue.value)"
                  autocomplete="off" placeholder="Search for event"
                  style="background-color: rgb(228, 232, 233);font-size:medium;text-align:center;padding-top:1.75%;padding-bottom:1.75%">
                <mat-divider></mat-divider>
                <!-- <mat-option value="" disabled selected>Please select a
                                                Town; Station</mat-option> -->
                <mat-option *ngFor="let value of filteredOptions" [value]="value.id">{{value.description}}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('selectedSearchDropdownID').invalid">
                Cannot go forth without selection.
              </mat-error>
            </mat-form-field>
          </div>
        </form>
        <div class="grid__inner grid__inner--layout-66">
          <div class="grid__cell" id="center">
            <button mat-raised-button class="button" (click)="lButClickAction()">{{parentData.lButTitle}}</button>
          </div>
          <div class="grid__cell" id="center">
            <button mat-raised-button class="button" (click)="rButClickAction()">{{parentData.rButTitle}}</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>