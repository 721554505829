import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WellnessPSAConsentMapper } from 'src/app/shared/interfaces/consentMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';

@Component({
  selector: 'app-corporate-wellness-psa-consent',
  templateUrl: './psa-consent.component.html',
  styleUrls: ['./psa-consent.component.scss']
})
export class CorporateWellnessPSAConsentComponent implements OnInit {
  isLoading: boolean = false;
  consent: boolean = null;
  clientId: string = null;


  constructor(private route: ActivatedRoute, public consultationService: ConsultationService, private router: Router, private globalData: GlobalDataService
  ) {

  }

  consentMapper: WellnessPSAConsentMapper;

  ngOnInit(): void {
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);
    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {
      /* console.log(result) */;
      this.consentMapper = {
        member_FullName: `${(result.patientDetail.fullNames || '')} ${(result.patientDetail.surname || '')}`,
        member_IdNumber: result.patientDetail.rsaidnumber || result.patientDetail.passportNo,
        member_IdNumberLabel: result.patientDetail.rsaidnumber && result.patientDetail.rsaidnumber.length > 10 ? " RSA ID Number" : " Passport Number",
        consultationDetailId: this.clientId,
        member_Signature: undefined,
        member_SignatureDate: undefined,
        examiner_Signature: undefined,
        examiner_SignatureDate: undefined,
        testPerformedId: undefined,
        consent: this.consent
      }
      this.isLoading = false;
    });
  }

  postSubmit(data) {
    this.consultationService.postWellnessPSAConsent(data).subscribe(result => {
      this.router.navigate(["../consultation"], { relativeTo: this.route })
    })
  }

}
