import { GeneralizedObject } from "./generalized-OBJECT";
    
    export class ProvincialCoordinator {
        ID: number; //RSA ID or user ID?
        FULLNAME: string;
        RANK:GeneralizedObject;
        TELEPHONE?: number;
        CELLPHONE: number;
        EMAIL: string;
        SIGNATURE:any;//what type would signature be?
      }