import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'three-bar-loader',
  templateUrl: './three-bar-loader.component.html',
  styleUrls: ['./three-bar-loader.component.styl']
})
export class ThreeBarLoaderComponent implements OnInit {

  constructor() {

    
  }

  ngOnInit() {
  }

}
