
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const cellNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const cellphoneNumber = control.value;
  if (!cellphoneNumber) {
    // Return null if the control value is empty
    return null;
  }

  // Remove any spaces from the cellphone number
  const cleanedCellphoneNumber = cellphoneNumber.replace(/\s/g, '').replace('(', '').replace(')', '').replace('-', '');

  const cellphonePattern = /^(?:\+27|0)[1-8][0-9]{8}$/;
  if (!cellphonePattern.test(cleanedCellphoneNumber)) {
    // Return an error object if the cellphone number is invalid
    return { invalidCellphone: true };
  }

  // Return null if the cellphone number is valid
  return null;
}