import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import * as Enums from '../enums';
import { AdminAssistantUser, ServiceProviderUser } from '../interfaces/service-provider-user';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService) { }

  getServiceProviderUsers(userId: string): Observable<Array<ServiceProviderUser>> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_SERVICE_PROVIDER_USERS + userId, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get users', undefined))
    );
  }

  getAdminAssistants(userId: string): Observable<Array<AdminAssistantUser>> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_ADMIN_ASSISTANT_USERS + userId, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get users', undefined))
    );
  }


  updateHealthPractitioner(userId: string): Observable<ServiceProviderUser> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_SERVICE_PROVIDER_USERS + userId, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get users', undefined))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

  reduceObject(obj): any {

    /* console.log(obj) */;
    return obj;
  }
}
