import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientDetailsService} from '../../../shared/services/client-details.service';
import {ClientDetailsMapper} from '../../../shared/interfaces/ClientDetailsMapper';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {GlobalDataService} from '../../../shared/services/global-data.service';
import {ClientMapper} from '../../../shared/interfaces/InfoBlockMapper';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {
  isLoading = false;
  isFailed = false;

  clientDetails: ClientDetailsMapper[] = [];
  dataSource = new MatTableDataSource<ClientDetailsMapper>(this.clientDetails);

  isCompanyDetails = false;
  inProgress = true;
  displayedColumns: string[] = ['firstname', 'surname', 'idNumber', 'clientNumber', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  csvGroupList: any = [];


  constructor(private patientDetailsService: ClientDetailsService, private router: Router, private globalData: GlobalDataService) { 
    this.globalData.updateBackRoute('/landing');
  }

  ngOnInit(): void {

    this.isLoading = true;
    this.patientDetailsService.getClientDetails().subscribe(result => {

      this.clientDetails = result;

      for (let i = 0; i < this.clientDetails.length; i++) {
        const index = this.clientDetails[i].fullNames.indexOf(',');
        this.clientDetails[i].firstName =  this.clientDetails[i].fullNames.substring(index + 2, this.clientDetails[i].fullNames.length).trim();
      }

      this.dataSource = new MatTableDataSource<ClientDetailsMapper>(this.clientDetails);

      setTimeout(() => this.dataSource.paginator = this.paginator);
      setTimeout(() => this.dataSource.sort = this.sort);
      this.isCompanyDetails = false;

      this.isLoading = false;
    });

  }

  previousPage(): void {
    window.history.back();
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  viewClient(name, surname, idNumber, clientNumber): void {
    try {
      const index = this.clientDetails.findIndex((search => search.rsaidnumber == idNumber));

      this.globalData.updateClientInfo( {
        firstName: this.clientDetails[index].firstName,
        surname: this.clientDetails[index].surname,
        idNumber: this.clientDetails[index].rsaidnumber,
        initials: this.clientDetails[index].initials,
        jobTitle: this.clientDetails[index].jobTitle,
        date: this.clientDetails[index].changeDate
      });
    } finally {
      this.router.navigate(['/client-profile-detail']);
    }
  }

}
