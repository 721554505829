<div class="container">
  <h1 mat-dialog-title class="text--align-center">Case manager</h1>
  <div mat-dialog-content>
    <div class="grid">
      <div class="grid__inner grid__inner--layout-66">
        <div class="grid__cell">Name: </div>
        <div class="grid__cell">E Maseko</div>
        <div class="grid__cell">Phone number: </div>
        <div class="grid__cell">082 555 3256</div>
        <div class="grid__cell">Email address: </div>
        <div class="grid__cell">emseko@gmail.com</div>
      </div>
    </div>
  </div>
  <div class="text--align-center">
    <div mat-dialog-actions>
      <button mat-button (click)="ok()" cdkFocusInitial
        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium">OK</button>
    </div>
  </div>
</div>
