import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../services/global-data.service';

@Component({
  selector: 'app-booking-calendar-button',
  templateUrl: './booking-calendar-button.component.html',
  styleUrls: ['./booking-calendar-button.component.scss']
})
export class BookingCalendarButtonComponent implements OnInit {
  hideCalendarButton: any = true;

  constructor(
    private router: Router,
    private globalData: GlobalDataService) {
    this.globalData.bookingCalendarVisibleVal.subscribe(r => this.hideCalendarButton = !r);
  }

  ngOnInit(): void {
  }

}
