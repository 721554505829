import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConsultationHealthEducationObject, ConsultationObject, ConsultationReferralObject, TestRequestedObject, UserConsultationReferralObject, UserHealthEducationObject } from 'src/app/shared/interfaces/consultationMapper';
import { AppointmentsService } from 'src/app/shared/services/appointments.service';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { DocumentService } from '../../../shared/services/document.service';
//TODO: Add logged in user profile her.
// import { KeycloakProfile } from 'keycloak-js';
import { TestPerformedObject } from 'src/app/shared/interfaces/responseMapper';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { CSS_POSITION } from '../../../shared/enums';

export interface ReferralElement {
  hasDocument: boolean;
  id: string;
  referralItem: string;
  providerTypeId: string;
  selected: boolean;
  active: boolean;
  referralDocumentId: string;
  consultationId: string;
  cro: UserConsultationReferralObject;
}

export interface HealthEducationElement {
  id: string;
  healthEducation: string;
  selected: boolean;
}





@Component({
  selector: 'app-close-out',
  templateUrl: './close-out.component.html',
  styleUrls: ['./close-out.component.scss']
})
export class CloseOutComponent implements OnInit {

  refDisplayedColumns: string[] = ['referralItem', 'requireDocument'];
  heDisplayedColumns: string[] = ['healthEducation'];

  animal: string;
  name: string;

  referralDatasource = new MatTableDataSource();
  healthEducationDatasource = new MatTableDataSource();

  referralItemArray: ReferralElement[] = [];
  healthEducationItemArray: HealthEducationElement[] = [];


  referralList: ConsultationReferralObject[];
  healthEducationList: ConsultationHealthEducationObject[];

  nurseInterventions = [
    { id: 0, name: 'Health education', checked: false },
    { id: 1, name: 'Follow up', checked: false },
    { id: 2, name: 'Referred', checked: false },
    { id: 3, name: 'Medication prescribed', checked: false },
    { id: 4, name: 'Medication administered on site', checked: false },
  ];

  cofOption = [
    { id: 0, name: 'Fit for duty as per job description ', follow: false },
    { id: 1, name: 'Fitness for duty - subject to further review', follow: false },
    { id: 2, name: 'Fit for duty subject to specific exclusions / job modifications', follow: true },
    { id: 3, name: 'Temporarily unfit for duty', follow: false },
    { id: 4, name: 'Unfit for duty', follow: false }
  ];

  cofFollowUp = {
    type: 'none',
    questions: [
      { name: '' }
    ]
  };

  isLoading = false;
  cof: string;
  allowEditing: boolean = true;
  documentString = '';
  cofFollow = [];
  option: any;
  consultationId = '';
  cofDocument = '';
  consultationDetail: ConsultationObject;
  consultationReferral: ConsultationReferralObject;
  consultationHealthEducation: ConsultationHealthEducationObject;
  referralsToSave: Map<string, boolean> = new Map();
  healthEducationsToSave: Map<string, boolean> = new Map();
  SavedReferrals: Map<string, UserConsultationReferralObject> = new Map();
  SavedhealthEducations: Map<string, boolean> = new Map();

  OHNPAllowed: boolean = false;
  OMPAllowed: boolean = false;
  finalizedAllowed: boolean = false;
  isFinalized: boolean = false;
  css_position: CSS_POSITION = CSS_POSITION.ABSOLUTE;
  // userProfile: KeycloakProfile;

  constructor(
    private documentService: DocumentService,
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    public stepper: MatStepper,
    public dialog: MatDialog,
    private appointmentLeads: AppointmentsService,
    private consultationWorkflowService: ConsultationWorkflowService,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit(): void {

    this.globalData.selectedClientVal.subscribe(result => {
      this.consultationId = result;
      this.consultationService.getConsultationDetail(result).subscribe((cd) => {
        this.consultationDetail = cd;
        this.isFinalized = this.consultationDetail.consultationWorkflowStatus == 5;
      });
    });
    //TODO: Add logged i user details here
    /*this.globalData.userProfileVal.subscribe(userProfile => {
      this.userProfile = userProfile
      this.globalData.userRolesVal.subscribe(roles => {
        this.consultationWorkflowService.getCurrentWorkflowStatus(this.consultationId).subscribe(workflow => {

          if (workflow.consultationWorkflowStatus >= 3) {
            this.OHNPAllowed = false;
            this.OMPAllowed = false;
            this.finalizedAllowed = false;

            if (workflow.consultationWorkflowStatus == 3 && (roles.includes("Perigon_OHNP"))) {
              this.OMPAllowed = true;
              this.finalizedAllowed = true;
            }

            if (workflow.consultationWorkflowStatus == 4 && (roles.includes("Perigon_OMP"))) {
              this.OHNPAllowed = true;
              this.finalizedAllowed = true;
            }

            if (workflow.consultationWorkflowStatus  == 5) {
              this.OMPAllowed = this.OHNPAllowed = this.finalizedAllowed = false;
            }
          }
          else {
            this.OHNPAllowed = true;
            this.OMPAllowed = true;
            this.finalizedAllowed = true;
          }

          this.documentService.getCOF(this.consultationId).subscribe(result => {
            if (result && result.document) {
              this.allowEditing = false;
              this.documentString = result.document;
            }
          });
          this.consultationService.getConsultationReferrals(this.consultationId).subscribe(result => {
            if (result) {
              this.consultationDetail = result;
              this.appointmentLeads.getListItems("Referral").subscribe(referralResult => {
                if (referralResult) {
                  this.referralList = referralResult.listItems;
                  var userReferralList = this.consultationDetail?.consultationReferrals;
                  this.referralList.forEach(rl => {
                    let cro = userReferralList?.find(t => t.providerTypeId == rl.id);
                    let referralElementItem = <ReferralElement>{};
                    referralElementItem.id = (cro || { id: null }).id;
                    referralElementItem.providerTypeId = rl.id;
                    referralElementItem.referralItem = rl.description;
                    referralElementItem.active = (cro || { active: false }).active;
                    referralElementItem.hasDocument = cro ? (cro.referralDocument ? cro.referralDocument.document != null : false) : false;
                    referralElementItem.cro = cro;
                    if (cro != undefined && cro != null) {

                      this.SavedReferrals.set(rl.id, cro);
                    }


                    this.referralItemArray.push(referralElementItem);
                  });
                  this.referralDatasource.data = this.referralItemArray;
                }
              });



              this.appointmentLeads.getListItems("Health Education").subscribe(HEresult => {
                if (HEresult) {
                  this.healthEducationList = HEresult.listItems;


                  var userHealthEducationList = this.consultationDetail?.consultationHealthEducations;

                  this.healthEducationList.forEach(hel => {

                    let healthEducationElementItem = <HealthEducationElement>{};
                    healthEducationElementItem.selected = false;

                    var uhel = userHealthEducationList?.find(t => t.listItemId == hel.id);
                    if (uhel != undefined) {

                      this.SavedhealthEducations.set(hel.id, false);
                      healthEducationElementItem.selected = true;
                    }

                    healthEducationElementItem.id = hel.id;
                    healthEducationElementItem.healthEducation = hel.description;

                    this.healthEducationItemArray.push(healthEducationElementItem);
                  });

                  this.healthEducationDatasource.data = this.healthEducationItemArray;
                }
              });
            }
          });
        });
      });
    });*/
  }

  downloadPdf(): void {
    this.snackBar.open('Downloading', null, { duration: 2500 });
    var base64String = this.documentString;
    var fileName = 'cof.pdf';
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  downloadReferralPdf(element): void {
    this.snackBar.open('Downloading', null, { duration: 2500 });
    var base64String = element.cro.referralDocument.document;
    var fileName = 'referral.pdf';
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  btnComplete_Click() {
    this.router.navigate(['/cof']);
  }

  btnSubmit_Click() {
    this.router.navigate(['/appointment']);
  }

  btnSumbitOHNP() {
    //TODO: Add logged in user details here
    this.consultationWorkflowService.setConsultationDetailWorkflow(this.consultationId, 3, /*this.userProfile.firstName + ' ' + this.userProfile.lastName*/'locum').subscribe((result) => {
      this.snackBar.open('Submitted', null, { duration: 2500 }).afterDismissed().subscribe(() => this.router.navigate(['/appointment']));
    });
  }


  btnSubmitOMP() {
    //TODO: Add logged in user details here
    this.consultationWorkflowService.setConsultationDetailWorkflow(this.consultationId, 4, /*this.userProfile.firstName + ' ' + this.userProfile.lastName*/'locum').subscribe((result) => {
      this.snackBar.open('Submitted', null, { duration: 2500 }).afterDismissed().subscribe(() => this.router.navigate(['/appointment']));
    });
  }

  referralSelectChange(event, referral: ReferralElement) {
    this.isLoading = true;

    let consultationReferral = <UserConsultationReferralObject>{};
    consultationReferral.id = referral.id;
    consultationReferral.consultationId = this.consultationId;
    consultationReferral.providerTypeId = referral.providerTypeId;
    consultationReferral.active = referral.active;
    consultationReferral.referralDocumentId = referral.referralDocumentId;
    this.consultationService.postConsultationReferral(consultationReferral).subscribe(cro => {
      referral.id = cro.id;
      referral.consultationId = cro.consultationId;
      referral.providerTypeId = cro.providerTypeId;
      referral.active = cro.active;
      referral.referralDocumentId = cro.referralDocumentId;
      referral.hasDocument = cro.referralDocument.document != null;
      this.isLoading = false;
    });

  }

  healtEducationSelectChange(event, listItemID) {
    if (event.checked == true) {
      this.healthEducationsToSave.set(listItemID, false);
    }
    else {
      this.healthEducationsToSave.delete(listItemID);
    }
  }

  btnSaveNotes_Click() {
    this.isLoading = true;
    this.consultationService.putConsultationDetail(this.consultationDetail).subscribe(r => {
      this.isLoading = false;
      this.snackBar.open('Saved', null, { duration: 2500 });
    });
  }

  btnSave_Click() {
    this.btnSaveNotes_Click();
    this.healthEducationsToSave.forEach((value: boolean, key: string) => {

      var existingHealthEducation = this.SavedhealthEducations.get(key);

      if (existingHealthEducation == undefined) {
        let consultationHealthEducation = <UserHealthEducationObject>{};
        consultationHealthEducation.consultationId = this.consultationId;
        consultationHealthEducation.listItemId = key;
        this.isLoading = true;
        this.consultationService.postConsultationHealthEducation(consultationHealthEducation).subscribe(result => {
          this.isLoading = false;
        });
      }

    });


  }

  btnRouteReferral_Click(cro) {
    this.isLoading = true;
    this.globalData.updateConsultationReferralId(cro.id);
    this.router.navigate(['/referral']);
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(CloseOutDialogComponent, {
      width: '550px'
    });
  }


  getFollowQuestions(): void {
    //console.log(this.cof);

    // console.log('Fit for duty subject to specific exclusions / job modifications');

    const isMatch = this.cof === 'Fit for duty subject to specific exclusions / job modifications' ? true : false;
    ///* console.log(isMatch) */;

    switch (this.cof) {
      case 'Fit for duty subject to specific exclusions / job modifications':
        ///* console.log('1') */;
        this.cofFollowUp = {
          type: 'multiple',
          questions: [
            { name: 'Noise zone' },
            { name: 'Respiratory risk zone' },
            { name: 'Heat stress zone' },
            { name: 'Driving – Codes (A1, A, B or EB)' },
            { name: 'Safety critical work' },
            { name: 'Driving – Codes (A1, A, B or EB)' },
            { name: 'Other' },
          ]
        };
        break;

      case 'Temporarily unfit for duty':
        ///* console.log('2') */;
        this.cofFollowUp = {
          type: 'single',
          questions: [
            { name: 'Non work related condition' },
            { name: 'Work related condition' }
          ]
        };
        break;

      case 'Unfit for duty':
        ///* console.log('3') */;
        this.cofFollowUp = {
          type: 'single',
          questions: [
            { name: 'Non work related condition' },
            { name: 'Work related condition' }
          ]
        };
        break;

      default:
        this.cofFollowUp = {
          type: 'none',
          questions: [
            { name: '' }
          ]
        };
    }
  }

  getValue(): void {
    //console.log(this.cofFollow);
  }

  goBack(step: MatStepper) {

    this.stepper.previous();
    let i = step.selectedIndex;
    this.globalData.updateSelectedStep(i);

    //this.currentStep = step.selectedIndex;
  }
}





/////////////////////////////////////////////////////////////////////////////////////////////////////////





@Component({
  selector: 'close-out-dialog',
  templateUrl: 'close-out-dialog.component.html',
})
export class CloseOutDialogComponent {
  consultationId: string;
  consultationDetail: ConsultationObject;
  //TODO: Add logged in user profile here
  // userProfile: KeycloakProfile;
  roles: any;
  ompFileReview: TestRequestedObject;
  testPerformed: TestPerformedObject;
  consultationWorkflowStatus?: number;
  route: string;

  constructor(
    public dialogRef: MatDialogRef<CloseOutDialogComponent>,
    private consultationService: ConsultationService,
    private globalData: GlobalDataService,
    private router: Router,
    private documentService: DocumentService,
    private consultationWorkflowService: ConsultationWorkflowService,
    private questionnaireService: QuestionnaireService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmitCLick() {
    //TODO: Add logged in user details here
    // this.globalData.userProfileVal.subscribe(userProfile => this.userProfile = userProfile);
    this.globalData.selectedClientVal.subscribe(result => this.consultationId = result);
    this.globalData.userRolesVal.subscribe(roles => this.roles = roles);

    this.consultationService.getConsultationDetail(this.consultationId).subscribe(result => {
      if (result) {
        this.consultationDetail = result;
        this.consultationWorkflowStatus = this.consultationDetail.consultationWorkflowStatus;
        this.route = this.consultationWorkflowStatus ? '/file-review' : '/appointment';
        this.consultationDetail.captureComplete = true;
        this.consultationDetail.changeDate = new Date();
        this.consultationDetail.captureCompleteName = "Perigon";
        this.consultationDetail.captureCompleteDate = new Date();
        this.consultationDetail.changeName = "Perigon";

        this.consultationService.putConsultationDetail(this.consultationDetail).subscribe(_ => {
          //TODO: Add logged in user details here
          this.consultationService.setConsultationDetailWorkflow(this.consultationId, 5, /*this.userProfile.firstName + ' ' + this.userProfile.lastName*/'locum').subscribe(_ => {
            this.dialogRef.close();
            this.router.navigate([this.route]);
          });
        });
      }
    });
  }
}
