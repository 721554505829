import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule } from "@angular/forms";
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { clinicianObject } from 'src/app/shared/interfaces/clinicianMapper';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ReportingService } from 'src/app/shared/services/reporting.service';

@Component({
  selector: 'app-wde-event-summary',
  templateUrl: './wde-event-summary.component.html',
  styleUrls: ['./wde-event-summary.component.scss']
})

export class AdhocWDEEventSummaryComponent implements OnInit {

  resultTypes = new FormControl();
  types: string[] = [];

  serviceProviders: any;

  errorMessage: string;
  userDateRange: boolean = true;

  selectedServiceProviderIds: string[];
  serviceProviderId: any;

  get serviceProviderIds() {
    if (this.isServiceProvider || this.isHealthPractitioner || this.isAdministrativeAssistant) {
      // Return the service provider ids for service provider, health practitioner, or administrative assistant
      // Replace the following line with your logic to retrieve the service provider ids
      return [this.serviceProviderId];
    } else {
      return this.selectedServiceProviderIds;
    }
  }

  startDate: any;
  endDate: any;
  coordinator: string;
  eventNames: string;

  filterValues: any = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['eventDate', 'employeeNumber', 'name', 'consents', 'tests'];

  word: string;
  filterSelectObj = [] as any;

  reportData: any = [];
  dateRangePicker: FormGroup;
  serviceProviderSelect: FormGroup;
  isLoading = false;
  showError = false;

  originalSearchData: any = [];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  clinician: clinicianObject;
  userRoles: string[];

  get userRole() {
    if (this.userRoles.indexOf('Administrator') > -1) {
      return 'Administrator';
    } else if (this.userRoles.indexOf('Coordinator') > -1) {
      return 'Coordinator';
    } else if (this.userRoles.indexOf('Health practitioner') > -1) {
      return 'Health practitioner';
    } else if (this.userRoles.indexOf('Service provider') > -1) {
      return 'Service provider';
    } else {
      return 'Administrative assistant';
    }
  }

  get isAdmin() {
    return this.userRole === 'Administrator';
  }

  get isCoordinator() {
    return this.userRole === 'Coordinator';
  }

  get isHealthPractitioner() {
    return this.userRole === 'Health practitioner';
  }

  get isServiceProvider() {
    return this.userRole === 'Service provider';
  }

  get isAdministrativeAssistant() {
    return this.userRole === 'Administrative assistant';
  }

  get useServiceProviderFilter() {
    return this.isAdmin || this.isCoordinator;
  }

  get dateRangeLimit() {
    if (this.isHealthPractitioner || this.isAdministrativeAssistant) {
      const today = new Date();
      today.setDate(today.getDate() - 60);
      return today;
    }
    return null;
  }

  get today() {
    return new Date();
  }


  constructor(
    public fb: FormBuilder,
    private router: Router,
    private globalData: GlobalDataService,
    private reportingService: ReportingService,
    private clinicService: ClinicService) {
    this.isLoading = true;

    this.globalData.updateBackRoute('/wellness-event/adhoc/landing');

    this.clinician = JSON.parse(localStorage.getItem("Clinician"));

    // Object to create Filter for
    this.filterSelectObj = [
      {
        name: 'SURNAME',
        columnProp: 'surname',
        options: []
      }
    ];


    const today = new Date();
    var lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    this.dateRangePicker = new FormGroup({
      dateRangeStart: new FormControl(null),
      dateRangeEnd: new FormControl(null)
    });

    this.serviceProviderSelect = new FormGroup({
      clinicSelect: new FormControl()
    });

    //this.globalData.userServiceProviderId$.subscribe(result => this.serviceProviderId = result);




  }

  ngOnInit() {
    this.isLoading = false;
    this.clinicService.getServiceProviders().subscribe((data) => {
      this.serviceProviders = data;
      this.isLoading = false;
    });
    this.globalData.userRolesVal.subscribe(ur => {
      this.userRoles = ur as string[];
    });

    this.globalData.userServiceProviderId$.subscribe(result => this.serviceProviderId = result);
    // this.listService.get(Enums.LISTS.PROVINCES).subscribe((data) => {
    //   this.provinces = data.listItems;
    //   this.provinces.sort((a, b) => a.description.localeCompare(b.description));
    // })
  }

  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj: any, key: any) {
    const uniqChk = [] as any;
    fullObj.filter((obj: any) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  // Called on Filter change
  filterChange(filter: any, event: any) {

    this.dataSource.filterPredicate = function (data: any, filter: any) {
      let result = false;
      let name = false;
      let status = false;
      let company = false;
      let type = false;

      if (filter.name) {
        if (data.name.toLowerCase().trim() == filter.name.toLowerCase().trim()) {
          name = true
        }
        else {
          name = false;
        }
      }
      else {
        name = true;
      }

      return name;
    }


    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    this.dataSource.filter = this.filterValues
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      ///* console.log(searchTerms) */;

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found

        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value: any, key: any) => {
      value.modelValue = undefined;
    })
    this.dataSource.filter = "";
    this.selectedServiceProviderIds = [];
  }

  selectOption(clId) {

    //this.serviceProviderIds = clId ? clId.value : clId;

    //this.globalData.updateAppointmentClinicId(clId ? clId.value : clId);
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.startDate = dateRangeStart.value;
    this.endDate = dateRangeEnd.value;
  }

  downloadReport() {
    this.isLoading = true;
    this.reportingService.downloadAdhocWDEEventSummary(this.serviceProviderIds, this.startDate, this.endDate).subscribe({
      next: data => {
        this.downloadExcel(data.fileData, data.fileName);
        this.isLoading = false;

      },
      error: error => {
        this.errorMessage = error.message;
        this.isLoading = false;
        this.showError = true;
        console.error('There was an error!', error);
      }
    });
  }

  processData(data) {

    //this.dataSource = data;
    if (data) {
      this.reportData = data;
      this.dataSource.data = data
      const remoteDummyData = []
      this.reportData.forEach(child => remoteDummyData.push(child));

      this.originalSearchData = this.dataSource;

      this.filterSelectObj.filter((o: any) => {
        o.options = this.getFilterObject(remoteDummyData, o.columnProp);
      });
    }
  }


  downloadExcel(base64String, fileName): void {
    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  previousPage() {
    console.log("Ping");
  }

  btnSearch_Click() {
    this.isLoading = true;
    this.reportingService.getAdhocWDEEventSummary(this.serviceProviderIds, this.startDate, this.endDate).subscribe({
      next: data => {
        this.processData(data);

        this.isLoading = false;
        ///////////////////////////////////////////////////////////////////////////
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 1000);
      },
      error: error => {
        this.errorMessage = error.message;
        this.isLoading = false;
        this.showError = true;
        console.error('There was an error!', error);
      }
    });


    // Overrride default filter behaviour of Material Datatable
    //this.dataSource.filterPredicate = this.createFilter();
    //this.resetFilters();


  }

  uniqueBy(arr, prop) {
    return arr.reduce((a, d) => {
      if (!a.includes(d[prop])) { a.push(d[prop]); }
      return a;
    }, []);
  }

}


