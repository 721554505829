<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false">
      <div class="grid__inner grid__inner--layout-12">
        <div class="grid__cell grid__cell--align-stretch motion-form-body mb-4 section-spacing--tier-1">
          <div class="mdc-card mdc-card__shadow-light">
            <div class="mdc-card__content">
              <span class="blank-line blank-l ine-2 d-block"></span>
              <h4 class="mdc-typography--headline5 text--align-center mb-3">Consent / refusal to have an HIV test</h4>
              <span class="blank-line blank-line-2 d-block"></span>
              <div>
                <span class="mdc-typography--body1">
                  <span>I, <u>{{consentMapper.member_FullName}}</u>, {{idOrPassport}}
                    <u>{{consentMapper.member_IdNumber}}</u>,
                    hereby request to have an HIV test. A finger-prick test will be done by a registered nurse. The
                    results from the HIV
                    finger-prick test will be made available to my associated medical aid. I understand that the results
                    will be kept
                    confidential, except for discourse of any non negative results to my medical aid for further
                    management and counselling.
                    I understand and accept counselling is done based on information I provide and that Momentum HRM
                    take
                    no responsibility if incomplete or untrue information is shared. I declare that I have been
                    counselled
                    prior to the test, that I received information about the test, and understand what the test is
                    about;
                    understand the purpose and benefit of the test; and the implications for my life if the test is
                    positive.</span>
                </span>
              </div>
              <div>
                <div>
                  <div class="button-height">
                    <mat-radio-group [ngClass]="{'red-border': showValidationErrors && consent == null}">
                      <mat-radio-button *ngFor="let l of agreeList" [value]="l.value" [checked]="l.value == consent"
                        [ngClass]="{'btn-red': !l.value, 'btn-green': l.value}" (change)="consent = $event.value">
                        {{l.name}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="form" matNativeControl>
            <div class="mdc-card mdc-card__shadow-light">
              <div class="mdc-card__content">
                <span class="blank-line blank-line-2 d-block"></span>
                <section id="consentPaperForm" *ngIf="!form.get('consentPaperForm').value">
                  <div>
                    <div>
                      <div class="sig-container" [ngClass]="{'red-border': sig1Border}" #sigContainer1>
                        <div fxLayout="row">
                          <button type="button" title="clear" class="mat-mini-fab" (click)="sigs.first.clear()"
                            [disabled]="!form.controls.signatureField1.valid">
                            <span class="mat-button-wrapper">
                              <i class="material-icons" role="img" aria-hidden="true">clear</i>
                            </span>
                          </button>
                        </div>
                        <div fxLayout="row">
                          <signature-field formControlName="signatureField1"></signature-field>
                        </div>
                      </div>
                    </div>
                    <mat-label>Signature of member</mat-label>
                  </div>
                  <div>
                    <div>
                      <div class="sig-container" [ngClass]="{'red-border': sig2Border}" #sigContainer2>
                        <div fxLayout="row">
                          <button type="button" title="clear" class="mat-mini-fab" (click)="sigs.last.clear()"
                            [disabled]="!form.controls.signatureField2.valid">
                            <span class="mat-button-wrapper">
                              <i class="material-icons" aria-label="Example icon-button with a delete icon" role="img"
                                aria-hidden="true">clear</i>
                            </span>
                          </button>
                        </div>
                        <div fxLayout="row">
                          <signature-field formControlName="signatureField2"></signature-field>
                        </div>
                      </div>
                    </div>
                    <mat-label>Signature of examiner</mat-label>
                  </div>
                </section>
              </div>
            </div>
          </form>
          <section class="grid__inner">
            <div class="mb-1">
              <span class="blank-line blank-line-2 d-block"></span>
            </div>
            <div class="grid__cell--span-all">
              <div class="grid__inner grid__inner--layout-b8b">
                <div class="grid__cell grid__cell--justify-center">
                  <div class="mdc-btn-container">
                    <button class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
                      (click)="submit(sectionOne)">Submit</button>
                  </div>
                  <div class="mdc-btn-container">
                    <mat-error class="text--align-center" *ngIf="showError">Please complete all required</mat-error>
                  </div>
                  <div class="mdc-btn-container">
                    <div class="grid__cell text--align-center">
                      <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                        (click)="back()">Back</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </article>
</div>
