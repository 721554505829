import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ListItemObject, ListObject } from '../../interfaces/questionnaireMapper';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'app-cancellation-dialog',
  templateUrl: './cancellation-dialog.component.html',
  styleUrls: ['./cancellation-dialog.component.scss']
})
export class CancellationDialogComponent implements OnInit {
  cancellationReasons: ListItemObject[] = [];



  form: FormGroup = new FormGroup({
    cancellationReason: new FormControl('', [Validators.required]),
    cancelledBy: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CancellationDialogComponent>,
    public listService: ListService
  ) { }

  ngOnInit() {
    this.listService.getById('49ACA9F1-F1F0-41E0-B4D3-5914014D9C88').subscribe((data: ListObject) => {
      this.cancellationReasons = data.listItems;
    });
  }

  onBack(): void {
    this.dialogRef.close();
  }

  confirmCancellation(): void {
    if (this.form.valid) {
      // You can use the form value to do something or pass it back to the opener
      this.dialogRef.close(this.form.value);
    }
  }
}
