import { ListItemObject } from "./questionnaireMapper";

export class BaseWellnessEventBookingForm {
  id: string; // event id
  eventDate: string; // datepicker
  eventStartTime: string; // datepicker
  eventEndTime: string; // datepicker
  shiftWorkerStartTime?: string; // datepicker
  shiftWorkerEndTime?: string; // datepicker
  address: string; // input
  specialLandmarks: string; // input
  dropOffFacilityDetails: string; // input etc. parking lot?
  amountOfEmployeesOnSite: number; // input
  amountOfExpectedParticipants: number; // input
  eventAtmosphereID: string;
  amountOfPrivateRoomsAvailable?: number; // input (if indoor)
  isVenueOpenPlan?: boolean; // input (if indoor)
  areGazebosRequisitioned: boolean; // input (if outdoor {could also be indoor for registration that happens outside})
  amountOfGazebos?: number; // input (if outdoor {could also be indoor for registration that happens outside})

  areNumberOfMobileUnitsRequired: boolean;


  numberOfMobileUnitsRequired?: number;


  specialRequirements?: string; // input (Biokenetist must also be on premises) -> could this be turned into a dropdown
  venueRestrictions?: string; // input -> see if we can come up with a definite list
  eventVenueDetail?: string;
  cancellationReasonId: string;
  cancelledBy: string;
  healthPractitionerTypeId: string[];
  numberOfHealthPractitioners: number;
  allocatedEquipment: string[];
  marketingCampaignDate: Date;
  setUpTimes: string;
  strikeDownTimes: string;
  serviceProviderId: string;
  eventSubmissionCoordinatorId: string;
  eventStatusId: string;

  siteCoordinatorFullName?: string;
  siteCoordinatorTelephone?: number;
  siteCoordinatorCellphone?: number;
  siteCoordinatorEmail?: string;

  serviceTypeID: string;
  wellnessDayEventType: number | null = null;
}

export class SAPSWellnessEventBookingForm extends BaseWellnessEventBookingForm {
  declare wellnessDayEventType: 1;

  provinceID: string;
  stationID: string;

  // SAPS site Coordinator
  siteCoordinatorRankId?: string;

  // SAPS event cooking coordinator
  provincialCoordinatorIdNumber?: number;
  provincialCoordinatorFULLNAME: string;
  provincialCoordinatorRANKID: string;
  provincialCoordinatorTELEPHONE?: number;
  provincialCoordinatorCELLPHONE: number;
  provincialCoordinatorEMAIL: string;
  provincialCoordinatorSIGNATURE?: any; // what type would signature be?
  provincialCoordinatorSignatureHardCopy: boolean;

  province?: ListItemObject | null;
  station?: ListItemObject | null;
}

export class StandardBasedWellnessEventBookingForm extends BaseWellnessEventBookingForm {

  companyName: string;
  companyDebtorCode: string;
  accountExecutiveId?: string;
  accountExecutive?: string;
  assessments: string[];
}

export class AdhocWellnessEventBookingForm extends StandardBasedWellnessEventBookingForm {
  declare wellnessDayEventType: 2;
}

export class CorporateWellnessEventBookingForm extends StandardBasedWellnessEventBookingForm {
  declare wellnessDayEventType: 3;
  accountExecutiveName: string;
  accountExecutiveSurname: string;
  accountExecutiveCellphone: string;
  accountExecutiveEmail: string;
  facilityManagerName: string;
  facilityManagerSurname: string;
  facilityManagerCellphone: string;
  facilityManagerEmail: string;
  teamLeaderFirstName: string;
  teamLeaderSurname: string;
  teamLeaderCellphone: string;
  teamLeaderEmail: string;
  companyAccountExecutiveName?: string;
  companyAccountExecutiveSurname?: string;
  companyAccountExecutiveCellphone?: string;
  companyAccountExecutiveEmail?: string;
  companyFacilityManagerName?: string;
  companyFacilityManagerSurname?: string;
  companyFacilityManagerCellphone?: string;
  companyFacilityManagerEmail?: string;
  amountOfExpectedSchemeParticipants?: number; // input
  amountOfExpectedNonSchemeParticipants?: number; // input
  paymentOptions: string[];
}
