import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import * as Enums from '../../shared/enums';
import { COFMapper } from '../../shared/interfaces/cofMapper';
import { BaseConsentMapper, WellnessConsentMapper, WellnessFLUConsentMapper, WellnessHivConsentMapper, WellnessPSAConsentMapper } from '../interfaces/consentMapper';
import { ConsultationObject, TestRequestedObject } from '../interfaces/consultationMapper';
import { VaccinationConsenFormMapper } from '../interfaces/vaccinationMapper';
import { GlobalDataService } from './global-data.service';

//TODO: Is Token needed here?
// import {KeycloakService} from 'keycloak-angular';




@Injectable({
  providedIn: 'root'
})
export class ConsultationService {
  postWellnessHIVConsent(consentMapper: WellnessHivConsentMapper): Observable<any> {

    /* console.log(body) */;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_WELLNESS_HIV_CONSENT, consentMapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  postWellnessPSAConsent(consentMapper: WellnessPSAConsentMapper): Observable<any> {

    /* console.log(body) */;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_WELLNESS_PSA_CONSENT, consentMapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  postWellnessFLUConsent(consentMapper: WellnessFLUConsentMapper): Observable<any> {

    /* console.log(body) */;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_WELLNESS_FLU_CONSENT, consentMapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  postWellnessPAPSMEARConsent(consentMapper: WellnessFLUConsentMapper): Observable<any> {

    /* console.log(body) */;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_WELLNESS_PAPSMEAR_CONSENT, consentMapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }

  resetConsultation(consultationId: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.RESET_CONSULTATION + consultationId, null, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to reset consultationt', undefined)));
  }

  getWellnessDayConsultation(eventId: String, patientId: String, clinicianId: String): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.WELLNESSDAY_CONSULTATION + eventId + "/" + patientId + "/" + clinicianId, null, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to reset consultationt', undefined)));
  }

  getAdhocWellnessDayConsultation(eventId: String, patientId: String, clinicianId: String): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.ADHOC_WELLNESSDAY_CONSULTATION + eventId + "/" + patientId + "/" + clinicianId, null, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to reset consultationt', undefined)));
  }

  getCorporateWellnessDayConsultation(eventId: String, patientId: String, clinicianId: String): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.CORPORATE_WELLNESSDAY_CONSULTATION + eventId + "/" + patientId + "/" + clinicianId, null, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to reset consultationt', undefined)));
  }

  postVacConsent(model: VaccinationConsenFormMapper): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_VACCONSENT, model, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable toget consultaiton detail', undefined)));
  }

  postCOF(model: COFMapper): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_COF, model, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get consultation detail', undefined)));
  }

  getConsultationReferrals(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_CONSULTATION_REFERRAL_LIST + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get consultation detail', undefined)));
  }

  getConsultationDetail(id): Observable<ConsultationObject> {

    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.CONSULTATION_DETAIL + id, this.httpOptions).pipe(
      tap(data => { console.log("Ping - detail", data) }),
      catchError(this.handleError('Unable to get consultation detail', undefined)));
  }

  getTestRequestedByWorkflowId(consultationId: String, workflowId: String): Observable<TestRequestedObject> {

    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_TESTREQUESTEDBYWORKFLOWID + '?consultationId=' + consultationId + '&workflowId=' + workflowId, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get test requested', undefined)));
  }

  getFileReviewRecords(fileReviewStatus: number, clinicId: string): Observable<ConsultationObject> {

    this.httpOptions = this.globalServ.getHttpOptions();

    let params = "";
    if (clinicId == null) {
      params = "?fileReviewStatus=" + fileReviewStatus;
    }
    else {
      params = "?fileReviewStatus=" + fileReviewStatus + "&clinicId=" + clinicId;
    }

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_FILEREVIEWRECORDS + params, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get file review records', undefined)));
  }


  setConsultationDetailWorkflow(consultationId: string, workflowStatus: number, userName: string, userID: string = null): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    let params = "?consultationId=" + consultationId + "&workflowStatus=" + workflowStatus + "&userName=" + userName;
    if (userID) {
      params += "&userID=" + userID;
    }

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.SET_CONSULTATION_DETAIL_WORKFLOW + params, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get file review records', undefined)));
  }

  getFileReviewRecordCount(fileReviewStatus: number, clinicId: string): Observable<number> {

    this.httpOptions = this.globalServ.getHttpOptions();

    let params = "";
    if (clinicId == null) {
      params = "?fileReviewStatus=" + fileReviewStatus;
    }
    else {
      params = "?fileReviewStatus=" + fileReviewStatus + "&clinicId=" + clinicId;
    }

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_FILEREVIEWRECORDCOUNT + params, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get file review record count', undefined)));
  }

  putConsultationDetail(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.put(Enums.API_PATH + Enums.API_PATHS.CONSULTATION_DETAIL, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to update consultation.', undefined)));
  }

  getSchedulingDetailByConsultationId(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.get(Enums.API_PATH + Enums.API_PATHS.GET_SCHEDULE_DETAILS + id, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get consultation detail', undefined)));
  }

  postConsultationReferral(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.CONSULTATION_REFERRAL, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to add consultation referrall.', undefined)));
  }

  postConsultationHealthEducation(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.CONSULTATION_HEALTH_EDUCATION, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to add consultation health Education.', undefined)));
  }


  postConsent(body): Observable<any> {

    /* console.log(body) */;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_CONSENT, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }


  postWellnessConsent(mapper: WellnessConsentMapper): Observable<any> {

    /* console.log(body) */;

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_WELLNESS_CONSENT, mapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  postAdhocWellnessConsent(mapper: WellnessConsentMapper): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_ADHOC_WELLNESS_CONSENT, mapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  postCorproateWellnessConsent(mapper: WellnessConsentMapper): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_CORPORATE_WELLNESS_CONSENT, mapper, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }

  postReferral(body): Observable<any> {

    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_REFERRAL, body, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to post.', undefined)));
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }
}
