<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <div id="loginForm">
    <article class="grid">
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <h1 class="mdc-typography--headline2">Login</h1><br /><br />
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>User email</mat-label>
                <input matInput type="text" formControlName="email" required>
                <mat-error *ngIf="loginForm.get('email').errors?.required">
                  Email is required.
                </mat-error>
                <mat-error *ngIf="loginForm.get('email').errors?.email">
                  Invalid email.
                </mat-error>
              </mat-form-field>
              <a style="color: grey;float: right !important;" [routerLink]="['/forgot-password']">Forgot
                password?</a>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>User password</mat-label>
                <input matInput [type]="passType" formControlName="password" required>
                <mat-error *ngIf="loginForm.get('password').invalid || loginForm.get('password').touched">
                  Password is required.
                </mat-error>
              </mat-form-field>
              <a (click)="hideShowPassword()" style="float:left">{{linkText}}</a>
            </div>
            <button mat-raised-button class="button" type="submit">
              Login
            </button>
          </form>
        </div>
        <div class="grid__cell">
          <mat-error *ngIf="invalidForm">
            Invalid form submitted. Please fill in all required fields.
          </mat-error>
        </div>
        <div class="grid__cell">
          <article class="grid">
            <div class="grid__inner grid__inner--layout-d4d" style="padding-left:10%">
              <div class="grid__cell">
                <p>Don't have account?
                  <!-- <a style="color: rgb(12, 12, 91) !important" [routerLink]="['/signup']">Sign up</a> -->
                  <a style="color: rgb(12, 12, 91) !important" [routerLink]="['/health-practitioner-signup']">Sign
                    up</a>
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </article>
  </div>
</div>