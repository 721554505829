import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { EventActivity } from '../../interfaces/eventActivity';
import { AdhocWellnessDayEventBookingService } from '../../services/adhoc-wellness-day-event-booking.service';

@Component({
  selector: 'app-adhoc-event-activity',
  templateUrl: './adhoc-event-activity.component.html',
  styleUrls: ['./adhoc-event-activity.component.scss']
})
export class AdhocEventActivityComponent implements OnInit {
  @ViewChild('createActivity') createActivity!: TemplateRef<any>
  @ViewChild('modifyActivity') modifyActivity!: TemplateRef<any>
  @ViewChild('cancelActivity') cancelActivity!: TemplateRef<any>
  @ViewChild('planActivity') planActivity!: TemplateRef<any>
  @ViewChild('executeActivity') executeActivity!: TemplateRef<any>


  @Input() eventId: string;
  activities: EventActivity[];

  constructor(private eventService: AdhocWellnessDayEventBookingService) { }

  ngOnInit(): void {
    this.eventService.getEventActivity(this.eventId).subscribe(activities => {
      this.activities = activities
    });
  }
  getActivityTemplate(activityType: string): TemplateRef<any> {
    switch (Number(activityType)) {
      case 0:
        return this.createActivity;
      case 1:
        return this.modifyActivity;
      case 2:
        return this.planActivity;
      case 3:
        return this.executeActivity;
      case 4:
        return this.cancelActivity;

      default:
        throw new Error(`Unknown activity type: ${activityType}`)
    }
  }

  doAction(tests: any): void {

  }

  doActionButton(action): void {

  }
}
