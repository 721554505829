import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GlobalDataService } from './global-data.service'
//TODO Is Tken needed here?
// import { KeycloakService } from 'keycloak-angular'

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  httpOptions: any = {}

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) { }
  // signUp(user: UserSignup): any {
  //   const headers = { 'Content-Type': 'application/json' };
  //   return this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.CREATE_USER_SIGNUP, user, { headers }).subscribe({
  //     next: data => {
  //       return data;
  //     },
  //     error: error => {
  //       return error;
  //     }
  //   })
  // }
  //change to proper return object
  // login(login: UserLogin): any {
  //   const headers = { 'Content-Type': 'application/json' };
  //   return this.http.post<any>(Enums.API_PATH + Enums.API_PATHS.CREATE_USER_AUTH, login, { headers }).subscribe({
  //     next: data => {
  //       return data;
  //     },
  //     error: error => {
  //       return error;
  //     }
  //   })
  // }
}
