
export class ArrayFunctions {
  static any<T>(predicate: (value: T, index: number, array: T[]) => boolean, thisArg?: any): boolean {
      var conditionMet: boolean = false;
      for (var i = 0; i < thisArg.length; i++) {
        if (predicate(thisArg[i], i, thisArg)) {
          conditionMet = true;
          break;
        }
      }
      return conditionMet;
  }

  static first<T>(predicate: (value: T, index: number, array: T[]) => boolean, thisArg?: Array<T>): T {
    if ( predicate ) {
      var obj = null;
      for (var i = 0; i < thisArg.length; i++) {
        var object = thisArg[i];
        if (predicate(object, i, thisArg)) {
          obj = thisArg[i];
          break;
        }
      }
      return obj;
    }
    else {
      return thisArg.length ? thisArg[0] : null;
    }
  }

  static except<T>(arr1: T[], arr2: T[]): T[] {
    var list: T[] = [];
    for (var i = 0; i < arr2.length; i++) {
      var val: T = arr2[i];
      var index = arr1.indexOf(val);
      if (index){
        list.push(arr1.splice(index, 1)[0]);
      }
    }
	  return arr1;
  }

  static all<T>(predicate: (value: T, index: number, array: T[]) => boolean, thisArg?: T[]): boolean {
    var conditionMet: boolean = true;
    if ( predicate ) {
        var obj: T = null;
        for (var i = 0; i < thisArg.length; i++) {
          var object = thisArg[i];
          if (!predicate(object, i, thisArg)) {
          conditionMet = false;
          break;
        }
      }
      return conditionMet;
    }
  }

 static distinct<T>(thisArg?: T[]): T[] {
    let distinct: T[] = [];
    for (var i = 0; i < thisArg.length; i++) {
      var object: T = thisArg[i];
      if (distinct.indexOf(object) == -1) {
        distinct.push(object);
      }
    }
    return distinct;
 }

 static sum<T>(predicate :(value: T, index: number, array: T[]) => any, thisArg?: T[]): any {
   if ( predicate ) {
    var sum = 0;
    for (var i = 0; i < thisArg.length; i++) {
      var object: T = thisArg[i];
      var value = predicate(object, i, thisArg);
      var numValue = isNaN(value) ? null : value;
      if (numValue !== 0 && !numValue) {
        throw new Error("Cannot sum undefined values. Not all values are numbers.");
      }
      sum += numValue;
    }
    return sum;
  }
 }

 static average<T>(predicate :(value: T, index: number, array: T[]) => any, thisArg?: T[]): any {
   if ( predicate ) {
    var sum = this.sum<T>(predicate, thisArg);
    return thisArg.length ? (sum / thisArg.length) : 0;
   }
}

// Array.prototype.contains = function (object) {
// 	var containsObj = false;
// 	for (var i = 0; i < this.length; i++) {
// 		var uObj = this[i];
// 		var equal = JSON.stringify(object) === JSON.stringify(uObj)
// 		if (equal) {
// 			containsObj = true;
// 			break;
// 		}
// 	}
// 	return containsObj;
// };

// Array.prototype.exists = function (object) {
// 	var containsObj = false;
// 	for (var i = 0; i < this.length; i++) {
// 		var uObj = this[i];
// 		if (object === uObj) {
// 			containsObj = true;
// 			break;
// 		}
// 	}
// 	return containsObj;
// };

// Array.prototype.where = function (predicate) {
// 	/// <returns value="this[0]"></returns>
// 	if (predicate) {
// 		/// <var value="this[0]"></var>
// 		var obj = [];
// 		for (var i = 0; i < this.length; i++) {
// 			var object = this[i];
// 			if (predicate.call(this, object, i)) {
// 				obj.push(this[i]);
// 			}
// 		}
// 		return obj;
// 	}
// 	else {
// 		return this.length ? this : null;
// 	}
// };

// Array.prototype.last = function (predicate) {
// 	var results = predicate ? this.filter(predicate) : this;
// 	return results.length ? results[results.length - 1] : null;
// };

// Array.prototype.skip = function (count) {
// 	return this.slice((this.length - count) * -1);
// };

// Array.prototype.max = function (predicate) {
// 	var max = null;
// 	for (var i = 0; i < this.length; i++) {
// 		var object = this[i];
// 		var value = predicate ? predicate.call( this, object, i ) : object;
// 		if ( value.constructor == Date ) {
// 			if ( !max ) {
// 				max = max || value;
// 			}
// 			max = max < value ? value : max;
// 		}
// 		else if (!isNaN(value)) {
// 			max = max !== 0 ? (max || value) : max;
// 			max = max < value ? value : max;
// 		}
// 	}
// 	return max;
// };

// Array.prototype.min = function (predicate) {
// 	var min = null;
// 	for (var i = 0; i < this.length; i++) {
// 		var object = this[i];
// 		var value = predicate ? predicate.call( this, object, i ) : object;
// 		if ( value.constructor == Date ) {
// 			if ( !min ) {
// 				min = min || value;
// 			}
// 			min = min > value ? value : min;
// 		}
// 		else if (!isNaN(value)) {
// 			min = min !== 0 ? (min || value) : min;
// 			min = min > value ? value : min;
// 		}
// 	}
// 	return min;
// };

// Array.prototype.trim = function () {
// 	return this.filter(function (s) {
// 		return (s === 0 || s) ? true : false;
// 	});
// };

// Array.prototype.sortBy = function (predicate) {
// 	var self = this;
// 	return self.sort(function (left, right) {
// 		var l = predicate.call(self, left, null);
// 		var propType = l.__proto__;
// 		var r = predicate.call(self, right, null);
// 		var result;
// 		var error = false;
// 		switch (propType) {
// 			case Object.prototype: {
// 				error = true;
// 				result = "Could not apply sort to complex object " + l.toString() + ".";
// 				break;
// 			}
// 			case Date.prototype:
// 			case Number.prototype: {
// 				result = (l > r
// 					? 1
// 					: (l == r
// 						? 0
// 						: -1));
// 				break;
// 			}
// 			case String.prototype: {
// 				result = l.toLowerCase().localeCompare(r.toLowerCase());
// 				break;
// 			}
// 		}

// 		if (error) {
// 			throw new Error(result);
// 		}

// 		return result;
// 	});
// };

// Array.prototype.sortByReverse = function (predicate) {
// }
}