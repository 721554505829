export interface ConsentMapper {
  details_FullName: string;
  details_IDNumber: string;
  exam_Consent: boolean;
  exam_Client_Signature: string
  exam_Client_DateSignature: Date;
  exam_Witness_Designation: string;
  exam_Witness_Surname: string;
  exam_Witness_Initials: string;
  exam_Witness_Signature: string;
  exam_Witness_DateSignature: Date;
  data_Consent: boolean;
  data_Client_Signature: string;
  data_Client_DateSignature: Date;
}

export class BaseConsentMapper<T> {
  consultationDetailId: string;
  member_FullName: string;
  member_IdNumberLabel?: string;
  member_IdNumber: string;
  member_Signature: string;
  member_SignatureDate: Date;
  consent: T;
  examiner_Signature: string;
  examiner_SignatureDate: Date;
  testPerformedId: string;
}

export interface WellnessConsentMapper {
  consultationDetailId: string;
  member_FullName: string;
  member_IdNumber: string;
  member_IdNumberLabel?: string;
  member_Signature: string;
  member_SignatureDate: Date;
  consents: number;
  examiner_Signature: string;
  examiner_SignatureDate: Date;
}

export class WellnessHivConsentMapper extends BaseConsentMapper<boolean> {

}
export class WellnessPSAConsentMapper extends BaseConsentMapper<boolean> {

}
export class WellnessFLUConsentMapper extends BaseConsentMapper<boolean> {

}


export interface ReferralMapper {

  id: string,
  referralAccountPaymentType: string;
  detailsDate?: Date;
  detailsDepartment?: string;
  detailsEmployeeNumber: string;
  detailsIDNumber: string;
  detailsName: string;
  detailsSignature?: string;
  detailsDateSignature?: Date;
  detailsFollowUpDate?: Date;
  detailsSite?: string;
  detailsSurname: string;
  detailsTo?: string;
  examinerCommenceDutyDate?: Date;
  examinerEmail?: string;
  examinerFindings?: string;
  examinerFollowUpDate?: Date;
  examinerName?: string;
  examinerSignature?: string;
  examinerTel?: string;
  nurseDate?: Date;
  nurseEmail?: string;
  nurseFindings?: string;
  nurseName?: string;
  nurseSignature?: string;
  nurseTel?: string;
  active: boolean,
  createName: string,
  createDate: Date,
  changeName?: string,
  changeDate?: Date,
  document: string
}
