export class ClinicianRegistration {
  name: string;
  surname: string;
  idNumber: string;
  contactNumber: string;
  email: string;
  PracticeName: string;
  PracticeNumber: string;
  serviceProviderId: string;
}

export class HealthPractitionerRegistration extends ClinicianRegistration {
  certType: string;
  certNumber: string;
  certification: string;
  certDiscretion: boolean;
  Role: string = 'Health practitioner';
}

export class AdminAssistantRegistration extends ClinicianRegistration {
  Role: string = 'Admin assistant';
}
