import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WellnessPSAConsentMapper } from 'src/app/shared/interfaces/consentMapper';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-corporate-wellness-pap-smear-consent',
  templateUrl: './pap-smear.component.html',
  styleUrls: ['./pap-smear.component.scss']
})
export class CorporateWellnessPAPSmearConsentComponent implements OnInit {

  isLoading: boolean = false;
  consent: boolean = null;
  clientId: string = null;


  constructor(private route: ActivatedRoute, public consultationService: ConsultationService, private router: Router, private globalData: GlobalDataService
  ) {

  }

  consentMapper: WellnessPSAConsentMapper;

  ngOnInit(): void {
    this.globalData.selectedClientVal.subscribe(result => this.clientId = result);
    this.consultationService.getConsultationDetail(this.clientId).subscribe(result => {
      /* console.log(result) */;
      this.consentMapper = {
        member_FullName: `${(result.patientDetail.fullNames || '')} ${(result.patientDetail.surname || '')}`,
        member_IdNumber: result.patientDetail.rsaidnumber || result.patientDetail.passportNo,
        member_IdNumberLabel: result.patientDetail.rsaidnumber && result.patientDetail.rsaidnumber.length > 10 ? " RSA ID Number" : " Passport Number",
        consultationDetailId: this.clientId,
        member_Signature: undefined,
        member_SignatureDate: undefined,
        examiner_Signature: undefined,
        examiner_SignatureDate: undefined,
        testPerformedId: undefined,
        consent: this.consent
      }
      this.isLoading = false;
    });
  }

  postSubmit(data) {
    this.isLoading = true;
    this.consultationService.postWellnessPAPSMEARConsent(data).subscribe(result => {
      this.isLoading = false;
      this.router.navigate(["../consultation"], { relativeTo: this.route })
    })
  }

}
