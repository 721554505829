<div class="container">
  <article class="grid">
    <ng-container *ngIf="isLoading === true">
      <span class="blank-line blank-line-4 d-block"></span>
      <three-bar-loader></three-bar-loader>
    </ng-container>
    <div class="grid__inner grid__inner--layout-b8b" *ngIf="isLoading === false">
      <div class="grid__cell grid__cell--align-center grid__cell--span-all">
        <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field">
          <mat-select formControlName="questionnaireSelection" placeholder="Select applicable questionnaire" required
            (selectionChange)="questionnaireSelection($event)">
            <mat-option *ngFor="let questionnaire of questionnaireList"
              [value]="questionnaire.id">{{questionnaire.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="display: none" appearance="legacy"
          class="whitneyFontClass motion-form-field unsetMarginPadding" hideRequiredMarker>
          <input matInput name="inputId" ngModel #inputControl="ngModel" placeholder="Questionnaire Id" type="text"
            maxlength="36" minlength="36" [(ngModel)]="inputId">
        </mat-form-field>
        <div class="mdc-btn-container">
          <input type="button" class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--medium"
            (click)="previewOrClear()" [value]="!questionnaireId ? 'Preview' : 'Clear'">
        </div>
        <div class="grid__cell grid__cell--align-center grid__cell--span-all" *ngIf="questionnaireId">
          <app-q [consultationId]="null" [questionnaireId]="questionnaireId" [reviewId]="null" [testRequested]="null"
            [consultationSelection]="'Questionnaire'" [showBackButton]="false" [allowSubmit]="false"
            [forcedExaminationStatus]="0" (onSaved)="onSaved()" (onBackPressed)="null">
          </app-q>
          <!--  <app-q
                  [consultationId]="null"
                  [questionnaireId]="questionnaireId"
                  [reviewId]="null"
                  [testRequested]="null"
          [consultationSelection]="'Questionnaire'"
          [showBackButton]="false"
          [allowSubmit]="false"
          [forcedExaminationStatus]="0"
          (onSaved)="null"
          (onBackPressed)="null">
        </app-q>-->
        </div>
      </div>
    </div>
  </article>
</div>
