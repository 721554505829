<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <app-consent-base (submitConsentMapper)="postSubmit($event)" [consent]="consentMapper">
      <!-- ADD A TWO-WAY BINDING -->
      <span class="blank-line blank-l ine-2 d-block"></span>
      <h4 class="mdc-typography--headline5 text--align-center mb-3">Consent / Refusal to have a Pap smear test</h4>
      <span class="blank-line blank-line-2 d-block"></span>
      <div>
        <span class="mdc-typography--body1">
          <span>I, <u>{{consentMapper?.member_FullName}}</u>, {{consentMapper?.member_IdNumberLabel}}
            <u>{{consentMapper?.member_IdNumber}}</u>,
            hereby request to have a Pap smear test. The test will be done by a registered nurse. I understand that the
            results will
            be kept confidential, except for discourse of any positive results to my medical aid for further management
            and
            counselling. I understand and accept counselling is done based on information I provide and that Momentum
            Metropolitan
            Holdings Limited takes no responsibility if incomplete or untrue information is shared.<br /><br />
            I declare that I have been counselled prior to the test, I received information about the test and I
            understand what the
            test is about. I also understand the purpose and benefit of the test and the implications for my life if the
            test is
            positive. I understand that false positives can occur and that early detections are curable<br /><br />
            I was informed that these are requirements for the test to be performed
            <ul>
              <li>Not have vaginal sex for two days before your exam.</li>
              <li>Not use tampons, vaginal creams or medicines, birth control foams or jellies, lubricants or</li>
              <li>Douches for at least two days before the test.</li>
              <li>Not having a period at the day of the test</li>
            </ul>
            <br /><br />
            <b>I hereby CONSENT to have the Pap smear test</b><br />
          </span>
        </span>
      </div>
    </app-consent-base>
  </article>
</div>